import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation, Link } from 'react-router-dom';

import { ReactComponent as MabelLogo } from '../images/Mabel_Logo_White_RGB.svg';
import { mabel_dark_blue } from '../colors';

import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  ModalProps,
  Typography,
} from 'antd';
import {
  ArrowLeftOutlined,
  LockOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { Auth } from 'aws-amplify';
import { CognitoUser, ChallengeName } from 'amazon-cognito-identity-js';
import { useRequest } from 'ahooks';
import useUrlState from '@ahooksjs/use-url-state';
import { useForm } from 'antd/lib/form/Form';

type LoginPageState = {
  method?: 'password' | 'forgot';
  username?: string;
  code?: string;
};

const LoginPage: React.FC = () => {
  const [urlState, setUrlState] = useUrlState<LoginPageState>(
    {
      method: 'password',
      username: undefined,
      code: undefined,
    },
    { navigateMode: 'replace' }
  );
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    // On login page load, check if user is already logged in, and if so redirect
    Auth.currentSession()
      .then((user) => history.replace(from))
      .catch((err) => {});
  }, [history, from]);

  const [isMFAModalVisible, setIsMFAModalVisible] = useState(false);

  const signInUser = (email: string, pass: string) => {
    return new Promise<CognitoUser>(async (resolve, reject) => {
      try {
        const user = await Auth.signIn(email, pass);
        if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          setIsMFAModalVisible(true);
        } else if (!user.challengeName) {
          await Auth.currentSession();
          history.replace(from);
        }
        resolve(user as CognitoUser);
      } catch (err) {
        reject(err);
      }
    });
  };

  const signInWithPassword = useRequest(signInUser, {
    manual: true,
    // onSuccess: (data, params) => history.replace(from),
  });
  const initForgotPassword = useRequest((email) => Auth.forgotPassword(email), {
    manual: true,
  });
  const completeForgotPassword = useRequest(
    (username, code, pass) => Auth.forgotPasswordSubmit(username, code, pass),
    {
      manual: true,
      onSuccess: (data, [username, code, pass]) =>
        signInWithPassword.run(username, pass),
    }
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: mabel_dark_blue,
      }}
    >
      <Helmet>
        <title>Login | Mabel</title>
      </Helmet>
      <div style={{ width: '60%', maxWidth: 400, margin: 36 }}>
        <MabelLogo />
      </div>
      <div style={{ padding: 32, backgroundColor: 'white', borderRadius: 8 }}>
        {urlState.method === 'forgot' && !urlState.code ? (
          <Form
            name="password_reset"
            layout="vertical"
            requiredMark={false}
            style={{ padding: 16 }}
            onFinish={(values) => initForgotPassword.run(values.email)}
          >
            <Form.Item>
              <Typography.Title level={4}>Reset your password</Typography.Title>
            </Form.Item>
            <Form.Item
              name="email"
              label="Email Address"
              validateTrigger="onBlur"
              rules={[
                { required: true, message: 'Email required' },
                {
                  type: 'email',
                  message: 'Please input a valid email address',
                },
              ]}
            >
              <Input
                prefix={<MailOutlined />}
                placeholder="Email"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Link to="/login">
                <Button type="link" icon={<ArrowLeftOutlined />}>
                  Back to login
                </Button>
              </Link>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={initForgotPassword.loading}
              >
                Send reset link
              </Button>
            </Form.Item>
            {initForgotPassword.error && (
              <Alert
                type="error"
                message={initForgotPassword.error.message}
                showIcon
              />
            )}
            {initForgotPassword.data && (
              <Alert
                type="success"
                message="Please click the link in your email"
                showIcon
              />
            )}
          </Form>
        ) : urlState.method === 'forgot' && urlState.code ? (
          <Form
            name="password_reset_complete"
            layout="vertical"
            requiredMark={false}
            style={{ padding: 16 }}
            validateTrigger="onBlur"
            onFinish={(values) =>
              completeForgotPassword.run(
                urlState.username,
                urlState.code,
                values.password
              )
            }
          >
            <Form.Item>
              <Typography.Title level={4}>
                Finish changing your password
              </Typography.Title>
            </Form.Item>
            <Form.Item
              name="password"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: 'Please input a new password',
                },
                {
                  min: 10,
                  message: 'Password must be at least 10 characters',
                },
                {
                  pattern: /(?=.*[0-9])/,
                  message: 'Password must contain at least one digit 0-9',
                },
                {
                  //eslint-disable-next-line
                  pattern: /(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])/,
                  message:
                    'Password must contain at least one special character',
                },
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: 'Please re-enter the new password',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('The passwords do not match')
                    );
                  },
                }),
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item>
              <Link to="/login">
                <Button type="link" icon={<ArrowLeftOutlined />}>
                  Back to login
                </Button>
              </Link>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={
                  completeForgotPassword.loading || signInWithPassword.loading
                }
              >
                Change password
              </Button>
            </Form.Item>
            {completeForgotPassword.error && (
              <Alert
                type="error"
                message={completeForgotPassword.error.message}
                showIcon
              />
            )}
          </Form>
        ) : (
          <Form
            name="password_login"
            layout="vertical"
            requiredMark={false}
            style={{ padding: 16 }}
            onFinish={(values) =>
              signInWithPassword.run(values.email, values.password)
            }
          >
            <Form.Item
              name="email"
              label="Email Address"
              validateTrigger="onBlur"
              rules={[
                { required: true, message: 'Email required' },
                {
                  type: 'email',
                  message: 'Please input a valid email address',
                },
              ]}
            >
              <Input
                prefix={<MailOutlined />}
                placeholder="Email"
                size="large"
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Password required' }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Typography.Text type="secondary">
                Forgot your password?{' '}
              </Typography.Text>
              <Button
                type="link"
                onClick={() => setUrlState({ method: 'forgot' })}
              >
                Reset password
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={signInWithPassword.loading}
                block
              >
                Log in
              </Button>
            </Form.Item>
            {signInWithPassword.error && (
              <Alert
                type="error"
                message={signInWithPassword.error.message}
                showIcon
              />
            )}
          </Form>
        )}
      </div>
      {signInWithPassword.data && (
        <MFAConfirmModal
          user={signInWithPassword.data}
          mfaType={signInWithPassword.data.challengeName}
          from={from}
          modalProps={{
            visible: isMFAModalVisible,
            onCancel: () => setIsMFAModalVisible(false),
          }}
        />
      )}
      <div style={{ color: 'white', paddingTop: 8 }}>
        By continuing, you are indicating that you accept our{' '}
        <a href="https://getmabel.com/legal/terms">terms of service</a>.
      </div>
    </div>
  );
};

const MFAConfirmModal: React.FC<{
  user: any;
  mfaType?: ChallengeName;
  from: Location;
  modalProps: ModalProps;
}> = ({ user, mfaType, from, modalProps }) => {
  const [form] = useForm();
  const history = useHistory();

  const confirmMFACode = async (
    user: any,
    code: string,
    mfaType?: ChallengeName
  ) => {
    try {
      if (mfaType !== 'SOFTWARE_TOKEN_MFA') {
        throw new Error(`${mfaType} not supported`);
      }
      await Auth.confirmSignIn(user, code, mfaType);
    } catch (err) {
      throw err;
    }
  };

  const { run, reset, loading, error } = useRequest(confirmMFACode, {
    manual: true,
    onSuccess: (data, params) => history.replace(from),
  });

  return (
    <Modal
      {...modalProps}
      title="Multi-Factor Authentication"
      okText="Submit"
      centered
      maskClosable={false}
      confirmLoading={loading}
      onOk={() => form.submit()}
      onCancel={(e) => {
        reset();
        form.resetFields();
        modalProps.onCancel?.(e);
      }}
    >
      <p>Enter an MFA code to complete sign-in:</p>
      <Form
        form={form}
        validateTrigger="onBlur"
        onFinish={({ code }) => run(user, code, mfaType)}
      >
        <Form.Item
          name="code"
          rules={[
            { required: true, message: 'Please input the code' },
            { pattern: /^\d+$/, message: 'Invalid code format' },
            { min: 6, message: 'Code must be at least 6 digits' },
          ]}
        >
          <Input placeholder="123456" />
        </Form.Item>
      </Form>
      {error && (
        <Typography.Text type="danger">Error: {error.message}</Typography.Text>
      )}
    </Modal>
  );
};

export default LoginPage;
