import React, { useState, useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Form, Input, Modal, Button } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import {
  OrganizationDetailsFragment,
  SearchOrganizationsDocument,
  ListOrganizationsDocument,
  useUpdateOrganizationMutation,
  useCreateOrganizationMutation,
} from '../../graphql/generated';
import { getOperationAST } from 'graphql';
import { NetworkSearchSelect } from '../autocompletes';

interface OrganizationFormProps extends FormProps {
  initialOrganization?: OrganizationDetailsFragment;
  updateMutation?: ReturnType<typeof useUpdateOrganizationMutation>[0];
  createMutation?: ReturnType<typeof useCreateOrganizationMutation>[0];
  showSubmit?: boolean;
  haveValuesChanged?: boolean;
  setHaveValuesChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrganizationForm: React.FC<OrganizationFormProps> = ({
  form,
  initialOrganization,
  updateMutation,
  createMutation,
  showSubmit,
  haveValuesChanged,
  setHaveValuesChanged,
  ...rest
}) => {
  const [formInternal] = useForm(form);
  const [
    updateMutationInternal,
    updateMutationInternalResults,
  ] = useUpdateOrganizationMutation();
  const [
    createMutationInternal,
    createMutationInternalResults,
  ] = useCreateOrganizationMutation();

  // If an initial organization is given, this populates the form
  const recordToFormVals = ({
    dashboards,
    ...rest
  }: OrganizationDetailsFragment) => {
    return {
      ...rest,
      dashboards: JSON.stringify(dashboards),
      organization_networks: rest.organization_networks
        .filter((on) => !on.is_archived && !on.network.is_archived)
        .map((on) => {
          return {
            value: on.network.network_id,
            label: on.network.network_name,
          };
        }),
    };
  };
  useEffect(() => {
    if (!initialOrganization) {
      formInternal.setFieldsValue({ organization_id: uuidv4() });
    } else {
      formInternal.resetFields();
      formInternal.setFieldsValue(recordToFormVals(initialOrganization));
    }
  }, [formInternal, initialOrganization]);

  // Takes the form vals and puts them into right format for writing
  const formValsToRecordChanges = (formValues: Store) => {
    const { dashboards, organization_networks, ...rest } = formValues;

    // Check that dashboard is valid JSON
    let parsed_dashboards = [];
    try {
      parsed_dashboards = JSON.parse(dashboards);
    } catch (e) {}
    const new_obj = {
      dashboards: parsed_dashboards,
      ...rest,
    };

    const changes = initialOrganization
      ? Object.fromEntries(
          Object.entries(new_obj).filter(
            ([k, v]) => (initialOrganization as any)[k] !== v
          )
        )
      : rest;
    return changes;
  };
  const formValsToNetworkChanges = (formValues: Store) => {
    const old_non_archived_networks = new Set(
      (initialOrganization?.organization_networks || [])
        .filter((on) => !on.is_archived)
        .map((on) => on.network.network_id)
    );
    const current_networks = new Set<string>(
      (formValues.organization_networks || []).map((p: any) => p.value)
    );
    const deleted_networks = [...old_non_archived_networks].filter(
      (n) => !current_networks.has(n)
    );
    const added_networks = [...current_networks].filter(
      (k) => !old_non_archived_networks.has(k)
    );

    const updated_networks = [
      ...deleted_networks.map((n) => {
        return {
          organization_id: initialOrganization?.organization_id,
          network_id: n,
          is_archived: true,
        };
      }),
      ...added_networks.map((n) => {
        return {
          organization_id: initialOrganization?.organization_id,
          network_id: n,
          is_archived: false,
        };
      }),
    ];
    return updated_networks;
  };

  const valuesHaveChanged = (formValues: Store) => {
    return (
      Object.keys(formValsToRecordChanges(formValues)).length > 0 ||
      Object.keys(formValsToNetworkChanges(formValues)).length > 0
    );
  };

  const handleInsert = (values: any) => {
    const organization = {
      ...formValsToRecordChanges(values),
      organization_networks: {
        data: formValsToNetworkChanges(values),
      },
    };
    (createMutation || createMutationInternal)({
      variables: { organization: organization },
      refetchQueries: [
        // This just gets the name of the query to refresh.
        // By passing it as just a string, we force a refresh of *all*
        // calls made by the query, regardless of variable values
        // This is useful since we're fetching pages individually, and
        // after inserting a new document then any page might have changed
        getOperationAST(SearchOrganizationsDocument)?.name?.value || '',
        getOperationAST(ListOrganizationsDocument)?.name?.value || '',
      ],
    });
  };

  const handleUpdate = (values: any) => {
    if (!initialOrganization) {
      return;
    }
    const changes = formValsToRecordChanges(values);
    (updateMutation || updateMutationInternal)({
      variables: {
        organization_id: initialOrganization.organization_id,
        changes: Object.keys(changes).length ? changes : undefined,
        organization_networks: formValsToNetworkChanges(values),
      },
    });
    if (setHaveValuesChanged) setHaveValuesChanged(false);
  };
  return (
    <Form
      {...rest}
      form={formInternal}
      labelCol={rest.labelCol || { span: 6 }}
      wrapperCol={rest.wrapperCol || { span: 18 }}
      onValuesChange={(_, allValues) =>
        setHaveValuesChanged
          ? setHaveValuesChanged(valuesHaveChanged(allValues))
          : null
      }
      onFinish={initialOrganization ? handleUpdate : handleInsert}
    >
      <Form.Item
        label="Organization Name"
        name="organization_name"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please provide a organization name',
          },
        ]}
      >
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item label="Description" name="organization_description">
        <Input placeholder="description" />
      </Form.Item>
      <Form.Item
        label="Dashboards"
        name="dashboards"
        rules={
          //check that the dashboards are valid json
          [
            {
              validator: (_, value) => {
                try {
                  // check that value is a valid JSON array
                  if (
                    value !== undefined &&
                    !Array.isArray(JSON.parse(value))
                  ) {
                    return Promise.reject('Must be a JSON array');
                  }
                  return Promise.resolve();
                } catch (e) {
                  return Promise.reject('Invalid JSON');
                }
              },
            },
          ]
        }
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Networks"
        name="organization_networks"
        key="organization_networks"
      >
        <NetworkSearchSelect
          mode="multiple"
          onChange={(value) => {
            form?.setFieldsValue({ organization_networks: value });
          }}
        />
      </Form.Item>
      <Form.Item name="organization_id" hidden={true}></Form.Item>

      {showSubmit ? (
        <Form.Item style={{ marginTop: 12 }} wrapperCol={{ offset: 6 }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!haveValuesChanged}
            loading={
              createMutationInternalResults.loading ||
              updateMutationInternalResults.loading
            }
          >
            Save
          </Button>
        </Form.Item>
      ) : null}
    </Form>
  );
};

interface OrganizationMutationModalProps {
  formName: string;
  initialRecord?: OrganizationDetailsFragment;
  visible: boolean;
  onCancel?: (() => void) | undefined;
  afterSubmit?: (() => void) | undefined;
}

const OrganizationMutationModal: React.FC<OrganizationMutationModalProps> = ({
  initialRecord,
  visible,
  onCancel,
  afterSubmit,
}) => {
  const [form] = useForm();
  const [updateMutation, updateMutationResult] = useUpdateOrganizationMutation({
    onCompleted: () => {
      form.resetFields();
      if (afterSubmit) afterSubmit();
    },
  });
  const [createMutation, createMutationResult] = useCreateOrganizationMutation({
    onCompleted: () => {
      form.resetFields();
      if (afterSubmit) afterSubmit();
    },
  });

  const [haveValuesChanged, setHaveValuesChanged] = useState(false);

  return (
    <Modal
      title={initialRecord ? 'Update Organization' : 'Create Organization'}
      okText={initialRecord ? 'Update Organization' : 'Create Organization'}
      okButtonProps={{ disabled: initialRecord && !haveValuesChanged }}
      visible={visible}
      onCancel={onCancel}
      onOk={(e) => form.submit()}
      confirmLoading={
        updateMutationResult.loading || createMutationResult.loading
      }
      width={660}
    >
      <OrganizationForm
        form={form}
        initialOrganization={initialRecord}
        updateMutation={updateMutation}
        createMutation={createMutation}
        haveValuesChanged={haveValuesChanged}
        setHaveValuesChanged={setHaveValuesChanged}
      />
    </Modal>
  );
};

export { OrganizationForm, OrganizationMutationModal };
