import React from 'react';
import { Tag, Tooltip } from 'antd';
import { TagProps } from 'antd/lib/tag';
import { OrganizationBasicFragment } from '../../graphql/generated';

interface OrganizationTagProps extends TagProps {
  organization: OrganizationBasicFragment;
}
const OrganizationTag: React.FC<OrganizationTagProps> = ({
  organization,
  ...rest
}) => {
  return (
    <Tooltip title={organization.organization_description || ''}>
      <Tag {...rest}>{organization.organization_name}</Tag>
    </Tooltip>
  );
};

export default OrganizationTag;
