import React, { useEffect, useState } from 'react';

import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Tooltip,
  Badge,
  Typography,
  Empty,
  Checkbox,
  Divider,
  InputNumber,
  Space,
  Radio,
  Dropdown,
  Menu,
  Tag,
  Popconfirm,
  Spin,
  Modal,
} from 'antd';
import { FormProps } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import {
  PvaDetailsFragment,
  useSearchPatientsByPvaDataLazyQuery,
  SearchPvAsDocument,
  useUpdatePvaMutation,
  PatientDetailsPvaFragment,
  useGetNetworkByNumberLazyQuery,
  useGetPracticeDetailsLazyQuery,
  useValidateActiveParticipantsLazyQuery,
  PracticeBasicFragment,
  PracticeDetailsFragment,
  ParticipantBasicFragment,
} from '../../graphql/generated';
import dayjs from 'dayjs';
import { DatePicker } from '../';
import { parseFullName } from 'parse-full-name';
import {
  ArrowUpOutlined,
  EditOutlined,
  HistoryOutlined,
  ImportOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { PatientMutationModal } from '../patients/PatientForm';
import NetworkTag from '../networks/NetworkTag';
import { getOperationAST } from 'graphql';
import {
  ClinicOutlined,
  StethoscopeOutlined,
  UserInjuredOutlined,
  WebOutlined,
} from '../MabelIcons';
import { ActiveParticipantSearchSelect } from '../autocompletes';
import { ShortURLResponse } from './AlignmentsTable';
import PracticeTag from '../practices/PracticeTag';
import { PVAAuditTimeline, PatientAuditTimeline } from '../auditLogs';

interface PVAFormProps extends FormProps {
  initialPVA: PvaDetailsFragment;
  parsedQRCodes?: (ShortURLResponse | null)[];
}

const PVAForm: React.FC<PVAFormProps> = ({
  initialPVA,
  parsedQRCodes,
  ...rest
}) => {
  const [haveValuesChanged, setHaveValuesChanged] = useState(false);
  const [formInternal] = Form.useForm();
  const [updatePvaForm, updatePvaFormResults] = useUpdatePvaMutation();

  const [
    getNetworkByNumber,
    getNetworkByNumberResults,
  ] = useGetNetworkByNumberLazyQuery({ fetchPolicy: 'network-only' });

  const [
    getPracticeDetails,
    getPracticeDetailsResults,
  ] = useGetPracticeDetailsLazyQuery({ fetchPolicy: 'network-only' });

  // store current selected matched patient
  const [selectedPatient, setSelectedPatient] = useState(
    initialPVA.matched_patient
  );

  // function to compare whether one string is a substring of another
  // case-insensitive and remove special chars
  const isNamePart = (namePart: string, name: string) => {
    const namePartNoSpecialChars = namePart.replace(/[^a-zA-Z0-9\s]/g, '');
    const nameNoSpecialChars = name.replace(/[^a-zA-Z0-9\s]/g, '');
    return nameNoSpecialChars
      .toLowerCase()
      .includes(namePartNoSpecialChars.toLowerCase());
  };

  const [
    searchPatientsByPvaData,
    searchPatientsByPvaDataResults,
  ] = useSearchPatientsByPvaDataLazyQuery({ fetchPolicy: 'network-only' });

  const uniquePatientsByPvaDataResults =
    searchPatientsByPvaDataResults.data?.search_patients_by_pva_data || [];

  const [
    validateActiveParticipants,
    validateActiveParticipantsResults,
  ] = useValidateActiveParticipantsLazyQuery();

  useEffect(() => {
    validateActiveParticipants({
      variables: {
        dc_id: getNetworkByNumberResults.data?.networks[0]?.dc_id || '',
        npi: initialPVA.pva_npi || '',
        tin: initialPVA.pva_tin || '',
      },
    });
  }, [initialPVA, validateActiveParticipants, getNetworkByNumberResults.data]);

  const recordToFormVals = ({
    matched_patient,
    signature_date,
    signature_date_raw,
    birth_date,
    birth_date_raw,
    pva_npi,
    ...rest
  }: PvaDetailsFragment) => {
    return {
      signature_date: dayjs(signature_date).isValid()
        ? dayjs(signature_date)
        : signature_date_raw && dayjs(signature_date_raw).isValid()
        ? dayjs(signature_date_raw)
        : null,
      birth_date: dayjs(birth_date).isValid()
        ? dayjs(birth_date)
        : birth_date_raw && dayjs(birth_date_raw).isValid()
        ? dayjs(birth_date_raw)
        : null,
      pva_npi: pva_npi,
      ...rest,
      ...(rest.beneficiary_name &&
      rest.beneficiary_first_name === null &&
      rest.beneficiary_last_name === null
        ? {
            beneficiary_first_name: parseFullName(rest.beneficiary_name).first,
            beneficiary_last_name: parseFullName(rest.beneficiary_name).last,
          }
        : {}),
    };
  };

  useEffect(() => {
    // get network number from QR code
    const parsed_nn = parseInt(
      parsedQRCodes?.find((q) => q?.query_params.network_number)?.query_params
        .network_number || ''
    );
    const network_from_qr_code = isNaN(parsed_nn) ? undefined : parsed_nn;
    const network_number =
      initialPVA.network?.network_number || network_from_qr_code;

    if (!initialPVA.network_number && network_number) {
      formInternal.setFieldsValue({ network_number: network_number });
      setHaveValuesChanged(true);
    }

    getNetworkByNumber({
      variables: { network_number: network_number ?? -1 },
    });
    if (
      initialPVA.beneficiary_name ||
      initialPVA.mbi ||
      initialPVA.birth_date ||
      initialPVA.letter_id ||
      initialPVA.letter_id_prefix
    ) {
      searchPatientsByPvaData({
        variables: {
          network_number: network_number ?? -1,
          mbi: initialPVA.mbi || '',
          patient_name: initialPVA.beneficiary_name || '',
          letter_id_prefix: initialPVA.letter_id
            ? initialPVA.letter_id.toLowerCase()
            : initialPVA.letter_id_prefix
            ? initialPVA.letter_id_prefix.length < 3
              ? ''
              : `${initialPVA.letter_id_prefix.toLowerCase()}%`
            : null,
          birth_date: initialPVA.birth_date || null,
        },
      });
    }
  }, [
    initialPVA,
    parsedQRCodes,
    formInternal,
    searchPatientsByPvaData,
    getNetworkByNumber,
  ]);

  useEffect(() => {
    const practice_from_qr_code = parsedQRCodes?.find(
      (q) => q?.query_params.practice_id
    )?.query_params.practice_id;
    const practice_id = initialPVA.pva_practice_id || practice_from_qr_code;
    if (!initialPVA.pva_practice_id && practice_id) {
      formInternal.setFieldsValue({ pva_practice_id: practice_id });
      setHaveValuesChanged(true);
    }

    if (practice_id) {
      getPracticeDetails({
        variables: {
          practice_id,
        },
      });
    }
  }, [initialPVA, parsedQRCodes, formInternal, getPracticeDetails]);

  // Takes the form vals and puts them into right format for writing
  const formValsToRecordChanges = (formValues: Store) => {
    const {
      signature_date,
      birth_date,
      matched_patient_id,
      ...rest
    } = formValues;
    const new_obj = {
      signature_date:
        signature_date && (signature_date as dayjs.Dayjs).format('YYYY-MM-DD'),
      birth_date:
        birth_date && (birth_date as dayjs.Dayjs).format('YYYY-MM-DD'),
      matched_patient_id: matched_patient_id || null,
      ...rest,
    };
    const changes = initialPVA
      ? Object.fromEntries(
          Object.entries(new_obj).filter(
            ([k, v]) => (initialPVA as any)[k] !== v
          )
        )
      : new_obj;
    return changes;
  };

  const valuesHaveChanged = (formValues: Store) => {
    return Object.keys(formValsToRecordChanges(formValues)).length > 0;
  };

  const handleUpdate = (values: any) => {
    const changes = formValsToRecordChanges(values);
    updatePvaForm({
      variables: {
        pva_id: initialPVA.pva_id,
        changes: Object.keys(changes).length ? changes : undefined,
      },
      refetchQueries: [getOperationAST(SearchPvAsDocument)?.name?.value || ''],
    });
    if (setHaveValuesChanged) setHaveValuesChanged(false);
  };

  const replaceBeneficiaryName = () => {
    formInternal.setFieldsValue({
      beneficiary_name: selectedPatient?.patient_name,
      beneficiary_first_name: parseFullName(selectedPatient?.patient_name || '')
        .first,
      beneficiary_last_name: parseFullName(selectedPatient?.patient_name || '')
        .last,
    });
  };

  const [isPVAAuditModalVisible, setIsPVAAuditModalVisible] = useState(false);

  return (
    <Form
      {...rest}
      form={formInternal}
      size="small"
      initialValues={recordToFormVals(initialPVA)}
      onValuesChange={(changedValues, allValues) => {
        if (setHaveValuesChanged) {
          setHaveValuesChanged(valuesHaveChanged(allValues));
        }
        if (changedValues.hasOwnProperty('network_number')) {
          getNetworkByNumber({
            variables: { network_number: changedValues.network_number },
          });
        }
        if (
          changedValues.hasOwnProperty('network_number') ||
          changedValues.hasOwnProperty('mbi') ||
          changedValues.hasOwnProperty('beneficiary_name') ||
          changedValues.hasOwnProperty('birth_date') ||
          changedValues.hasOwnProperty('letter_id_prefix')
        ) {
          searchPatientsByPvaData({
            variables: {
              network_number: allValues.network_number ?? -1,
              mbi: allValues.mbi || '',
              patient_name: allValues.beneficiary_name || '',
              letter_id_prefix: allValues.letter_id_prefix
                ? allValues.letter_id_prefix.length < 3
                  ? ''
                  : `${allValues.letter_id_prefix.toLowerCase()}%`
                : null,
              birth_date:
                (allValues.birth_date &&
                  (allValues.birth_date as dayjs.Dayjs).format('YYYY-MM-DD')) ||
                null,
            },
          });
        }
        if (
          changedValues.hasOwnProperty('matched_patient_id') ||
          changedValues.hasOwnProperty('network_number') ||
          changedValues.hasOwnProperty('signature_date') ||
          changedValues.hasOwnProperty('pva_npi') ||
          changedValues.hasOwnProperty('pva_tin')
        ) {
          validateActiveParticipants({
            variables: {
              npi: allValues.pva_npi || '',
              tin: allValues.pva_tin || '',
              dc_id: getNetworkByNumberResults.data?.networks[0]?.dc_id || '',
            },
          });
        }
      }}
      onFinish={handleUpdate}
    >
      <Form.Item
        label="PVA ID"
        labelCol={{ span: 5 }}
        style={{ marginBottom: 0 }}
      >
        <Space direction="horizontal">
          <Typography.Text copyable>{initialPVA.pva_id}</Typography.Text>
          {initialPVA.pages && (
            <>
              <Typography.Text style={{ marginLeft: 8 }}>
                {initialPVA.pages.length} page
                {initialPVA.pages.length > 1 ? 's' : ''}
              </Typography.Text>
              <Tag
                color={
                  initialPVA.pages.reduce(
                    (sum: number, current: any) =>
                      sum +
                      (current?.barcodes?.filter(
                        (code: any) => code.kind === 'QRCode'
                      ).length || 0),
                    0
                  ) !== 1
                    ? 'red'
                    : 'green'
                }
                icon={<QrcodeOutlined />}
              >
                {initialPVA.pages.reduce(
                  (sum: number, current: any) =>
                    sum +
                    (current?.barcodes?.filter(
                      (code: any) => code.kind === 'QRCode'
                    ).length || 0),
                  0
                )}{' '}
                QR Code
                {initialPVA.pages.reduce(
                  (sum: number, current: any) =>
                    sum +
                    (current?.barcodes?.filter(
                      (code: any) => code.kind === 'QRCode'
                    ).length || 0),
                  0
                ) !== 1
                  ? 's'
                  : ''}
              </Tag>
            </>
          )}
          {initialPVA.num_pages && initialPVA.num_pages > 0 && (
            <Typography.Text style={{ marginLeft: 8 }}>
              {initialPVA.num_pages} page{initialPVA.num_pages > 1 ? 's' : ''}
            </Typography.Text>
          )}
          <Spin spinning={initialPVA.num_pages && !parsedQRCodes} size="small">
            {initialPVA.num_pages &&
              parsedQRCodes &&
              parsedQRCodes?.filter(Boolean).length > 0 && (
                <Tag
                  color={
                    parsedQRCodes?.filter(Boolean).length > 1 ? 'red' : 'green'
                  }
                  icon={<QrcodeOutlined />}
                >
                  {parsedQRCodes?.filter(Boolean).length} QR code
                  {parsedQRCodes?.filter(Boolean).length > 1 ? 's' : ''}
                </Tag>
              )}
          </Spin>
        </Space>
        <br />
        <small>
          <i>
            Created {dayjs(initialPVA.created_at).format('YYYY-MM-DD HH:mm:ss')}{' '}
            <Tooltip title="View Changes">
              <Button
                type="link"
                icon={<HistoryOutlined />}
                onClick={() => setIsPVAAuditModalVisible(true)}
              />
            </Tooltip>
            <Modal
              title={'PVA Audit - ' + initialPVA.pva_id}
              visible={isPVAAuditModalVisible}
              width={600}
              footer={null}
              onCancel={() => setIsPVAAuditModalVisible(false)}
              destroyOnClose
              maskClosable
            >
              <PVAAuditTimeline pva_id={initialPVA.pva_id} />
            </Modal>
          </i>
        </small>
      </Form.Item>
      <Form.Item label="Submission ID" labelCol={{ span: 5 }}>
        {initialPVA.submission_source === 'EarthClassMail' ? (
          <Typography.Link
            href={encodeURI(
              `https://app.earthclassmail.com/inboxes/${
                process.env.REACT_APP_ECM_INBOX_ID
              }/pieces?contains=${initialPVA.submission_id?.split('.')[0]}`
            )}
            target="_blank"
          >
            <img
              alt="EarthClassMail icon"
              src="https://www.earthclassmail.com/images/logo/ecm-no-text.svg"
              width={12}
              style={{ margin: 4 }}
            />{' '}
            {initialPVA.submission_id}
          </Typography.Link>
        ) : initialPVA.submission_source === 'StableMail' ? (
          <Typography.Link
            href={encodeURI(
              `https://dashboard.usestable.com/mailroom/${
                initialPVA.submission_id?.split('.')[0]
              }`
            )}
            target="_blank"
          >
            <img
              alt="StableMail icon"
              src="https://assets-global.website-files.com/63af3bafea6dbc658ece0e8d/63c1d1f9b0f8b51af0163f87_stable-32.png"
              width={12}
              style={{ margin: 4 }}
            />{' '}
            {initialPVA.submission_id}
          </Typography.Link>
        ) : initialPVA.submission_source === 'Jotform' ? (
          <Typography.Link
            href={encodeURI(
              `https://www.jotform.com/inbox/${process.env.REACT_APP_JOTFORM_PVA_FORM_ID}/${initialPVA.submission_id}`
            )}
            target="_blank"
          >
            <img
              alt="Jotform icon"
              src="https://www.jotform.com/resources/assets/svg/jotform-icon-transparent.svg"
              width={20}
            />{' '}
            {initialPVA.submission_id}
          </Typography.Link>
        ) : initialPVA.submission_source === 'DropboxUpload' ? (
          <>
            <img
              alt="DropboxUpload icon"
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Dropbox_Icon.svg"
              width={20}
            />{' '}
            {initialPVA.submission_id}
          </>
        ) : initialPVA.submission_source === 'WebUpload' ? (
          <>
            <WebOutlined style={{ width: 20 }} />
            {initialPVA.submission_id}
          </>
        ) : initialPVA.submission_source === 'HelloFax' ? (
          <>
            <img
              alt="HelloFax icon"
              src="https://img.stackshare.io/service/1345/R5PcIJdS.png"
              width={20}
            />{' '}
            {initialPVA.submission_id}
          </>
        ) : (
          initialPVA.submission_id
        )}
      </Form.Item>
      <Form.Item
        label="Letter ID Prefix"
        labelCol={{ span: 5 }}
        style={{ marginBottom: 0 }}
        dependencies={['letter_id_prefix']}
      >
        {({ getFieldValue }) => (
          <Space align="start">
            <Form.Item
              name="letter_id_prefix"
              style={{ display: 'inline-block', width: 120, marginBottom: 4 }}
              help={
                getFieldValue('letter_id_prefix') &&
                (getFieldValue('letter_id_prefix').length < 3
                  ? 'At least 3 chars requried'
                  : getFieldValue('letter_id_prefix').match(/[^0-9a-f]/i)
                  ? 'ID contains unexpected character'
                  : getFieldValue('letter_id_prefix').length > 8
                  ? 'Only 8 charaters expected'
                  : undefined)
              }
              validateStatus={
                getFieldValue('letter_id_prefix') &&
                (getFieldValue('letter_id_prefix').length < 3 ||
                getFieldValue('letter_id_prefix').match(/[^0-9a-f]/i)
                  ? 'error'
                  : getFieldValue('letter_id_prefix').length > 8
                  ? 'warning'
                  : 'success')
              }
            >
              <Input allowClear autoComplete="off" />
            </Form.Item>
            {initialPVA.letter_id && (
              <Form.Item
                name="letter_id"
                style={{ display: 'inline-block', width: 320, marginBottom: 4 }}
              >
                <Typography.Text type="secondary">
                  <small>{initialPVA.letter_id}</small>
                </Typography.Text>
              </Form.Item>
            )}
          </Space>
        )}
      </Form.Item>
      <Form.Item
        labelCol={{ span: 5 }}
        label="Network Number"
        style={{ marginBottom: 4 }}
        validateStatus={
          getNetworkByNumberResults.loading
            ? 'validating'
            : getNetworkByNumberResults.data?.networks?.length === 0
            ? 'error'
            : 'success'
        }
        help={
          getNetworkByNumberResults.data?.networks.length === 0
            ? 'No matching network found'
            : null
        }
      >
        <Space>
          <Form.Item name="network_number" noStyle>
            <InputNumber keyboard={false} />
          </Form.Item>
          {getNetworkByNumberResults.data?.networks[0]?.network_id && (
            <NetworkTag network={getNetworkByNumberResults.data?.networks[0]} />
          )}
        </Space>
      </Form.Item>
      <Form.Item
        labelCol={{ span: 5 }}
        name="pva_practice_id"
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.pva_practice_id !== currentValues.pva_practice_id
        }
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Practice ID"
        labelCol={{ span: 5 }}
        style={{ marginBottom: 4 }}
      >
        {getPracticeDetailsResults.loading ? (
          <Spin />
        ) : (
          getPracticeDetailsResults.data?.practices_by_pk && (
            <>
              <PracticeTag
                practice={getPracticeDetailsResults.data?.practices_by_pk}
              />
              {getPracticeDetailsResults.data?.practices_by_pk?.tin && (
                <span style={{ margin: '0px 4px' }}>
                  TIN: {getPracticeDetailsResults.data?.practices_by_pk?.tin}
                </span>
              )}
              {getPracticeDetailsResults.data?.practices_by_pk
                ?.default_provider && (
                <p>
                  <Tooltip
                    title={
                      getPracticeDetailsResults.data?.practices_by_pk
                        ?.default_provider?.provider_name
                    }
                  >
                    Default Provider:{' '}
                    <Typography.Text copyable>
                      {
                        getPracticeDetailsResults.data?.practices_by_pk
                          ?.default_provider?.provider_id
                      }
                    </Typography.Text>
                  </Tooltip>
                </p>
              )}
            </>
          )
        )}
      </Form.Item>
      <Divider dashed>Practice</Divider>
      <Form.Item
        noStyle
        dependencies={[
          'matched_patient_id',
          'provider_name_or_med_group',
          'signature_date',
        ]}
      >
        {({ getFieldValue, setFieldsValue, getFieldsValue }) => (
          <>
            <Form.Item
              label="Practice Name"
              name="pva_practice_name"
              labelCol={{ span: 5 }}
              style={{ marginBottom: 4 }}
              validateStatus={
                selectedPatient &&
                getFieldValue('pva_practice_name') &&
                !selectedPatient.practice?.practice_name.includes(
                  'Unaffiliated'
                ) &&
                selectedPatient?.practice?.practice_name
                  ?.trim()
                  .toLowerCase() !==
                  getFieldValue('pva_practice_name')?.trim().toLowerCase()
                  ? 'warning'
                  : 'success'
              }
              help={
                selectedPatient &&
                getFieldValue('pva_practice_name') &&
                !selectedPatient.practice?.practice_name.includes(
                  'Unaffiliated'
                ) &&
                selectedPatient?.practice?.practice_name
                  ?.trim()
                  .toLowerCase() !==
                  getFieldValue('pva_practice_name')?.trim().toLowerCase() &&
                `Name does not match ${selectedPatient?.practice?.practice_name}`
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Provider/Group"
              name="provider_name_or_med_group"
              labelCol={{ span: 5 }}
              style={{ marginBottom: 16 }}
            >
              <Input />
            </Form.Item>
            <Form.Item noStyle>
              <Form.Item
                label=" "
                labelCol={{ span: 5 }}
                colon={false}
                style={{ marginBottom: 0 }}
              >
                <Space>
                  Search for active participant:
                  <ActiveParticipantSearchSelect
                    style={{ width: 300 }}
                    allowClear
                    showSearch
                    dc_id={
                      getNetworkByNumberResults.data?.networks[0]?.dc_id || ''
                    }
                    signature_date={getFieldValue('signature_date')}
                    onChange={(val, option) => {
                      setFieldsValue({
                        pva_npi: ((option as any)
                          ?.participant as ParticipantBasicFragment)
                          ?.individual_npi,
                        pva_tin: ((option as any)
                          ?.participant as ParticipantBasicFragment)?.tin,
                      });
                      validateActiveParticipants({
                        variables: {
                          npi: getFieldValue('pva_npi') || '',
                          tin: getFieldValue('pva_tin') || '',
                          dc_id:
                            getNetworkByNumberResults.data?.networks[0]
                              ?.dc_id || '',
                        },
                      });
                      if (setHaveValuesChanged) {
                        setHaveValuesChanged(
                          valuesHaveChanged(getFieldsValue())
                        );
                      }
                    }}
                  />
                  <Dropdown
                    overlay={
                      <Menu
                        onClick={({ item, key }) => {
                          // copy to clipboard
                          navigator.clipboard.writeText(key.toString());
                        }}
                      >
                        <Menu.ItemGroup title="Search Suggestions (click to copy)">
                          {getFieldValue('provider_name_or_med_group') && (
                            <Menu.Item
                              key={getFieldValue('provider_name_or_med_group')}
                            >
                              {getFieldValue('provider_name_or_med_group')}
                            </Menu.Item>
                          )}
                          {selectedPatient?.suspected_provider_npi && (
                            <Menu.Item
                              key={selectedPatient?.suspected_provider_npi}
                            >
                              {selectedPatient?.suspected_provider_npi}
                              <Typography.Text type="secondary">
                                <span style={{ marginLeft: 8 }}>
                                  <UserInjuredOutlined /> Patient-level
                                </span>
                              </Typography.Text>
                            </Menu.Item>
                          )}
                          {getPracticeDetailsResults.data?.practices_by_pk
                            ?.default_provider?.provider_id && (
                            <Menu.Item
                              key={
                                getPracticeDetailsResults.data?.practices_by_pk
                                  ?.default_provider?.provider_id
                              }
                            >
                              {
                                getPracticeDetailsResults.data?.practices_by_pk
                                  ?.default_provider?.provider_id
                              }
                              <Typography.Text type="secondary">
                                <span style={{ marginLeft: 8 }}>
                                  <ClinicOutlined /> Practice-level
                                </span>
                              </Typography.Text>
                            </Menu.Item>
                          )}
                        </Menu.ItemGroup>
                      </Menu>
                    }
                  >
                    <InfoCircleOutlined />
                  </Dropdown>
                </Space>
              </Form.Item>
            </Form.Item>
          </>
        )}
      </Form.Item>
      <Form.Item
        label=" "
        labelCol={{ span: 5 }}
        colon={false}
        style={{ marginBottom: 0 }}
      >
        <Space>
          Name:
          {validateActiveParticipantsResults.data
            ?.analytics_analytics_cms_active_dce_participants[0]
            ?.legal_business_name && (
            <Typography.Text copyable>
              {
                validateActiveParticipantsResults.data
                  ?.analytics_analytics_cms_active_dce_participants[0]
                  ?.legal_business_name
              }
            </Typography.Text>
          )}
        </Space>
      </Form.Item>
      <Form.Item noStyle dependencies={['signature_date']}>
        {({ getFieldValue }) => (
          <Form.Item
            label=" "
            labelCol={{ span: 5 }}
            colon={false}
            validateStatus={
              !getFieldValue('pva_npi') ||
              !getFieldValue('pva_tin') ||
              !getFieldValue('signature_date') ||
              validateActiveParticipantsResults.data?.analytics_analytics_cms_active_dce_participants?.filter(
                (participant) =>
                  participant.effective_start_date &&
                  participant.effective_end_date &&
                  dayjs(participant.effective_start_date)
                    .subtract(1, 'day')
                    .isBefore(getFieldValue('signature_date')) &&
                  dayjs(participant.effective_end_date)
                    .add(1, 'day')
                    .isAfter(getFieldValue('signature_date'))
              ).length === 0 ||
              // show warning if pva_tin doesn't match practice default tin
              (getPracticeDetailsResults.data?.practices_by_pk?.tin &&
                getPracticeDetailsResults.data?.practices_by_pk?.tin !==
                  getFieldValue('pva_tin'))
                ? 'warning'
                : 'success'
            }
            help={
              !getFieldValue('pva_npi') || !getFieldValue('pva_tin')
                ? 'NPI and TIN are required'
                : !getFieldValue('signature_date')
                ? 'No signature date found'
                : validateActiveParticipantsResults.data?.analytics_analytics_cms_active_dce_participants?.filter(
                    (participant) =>
                      participant.effective_start_date &&
                      participant.effective_end_date &&
                      dayjs(participant.effective_start_date)
                        .subtract(1, 'day')
                        .isBefore(getFieldValue('signature_date')) &&
                      dayjs(participant.effective_end_date)
                        .add(1, 'day')
                        .isAfter(getFieldValue('signature_date'))
                  ).length === 0
                ? 'No valid NPI/TIN pair found'
                : getPracticeDetailsResults.data?.practices_by_pk?.tin &&
                  getPracticeDetailsResults.data?.practices_by_pk?.tin !==
                    getFieldValue('pva_tin')
                ? "TIN does not match practice's default TIN"
                : null
            }
          >
            <Space>
              NPI:
              <Form.Item name="pva_npi" noStyle>
                <Input width={120} />
              </Form.Item>
              TIN:
              <Form.Item name="pva_tin" noStyle>
                <Input width={120} />
              </Form.Item>
            </Space>
          </Form.Item>
        )}
      </Form.Item>

      <Divider dashed>Patient</Divider>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.matched_patient_id !== currentValues.matched_patient_id ||
          prevValues.beneficiary_name !== currentValues.beneficiary_name
        }
      >
        {({ getFieldValue }) => (
          <Form.Item
            label="Beneficiary Name"
            name="beneficiary_name"
            labelCol={{ span: 5 }}
            style={{ marginBottom: 4 }}
            help={
              selectedPatient?.patient_name &&
              selectedPatient?.patient_name?.toLowerCase() !==
                getFieldValue('beneficiary_name')?.toLowerCase() &&
              `Name does not match ${selectedPatient?.patient_name}`
            }
            validateStatus={
              selectedPatient?.patient_name &&
              selectedPatient?.patient_name?.toLowerCase() !==
                getFieldValue('beneficiary_name')?.toLowerCase()
                ? 'warning'
                : 'success'
            }
          >
            <Input />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item
        noStyle
        dependencies={['matched_patient_id', 'beneficiary_name']}
      >
        {({ getFieldValue }) => (
          <Form.Item
            label={
              <Popconfirm
                title={`Replace ${getFieldValue('beneficiary_name')} with ${
                  selectedPatient?.patient_name
                }`}
                disabled={
                  !selectedPatient?.patient_name ||
                  selectedPatient?.patient_name ===
                    getFieldValue('beneficiary_name')
                }
                onConfirm={replaceBeneficiaryName}
              >
                <Button
                  shape="round"
                  icon={<ImportOutlined rotate={90} />}
                  disabled={
                    !selectedPatient?.patient_name ||
                    selectedPatient?.patient_name ===
                      getFieldValue('beneficiary_name')
                  }
                >
                  Import
                </Button>
              </Popconfirm>
            }
            colon={false}
            labelCol={{ span: 5 }}
            style={{ marginBottom: 0 }}
          >
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.matched_patient_id !==
                  currentValues.matched_patient_id ||
                prevValues.beneficiary_first_name !==
                  currentValues.beneficiary_first_name
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  name="beneficiary_first_name"
                  style={{
                    display: 'inline-block',
                    width: 'calc(50% - 4px)',
                    marginRight: 4,
                  }}
                  help={
                    selectedPatient?.patient_name &&
                    getFieldValue('beneficiary_first_name') &&
                    !isNamePart(
                      getFieldValue('beneficiary_first_name'),
                      selectedPatient?.patient_name
                    )
                      ? `${getFieldValue(
                          'beneficiary_first_name'
                        )} is not part of ${selectedPatient?.patient_name}`
                      : 'First name'
                  }
                  validateStatus={
                    selectedPatient?.patient_name &&
                    getFieldValue('beneficiary_first_name') &&
                    !isNamePart(
                      getFieldValue('beneficiary_first_name'),
                      selectedPatient?.patient_name
                    )
                      ? 'warning'
                      : 'success'
                  }
                >
                  <Input />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.matched_patient_id !==
                  currentValues.matched_patient_id ||
                prevValues.beneficiary_last_name !==
                  currentValues.beneficiary_last_name
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  name="beneficiary_last_name"
                  style={{
                    display: 'inline-block',
                    width: 'calc(50% - 4px)',
                    marginLeft: 4,
                  }}
                  help={
                    selectedPatient?.patient_name &&
                    getFieldValue('beneficiary_last_name') &&
                    !isNamePart(
                      getFieldValue('beneficiary_last_name'),
                      selectedPatient?.patient_name
                    )
                      ? `${getFieldValue(
                          'beneficiary_last_name'
                        )} is not part of ${selectedPatient?.patient_name}`
                      : 'Last name'
                  }
                  validateStatus={
                    selectedPatient?.patient_name &&
                    getFieldValue('beneficiary_last_name') &&
                    !isNamePart(
                      getFieldValue('beneficiary_last_name'),
                      selectedPatient?.patient_name
                    )
                      ? 'warning'
                      : 'success'
                  }
                >
                  <Input />
                </Form.Item>
              )}
            </Form.Item>
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item noStyle dependencies={['birth_date']}>
        {({ getFieldValue }) => (
          <Form.Item
            label="Birth Date"
            labelCol={{ span: 5 }}
            style={{ marginBottom: 4 }}
            name="birth_date"
            validateStatus={
              selectedPatient?.birth_date &&
              getFieldValue('birth_date') &&
              selectedPatient.birth_date !==
                dayjs(getFieldValue('birth_date')).format('YYYY-MM-DD')
                ? 'warning'
                : getFieldValue('birth_date') &&
                  dayjs(getFieldValue('birth_date')).isAfter(
                    dayjs().subtract(1, 'year')
                  )
                ? 'warning'
                : 'success'
            }
            help={
              getFieldValue('birth_date') &&
              dayjs(getFieldValue('birth_date')).isAfter(
                dayjs().subtract(1, 'year')
              )
                ? 'Birth date within the last year?'
                : selectedPatient?.birth_date &&
                  getFieldValue('birth_date') &&
                  selectedPatient.birth_date !==
                    dayjs(getFieldValue('birth_date')).format('YYYY-MM-DD')
                ? 'Birth date does not match patient record'
                : undefined
            }
          >
            <DatePicker format="M/D/YYYY" />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item
        label="Medicare Number"
        name="mbi"
        labelCol={{ span: 5 }}
        style={{ marginBottom: 4 }}
        hasFeedback={formInternal.getFieldValue('mbi')}
        validateStatus={
          selectedPatient?.mbi &&
          selectedPatient.mbi !== formInternal.getFieldValue('mbi')
            ? 'error'
            : formInternal.getFieldValue('mbi')
            ? formInternal
                .getFieldValue('mbi')
                .match(
                  /^[1-9][^a-zSLOIBZ\W_0-9][^a-zSLOIBZ\W_][0-9][^a-zSLOIBZ\W_0-9][^a-zSLOIBZ\W_][0-9][^a-zSLOIBZ\W_0-9]{2}[0-9]{2}$/
                ) && formInternal.getFieldValue('mbi') !== '1EG4TE5MK72'
              ? 'success'
              : 'error'
            : undefined
        }
        help={
          formInternal.getFieldValue('mbi') &&
          !formInternal
            .getFieldValue('mbi')
            .match(
              /^[1-9][^a-zSLOIBZ\W_0-9][^a-zSLOIBZ\W_][0-9][^a-zSLOIBZ\W_0-9][^a-zSLOIBZ\W_][0-9][^a-zSLOIBZ\W_0-9]{2}[0-9]{2}$/
            )
            ? 'Invalid MBI Syntax'
            : formInternal.getFieldValue('mbi') === '1EG4TE5MK72'
            ? '1EG4TE5MK72 is an example MBI, not a valid one'
            : selectedPatient?.mbi &&
              selectedPatient.mbi !== formInternal.getFieldValue('mbi')
            ? 'MBI does not match patient record'
            : undefined
        }
      >
        <Input
          allowClear
          onInput={(e) =>
            ((e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value
              .replace(/[\W_]/, '')
              .trim()
              .toUpperCase())
          }
        />
      </Form.Item>
      <Divider dashed>Signature</Divider>
      <Form.Item noStyle dependencies={['is_signed', 'signature_date']}>
        {({ getFieldValue }) => (
          <Form.Item
            label="Signed?"
            labelCol={{ span: 5 }}
            colon={false}
            validateStatus={
              // check that signature date is no more than three months ago with dayjs
              getFieldValue('signature_date') &&
              (dayjs(getFieldValue('signature_date')).isBefore(
                dayjs().subtract(3, 'month')
              ) ||
              dayjs().add(1, 'day').isBefore(getFieldValue('signature_date'))
                ? 'warning'
                : 'success')
            }
            help={
              getFieldValue('signature_date') &&
              (dayjs(getFieldValue('signature_date')).isBefore(
                dayjs().subtract(3, 'month')
              )
                ? 'Signature date is more than three months ago'
                : dayjs()
                    .add(1, 'day')
                    .isBefore(getFieldValue('signature_date'))
                ? 'Signature date is in the future'
                : undefined)
            }
          >
            <Space>
              <Form.Item name="is_signed" valuePropName="checked" noStyle>
                <Checkbox />
              </Form.Item>
              Date:
              <Form.Item name="signature_date" noStyle>
                <DatePicker format="M/D/YYYY" />
              </Form.Item>
              <Typography.Text type="secondary">
                {initialPVA.signer_printed_name}
              </Typography.Text>
            </Space>
          </Form.Item>
        )}
      </Form.Item>
      <Divider dashed> </Divider>
      <Form.Item
        noStyle
        dependencies={[
          'mbi',
          'letter_id_prefix',
          'provider_name_or_med_group',
          'pva_practice_name',
          'beneficiary_name',
          'birth_date',
        ]}
      >
        {({ getFieldValue, setFieldsValue }) => (
          <Form.Item
            label="Matched Patient"
            name="matched_patient_id"
            labelCol={{ span: 5 }}
            hasFeedback
            validateStatus={
              searchPatientsByPvaDataResults.loading
                ? 'validating'
                : uniquePatientsByPvaDataResults.length === 0
                ? 'error'
                : 'success'
            }
            help={
              !searchPatientsByPvaDataResults.loading &&
              uniquePatientsByPvaDataResults.length === 0 &&
              'No matching patients found'
            }
          >
            <Select
              allowClear
              onChange={(value) => {
                const patient = uniquePatientsByPvaDataResults.find(
                  (p) => p.patient_id === value
                );
                setSelectedPatient(patient);
              }}
              options={uniquePatientsByPvaDataResults.map((patient) => {
                return {
                  key: patient.patient_id,
                  label: (
                    <>
                      <Typography.Text
                        disabled={patient.is_archived}
                        delete={patient.is_archived}
                      >
                        {
                          <span
                            className={
                              patient.patient_name?.trim().toUpperCase() !==
                              getFieldValue('beneficiary_name')
                                ?.trim()
                                .toUpperCase()
                                ? 'wavy-warning'
                                : ''
                            }
                          >
                            {patient.patient_name}
                          </span>
                        }{' '}
                        {
                          <small style={{ marginLeft: 8 }}>
                            <Space direction="horizontal">
                              {patient.birth_date && (
                                <span>
                                  {patient.birth_date &&
                                    dayjs(patient.birth_date).format(
                                      'M/D/YYYY'
                                    )}
                                </span>
                              )}
                              <span
                                className={
                                  patient.mbi?.trim().toUpperCase() !==
                                  getFieldValue('mbi')?.trim().toUpperCase()
                                    ? 'wavy-error'
                                    : ''
                                }
                              >
                                {patient.mbi || (
                                  <Typography.Text type="secondary">
                                    Missing MBI
                                  </Typography.Text>
                                )}
                              </span>
                            </Space>
                          </small>
                        }{' '}
                      </Typography.Text>
                      <small>
                        {getFieldValue('letter_id_prefix') ? (
                          <Typography.Text type="secondary">
                            {(
                              patient.letters.find((letter) =>
                                letter.letter_id.startsWith(
                                  getFieldValue('letter_id_prefix')
                                )
                              )?.letter_id ||
                              patient.emails.find((email) =>
                                email.email_id.startsWith(
                                  getFieldValue('letter_id_prefix')
                                )
                              )?.email_id
                            )?.slice(0, 18)}
                            ...
                          </Typography.Text>
                        ) : null}
                      </small>
                      <br />
                      <Typography.Text type="secondary">
                        <small>
                          <i>
                            <span
                              className={
                                patient.practice?.practice_name &&
                                getFieldValue('pva_practice_name') &&
                                !patient.practice?.practice_name.includes(
                                  'Unaffiliated'
                                ) &&
                                patient.practice?.practice_name
                                  ?.trim()
                                  .toUpperCase() !==
                                  getFieldValue('pva_practice_name')
                                    ?.trim()
                                    .toUpperCase()
                                  ? 'wavy-warning'
                                  : ''
                              }
                            >
                              {patient.practice?.practice_name}
                            </span>{' '}
                            {patient.practice?.practice_description &&
                              `: ${patient.practice?.practice_description}`}
                          </i>
                        </small>
                      </Typography.Text>
                    </>
                  ),
                  value: patient.patient_id,
                };
              })}
            />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.matched_patient_id !== currentValues.matched_patient_id ||
          prevValues.beneficiary_name !== currentValues.beneficiary_name ||
          prevValues.mbi !== currentValues.mbi ||
          prevValues.birth_date !== currentValues.birth_date ||
          prevValues.pva_practice_id !== currentValues.pva_practice_id
        }
      >
        {({ getFieldValue }) => (
          <>
            <Row gutter={16}>
              <Col offset={5} span={19}>
                <PatientFieldsDisplay
                  patient={uniquePatientsByPvaDataResults.find(
                    (p) => p.patient_id === getFieldValue('matched_patient_id')
                  )}
                  beneficiary_name={getFieldValue('beneficiary_name')}
                  mbi={getFieldValue('mbi')}
                  birth_date={
                    getFieldValue('birth_date') &&
                    (getFieldValue('birth_date') as dayjs.Dayjs).format(
                      'YYYY-MM-DD'
                    )
                  }
                  inferred_practice={
                    getPracticeDetailsResults.data?.practices_by_pk || undefined
                  }
                  default_practice={
                    getNetworkByNumberResults.data?.networks[0]
                      ?.default_practice || undefined
                  }
                />
              </Col>
            </Row>
          </>
        )}
      </Form.Item>
      <Divider dashed> </Divider>
      <Form.Item label="Notes" name="transcriber_notes" labelCol={{ span: 5 }}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="processing_status"
        labelCol={{ span: 5 }}
        label="Status"
        dependencies={[
          'provider_name_or_med_group',
          'beneficiary_first_name',
          'beneficiary_last_name',
          'mbi',
          'is_signed',
          'signature_date',
          'matched_patient_id',
          'letter_id_prefix',
          'pva_npi',
          'pva_tin',
        ]}
        rules={[
          ({ getFieldValue }) => ({
            validator: (_, value) => {
              if (value === 'Completed') {
                if (!getFieldValue('provider_name_or_med_group')) {
                  return Promise.reject('Provider/Med Group Name Required');
                }
                if (!getFieldValue('beneficiary_first_name')) {
                  return Promise.reject('First Name Required');
                }
                if (!getFieldValue('beneficiary_last_name')) {
                  return Promise.reject('Last Name Required');
                }
                if (!getFieldValue('mbi')) {
                  return Promise.reject('MBI Required');
                }
                if (!getFieldValue('is_signed')) {
                  return Promise.reject('Signature Required');
                }
                if (!getFieldValue('signature_date')) {
                  return Promise.reject('Signature Date Required');
                }
                if (
                  dayjs()
                    .add(1, 'day')
                    .isBefore(getFieldValue('signature_date'))
                ) {
                  return Promise.reject(
                    'Signature Date cannot be in the future'
                  );
                }
                if (
                  dayjs('2021-08-01').isAfter(getFieldValue('signature_date'))
                ) {
                  return Promise.reject(
                    'Signature Date cannot be before 2021-08-01'
                  );
                }
                if (!getFieldValue('pva_npi')) {
                  return Promise.reject('NPI Required');
                }
                if (!getFieldValue('pva_tin')) {
                  return Promise.reject('TIN Required');
                }
                if (uniquePatientsByPvaDataResults.length === 0) {
                  return Promise.reject('No matching patient found');
                }
                if (!getFieldValue('matched_patient_id')) {
                  return Promise.reject('Matching Patient Required');
                }
                let match = uniquePatientsByPvaDataResults.find(
                  (patient) =>
                    (patient.patient_id = getFieldValue('matched_patient_id'))
                );

                // check matched patient mbi matches mbi on form
                if (match && match.mbi !== getFieldValue('mbi')) {
                  return Promise.reject("MBIs don't match");
                }
                let letter_match =
                  getFieldValue('letter_id_prefix') &&
                  (match?.letters.find((letter) =>
                    letter.letter_id.startsWith(
                      getFieldValue('letter_id_prefix')
                    )
                  ) ||
                    match?.emails.find((email) =>
                      email.email_id.startsWith(
                        getFieldValue('letter_id_prefix')
                      )
                    ));
                if (
                  letter_match &&
                  getFieldValue('signature_date').isBefore(
                    dayjs(
                      // send_datetime is UTC, so earliest send date is based on west coast time
                      new Date(letter_match.send_datetime).toLocaleDateString(
                        undefined,
                        {
                          timeZone: 'America/Los_Angeles',
                        }
                      )
                    )
                  )
                ) {
                  return Promise.reject(
                    'Signature date cannot be before letter/email send date'
                  );
                }
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Radio.Group size="middle" buttonStyle="solid">
          <Radio.Button value="Unprocessed">
            <Badge status="default" />
            Unprocessed
          </Radio.Button>
          <Radio.Button value="Completed">
            <Badge status="success" />
            Completed
          </Radio.Button>
          <Dropdown
            placement="topLeft"
            overlay={
              <Menu
                onClick={({ key }) => {
                  formInternal.setFieldsValue({
                    processing_status: 'Invalid',
                    transcriber_notes: key,
                  });
                  if (setHaveValuesChanged) {
                    setHaveValuesChanged(
                      valuesHaveChanged(formInternal.getFieldsValue())
                    );
                  }
                }}
              >
                <Menu.ItemGroup title="Select and add note">
                  <Menu.Item key="Not a PVA">Not a PVA</Menu.Item>
                  <Menu.Item key="Multiple forms">Multiple forms</Menu.Item>
                  <Menu.Item key="Designated different PCP">
                    Designated different PCP
                  </Menu.Item>
                  <Menu.Item key="Provider not in network">
                    Provider not in network
                  </Menu.Item>
                  <Menu.Item key="Missing signature">
                    Missing signature
                  </Menu.Item>
                  <Menu.Item key="Missing date">Missing date</Menu.Item>
                  <Menu.Item key="Missing/Invalid MBI">
                    Missing/Invalid MBI
                  </Menu.Item>
                  <Menu.Item key="Deceased">Deceased</Menu.Item>
                </Menu.ItemGroup>
              </Menu>
            }
          >
            <Radio.Button value="Invalid">
              <Badge status="error" />
              Invalid
            </Radio.Button>
          </Dropdown>
          <Dropdown
            placement="topLeft"
            overlay={
              <Menu
                onClick={({ key }) => {
                  formInternal.setFieldsValue({
                    processing_status: 'Awaiting Information',
                    transcriber_notes: key,
                  });
                  if (setHaveValuesChanged) {
                    setHaveValuesChanged(
                      valuesHaveChanged(formInternal.getFieldsValue())
                    );
                  }
                }}
              >
                <Menu.ItemGroup title="Select and add note">
                  <Menu.Item key="No NPI on File">No NPI on File</Menu.Item>
                  <Menu.Item key="Missing/Invalid MBI">
                    Missing/Invalid MBI
                  </Menu.Item>
                </Menu.ItemGroup>
              </Menu>
            }
          >
            <Radio.Button value="Awaiting Information">
              <Badge status="warning" />
              Awaiting Information
            </Radio.Button>
          </Dropdown>
          <Dropdown
            placement="topLeft"
            overlay={
              <Menu
                onClick={({ key }) => {
                  formInternal.setFieldsValue({
                    processing_status: 'Internal Review',
                    transcriber_notes: key,
                  });
                  if (setHaveValuesChanged) {
                    setHaveValuesChanged(
                      valuesHaveChanged(formInternal.getFieldsValue())
                    );
                  }
                }}
              >
                <Menu.ItemGroup title="Select and add note">
                  <Menu.Item key="Multiple forms; split required">
                    Multiple forms; split required
                  </Menu.Item>
                  <Menu.Item key="Unknown Provider">Unknown Provider</Menu.Item>
                  <Menu.Item key="Unknown TIN">Unknown TIN</Menu.Item>
                </Menu.ItemGroup>
              </Menu>
            }
          >
            <Radio.Button value="Internal Review">
              <Badge status="warning" />
              Internal Review
            </Radio.Button>
          </Dropdown>
        </Radio.Group>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 5 }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={
            !haveValuesChanged ||
            updatePvaFormResults.loading ||
            getNetworkByNumberResults.loading ||
            searchPatientsByPvaDataResults.loading
          }
          loading={updatePvaFormResults.loading}
          size="middle"
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

const PatientFieldsDisplay: React.FC<{
  patient?: PatientDetailsPvaFragment;
  beneficiary_name?: string;
  mbi?: string;
  birth_date?: string;
  inferred_practice?: PracticeDetailsFragment;
  default_practice?: PracticeBasicFragment;
}> = ({
  patient,
  beneficiary_name,
  mbi,
  birth_date,
  inferred_practice,
  default_practice,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isPatientAuditModalVisible, setIsPatientAuditModalVisible] = useState(
    false
  );
  const [selectedRecord, setSelectedRecord] = useState<
    PatientDetailsPvaFragment | undefined
  >(patient);
  return patient ? (
    <>
      <PatientMutationModal
        formName="Update"
        initialRecord={selectedRecord}
        visible={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        afterSubmit={() => setShowUpdateModal(false)}
      />
      <Modal
        title={'Patient Audit - ' + patient.patient_id}
        visible={isPatientAuditModalVisible}
        width={600}
        footer={null}
        onCancel={() => setIsPatientAuditModalVisible(false)}
        destroyOnClose
        maskClosable
      >
        <PatientAuditTimeline patient_id={patient.patient_id} />
      </Modal>
      <span style={{ float: 'right' }}>
        <Tooltip title="View Changes">
          <Button
            type="link"
            icon={<HistoryOutlined />}
            onClick={() => setIsPatientAuditModalVisible(true)}
          />
        </Tooltip>
      </span>
      <Space direction="vertical">
        <Row gutter={16}>
          <Col>
            <UserInjuredOutlined style={{ fontSize: 16, paddingTop: 4 }} />
          </Col>
          <Col>
            <Typography.Title
              level={5}
              style={{ marginBottom: 0 }}
              disabled={patient.is_archived}
              delete={patient.is_archived}
            >
              <Tooltip title={patient?.patient_id}>
                <span
                  className={
                    patient.patient_name?.trim().toUpperCase() !==
                    beneficiary_name?.trim().toUpperCase()
                      ? 'wavy-warning'
                      : ''
                  }
                >
                  {patient.patient_name}
                </span>{' '}
              </Tooltip>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => {
                  setSelectedRecord(patient);
                  setShowUpdateModal(true);
                }}
              >
                Edit
              </Button>
            </Typography.Title>
            <div
              className={
                birth_date &&
                patient.birth_date &&
                birth_date !== patient.birth_date
                  ? 'wavy-error'
                  : ''
              }
            >
              <Typography.Text>
                {patient.birth_date &&
                  dayjs(patient.birth_date).format('M/D/YYYY')}
              </Typography.Text>
            </div>
            <div
              className={
                patient.mbi?.trim().toUpperCase() !== mbi?.trim().toUpperCase()
                  ? 'wavy-error'
                  : ''
              }
            >
              {patient.mbi ? (
                <Typography.Text copyable>{patient.mbi}</Typography.Text>
              ) : (
                <Typography.Text type="danger">Missing MBI</Typography.Text>
              )}
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col>
            <ClinicOutlined />
          </Col>
          <Col>{patient?.practice?.practice_name}</Col>
        </Row>
      </Space>
      <Row gutter={16}>
        <Col>
          <StethoscopeOutlined />
        </Col>
        <Col>
          {patient.suspected_provider_npi ? (
            <>
              <Typography.Text copyable>
                {patient.suspected_provider_npi}
              </Typography.Text>
              {patient?.suspected_provider?.provider_name &&
                ` (${patient?.suspected_provider?.provider_name})`}{' '}
              <Tooltip title="NPI registry info">
                <Typography.Link
                  href={`https://npiregistry.cms.hhs.gov/provider-view/${patient.suspected_provider_npi}`}
                  target="_blank"
                >
                  <ArrowUpOutlined rotate={45} />
                </Typography.Link>
              </Tooltip>
            </>
          ) : null}
        </Col>
      </Row>
    </>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="No patient selected"
    >
      <PatientMutationModal
        formName="Create"
        visible={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        afterSubmit={() => setShowCreateModal(false)}
        preFill={{
          practice: default_practice,
          patient_name: beneficiary_name,
          external_id: mbi,
          mbi: mbi,
          birth_date: dayjs(birth_date).isValid() ? dayjs(birth_date) : null,
        }}
      />
      <Button
        type="default"
        size="small"
        icon={<PlusOutlined />}
        onClick={() => setShowCreateModal(true)}
      >
        Create Patient
      </Button>
    </Empty>
  );
};

export { PVAForm };
