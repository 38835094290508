import React, { useState, useEffect, useContext } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Form, Input, Modal, Button, Switch } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import { DatePicker } from '../';

import {
  PatientDetailsFragment,
  SearchPatientsAdvancedDocument,
  SearchPatientsByPvaDataDocument,
  useUpdatePatientMutation,
  useCreatePatientMutation,
  useGetUserDetailsQuery,
  PracticeBasicFragment,
} from '../../graphql/generated';
import { getOperationAST } from 'graphql';
import dayjs from 'dayjs';
import { UserContext } from '../../contexts/UserContext';
import {
  PracticeSearchSelect,
  ProviderSearchAutocomplete,
} from '../autocompletes';
import MaskedInput from 'antd-mask-input';

interface PatientFormProps extends FormProps {
  initialPatient?: PatientDetailsFragment;
  preFill?: Partial<
    PatientDetailsFragment & {
      practice: PracticeBasicFragment | undefined;
    }
  >;
  updateMutation?: ReturnType<typeof useUpdatePatientMutation>[0];
  createMutation?: ReturnType<typeof useCreatePatientMutation>[0];
  showSubmit?: boolean;
  haveValuesChanged?: boolean;
  setHaveValuesChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PatientForm: React.FC<PatientFormProps> = ({
  form,
  initialPatient,
  preFill,
  updateMutation,
  createMutation,
  showSubmit,
  haveValuesChanged,
  setHaveValuesChanged,
  ...rest
}) => {
  const { user } = useContext(UserContext);
  const userDetailsQuery = useGetUserDetailsQuery({
    variables: { user_id: user?.user_id },
  });

  const [formInternal] = useForm(form);
  const [
    updateMutationInternal,
    updateMutationInternalResults,
  ] = useUpdatePatientMutation();
  const [
    createMutationInternal,
    createMutationInternalResults,
  ] = useCreatePatientMutation();

  // If an initial patient is given, this populates the form
  const recordToFormVals = ({
    birth_date,
    deceased_as_of_date,
    practice_id,
    ...rest
  }: PatientDetailsFragment) => {
    return {
      birth_date:
        birth_date && dayjs(birth_date).isValid() ? dayjs(birth_date) : null,
      deceased_as_of_date:
        deceased_as_of_date && dayjs(deceased_as_of_date).isValid()
          ? dayjs(deceased_as_of_date)
          : null,
      ...rest,
      practice_id: practice_id
        ? {
            value: practice_id,
            label: practice_id,
          }
        : undefined,
    };
  };
  useEffect(() => {
    if (initialPatient) {
      formInternal.resetFields();
      formInternal.setFieldsValue(recordToFormVals(initialPatient));
    }
  }, [formInternal, initialPatient]);

  useEffect(() => {
    if (preFill) {
      formInternal.setFieldsValue(preFill);
      if (preFill.practice) {
        formInternal.setFieldsValue({
          practice_id: {
            value: preFill.practice.practice_id,
            label: preFill.practice.practice_name,
          },
        });
      }
    }
  }, [formInternal, preFill]);

  // Takes the form vals and puts them into right format for writing
  const formValsToRecordChanges = (formValues: Store) => {
    const {
      birth_date,
      deceased_as_of_date,
      mbi,
      practice_id,
      ...rest
    } = formValues;
    const new_obj = {
      birth_date:
        birth_date && (birth_date as dayjs.Dayjs).format('YYYY-MM-DD'),
      deceased_as_of_date:
        deceased_as_of_date &&
        (deceased_as_of_date as dayjs.Dayjs).format('YYYY-MM-DD'),
      mbi: mbi ? mbi.replaceAll('-', '').toUpperCase() : null,
      practice_id: formValues.practice_id?.value || null,
      ...rest,
    };
    const changes = initialPatient
      ? Object.fromEntries(
          Object.entries(new_obj).filter(
            ([k, v]) => (initialPatient as any)[k] !== v
          )
        )
      : new_obj;
    return changes;
  };

  const valuesHaveChanged = (formValues: Store) => {
    return Object.keys(formValsToRecordChanges(formValues)).length > 0;
  };

  const handleInsert = (values: any) => {
    const patient = {
      patient_id: uuidv4(),
      ...formValsToRecordChanges(values),
      ...(user?.role === 'practice_user'
        ? {
            practice_id: userDetailsQuery.data?.users_by_pk?.practice_id,
          }
        : {}),
    };
    (createMutation || createMutationInternal)({
      variables: { patient: patient },
      refetchQueries: [
        // This just gets the name of the query to refresh.
        // By passing it as just a string, we force a refresh of *all*
        // calls made by the query, regardless of variable values
        // This is useful since we're fetching pages individually, and
        // after inserting a new document then any page might have changed
        getOperationAST(SearchPatientsAdvancedDocument)?.name?.value || '',
        getOperationAST(SearchPatientsByPvaDataDocument)?.name?.value || '',
      ],
    });
  };

  const handleUpdate = (values: any) => {
    if (!initialPatient) {
      return;
    }
    const changes = formValsToRecordChanges(values);
    (updateMutation || updateMutationInternal)({
      variables: {
        patient_id: initialPatient.patient_id,
        changes: Object.keys(changes).length ? changes : undefined,
      },
      refetchQueries: [
        getOperationAST(SearchPatientsAdvancedDocument)?.name?.value || '',
        getOperationAST(SearchPatientsByPvaDataDocument)?.name?.value || '',
      ],
    });
    if (setHaveValuesChanged) setHaveValuesChanged(false);
  };
  return (
    <Form
      {...rest}
      form={formInternal}
      wrapperCol={rest.wrapperCol || { span: 18 }}
      onValuesChange={(changedValues, allValues) => {
        if (setHaveValuesChanged) {
          setHaveValuesChanged(valuesHaveChanged(allValues));
        }
      }}
      onFinish={initialPatient ? handleUpdate : handleInsert}
    >
      <Form.Item
        label="Practice"
        name="practice_id"
        hidden={user?.role !== 'admin' || !!initialPatient}
        // practice_user gets practice_id filled automatically, so not required
        rules={[{ required: user?.role !== 'practice_user' }]}
      >
        <PracticeSearchSelect
          allowClear
          onChange={(value) => {
            form?.setFieldsValue({ practice_id: value });
          }}
        />
      </Form.Item>

      <Form.Item
        label="Patient Name"
        name="patient_name"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please provide a patient name',
          },
        ]}
      >
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item label="External Patient ID" name="external_id">
        <Input placeholder="External ID" />
      </Form.Item>
      <Form.Item label="Birthdate" name="birth_date">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Sex Assigned at Birth" name="sex_assigned_at_birth">
        <Input />
      </Form.Item>
      <Form.Item label="Email" name="patient_email">
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item label="Mailing Address" name="patient_address">
        <Input.TextArea placeholder="Address" autoSize={{ minRows: 2 }} />
      </Form.Item>
      <Form.Item label="Mobile Phone" name="patient_phone">
        <Input placeholder="+15551112222" />
      </Form.Item>
      <Form.Item label="Primary Care Provider Name" name="patient_pcp">
        <Input />
      </Form.Item>
      <Form.Item
        label="Primary Care Provider NPI"
        name="suspected_provider_npi"
      >
        <ProviderSearchAutocomplete allowClear />
      </Form.Item>
      <Form.Item label="Alignment Report TIN" name="alignment_tin">
        <Input />
      </Form.Item>
      <Form.Item
        label="Medicare Beneficiary Identifier"
        name="mbi"
        hasFeedback={formInternal.getFieldValue('mbi')}
        dependencies={['mbi']}
        rules={[
          {
            pattern: /^[1-9][^a-zSLOIBZ\W_0-9][^a-zSLOIBZ\W_][0-9][^a-zSLOIBZ\W_0-9][^a-zSLOIBZ\W_][0-9][^a-zSLOIBZ\W_0-9]{2}[0-9]{2}$/,
            message: 'Not a valid mbi',
          },
        ]}
      >
        <MaskedInput
          allowClear
          mask="1A#1A#1AA11"
          placeholder="1EG4TE5MK72"
          placeholderChar=" "
        />
      </Form.Item>
      <Form.Item
        label="Primary Insurance Payer Name"
        name="primary_insurance_payer_name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Primary Insurance Payer ID"
        name="primary_insurance_payer_id"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Primary Insurance Plan Name"
        name="primary_insurance_plan_name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Secondary Insurance Payer Name"
        name="secondary_insurance_payer_name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Secondary Insurance Payer ID"
        name="secondary_insurance_payer_id"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Secondary Insurance Plan Name"
        name="secondary_insurance_plan_name"
      >
        <Input />
      </Form.Item>
      <Form.Item label="Logo URL" name="patient_logo">
        <Input />
      </Form.Item>
      <Form.Item label="Archived?" name="is_archived" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="Deceased Date (as of)" name="deceased_as_of_date">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Notes" name="patient_notes">
        <Input.TextArea autoSize={{ minRows: 2 }} />
      </Form.Item>
      {showSubmit ? (
        <Form.Item style={{ marginTop: 12 }} wrapperCol={{ offset: 6 }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!haveValuesChanged}
            loading={
              createMutationInternalResults.loading ||
              updateMutationInternalResults.loading
            }
          >
            Save
          </Button>
        </Form.Item>
      ) : null}
    </Form>
  );
};

interface PatientMutationModalProps {
  formName: string;
  initialRecord?: PatientDetailsFragment;
  preFill?: Partial<PatientDetailsFragment> &
    Partial<{
      practice: PracticeBasicFragment;
    }>;
  visible: boolean;
  onCancel?: (() => void) | undefined;
  afterSubmit?: (() => void) | undefined;
}

const PatientMutationModal: React.FC<PatientMutationModalProps> = ({
  initialRecord,
  preFill,
  visible,
  onCancel,
  afterSubmit,
}) => {
  const [form] = useForm();
  const [haveValuesChanged, setHaveValuesChanged] = useState(false);
  const [updateMutation, updateMutationResult] = useUpdatePatientMutation({
    onCompleted: () => {
      form.resetFields();
      if (afterSubmit) afterSubmit();
    },
  });
  const [createMutation, createMutationResult] = useCreatePatientMutation({
    onCompleted: () => {
      form.resetFields();
      if (afterSubmit) afterSubmit();
    },
  });

  return (
    <Modal
      title={initialRecord ? 'Update Patient' : 'Create Patient'}
      okText={initialRecord ? 'Update Patient' : 'Create Patient'}
      okButtonProps={{ disabled: initialRecord && !haveValuesChanged }}
      visible={visible}
      onCancel={onCancel}
      onOk={(e) => form.submit()}
      confirmLoading={
        updateMutationResult.loading || createMutationResult.loading
      }
      width={660}
    >
      <PatientForm
        form={form}
        layout="vertical"
        initialPatient={initialRecord}
        preFill={preFill}
        updateMutation={updateMutation}
        createMutation={createMutation}
        haveValuesChanged={haveValuesChanged}
        setHaveValuesChanged={setHaveValuesChanged}
      />
    </Modal>
  );
};

export { PatientForm, PatientMutationModal };
