import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { mabel_light_green, warning_color } from '../../colors';
import {
  Descriptions,
  Form,
  Input,
  Space,
  Dropdown,
  Menu,
  Tooltip,
  Button,
  Typography,
  Modal,
  message,
} from 'antd';

import { cacheIdFromObject } from '../../graphql/utils';

import {
  PracticeDetailsFragment,
  useSendBaaMutation,
  useAdminMarkBaaCompletedMutation,
  PracticeDetailsFragmentDoc,
  PracticeBasicFragment,
  useListCampaignTemplatesForPracticeQuery,
  useUpdatePracticeMutation,
  useUpdateProviderEmploymentMutation,
} from '../../graphql/generated';

import ProviderTag from '../providers/ProviderTag';
import NetworkTag from '../networks/NetworkTag';
import UserTag from '../users/UserTag';
import {
  MailOutlined,
  UserOutlined,
  CheckCircleTwoTone,
  WarningTwoTone,
  CheckOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { UserContext } from '../../contexts/UserContext';
import SwitchRoleButton from '../utils/SwitchRoleButton';
import { Link } from 'react-router-dom';
import KeyValueForm from '../utils/KeyValueForm';
import ValidatedAddress from '../utils/ValidatedAddress';

const PracticeDescription: React.FC<{ practice: PracticeDetailsFragment }> = ({
  practice,
}) => {
  const campaignTemplatesQuery = useListCampaignTemplatesForPracticeQuery({
    variables: { practice_id: practice.practice_id },
  });

  const [mergeVarModalVisible, setMergeVarModalVisible] = useState(false);
  const [mergeVarForm] = Form.useForm();
  const [
    updatePracticeMutation,
    updatePracticeMutationResults,
  ] = useUpdatePracticeMutation();

  const [
    updateProviderEmploymentMutation,
  ] = useUpdateProviderEmploymentMutation({
    onCompleted: () => message.success('Saved'),
    onError: (error) => message.error(error.message),
  });

  return (
    <Descriptions
      title={practice.practice_name}
      layout="horizontal"
      column={1}
      style={{ margin: '24px 48px' }}
    >
      <Descriptions.Item label="ID">{practice.practice_id}</Descriptions.Item>
      <Descriptions.Item label="Address" style={{ whiteSpace: 'pre-line' }}>
        {practice.practice_address && (
          <ValidatedAddress
            address={practice.practice_address}
            deliverability={practice.deliverability}
          />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Company">
        {practice.practice_company}
      </Descriptions.Item>
      <Descriptions.Item label="Description">
        {practice.practice_description}
      </Descriptions.Item>
      <Descriptions.Item label="Preferred Network">
        {practice.primary_network ? (
          <NetworkTag network={practice.primary_network} />
        ) : null}
      </Descriptions.Item>
      <Descriptions.Item label="Legal Business Name">
        {practice.legal_business_name}
      </Descriptions.Item>
      <Descriptions.Item
        label="Main Doctor Name Extension"
        style={{ whiteSpace: 'pre-line' }}
      >
        {practice.main_doctor_name_extension}
      </Descriptions.Item>
      <Descriptions.Item label=" Language">
        {practice.practice_language}
      </Descriptions.Item>
      <Descriptions.Item label="TIN">{practice.tin}</Descriptions.Item>
      <Descriptions.Item label="Providers">
        <div style={{ display: 'block' }}>
          {practice.provider_employments
            .filter((e) => !e.is_archived && !e.provider.is_archived)
            .map((e) => (
              <div key={e.provider.provider_id} style={{ marginBottom: 8 }}>
                <ProviderTag provider={e.provider} /> <b>NPI</b>:{' '}
                {e.provider_id}, <b>TIN</b>:{' '}
                <Typography.Text
                  editable={{
                    onChange: (tin) => {
                      if (tin !== e.tin) {
                        updateProviderEmploymentMutation({
                          variables: {
                            practice_id: e.practice_id,
                            provider_id: e.provider_id,
                            changes: { tin: tin },
                          },
                        });
                      }
                    },
                  }}
                >
                  {e.tin || ''}
                </Typography.Text>
              </div>
            ))}
        </div>
      </Descriptions.Item>
      <Descriptions.Item label="Default Provider">
        {practice.default_provider && (
          <ProviderTag provider={practice.default_provider} />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Users">
        <>
          {practice.users.map((user) => (
            <UserTag user={user} key={user.user_id} />
          ))}
        </>
      </Descriptions.Item>
      <Descriptions.Item label="Logo" style={{ display: 'flex' }}>
        {practice.practice_logo ? (
          <img
            src={practice.practice_logo}
            style={{ width: 320, height: 120 }}
            alt="practice logo"
          />
        ) : null}
      </Descriptions.Item>
      <Descriptions.Item label="Color" style={{ display: 'flex' }}>
        {practice.practice_color ? (
          <div
            style={{
              padding: 4,
              margin: 2,
              background: '#fff',
              borderRadius: '2px',
              boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
              display: 'inline-block',
            }}
          >
            <div
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '2px',
                background: practice.practice_color,
              }}
            />
          </div>
        ) : null}
      </Descriptions.Item>
      <Descriptions.Item label="Campaigns">
        <div style={{ display: 'block' }}>
          {campaignTemplatesQuery.data?.campaign_templates.map((campaign) => (
            <div key={campaign.campaign_template_id}>
              <Link to={`/campaign-manager/${campaign.campaign_template_id}`}>
                {campaign.campaign_name}
              </Link>
              {campaign.campaign_approvals.length > 0 && (
                <span style={{ marginLeft: 12 }}>
                  <CheckCircleTwoTone twoToneColor={mabel_light_green} />{' '}
                  <i>
                    Approved{' '}
                    {dayjs(campaign.campaign_approvals[0].created_at).format(
                      'll LT'
                    )}{' '}
                    by {campaign.campaign_approvals[0].approver.user_name}
                  </i>
                </span>
              )}
            </div>
          ))}
        </div>
      </Descriptions.Item>
      <Descriptions.Item label="Practice Variables">
        <Tooltip
          title={
            <pre>
              {JSON.stringify(practice.practice_merge_vars || {}, null, 2)}
            </pre>
          }
        >
          <Typography.Text
            ellipsis
            code
            editable={{
              tooltip: false,
              editing: false,
              onStart: () => {
                setMergeVarModalVisible(true);
              },
            }}
          >
            {JSON.stringify(practice.practice_merge_vars || {}, null, 2)}
          </Typography.Text>
        </Tooltip>
        <Modal
          visible={mergeVarModalVisible}
          title={
            <Typography.Text>
              Modify Practice Variables{' '}
              <Tooltip title="Values will only be used if not defined at the campaign, component, or patient level">
                <QuestionCircleOutlined />
              </Tooltip>
            </Typography.Text>
          }
          okText="Save"
          cancelText="Cancel"
          confirmLoading={updatePracticeMutationResults.loading}
          onCancel={() => {
            mergeVarForm.resetFields();
            setMergeVarModalVisible(false);
          }}
          onOk={mergeVarForm.submit}
        >
          <KeyValueForm
            form={mergeVarForm}
            initialValues={practice.practice_merge_vars}
            onFinish={(vars) => {
              updatePracticeMutation({
                variables: {
                  practice_id: practice.practice_id,
                  changes: { practice_merge_vars: vars },
                },
              }).then(() => setMergeVarModalVisible(false));
            }}
          />
        </Modal>
      </Descriptions.Item>
      <Descriptions.Item label="BAAs">
        <div style={{ display: 'block' }}>
          {practice.baas.map((baa) => (
            <div key={cacheIdFromObject(baa) || ''}>
              <Tooltip title={`External ID: ${baa.external_id}`}>
                {baa.status === 'Completed' ? (
                  <CheckCircleTwoTone twoToneColor={mabel_light_green} />
                ) : baa.status === 'Declined' || baa.status === 'Voided' ? (
                  <WarningTwoTone twoToneColor={warning_color} />
                ) : (
                  <MailOutlined />
                )}{' '}
                {baa.status}{' '}
                <small>
                  <i>
                    {baa.recipient_name}
                    {baa.recipient_email && ` (${baa.recipient_email})`},
                    updated {dayjs(baa.updated_at).format('llll')}
                  </i>
                </small>
              </Tooltip>
            </div>
          ))}
          <BAAForm practice={practice} />
        </div>
      </Descriptions.Item>
      <Descriptions.Item>
        <SwitchRoleButton
          practice_id={practice.practice_id}
          changeRole="practice_user"
        />
      </Descriptions.Item>
    </Descriptions>
  );
};

const BAAForm = ({ practice }: { practice: PracticeBasicFragment }) => {
  const [form] = useForm();
  const { user } = useContext(UserContext);

  const [sendBaaMutation, sendBaaMutationResult] = useSendBaaMutation();
  const [createBAA, createBAAResult] = useAdminMarkBaaCompletedMutation();

  const onFinish = (values: any) => {
    const variables = {
      practice_id: practice.practice_id,
      recipient_email: values.email,
      recipient_name: values.name,
      template_vars: {
        recipient_name: values.name,
        practice_name: practice.practice_name || '',
        practice_address: practice.practice_address || '',
      },
    };
    sendBaaMutation({
      variables: variables,
      update: (cache, { data }) => {
        const cached_practice = cache.readFragment<PracticeDetailsFragment>({
          id: cacheIdFromObject(practice) as string,
          fragment: PracticeDetailsFragmentDoc,
          fragmentName: 'PracticeDetails',
        });
        if (cached_practice) {
          cache.writeFragment<PracticeDetailsFragment>({
            id: cacheIdFromObject(practice) as string,
            fragment: PracticeDetailsFragmentDoc,
            fragmentName: 'PracticeDetails',
            data: {
              ...cached_practice,
              baas: data?.send_baa?.baa
                ? [...cached_practice?.baas, data?.send_baa?.baa]
                : cached_practice?.baas,
            },
          });
        }
      },
    }).then(() => {
      form.resetFields();
    });
  };
  return (
    <Form
      name="baa"
      form={form}
      initialValues={{ practice_id: practice.practice_id }}
      onFinish={onFinish}
    >
      <Space align="start">
        <Form.Item name="name" rules={[{ required: true }]}>
          <Input placeholder="Name" size="small" prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[{ required: true, type: 'email' }]}
          validateTrigger="onBlur"
        >
          <Input placeholder="Email" size="small" prefix={<MailOutlined />} />
        </Form.Item>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) =>
            user?.role === 'admin' ? (
              <Dropdown.Button
                size="small"
                htmlType="submit"
                overlay={
                  <Menu>
                    <Menu.Item
                      key="complete"
                      icon={<CheckOutlined />}
                      disabled={!getFieldValue('name')}
                      onClick={() => {
                        createBAA({
                          variables: {
                            signatory_email: getFieldValue('email'),
                            signatory_name: getFieldValue('name'),
                            external_id: uuidv4(),
                            practice_id: practice.practice_id,
                          },
                          update: (cache, { data }) => {
                            const cached_practice = cache.readFragment<PracticeDetailsFragment>(
                              {
                                id: cacheIdFromObject(practice) as string,
                                fragment: PracticeDetailsFragmentDoc,
                                fragmentName: 'PracticeDetails',
                              }
                            );
                            if (cached_practice) {
                              cache.writeFragment<PracticeDetailsFragment>({
                                id: cacheIdFromObject(practice) as string,
                                fragment: PracticeDetailsFragmentDoc,
                                fragmentName: 'PracticeDetails',
                                data: {
                                  ...cached_practice,
                                  baas: data?.insert_business_associate_agreements_one
                                    ? [
                                        ...cached_practice.baas,
                                        data.insert_business_associate_agreements_one,
                                      ]
                                    : cached_practice?.baas,
                                },
                              });
                            }
                          },
                        });
                        form.resetFields();
                      }}
                    >
                      Manually mark as complete
                    </Menu.Item>
                  </Menu>
                }
                buttonsRender={([leftButton, rightButton]) => [
                  leftButton,
                  React.cloneElement(rightButton as any, {
                    loading:
                      sendBaaMutationResult.loading || createBAAResult.loading,
                  }),
                ]}
              >
                Send BAA
              </Dropdown.Button>
            ) : (
              <Button size="small" htmlType="submit">
                Send BAA
              </Button>
            )
          }
        </Form.Item>
      </Space>
    </Form>
  );
};

export default PracticeDescription;
export { BAAForm };
