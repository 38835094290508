import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Typography,
  Result,
  Button,
  Table,
  Space,
  Descriptions,
  Tooltip,
  Popconfirm,
  SelectProps,
  Select,
  Form,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  PlusOutlined,
  StopOutlined,
  CheckOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import {
  LetterTemplateBasicFragment,
  ListOrganizationsQuery,
  useGetLetterTemplateLazyQuery,
  useGetPracticesForOrganizationQuery,
  useListOrganizationsQuery,
  useListPublicLetterTemplatesQuery,
  useUpdateOrganizationMutation,
} from '../graphql/generated';

import { OrganizationMutationModal } from '../components/organizations/OrganizationForm';

import { BuildingOutlined } from '../components/MabelIcons';
import SwitchRoleButton from '../components/utils/SwitchRoleButton';
import NetworkTag from '../components/networks/NetworkTag';
import { useRequest } from 'ahooks';
import JSZip from 'jszip';
import { getLobTemplatePDF } from '../components/templates/LetterTemplatePreview';

const { Title } = Typography;

type OrganizationType = ListOrganizationsQuery['organizations'][0];

const OrganizationUpdateButton = ({
  children,
  organization_id,
  changes,
  require_confirm,
  confirm_title,
}: any) => {
  const [
    updateMutation,
    updateMutationResult,
  ] = useUpdateOrganizationMutation();
  return !require_confirm ? (
    <Button
      type="link"
      size="small"
      style={{ margin: 0 }}
      loading={updateMutationResult.loading}
      onClick={() => {
        updateMutation({
          variables: {
            organization_id: organization_id,
            changes: changes,
          },
        });
      }}
    >
      {children}
    </Button>
  ) : (
    <Popconfirm
      title={confirm_title}
      onConfirm={() => {
        updateMutation({
          variables: {
            organization_id: organization_id,
            changes: changes,
          },
        });
      }}
      okText="Yes"
      cancelText="No"
    >
      <Button
        type="link"
        size="small"
        style={{ margin: 0 }}
        loading={updateMutationResult.loading}
      >
        {children}
      </Button>
    </Popconfirm>
  );
};

const OrganizationsAdminPage = () => {
  const listOrganizationsQuery = useListOrganizationsQuery();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<
    OrganizationType | undefined
  >();

  if (listOrganizationsQuery.error)
    return (
      <Result
        status="error"
        title="There was an error retrieving organizations"
        subTitle={listOrganizationsQuery.error?.message}
      />
    );

  const columns: ColumnsType<OrganizationType> = [
    {
      title: 'Organization Name',
      dataIndex: 'organization_name',
      sorter: (a, b) => a.organization_name.localeCompare(b.organization_name),
    },
    {
      title: 'Description',
      dataIndex: 'organization_description',
    },
    {
      title: 'Networks',
      dataIndex: 'organization_networks',
      render: (_, record) => (
        <>
          {record.organization_networks
            .filter((on) => !on.is_archived && !on.network.is_archived)
            .map((on) => (
              <NetworkTag network={on.network} key={on.network.network_id} />
            ))}
        </>
      ),
    },
    {
      title: 'Active?',
      dataIndex: 'is_archived',
      width: 1,
      align: 'center',
      sorter: (a, b) => +a.is_archived - +b.is_archived,
      filters: [
        {
          text: 'Active',
          value: 'false',
        },
        {
          text: 'Archived',
          value: 'true',
        },
      ],
      defaultFilteredValue: ['false'],
      onFilter: (value, record) => record.is_archived.toString() === value,
      render: (is_archived) =>
        is_archived ? (
          <Tooltip title="Item has been archived">
            <StopOutlined className="danger-color" />
          </Tooltip>
        ) : (
          <CheckOutlined className="success-color" />
        ),
    },
    {
      key: 'edit',
      width: 1,
      align: 'center',
      render: (text, record) => {
        return record.is_archived ? (
          <OrganizationUpdateButton
            organization_id={record.organization_id}
            changes={{
              is_archived: false,
            }}
          >
            Restore
          </OrganizationUpdateButton>
        ) : (
          <div style={{ display: 'inline-flex' }}>
            <Button
              type="link"
              size="small"
              style={{ margin: 0 }}
              onClick={() => {
                setSelectedRecord(record);
                setShowUpdateModal(true);
              }}
            >
              Edit
            </Button>
            <OrganizationUpdateButton
              organization_id={record.organization_id}
              changes={{
                is_archived: true,
              }}
              require_confirm={true}
              confirm_title="Archive this item?"
            >
              Archive
            </OrganizationUpdateButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Organizations Admin | Mabel</title>
      </Helmet>
      <Title level={3}>
        <BuildingOutlined /> Organizations
      </Title>
      <Space direction="vertical" style={{ width: '100%' }}>
        <div style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowCreateModal(true)}
          >
            Add Organization
          </Button>
          <OrganizationMutationModal
            formName="Create"
            visible={showCreateModal}
            onCancel={() => setShowCreateModal(false)}
            afterSubmit={() => setShowCreateModal(false)}
          />
          <OrganizationMutationModal
            formName="Update"
            initialRecord={selectedRecord}
            visible={showUpdateModal}
            onCancel={() => setShowUpdateModal(false)}
            afterSubmit={() => setShowUpdateModal(false)}
          />
        </div>
        <Table<OrganizationType>
          size="small"
          rowKey="organization_id"
          loading={listOrganizationsQuery.loading}
          dataSource={listOrganizationsQuery.data?.organizations}
          pagination={{
            position: ['bottomRight'],
            showSizeChanger: true,
            defaultPageSize: 100,
          }}
          columns={columns}
          showSorterTooltip={false}
          expandable={{
            expandedRowRender: (record) => (
              <Descriptions
                title={record.organization_name}
                layout="horizontal"
                column={1}
                style={{ margin: '24px 48px' }}
              >
                <Descriptions.Item label="ID">
                  {record.organization_id}
                </Descriptions.Item>
                <Descriptions.Item label="Description">
                  {record.organization_description}
                </Descriptions.Item>
                <Descriptions.Item label="Dashboards">
                  {JSON.stringify(record.dashboards)}
                </Descriptions.Item>
                <Descriptions.Item>
                  <TemplatePacketGenerator
                    organization_id={record.organization_id}
                  />
                </Descriptions.Item>
                <Descriptions.Item>
                  <SwitchRoleButton
                    organization_id={record.organization_id}
                    changeRole="organization_user"
                  />
                </Descriptions.Item>
              </Descriptions>
            ),
          }}
        ></Table>
      </Space>
    </>
  );
};

const PublicLetterTemplateSelect: React.FC<
  SelectProps<string>
> = React.forwardRef((props, ref) => {
  const letterTemplatesQueryResult = useListPublicLetterTemplatesQuery({
    fetchPolicy: 'cache-and-network',
  });
  return (
    <Select
      showSearch
      optionFilterProp="label"
      placeholder="Select a letter template"
      {...props}
      options={(letterTemplatesQueryResult.data?.letter_templates || []).map(
        (template) => {
          return {
            label: template.letter_template_name,
            value: template.letter_template_id,
          };
        }
      )}
    />
  );
});

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function processInBatches<T, U>(
  items: T[],
  worker: (item: T) => Promise<U>,
  batchSize: number,
  waitTime: number
): Promise<U[]> {
  let result: U[] = [];
  for (let i = 0; i < items.length; i += batchSize) {
    const batch = items.slice(i, i + batchSize);
    result = [...result, ...(await Promise.all(batch.map(worker)))];
    if (i + batchSize < items.length) {
      await delay(waitTime);
    }
  }
  return result;
}

const TemplatePacketGenerator: React.FC<{
  organization_id: string;
}> = ({ organization_id }) => {
  const getValidFilename = (s: string) => s.replace(/[\\/:"*?<>|]/g, '-');
  // const getTemplateDetails = useGetLetterTemplateQuery({
  //   variables: { letter_template_id: '28d93224-7317-4e08-8d07-d0ed6e888a70' },
  // });

  const [
    getTemplateDetails,
    getTemplateDetailsResult,
  ] = useGetLetterTemplateLazyQuery();

  const getPracticesForOrgQuery = useGetPracticesForOrganizationQuery({
    variables: { organization_id: organization_id },
  });

  const getTemplateForPractice = async (
    template: LetterTemplateBasicFragment,
    practice_id: string
  ) => {
    const pdfResponse = await getLobTemplatePDF(
      template.external_id,
      template.external_secondary_id,
      template.size,
      template.format,
      template.letter_template_id,
      practice_id
    );
    return pdfResponse;
  };

  const downloadPracticeTemplatesRequest = useRequest(
    async () => {
      const jszip = new JSZip();
      const folder_name = `${getValidFilename(
        getTemplateDetailsResult.data?.letter_templates_by_pk
          ?.letter_template_name!
      )} ${new Date().toISOString().split('T')[0]}`;

      const templateFolder = jszip.folder(folder_name);
      const networks = getPracticesForOrgQuery.data?.networks;
      await Promise.all(
        networks!.map(async (network) => {
          const networkFolder = templateFolder?.folder(network.network_name);
          const practices = network.practices.filter(
            (p) =>
              !p.practice_description?.includes('#HEDR') &&
              !p.practice_description?.includes('#BNL') &&
              !p.practice_name?.includes('Unaffiliated')
          );

          const practicePDFs = await processInBatches(
            practices,
            (p) =>
              getTemplateForPractice(
                getTemplateDetailsResult.data?.letter_templates_by_pk!,
                p.practice_id
              ),
            25,
            1000
          );
          practicePDFs.forEach((pdf, i) => {
            const practice_name_template_name_i = getValidFilename(
              `${practices[i].practice_name} ${getTemplateDetailsResult.data
                ?.letter_templates_by_pk?.letter_template_name!}_${i}`
            );
            if (pdf.back) {
              networkFolder?.file(
                `${practice_name_template_name_i} (Front).pdf`,
                pdf.front
              );
              networkFolder?.file(
                `${practice_name_template_name_i} (Back).pdf`,
                pdf.back
              );
            } else {
              networkFolder?.file(
                `${practice_name_template_name_i}.pdf`,
                pdf.front
              );
            }
          });
        })
      );

      const blob = await jszip.generateAsync({ type: 'blob' });
      saveAs(blob, `${folder_name}.zip`);
    },
    {
      manual: true,
      ready: Boolean(getTemplateDetailsResult.data),
    }
  );

  return (
    <Form
      layout="inline"
      onValuesChange={(_, allValues) => {
        getTemplateDetails({
          variables: {
            letter_template_id: allValues.letter_template_id,
          },
        });
      }}
      onFinish={() => downloadPracticeTemplatesRequest.run()}
    >
      <Form.Item
        label="Select a Letter Template"
        name="letter_template_id"
        rules={[{ required: true, message: 'Please select a letter template' }]}
      >
        <PublicLetterTemplateSelect style={{ width: 300 }} />
      </Form.Item>
      <Form.Item>
        <Button
          type="dashed"
          htmlType="submit"
          icon={<DownloadOutlined />}
          loading={
            downloadPracticeTemplatesRequest.loading ||
            getTemplateDetailsResult.loading
          }
        >
          Download Previews for All Practices
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OrganizationsAdminPage;
