import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Typography } from 'antd';
import { useLocation } from 'react-router-dom';

import HEDRTable from '../components/health-equity-reports/HEDRTable';
import { FileTextOutlined } from '@ant-design/icons';

const HEDRProcessingPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('query') || undefined;

  return (
    <>
      <Helmet>
        <title>HEDR Processing | Mabel</title>
      </Helmet>
      <Typography.Title level={3}>
        <FileTextOutlined /> HEDR Processing
      </Typography.Title>
      <HEDRTable initialQuery={query} />
    </>
  );
};

export default HEDRProcessingPage;
