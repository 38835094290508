import React from 'react';

import { Empty, Spin } from 'antd';

import { useGetCampaignTemplateQuery } from '../graphql/generated';

import CampaignEditPage from './CampaignEditPage';
import { useParams } from 'react-router-dom';

const CampaignsPage = () => {
  const { campaign_template_id } = useParams<{
    campaign_template_id: string;
  }>();

  const getCampaignTemplateResult = useGetCampaignTemplateQuery({
    variables: {
      campaign_template_id: campaign_template_id,
    },
  });

  return getCampaignTemplateResult.called &&
    !getCampaignTemplateResult.loading &&
    !getCampaignTemplateResult.data?.campaign_templates_by_pk ? (
    <Empty description="Campaign not found" />
  ) : (
    <Spin spinning={getCampaignTemplateResult.loading}>
      {getCampaignTemplateResult.data?.campaign_templates_by_pk && (
        <CampaignEditPage
          campaign={getCampaignTemplateResult.data?.campaign_templates_by_pk}
        />
      )}
    </Spin>
  );
};

export default CampaignsPage;
