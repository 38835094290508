import React, { useState, useEffect, useContext } from 'react';

import { Auth } from 'aws-amplify';

import { Form, Input, Modal, Switch, Typography, ModalProps, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { UserContext } from '../../contexts/UserContext';
import QRCodeCanvas from 'qrcode.react';
import { useRequest } from 'ahooks';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import {
  UserDetailsFragment,
  useUpdateUserByPkMutation,
} from '../../graphql/generated';

const MFAsettingsForm: React.FC<{ initialUser: UserDetailsFragment }> = ({
  initialUser,
}) => {
  const [form] = useForm();
  const { user } = useContext(UserContext);

  useEffect(() => {
    form.setFieldsValue({ mfa_enabled: initialUser.preferred_mfa !== 'NOMFA' });
  }, [initialUser, form]);

  const [showTOTPSetupModal, setShowTOTPSetupModal] = useState(false);
  const [updateUserMutation] = useUpdateUserByPkMutation();

  const removeTOTPoption = async () => {
    try {
      await updateUserMutation({
        variables: {
          user_id: user?.user_id!,
          changes: { preferred_mfa: 'NOMFA' },
        },
      });
      await Auth.setPreferredMFA(user?.cognito_user, 'NOMFA');
    } catch (error) {
      // Rethrow the error so useRequest can catch it
      throw error;
    }
  };
  const { run: runRemoveTOTP, loading: removeTOTPLoading } = useRequest(
    removeTOTPoption,
    {
      manual: true,
      throwOnError: true,
    }
  );

  return (
    <>
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
        <Form.Item
          label="Enable MFA"
          name="mfa_enabled"
          valuePropName="checked"
        >
          <Switch
            loading={removeTOTPLoading}
            checkedChildren={<LockOutlined />}
            unCheckedChildren={<UnlockOutlined />}
            onChange={(checked) => {
              if (checked) {
                setShowTOTPSetupModal(true);
              } else {
                Modal.confirm({
                  title: 'Disable Multi-Factor Authentication',
                  content: 'Are you sure you want to disable MFA?',
                  okText: 'Disable',
                  centered: true,
                  onCancel: () => {
                    form.setFieldsValue({ mfa_enabled: true });
                  },
                  onOk: runRemoveTOTP,
                });
              }
            }}
          />
        </Form.Item>
      </Form>
      <TOTPSetupModal
        modalProps={{
          visible: showTOTPSetupModal,
          onCancel: () => {
            form.setFieldsValue({ mfa_enabled: false });
            setShowTOTPSetupModal(false);
          },
        }}
      />
    </>
  );
};

const TOTPSetupModal: React.FC<{
  modalProps: ModalProps;
}> = ({ modalProps }) => {
  const { user } = useContext(UserContext);
  const [form] = useForm();

  const getTOTPsetupData = async () => {
    const userData = await Auth.userAttributes(user?.cognito_user);
    const secret = await Auth.setupTOTP(user?.cognito_user);
    return {
      email: userData?.find((a) => a.getName() === 'email')?.getValue(),
      secret: secret,
    };
  };
  const { run, data, loading } = useRequest(getTOTPsetupData);
  useEffect(() => {
    if (modalProps.visible) {
      run();
    }
  }, [run, modalProps.visible]);

  const [updateUserMutation] = useUpdateUserByPkMutation();

  const verifyTotpToken = async (totpCode: string) => {
    try {
      await Auth.verifyTotpToken(user?.cognito_user, totpCode);
      // update the database first so that switch displays correctly
      await updateUserMutation({
        variables: {
          user_id: user?.user_id!,
          changes: { preferred_mfa: 'SOFTWARE_TOKEN_MFA' },
        },
      });
      // don't forget to set TOTP as the preferred MFA method
      await Auth.setPreferredMFA(user?.cognito_user, 'TOTP');
    } catch (error) {
      // Rethrow the error so useRequest can catch it
      throw error;
    }
  };

  const {
    run: runVerify,
    loading: loadingVerify,
    error: errorVerify,
    reset: resetVerify,
  } = useRequest(verifyTotpToken, {
    manual: true,
  });

  return (
    <Modal
      {...modalProps}
      title="Setup Multi-Factor Authentication"
      width={640}
      okText="Verify"
      onOk={() => form.submit()}
      onCancel={(e) => {
        form.resetFields();
        modalProps.onCancel?.(e);
      }}
      confirmLoading={loadingVerify}
      centered
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: 24,
        }}
      >
        <Spin spinning={loading}>
          <QRCodeCanvas
            value={`otpauth://totp/${encodeURI(
              process.env.REACT_APP_WEBSITE_NAME || ''
            )}:${data?.email}?secret=${data?.secret}&issuer=${encodeURI(
              process.env.REACT_APP_WEBSITE_NAME || ''
            )}`}
            size={192}
          />
        </Spin>
      </div>
      <p>
        <b>Step 1:</b> Scan the QR code above in your preferred authenticator
        app, e.g. Google Authenticator.
      </p>
      <p>
        <i>
          (Recommended) Record the following secret in case you lose your 2nd
          factor device:
        </i>
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '1em',
        }}
      >
        <Spin spinning={loading}>
          <Typography.Text type="secondary">{data?.secret}</Typography.Text>
        </Spin>
      </div>
      <p>
        <b>Step 2:</b> Enter the one-time code generated by your authenticator
        app
      </p>
      <Form
        form={form}
        validateTrigger="onBlur"
        onFinish={({ totp_code }) => {
          resetVerify();
          runVerify(totp_code);
        }}
      >
        <Form.Item
          name="totp_code"
          rules={[
            {
              required: true,
              message: 'Please enter the code from your authenticator app',
            },
            { pattern: /^\d+$/, message: 'Invalid code format' },
            { min: 6, message: 'Code must be at least 6 digits' },
          ]}
        >
          <Input placeholder="123456" />
        </Form.Item>
      </Form>
      {errorVerify && (
        <Typography.Text type="danger">
          Error: {errorVerify.message}
        </Typography.Text>
      )}
    </Modal>
  );
};

export { MFAsettingsForm };
