import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  QuestionCircleTwoTone,
  WarningTwoTone,
} from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import React from 'react';
import { error_color, mabel_light_grey, warning_color } from '../../colors';

const ValidatedAddress: React.FC<{
  address?: JSX.Element | string;
  deliverability?: string;
}> = ({ address, deliverability }) => {
  return (
    <Space direction="horizontal">
      <Tooltip title={deliverability || 'deliverability unknown'}>
        {deliverability === 'undeliverable' ? (
          <ExclamationCircleTwoTone twoToneColor={error_color} />
        ) : deliverability === 'deliverable' ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : deliverability?.startsWith('deliverable') ? (
          <WarningTwoTone twoToneColor={warning_color} />
        ) : !deliverability ? (
          <QuestionCircleTwoTone twoToneColor={mabel_light_grey} />
        ) : null}
      </Tooltip>
      {address}
    </Space>
  );
};

export default ValidatedAddress;
