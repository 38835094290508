import React from 'react';

import { Tooltip, Spin, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import {
  CampaignSmsComponentDetailsFragment,
  SmsTemplateBasicFragment,
  PatientBasicFragment,
  PracticeDetailsFragment,
} from '../../graphql/generated';

import { useRequest } from 'ahooks';
import * as Handlebars from 'handlebars';
import { getMergeVars } from './TemplateUtils';

const { Text } = Typography;

const SMSTemplatePreview: React.FC<{
  sms_template: SmsTemplateBasicFragment;
  practice: PracticeDetailsFragment;
  campaign_sms_component?: CampaignSmsComponentDetailsFragment;
  patient?: PatientBasicFragment;
}> = ({ sms_template, practice, campaign_sms_component, patient }) => {
  const { data: mergeVars, loading: mergeVarsLoading } = useRequest(
    async () =>
      getMergeVars(
        'sms',
        campaign_sms_component?.campaign_sms_component_id,
        practice.practice_id,
        patient?.patient_id,
        sms_template.sms_template_id
      ),
    {
      refreshDeps: [campaign_sms_component, practice, patient, sms_template],
    }
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '12px 0px',
      }}
    >
      <Spin spinning={mergeVarsLoading}>
        <div style={{ textAlign: 'right', fontSize: 12, marginBottom: 8 }}>
          <Text style={{ width: '80%' }}>
            To:{' '}
            {patient?.patient_phone ? (
              `${patient.patient_phone}`
            ) : (
              <Text type="secondary">
                <i>empty number</i>
              </Text>
            )}
          </Text>
          <br />
        </div>
        <div style={{ textAlign: 'left', fontSize: 12, marginBottom: 2 }}>
          <Text type="secondary" ellipsis style={{ width: '80%' }}>
            `+1 (555) 555-5555
          </Text>
          <br />
        </div>
        <div className="sms-receive">
          {sms_template.body_text && mergeVars
            ? Handlebars.compile(sms_template.body_text)(mergeVars)
            : ''}
        </div>
      </Spin>
      <Tooltip title="Download SMS text">
        <a
          href={`data:application/octet-stream,${encodeURIComponent(
            sms_template.body_text && mergeVars
              ? Handlebars.compile(sms_template.body_text)(mergeVars)
              : ''
          )}`}
          download={`${sms_template.sms_template_name}.txt`}
        >
          <Spin spinning={mergeVarsLoading}>
            <DownloadOutlined style={{ fontSize: 18, marginLeft: 8 }} />
          </Spin>
        </a>
      </Tooltip>
    </div>
  );
};
export default SMSTemplatePreview;
