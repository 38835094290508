import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Storage } from 'aws-amplify';

import { useParams, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import Papa from 'papaparse';

import WomanOnStool from '../images/Woman_On_Stool.png';

import { mabel_light_green, warning_color } from '../colors';
import {
  Typography,
  Steps,
  Layout,
  Button,
  Space,
  Skeleton,
  Result,
  Upload,
  message,
  Alert,
  Tooltip,
  Row,
  Col,
  Select,
  Form,
  Table,
  Collapse,
  Spin,
  Progress,
} from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { AlertProps } from 'antd/lib/alert/';
import { FormItemProps } from 'antd/lib/form/';

import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  CheckCircleTwoTone,
  WarningTwoTone,
  MailOutlined,
  ReloadOutlined,
  InboxOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import {
  useGetUserDetailsQuery,
  useGetPracticeDetailsLazyQuery,
  PracticeDetailsFragment,
  useUpdatePracticeMutation,
  useCreatePatientRosterFileMutation,
  useUpdatePatientRosterFileMutation,
  useUpsertPatientsMutation,
  PatientBasicFragment,
  Patients_Update_Column,
  Order_By,
  refetchSearchPatientsAdvancedQuery,
  refetchGetPatientCountQuery,
} from '../graphql/generated';

import { cacheIdFromObject } from '../graphql/utils';
import { UserContext } from '../contexts/UserContext';
import { BAAForm } from '../components/practices/PracticeDescription';
import { PracticeForm } from '../components/practices/PracticeForm';
import { useForm, FormInstance } from 'antd/lib/form/Form';
const { Step } = Steps;

// Taken from https://emailregex.com/
const EMAIL_SYNTAX_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_SYNTAX_REGEX = /^(?:(?:\+1\s?)?[(]?(\d{3})[)]?[\s.-]?\d{3}[\s.-]?\d{4})$/;
const LOOSE_MBI_SYNTAX_REGEX = /^[1-9][^SLOIBZsloibz\W_0-9][^SLOIBZsloibz\W_][0-9]-?[^SLOIBZsloibz\W_0-9][^SLOIBZsloibz\W_][0-9]-?[^SLOIBZsloibz\W_0-9]{2}[0-9]{2}$/;
const INACTIVE_SYNTAX_REGEX = /^inactive|deceased|archived|true$/i;
const DO_NOT_CONTACT_SYNTAX_REGEX = /^1|yes|x|true$/i;

const PATIENT_ID_REGEX = /((patient|pat)[ _-]?id)|^id$/i;
const FULL_NAME_REGEX = /^(patient|full)[ _-]?name$/i;
const TITLE_REGEX = /^(title|suffix)$/i;
const FIRST_NAME_REGEX = /(first|given)[ _-]?name/i;
const MIDDLE_NAME_REGEX = /middle[ _-]?name/i;
const LAST_NAME_REGEX = /((last|family)[ _-]?name)|surname/i;
const BIRTH_DATE_REGEX = /dob|(birth[ _-]?date)|(date[ _-]?of[ _-]?birth)/i;
const DECEASED_DATE_REGEX = /deceased_date/i;
const EMAIL_REGEX = /email[_]?(address)?/i;
const DO_NOT_EMAIL_REGEX = /^do_not_email$/i;
const MAILING_ADDRESS_REGEX = /^mailing[ _-]?address$/i;
const ADDRESS_LINE_1_REGEX = /address[ _-]?(line)?[ _-]?1/i;
const ADDRESS_LINE_2_REGEX = /address[ _-]?(line)?[ _-]?2/i;
const CITY_REGEX = /^city|[^a-z]city/i;
const STATE_REGEX = /^state|[^a-z]state/i;
const ZIP_CODE_REGEX = /(^zip|[^a-z]zip)[ _-]?(code)?/i;
const DO_NOT_DIRECT_MAIL_REGEX = /^do_not_direct_mail$/i;
const PHONE_REGEX = /phone/i;
const PCP_REGEX = /^((primary[_-]care[_-]|primary[_-])?provider|pcp)$/i;
const LOGO_REGEX = /^((patient[_-])?logo)$/i;
const NPI_REGEX = /(provider[_-])?npi([ _-]number)?/i;
const TIN_REGEX = /tin/i;
const GENDER_REGEX = /gender|sex(_assigned_at_birth)?/i;
const MBI_REGEX = /(mbi)|(medicare[ _-]beneficiary[ _-]identifier)/i;

const PRIMARY_INS_PAYER_ID_REGEX = /^primary_insurance_payer_id$/i;
const PRIMARY_INS_PAYER_NAME_REGEX = /^primary_insurance_payer_name$/i;
const PRIMARY_INS_PLAN_NAME_REGEX = /^primary_insurance_plan_name$/i;
const PRIMARY_INS_MEMBER_ID_REGEX = /^primary_insurance_member_id$/i;
const SECONDARY_INS_PAYER_ID_REGEX = /^secondary_insurance_payer_id$/i;
const SECONDARY_INS_PAYER_NAME_REGEX = /^secondary_insurance_payer_name$/i;
const SECONDARY_INS_PLAN_NAME_REGEX = /^secondary_insurance_plan_name$/i;
const SECONDARY_INS_MEMBER_ID_REGEX = /^secondary_insurance_member_id$/i;
const INACTIVE_REGEX = /^(inactive)|(disabled)$/i;

const patientSampleFileJSON = [
  {
    patient_id: '12345ABCD',
    //name
    title: 'Dr.',
    first_name: 'Anthony',
    middle_name: 'Stephen',
    last_name: 'Fauci',
    birth_date: '08/10/1942',
    //email
    email: 'fauci@example.com',
    do_not_email: '',
    //mail
    address_line_1: '600 William Street',
    address_line_2: '439',
    city: 'Oakland',
    state: 'CA',
    zip_code: '94612',
    do_not_direct_mail: '',
    //phone,
    mobile_phone: '+15035551234',
    //insurance
    mbi: '1EG4-TE5-MK73',
    primary_insurance_member_id: 'ASDF-9871-356',
    primary_insurance_payer_id: '5321',
    primary_insurance_payer_name: 'Blue Cross of California',
    primary_insurance_plan_name: '',
    secondary_insurance_member_id: '232112312',
    secondary_insurance_payer_id: '32421',
    secondary_insurance_payer_name: 'Medi-Cal',
    secondary_insurance_plan_name: '',
    pcp: 'Dr. John Dolittle',
    logo:
      'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png',
    provider_npi: '1234567890',
    inactive: '',
  },
  {
    patient_id: '67890XYS',
    //name
    title: 'Mr.',
    first_name: 'William',
    middle_name: 'Bradley',
    last_name: 'Pitt',
    //date of birth
    birth_date: '1963-12-18',
    //email
    email: 'bradp@example.com',
    do_not_email: 'true',
    //mail
    address_line_1: '8 10th Street',
    address_line_2: '#3005',
    city: 'San Francisco',
    state: 'CA',
    zip_code: '94103',
    do_not_direct_mail: 'true',
    //phone,
    mobile_phone: '415-555-1234',
    //insurance
    mbi: '4QG4TT5MK72',
    primary_insurance_member_id: 'AWF-1123-356',
    primary_insurance_payer_id: '83211',
    primary_insurance_payer_name: 'Noridian Part B Claims-Medicare',
    primary_insurance_plan_name: '',
    secondary_insurance_member_id: '3212322',
    secondary_insurance_payer_id: '98765',
    secondary_insurance_payer_name: 'Medi-Cal',
    secondary_insurance_plan_name: '',
    pcp: 'Dr. Theodor Seuss',
    logo:
      'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png',
    inactive: '',
  },
];

type PatientFieldMap = Omit<
  { [P in keyof PatientBasicFragment]: string[] },
  'patient_id' | 'practice_id' | 'created_at' | 'updated_at'
>;

const getFieldMap = (fields: string[]): PatientFieldMap => {
  return {
    external_id: [
      fields.find((f) => PATIENT_ID_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    patient_name: fields.find((f) => FULL_NAME_REGEX.test(f))
      ? [fields.find((f) => FULL_NAME_REGEX.test(f)) as string]
      : [
          fields.find((f) => TITLE_REGEX.test(f)),
          fields.find((f) => FIRST_NAME_REGEX.test(f)),
          fields.find((f) => MIDDLE_NAME_REGEX.test(f)),
          fields.find((f) => LAST_NAME_REGEX.test(f)),
        ].filter((f): f is string => Boolean(f)),
    birth_date: [
      fields.find((f) => BIRTH_DATE_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    deceased_as_of_date: [
      fields.find((f) => DECEASED_DATE_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    patient_email: [
      fields.find((f) => EMAIL_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    patient_address: fields.find((f) => MAILING_ADDRESS_REGEX.test(f))
      ? [fields.find((f) => MAILING_ADDRESS_REGEX.test(f)) as string]
      : [
          fields.find((f) => ADDRESS_LINE_1_REGEX.test(f)),
          fields.find((f) => ADDRESS_LINE_2_REGEX.test(f)),
          fields.find((f) => CITY_REGEX.test(f)),
          fields.find((f) => STATE_REGEX.test(f)),
          fields.find((f) => ZIP_CODE_REGEX.test(f)),
        ].filter((f): f is string => Boolean(f)),
    patient_phone: [
      fields.find((f) => PHONE_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    do_not_email: [
      fields.find((f) => DO_NOT_EMAIL_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    do_not_direct_mail: [
      fields.find((f) => DO_NOT_DIRECT_MAIL_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    primary_insurance_member_id: [
      fields.find((f) => PRIMARY_INS_MEMBER_ID_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    primary_insurance_payer_id: [
      fields.find((f) => PRIMARY_INS_PAYER_ID_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    primary_insurance_payer_name: [
      fields.find((f) => PRIMARY_INS_PAYER_NAME_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    primary_insurance_plan_name: [
      fields.find((f) => PRIMARY_INS_PLAN_NAME_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    secondary_insurance_member_id: [
      fields.find((f) => SECONDARY_INS_MEMBER_ID_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    secondary_insurance_payer_id: [
      fields.find((f) => SECONDARY_INS_PAYER_ID_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    secondary_insurance_payer_name: [
      fields.find((f) => SECONDARY_INS_PAYER_NAME_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    secondary_insurance_plan_name: [
      fields.find((f) => SECONDARY_INS_PLAN_NAME_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    is_archived: [
      fields.find((f) => INACTIVE_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    patient_pcp: [
      fields.find((f) => PCP_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    patient_logo: [
      fields.find((f) => LOGO_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    mbi: [fields.find((f) => MBI_REGEX.test(f))].filter((f): f is string =>
      Boolean(f)
    ),
    suspected_provider_npi: [
      fields.find((f) => NPI_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    alignment_tin: [
      fields.find((f) => TIN_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
    sex_assigned_at_birth: [
      fields.find((f) => GENDER_REGEX.test(f)),
    ].filter((f): f is string => Boolean(f)),
  };
};

const extractPatientFields = (record: any, field_map: PatientFieldMap) => {
  return Object.entries(field_map).reduce((output: any, [key, fields]) => {
    if (fields?.length) {
      output[key] = fields
        ?.map((field: string) => (record[field] || '').trim())
        .filter(Boolean)
        .join(key === 'patient_address' ? ', ' : ' ');
    }
    return output;
  }, {});
};

const validateFieldSyntax = (
  fieldName: keyof PatientFieldMap,
  value: string
) => {
  if (fieldName === 'birth_date') {
    return dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : null;
  } else if (fieldName === 'deceased_as_of_date') {
    return dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : null;
  } else if (fieldName === 'patient_email') {
    return EMAIL_SYNTAX_REGEX.test(value) ? value : null;
  } else if (fieldName === 'patient_phone') {
    return PHONE_SYNTAX_REGEX.test(value) ? value : null;
  } else if (fieldName === 'is_archived') {
    return INACTIVE_SYNTAX_REGEX.test(value);
  } else if (fieldName === 'do_not_email') {
    return DO_NOT_CONTACT_SYNTAX_REGEX.test(value);
  } else if (fieldName === 'do_not_direct_mail') {
    return DO_NOT_CONTACT_SYNTAX_REGEX.test(value);
  } else if (fieldName === 'mbi') {
    return LOOSE_MBI_SYNTAX_REGEX.test(value)
      ? value.replaceAll('-', '').toUpperCase()
      : null;
  }
  return value;
};

interface ParseStat {
  empty: number;
  samples: any[];
}

interface ParseData {
  data: any[];
  errors: Papa.ParseError[];
  meta: Papa.ParseMeta;
  stats: { [key: string]: ParseStat };
}

const OnboardingPage = () => {
  const history = useHistory();
  const params = useParams<{ step?: string }>();
  const [currentStep, setCurrentStep] = useState(0);
  useEffect(() => {
    const step = params.step;
    setCurrentStep(step && +step >= 0 ? +step : 0);
  }, [params.step, setCurrentStep, currentStep]);

  const { user } = useContext(UserContext);
  const userDetailsQuery = useGetUserDetailsQuery({
    variables: { user_id: user?.user_id },
  });
  const [
    practiceDetailsQuery,
    practiceDetailsQueryResult,
  ] = useGetPracticeDetailsLazyQuery();
  useEffect(() => {
    const practice_id = userDetailsQuery.data?.users_by_pk?.practice_id;
    if (practice_id) {
      practiceDetailsQuery({ variables: { practice_id: practice_id } });
    }
  }, [userDetailsQuery.data, practiceDetailsQuery]);

  const [practiceForm] = useForm();
  const [
    updatePracticeMutation,
    updatePracticeMutationResults,
  ] = useUpdatePracticeMutation({
    onCompleted: () => advanceStep(),
  });

  const [playAnimation] = useState(false);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [parseResults, setParseResults] = useState<ParseData | undefined>();
  const getFieldStats = (
    fieldName: keyof PatientFieldMap
  ): ParseStat | undefined => {
    //Inelegant, but object keys are stringified, and comma could appear in column names
    //Using an odd separator sidesteps this
    const fieldJoiner = '<--|-->';
    if (fieldMap && parseResults) {
      const fieldKey = fieldMap[fieldName]?.slice().sort().join(fieldJoiner);

      if (fieldKey && !(fieldKey in parseResults!.stats)) {
        const fieldStats: ParseStat = { empty: 0, samples: [] };
        parseResults.data.forEach((row) => {
          const fieldVal = extractPatientFields(row, fieldMap)[fieldName];
          if (!fieldVal) fieldStats.empty++;
          else if (fieldStats.samples.length < 2) fieldStats.samples.push(row);
        });

        setParseResults((pf) => {
          pf!.stats[fieldKey] = fieldStats;
          return pf;
        });
        return fieldStats;
      } else if (fieldKey && fieldKey in parseResults.stats) {
        return parseResults!.stats[fieldKey];
      }
    }
  };

  const [patientUploadCount, setPatientUploadCount] = useState(0);
  const [
    upsertPatientsMutation,
    upsertPatientsMutationResult,
  ] = useUpsertPatientsMutation({
    onCompleted: (result) => {
      const numUpserted = result.insert_patients?.affected_rows || 0;
      setPatientUploadCount(patientUploadCount + numUpserted);
    },
    refetchQueries: [
      refetchGetPatientCountQuery(),
      refetchSearchPatientsAdvancedQuery({
        exact_query: null,
        substring_query: null,
        additional_filters: [{ is_archived: { _in: [false] } }],
        offset: 0,
        limit: 10,
        order_by: [{ created_at: Order_By.Desc }],
      }),
    ],
  });

  const [fieldMapForm] = useForm();
  const [fieldMap, setFieldMap] = useState<PatientFieldMap>();

  const getPatientObjectFromRow = (record: any, field_map: PatientFieldMap) => {
    const output = {
      patient_id: uuidv4(),
      practice_id: userDetailsQuery.data?.users_by_pk?.practice_id,
    } as any;
    const extracted_fields = extractPatientFields(record, field_map);
    Object.entries(extracted_fields).forEach(([key, value]) => {
      output[key] = validateFieldSyntax(
        key as keyof PatientFieldMap,
        value as string
      );
    });
    return output;
  };

  const patientUpsertPromiseChain = async (
    rows: any[],
    values: any
  ): Promise<any> => {
    const batchSize = 500;
    const mapped_cols = Object.keys(values).filter((k) => values[k].length > 0);
    if (rows.length === 0) {
      return Promise.resolve();
    } else {
      await upsertPatientsMutation({
        variables: {
          objects: rows
            .slice(0, batchSize)
            .map((row) => getPatientObjectFromRow(row, values)),
          cols_to_update: mapped_cols as Patients_Update_Column[],
        },
      });
      return patientUpsertPromiseChain(
        rows.slice(batchSize, rows.length),
        values
      );
    }
  };

  const [updateFileMutation] = useUpdatePatientRosterFileMutation();
  const uploadPatients = (values: any) => {
    if (parseResults) {
      const rows = parseResults.data;
      patientUpsertPromiseChain(rows, values).then(() => {});

      const url = new URL(fileList[fileList.length - 1].url!);
      if (url) {
        const match = url.pathname.match(
          /^\/(?<level>[^\s/]+)\/(?<identity_id>[^\s/]+)\/(?<key>\S+)/
        );
        updateFileMutation({
          variables: {
            identity_id: decodeURIComponent(match?.groups?.identity_id!),
            key: decodeURIComponent(match?.groups?.key!),
            level: decodeURIComponent(match?.groups?.level!),
            changes: { field_mapping: fieldMap },
          },
        });
      }
    }
  };

  if (userDetailsQuery.error) {
    return (
      <Result
        status="error"
        title="There was an error retrieving your user information"
        subTitle={userDetailsQuery.error.message}
      />
    );
  }
  if (practiceDetailsQueryResult.error) {
    return (
      <Result
        status="error"
        title="There was an error retrieving your practice information"
        subTitle={practiceDetailsQueryResult.error.message}
      />
    );
  }
  const practiceBaas =
    practiceDetailsQueryResult.data?.practices_by_pk?.baas || [];
  const completedBaas = practiceBaas.filter(
    (baa) => baa.status === 'Completed'
  );
  const advanceStep = () => {
    history.push(`/onboarding/${currentStep + 1}`);
  };

  const steps = [
    {
      key: 'welcome',
      title: 'Welcome to Mabel',
      description: "Let's get started",
      content: <WelcomeStep playAnimation={playAnimation} />,
      onNext: advanceStep,
    },
    {
      key: 'sign-baa',
      title: 'Sign BAA',
      description: 'Ensure HIPAA compliance',
      content: (
        <BAAStep practice={practiceDetailsQueryResult.data?.practices_by_pk!} />
      ),
      status: (index: number) =>
        completedBaas.length > 0
          ? 'finish'
          : currentStep > index
          ? 'error'
          : undefined,
      //canAdvance should be specified and eval to false if next button should be disabled
      canAdvance: () =>
        (practiceDetailsQueryResult.data?.practices_by_pk?.baas || []).length >
        0,
    },
    {
      key: 'practice-setup',
      title: 'Practice Setup',
      description: "Customize your practice's logo, color, and more",
      onNext: () => practiceForm.submit(),
      content: (
        <>
          <Typography.Title level={2}>Practice Setup</Typography.Title>
          <Space
            direction="vertical"
            style={{ maxWidth: 640, textAlign: 'left' }}
          >
            <Typography.Text style={{ textAlign: 'center' }}>
              The information you provide here will be used to customize your
              marketing materials.
            </Typography.Text>
            <PracticeForm
              form={practiceForm}
              initialPractice={
                practiceDetailsQueryResult.data?.practices_by_pk!
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              style={{ margin: '28px 0px' }}
              updateMutation={updatePracticeMutation}
            />
          </Space>
        </>
      ),
    },
    {
      key: 'upload-patient-file',
      title: 'Upload Patient List',
      description: 'Provide a .csv file with patient contact info',
      content: (
        <FileUploadStep
          practice={practiceDetailsQueryResult.data?.practices_by_pk!}
          fileList={fileList}
          setFileList={setFileList}
          parseResults={parseResults}
          setParseResults={setParseResults}
        />
      ),
      canAdvance: () => Boolean(parseResults),
      status: (index: number) =>
        fileList.length > 0
          ? 'finish'
          : currentStep > index
          ? 'error'
          : undefined,
    },
    {
      key: 'validate-import',
      title: 'Validate Import Data',
      description: 'Make sure your data fields are interpreted correctly',
      content: (
        <FieldMatchStep
          parsedFile={parseResults}
          getFieldStats={getFieldStats}
          fieldMapForm={fieldMapForm}
          fieldMap={fieldMap}
          setFieldMap={setFieldMap}
        />
      ),
      status: (index: number) =>
        fileList.length > 0
          ? 'finish'
          : currentStep > index
          ? 'error'
          : undefined,
      canAdvance: () => fileList.length > 0,
      onNext: () => {
        fieldMapForm
          .validateFields()
          .then((values) => {
            uploadPatients(values);
            advanceStep();
          })
          .catch((errorInfo) => console.warn(errorInfo));
      },
    },
    {
      key: 'complete-import',
      title: 'Finish Import',
      description: 'Do a victory dance then review your campaigns',
      canAdvance: () =>
        upsertPatientsMutationResult.called &&
        !upsertPatientsMutationResult.loading,
      content: (
        <>
          <Typography.Title level={2}>Finish Import</Typography.Title>
          {!parseResults ? (
            <Result
              title="No File to Validate"
              subTitle="You must upload a file in the previous step."
              status="error"
            />
          ) : !upsertPatientsMutationResult.called ? (
            <Result
              title="Import not started"
              subTitle="You must confirm a field mapping in the previous step."
              status="error"
            />
          ) : upsertPatientsMutationResult.error ? (
            <Result
              status="success"
              title="Oops! There was an error importing your patients."
              subTitle={upsertPatientsMutationResult.error?.message}
            />
          ) : (
            <Result
              status="success"
              title={
                parseResults &&
                patientUploadCount === parseResults.data.length ? (
                  <span style={{ fontSize: 24 }} role="img" aria-label="party">
                    {patientUploadCount} patients uploaded! 🎉
                  </span>
                ) : null
              }
              subTitle={
                parseResults && patientUploadCount === parseResults.data.length
                  ? "Now let's approve your first marketing campaign."
                  : null
              }
              icon={
                <Progress
                  type="circle"
                  format={(percent) => (
                    <>
                      <div>{percent + '%'}</div>
                      <Spin
                        spinning={upsertPatientsMutationResult.loading}
                        size="small"
                      />
                    </>
                  )}
                  percent={
                    parseResults
                      ? Math.floor(
                          (patientUploadCount * 100) / parseResults.data.length
                        )
                      : 0
                  }
                />
              }
            />
          )}
        </>
      ),
    },
  ];
  return (
    <Layout>
      <Helmet>
        <title>Onboarding | Mabel</title>
      </Helmet>
      <Layout.Sider theme="light" width={280} style={{ paddingRight: 24 }}>
        <Typography.Title level={4}>Onboarding Steps:</Typography.Title>

        <Steps
          current={currentStep}
          onChange={(currentStep) => history.push(`/onboarding/${currentStep}`)}
          direction="vertical"
        >
          {steps.map((item, index) => (
            <Step
              key={item.key}
              title={item.title}
              description={item.description}
              status={item.status ? item.status(index) : undefined}
            />
          ))}
        </Steps>
      </Layout.Sider>
      <Layout.Content
        className="layout-page-background"
        style={{
          borderLeft: '1px solid #f0f0f0',
          textAlign: 'center',
          paddingLeft: 24,
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          {userDetailsQuery.loading || practiceDetailsQueryResult.loading ? (
            <Skeleton />
          ) : practiceDetailsQueryResult.data ? (
            steps[currentStep].content
          ) : null}
          <Space direction="horizontal">
            {currentStep > 0 && (
              <Button
                icon={<ArrowLeftOutlined />}
                onClick={() => history.push(`/onboarding/${currentStep - 1}`)}
              >
                Previous
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button
                type="primary"
                loading={updatePracticeMutationResults.loading}
                onClick={() => {
                  if (steps[currentStep].onNext) {
                    steps[currentStep].onNext!();
                  } else {
                    advanceStep();
                  }
                }}
                disabled={
                  steps[currentStep].canAdvance &&
                  !steps[currentStep].canAdvance!()
                }
              >
                Next
                <ArrowRightOutlined />
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => history.push('/campaigns')}
                disabled={
                  steps[currentStep].canAdvance &&
                  !steps[currentStep].canAdvance!()
                }
              >
                Go to Campaigns
              </Button>
            )}
          </Space>
        </Space>
      </Layout.Content>
    </Layout>
  );
};

const WelcomeStep: React.FC<{
  playAnimation?: boolean;
  onAnimationComplete?: () => void;
}> = ({ playAnimation = false, onAnimationComplete }) => {
  return (
    <>
      <Typography.Title level={2}>Welcome to Mabel</Typography.Title>
      <Space direction="vertical">
        <Typography.Text>Let's get started!</Typography.Text>
        <img
          src={WomanOnStool}
          style={{ width: 165, height: 271, margin: 48 }}
          alt="Welcoming woman sitting on stool"
        />
      </Space>
    </>
  );
};

const BAAStep: React.FC<{ practice: PracticeDetailsFragment }> = ({
  practice,
}) => {
  return (
    <>
      <Typography.Title level={2}>
        Sign the Business Associate Agreement
      </Typography.Title>
      <Space direction="vertical" style={{ maxWidth: 640 }}>
        <Typography.Text>
          Before you upload any material containing PHI, your practice must sign
          a{' '}
          <a
            href="https://www.hhs.gov/hipaa/for-professionals/privacy/guidance/business-associates/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            business associate agreement
          </a>{' '}
          with us. Please provide the name and email of the person at your
          practice who is authorized to sign a BAA.
        </Typography.Text>
        <div style={{ margin: 28 }}>
          <Space direction="vertical">
            {practice.baas.map((baa) => (
              <div key={cacheIdFromObject(baa) || ''}>
                {baa.status === 'Completed' ? (
                  <CheckCircleTwoTone twoToneColor={mabel_light_green} />
                ) : baa.status === 'Declined' || baa.status === 'Voided' ? (
                  <WarningTwoTone twoToneColor={warning_color} />
                ) : (
                  <MailOutlined />
                )}{' '}
                {baa.status}{' '}
                <small>
                  <i>
                    {baa.recipient_email}, updated{' '}
                    {dayjs(baa.updated_at).format('llll')}
                  </i>
                </small>
              </div>
            ))}
            <BAAInteractive practice={practice} />
          </Space>
        </div>
      </Space>
    </>
  );
};

const BAAInteractive: React.FC<{ practice: PracticeDetailsFragment }> = ({
  practice,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [
    practiceDetailsQuery,
    practiceDetailsQueryResult,
  ] = useGetPracticeDetailsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const completed_baas = practice.baas?.filter(
    (baa) => baa.status === 'Completed'
  );
  if (completed_baas.length) {
    return null;
  }
  return (
    <Space direction="vertical">
      {practice.baas?.length ? (
        <Button
          size="small"
          icon={<ReloadOutlined spin={practiceDetailsQueryResult.loading} />}
          onClick={() =>
            practiceDetailsQuery({
              variables: { practice_id: practice.practice_id },
            })
          }
        >
          Refresh
        </Button>
      ) : null}
      {!practice.baas?.length || showForm ? (
        <BAAForm practice={practice} />
      ) : (
        <Button type="link" onClick={() => setShowForm(true)}>
          Send another
        </Button>
      )}
    </Space>
  );
};

const FileUploadStep: React.FC<{
  practice: PracticeDetailsFragment;
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;

  parseResults?: ParseData;
  setParseResults: React.Dispatch<React.SetStateAction<ParseData | undefined>>;
}> = ({ practice, fileList, setFileList, parseResults, setParseResults }) => {
  const { user } = useContext(UserContext);
  const userDetailsQuery = useGetUserDetailsQuery({
    variables: { user_id: user?.user_id },
  });

  const patientTemplateURL = window.URL.createObjectURL(
    new File([Papa.unparse(patientSampleFileJSON)], 'template.csv', {
      type: 'text/csv',
    })
  );

  const [createFileMutation] = useCreatePatientRosterFileMutation();
  const [updateFileMutation] = useUpdatePatientRosterFileMutation();

  //const listPatientRosterFilesQuery = useListPatientRosterFilesQuery();
  //useEffect(() => {
  // Promise.all(
  //   (listPatientRosterFilesQuery.data?.patient_roster_files || []).map(
  //     (roster_file) =>
  //       Storage.get(roster_file.key, {
  //         level: roster_file.level,
  //         identityId: roster_file.identity_id,
  //         bucket: process.env.REACT_APP_PRIVATE_STORAGE_BUCKET,
  //         download: true,
  //       }).then(async (fileBlob) => {
  //         const filePath = roster_file.key.split('/');
  //         return {
  //           ...(fileBlob as any).Body,
  //           uid: `${roster_file.identity_id}/${roster_file.level}/${roster_file.key}`,
  //           name: filePath[filePath.length - 1],
  //           url: await Storage.get(roster_file.key, {
  //             level: roster_file.level,
  //             identityId: roster_file.identity_id,
  //             bucket: process.env.REACT_APP_PRIVATE_STORAGE_BUCKET,
  //           }),
  //         };
  //       })
  //   )
  // ).then((fetchedFiles) => {
  //   setFileList(fetchedFiles);
  // });
  //}, [setFileList, listPatientRosterFilesQuery]);

  const completedBaas =
    practice.baas.filter((baa) => baa.status === 'Completed') || [];

  const beforeUpload = (file: File) => {
    const allowed_types = [
      'text/plain',
      'text/x-csv',
      'application/vnd.ms-excel',
      'application/csv',
      'application/x-csv',
      'text/csv',
      'text/comma-separated-values',
      'text/x-comma-separated-values',
      'text/tab-separated-values',
    ];
    if (file.type && !allowed_types.includes(file.type)) {
      message.error('Only text or .csv file types allowed');
      return false;
    }
    if (!file.type) {
      console.warn('Could not determine file type. Uploading anyway.');
    }
    return true;
  };

  const customRequest = ({
    onProgress,
    onError,
    onSuccess,
    data,
    filename,
    file,
  }: any) => {
    const key = `practice/${
      practice.practice_id
    }/patients/${new Date().getTime()}/${file.name}`;
    Storage.put(key, file, {
      level: 'private',
      contentType: file.type,
      bucket: process.env.REACT_APP_PRIVATE_STORAGE_BUCKET,
      progressCallback(progress: any) {
        onProgress({ percent: (100 * progress.loaded) / progress.total }, file);
      },
    })
      .then((result) => {
        Storage.get(key, {
          level: 'private',
          bucket: process.env.REACT_APP_PRIVATE_STORAGE_BUCKET,
        }).then((result) => {
          createFileMutation({
            variables: {
              practice_id: practice.practice_id,
              identity_id: userDetailsQuery.data?.users_by_pk?.identity_id!,
              key: key,
            },
          }).then((_) => {
            const newVal = { url: result, file: file };
            onSuccess(newVal, file);
          });
        });
      })
      .catch((err) => onError(err));

    let results: ParseData = {
      data: [],
      errors: [],
      meta: {
        delimiter: ',',
        linebreak: '\n',
        aborted: false,
        truncated: false,
        cursor: 0,
      },
      stats: {},
    };

    Papa.parse<any>(file, {
      header: true,
      skipEmptyLines: true,
      // worker: true,
      step: (row) => {
        const { errors, meta } = row;
        const data = row.data as { [key: string]: any };

        meta.fields?.forEach((col, i) => {
          results.stats[col] = results.stats[col] || {
            empty: 0,
            samples: [],
          };

          if (data[col] && results.stats[col].samples.length < 2) {
            results.stats[col].samples.push(data);
          } else if (!data[col]) {
            results.stats[col].empty =
              (results.stats[col].empty || 0) + (data[col] ? 0 : 1);
          }
        });

        results.data.push(data);
        results.errors.push(...errors);
        results.meta = meta;
      },
      complete: () => {
        setParseResults(results);
      },
    });
  };

  const handleRemove = (file: UploadFile) => {
    setParseResults(undefined);
    if (file.url) {
      const url = new URL(file.url);
      const match = url.pathname.match(
        /^\/(?<level>[^\s/]+)\/(?<identity_id>[^\s/]+)\/(?<key>\S+)/
      );
      if (match) {
        updateFileMutation({
          variables: {
            identity_id: decodeURIComponent(match?.groups?.identity_id!),
            key: decodeURIComponent(match?.groups?.key!),
            level: decodeURIComponent(match?.groups?.level!),
            changes: { is_archived: true },
          },
        });
      }
      // TODO: actually remove file from s3?
    }
  };

  const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(
      fileList.slice(-1).map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      })
    );
  };

  return (
    <>
      <Typography.Title level={2}>Upload Patient List</Typography.Title>
      <Space direction="vertical" style={{ maxWidth: 480 }}>
        <div style={{ textAlign: 'start', margin: '16px 0 32px' }}>
          <Space direction="vertical">
            {completedBaas.length === 0 && (
              <Alert
                type="error"
                message="BAA is incomplete."
                description="You can't upload patient data until your practice has signed a Business Associate Agreement."
                showIcon
              />
            )}
            <Upload.Dragger
              disabled={completedBaas.length === 0}
              fileList={fileList}
              beforeUpload={beforeUpload}
              accept=".csv,.tsv"
              customRequest={customRequest}
              onChange={handleChange}
              onRemove={handleRemove}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click here or drag files to this area to upload
              </p>
              <p
                className="ant-upload-hint"
                style={{ margin: 16, textAlign: 'start' }}
              >
                Upload a <b>.csv</b> or <b>.tsv</b>. The most important fields
                we look for are listed below. You can download a sample template
                here:{' '}
                <a
                  href={patientTemplateURL}
                  download="patient_template.csv"
                  onClick={(e) => e.stopPropagation()}
                >
                  patient_template.csv
                </a>
              </p>
              <ul
                className="p ant-upload-hint"
                style={{
                  margin: 16,
                  textAlign: 'start',
                  color: 'rgba(0, 0, 0, 0.45)',
                }}
              >
                <li>
                  <b>Patient ID</b>{' '}
                  <Tooltip title="A unique identifier assigned by your EHR. Can be an MRN or any other unique identifier.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </li>
                <li>
                  <b>Patient Name </b>{' '}
                  <Tooltip title="Can be a single field for the patient's full name, or multiple fields for first name/middle name/last name, etc.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </li>
                <li>
                  <b>Date of Birth</b>{' '}
                  <Tooltip title="Can be any US date format, e.g. 10/23/1946 or 1946-10-23">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </li>
                <li>
                  <b>Email Address</b>{' '}
                  <Tooltip title="The patient's email address.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </li>
                <li>
                  <b>
                    Mailing Address{' '}
                    <Tooltip title="Can be a single field for the full mailing address, or multiple fields for street/city/state/zip, etc.">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </b>
                </li>
                <li>
                  <b>
                    Insurance Carriers{' '}
                    <Tooltip title="The name of the patient's primary insurance carrier, and secondary carrier if any. We will also ingest the specific plan names, if they are available.">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </b>
                </li>
              </ul>
            </Upload.Dragger>
            {parseResults && (
              <Alert
                type={parseResults.errors.length ? 'error' : 'success'}
                showIcon
                message={
                  parseResults.errors.length
                    ? `Error${
                        parseResults.errors.length > 1 ? 's' : ''
                      } detected`
                    : 'Success!'
                }
                description={
                  parseResults.errors.length ? (
                    <ul>
                      {parseResults.errors.map((error) => (
                        <li key={error.row}>
                          Row {error.row}: {error.message}.
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "That's a .csv alright!"
                  )
                }
              />
            )}
          </Space>
        </div>
      </Space>
    </>
  );
};

const FieldMatchStep: React.FC<{
  parsedFile?: ParseData;
  getFieldStats: (fieldKey: keyof PatientFieldMap) => ParseStat | undefined;

  fieldMap?: PatientFieldMap;
  setFieldMap: React.Dispatch<
    React.SetStateAction<PatientFieldMap | undefined>
  >;
  fieldMapForm: FormInstance;
}> = ({ parsedFile, getFieldStats, fieldMap, setFieldMap, fieldMapForm }) => {
  useEffect(() => {
    if (parsedFile) {
      const fm = fieldMap || getFieldMap(parsedFile.meta.fields || []);
      setFieldMap(fm);
      fieldMapForm.setFieldsValue(fm);
    }
  }, [parsedFile, fieldMapForm, fieldMap, setFieldMap]);

  return (
    <>
      <Typography.Title level={2}>Validate Import Data</Typography.Title>
      {!parsedFile && (
        <Result
          title="No File to Validate"
          subTitle="You must upload a file in the previous step."
          status="error"
        />
      )}
      <Space direction="vertical" style={{ width: '100%', textAlign: 'start' }}>
        {parsedFile && fieldMap && (
          <>
            <Typography.Paragraph style={{ textAlign: 'center' }}>
              We found <b>{parsedFile?.data.length.toString()} patients</b> in
              your file!
            </Typography.Paragraph>
            <Row
              gutter={[16, 16]}
              justify="center"
              style={{ textAlign: 'center' }}
            >
              <Col xs={11} lg={8}>
                <b>
                  Select the fields from your data that describe the Patient ID,
                  Name, etc.{' '}
                </b>
              </Col>
              <Col span={2}></Col>
              <Col xs={11} lg={8}>
                <b>Preview what it will look like after importing:</b>
              </Col>
            </Row>
            <Form
              form={fieldMapForm}
              layout="vertical"
              onValuesChange={(changedValue, values) =>
                setFieldMap((fields) => Object({ ...fields, ...values }))
              }
              scrollToFirstError
            >
              <FieldMapChooser
                fieldMap={fieldMap}
                parsedFile={parsedFile}
                getFieldStats={getFieldStats}
                label="Patient ID"
                field_name="external_id"
                alertProps={{
                  type: 'error',
                  description:
                    'You must select the field(s) from your file that specify the ID of the patient',
                }}
                formItemProps={{
                  validateFirst: true,
                  rules: [
                    {
                      required: true,
                      message: 'You must specify a patient ID',
                    },
                    {
                      validator(_, value) {
                        const ids = new Set(
                          parsedFile.data.map(
                            (record) =>
                              extractPatientFields(record, fieldMap)[
                                'external_id'
                              ]
                          )
                        );
                        if (ids.size === parsedFile.data.length) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          'You have duplicate patient ids. They must be unique'
                        );
                      },
                    },
                  ],
                }}
              />
              <FieldMapChooser
                fieldMap={fieldMap}
                parsedFile={parsedFile}
                getFieldStats={getFieldStats}
                label="Name"
                field_name="patient_name"
                alertProps={{
                  type: 'error',
                  description:
                    'You must select the field(s) from your file that specify the patient name',
                }}
                formItemProps={{
                  rules: [
                    {
                      required: true,
                      message: 'You must specify a patient name',
                    },
                  ],
                  help:
                    'Note: you can select multiple fields (e.g. first_name, middle_name, last_name) to construct the full name.',
                }}
              />
              <FieldMapChooser
                fieldMap={fieldMap}
                parsedFile={parsedFile}
                getFieldStats={getFieldStats}
                label="Date of Birth"
                field_name="birth_date"
                formItemProps={{
                  rules: [
                    {
                      validator(_, value) {
                        const anyInvalidDates = parsedFile.data.some(
                          (record) => {
                            const inputDate = extractPatientFields(
                              record,
                              fieldMap
                            )['birth_date'];
                            return (
                              inputDate &&
                              !dayjs(
                                inputDate,
                                //Support ISO and MDY and allow for zero-prefixed and single digit days/months,
                                //MM/DD = zero-prefixed (e.g. 01/02), M/D = non-prefixed (e.g. 1/2)
                                [
                                  'YYYY-M-D',
                                  'YYYY-MM-D',
                                  'YYYY-M-DD',
                                  'YYYY-MM-DD',
                                  'M/D/YYYY',
                                  'MM/D/YYYY',
                                  'M/DD/YYYY',
                                  'MM/DD/YYYY',
                                ],
                                true
                              ).isValid()
                            );
                          }
                        );
                        if (anyInvalidDates) {
                          return Promise.reject(
                            'You have invalid birthdates. All dates must be in YYYY-MM-DD or MM/DD/YYYY format.'
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ],
                }}
              />
              <FieldMapChooser
                fieldMap={fieldMap}
                parsedFile={parsedFile}
                getFieldStats={getFieldStats}
                label="Sex Assigned at Birth"
                field_name="sex_assigned_at_birth"
              />
              <FieldMapChooser
                fieldMap={fieldMap}
                parsedFile={parsedFile}
                getFieldStats={getFieldStats}
                label="Email"
                field_name="patient_email"
              />
              <FieldMapChooser
                fieldMap={fieldMap}
                parsedFile={parsedFile}
                getFieldStats={getFieldStats}
                label="Mailing Address"
                field_name="patient_address"
                formItemProps={{
                  help:
                    'Note: you can select multiple fields (e.g. address_line_1, address_line_2, city, state, zip) to construct the full address.',
                }}
              />
              <FieldMapChooser
                fieldMap={fieldMap}
                parsedFile={parsedFile}
                getFieldStats={getFieldStats}
                label="Mobile Phone"
                field_name="patient_phone"
              />
              <FieldMapChooser
                fieldMap={fieldMap}
                parsedFile={parsedFile}
                getFieldStats={getFieldStats}
                label="Name of Primary Care Provider"
                field_name="patient_pcp"
              />
              <FieldMapChooser
                fieldMap={fieldMap}
                parsedFile={parsedFile}
                getFieldStats={getFieldStats}
                label="NPI of Primary Care Provider"
                field_name="suspected_provider_npi"
              />
              <FieldMapChooser
                fieldMap={fieldMap}
                parsedFile={parsedFile}
                getFieldStats={getFieldStats}
                label="TIN from Alignment Report"
                field_name="alignment_tin"
              />
              <FieldMapChooser
                fieldMap={fieldMap}
                parsedFile={parsedFile}
                getFieldStats={getFieldStats}
                label="Medicare Beneficiary Identifier"
                field_name="mbi"
              />
              <FieldMapChooser
                fieldMap={fieldMap}
                parsedFile={parsedFile}
                getFieldStats={getFieldStats}
                label="Logo URL"
                field_name="patient_logo"
              />
              <Collapse ghost className="fields-collapse">
                <Collapse.Panel
                  header="Insurance Fields"
                  key="insurance"
                  forceRender
                >
                  <FieldMapChooser
                    fieldMap={fieldMap}
                    parsedFile={parsedFile}
                    getFieldStats={getFieldStats}
                    label="Primary Insurance Member ID"
                    field_name="primary_insurance_member_id"
                  />
                  <FieldMapChooser
                    fieldMap={fieldMap}
                    parsedFile={parsedFile}
                    getFieldStats={getFieldStats}
                    label="Primary Insurance Payer Name"
                    field_name="primary_insurance_payer_name"
                  />
                  <FieldMapChooser
                    fieldMap={fieldMap}
                    parsedFile={parsedFile}
                    getFieldStats={getFieldStats}
                    label="Primary Insurance Plan Description"
                    field_name="primary_insurance_plan_name"
                  />
                  <FieldMapChooser
                    fieldMap={fieldMap}
                    parsedFile={parsedFile}
                    getFieldStats={getFieldStats}
                    label="Primary Insurance Payer ID"
                    field_name="primary_insurance_payer_id"
                  />
                  <FieldMapChooser
                    fieldMap={fieldMap}
                    parsedFile={parsedFile}
                    getFieldStats={getFieldStats}
                    label="Secondary Insurance Member ID"
                    field_name="secondary_insurance_member_id"
                  />
                  <FieldMapChooser
                    fieldMap={fieldMap}
                    parsedFile={parsedFile}
                    getFieldStats={getFieldStats}
                    label="Secondary Insurance Payer Name"
                    field_name="secondary_insurance_payer_name"
                  />
                  <FieldMapChooser
                    fieldMap={fieldMap}
                    parsedFile={parsedFile}
                    getFieldStats={getFieldStats}
                    label="Secondary Insurance Plan Description"
                    field_name="secondary_insurance_plan_name"
                  />
                  <FieldMapChooser
                    fieldMap={fieldMap}
                    parsedFile={parsedFile}
                    getFieldStats={getFieldStats}
                    label="Secondary Insurance Payer ID"
                    field_name="secondary_insurance_payer_id"
                  />
                </Collapse.Panel>
              </Collapse>
              <Collapse ghost className="fields-collapse">
                <Collapse.Panel
                  header="Inactive and 'do-not-contact' fields"
                  key="no_contact"
                  forceRender
                >
                  <FieldMapChooser
                    fieldMap={fieldMap}
                    parsedFile={parsedFile}
                    getFieldStats={getFieldStats}
                    label="Inactive"
                    field_name="is_archived"
                  />
                  <FieldMapChooser
                    fieldMap={fieldMap}
                    parsedFile={parsedFile}
                    getFieldStats={getFieldStats}
                    label="Deceased Date"
                    field_name="deceased_as_of_date"
                    formItemProps={{
                      rules: [
                        {
                          validator(_, value) {
                            const anyInvalidDates = parsedFile.data.some(
                              (record) => {
                                const inputDate = extractPatientFields(
                                  record,
                                  fieldMap
                                )['deceased_as_of_date'];
                                return (
                                  inputDate &&
                                  !dayjs(
                                    inputDate,
                                    //Support ISO and MDY and allow for zero-prefixed and single digit days/months,
                                    //MM/DD = zero-prefixed (e.g. 01/02), M/D = non-prefixed (e.g. 1/2)
                                    [
                                      'YYYY-M-D',
                                      'YYYY-MM-D',
                                      'YYYY-M-DD',
                                      'YYYY-MM-DD',
                                      'M/D/YYYY',
                                      'MM/D/YYYY',
                                      'M/DD/YYYY',
                                      'MM/DD/YYYY',
                                    ],
                                    true
                                  ).isValid()
                                );
                              }
                            );
                            if (anyInvalidDates) {
                              return Promise.reject(
                                'You have invalid deceased dates. All dates must be in YYYY-MM-DD or MM/DD/YYYY format.'
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ],
                    }}
                  />
                  <FieldMapChooser
                    fieldMap={fieldMap}
                    parsedFile={parsedFile}
                    getFieldStats={getFieldStats}
                    label="Do Not Email"
                    field_name="do_not_email"
                  />
                  <FieldMapChooser
                    fieldMap={fieldMap}
                    parsedFile={parsedFile}
                    getFieldStats={getFieldStats}
                    label="Do Not Direct Mail"
                    field_name="do_not_direct_mail"
                  />
                </Collapse.Panel>
              </Collapse>
            </Form>
          </>
        )}
      </Space>
    </>
  );
};

const FieldMapChooser: React.FC<{
  fieldMap: any;
  parsedFile: ParseData;
  getFieldStats: (fieldKey: keyof PatientFieldMap) => ParseStat | undefined;

  label: string | React.ReactNode;
  field_name: keyof PatientFieldMap;
  formItemProps?: Partial<FormItemProps>;
  alertProps?: Partial<AlertProps>;
}> = ({
  fieldMap,
  parsedFile,
  getFieldStats,
  label,
  field_name,
  formItemProps,
  alertProps,
}) => {
  //Inelegant, but object keys are stringified, and comma could appear in column names
  //Using an odd separator sidesteps this
  const fieldStats = getFieldStats(field_name as keyof PatientFieldMap);
  return (
    <Form.Item>
      <Row gutter={16} justify="center" align="middle">
        <Col xs={11} lg={8}>
          <Form.Item label={label} name={field_name} {...formItemProps}>
            <Select
              mode="multiple"
              options={parsedFile.meta.fields?.map((f) =>
                Object({ name: f, value: f })
              )}
              placeholder="Select field names from your file"
            />
          </Form.Item>
        </Col>
        <Col span={2} style={{ textAlign: 'center' }}>
          <ArrowRightOutlined />
        </Col>
        <Col xs={11} lg={8}>
          {fieldStats ? (
            <>
              <Table
                dataSource={fieldStats.samples
                  .map((r: any, i: number) => {
                    return Object({ ...r, key: i });
                  })
                  .concat([null, null])
                  .splice(0, 2)}
                size="small"
                pagination={false}
                bordered
              >
                <Table.Column
                  title={label}
                  key={field_name}
                  render={(record: any) =>
                    record ? (
                      extractPatientFields(record, fieldMap)[field_name]
                    ) : (
                      <span style={{ whiteSpace: 'pre' }}> </span>
                    )
                  }
                />
              </Table>
              {fieldStats.empty !== 0 && (
                <Typography.Text type="danger" style={{ margin: 8 }}>
                  <ExclamationCircleOutlined />{' '}
                  {fieldStats.samples.length === 0
                    ? 'All values are blank'
                    : `${fieldStats.empty} blank value${
                        fieldStats.empty > 1 ? 's' : ''
                      }`}
                </Typography.Text>
              )}
            </>
          ) : (
            <Alert
              type="warning"
              message={label}
              description="No fields selected. This data will not be imported."
              showIcon
              {...alertProps}
            />
          )}
        </Col>
      </Row>
    </Form.Item>
  );
};

export default OnboardingPage;
