import React from 'react';
import { Tag, Tooltip } from 'antd';
import { TagProps } from 'antd/lib/tag';
import { PracticeBasicFragment } from '../../graphql/generated';

interface PracticeTagProps extends TagProps {
  practice: PracticeBasicFragment;
}
const PracticeTag: React.FC<PracticeTagProps> = ({ practice, ...rest }) => {
  return (
    <Tooltip
      title={
        <>
          {practice.practice_name}
          <br />
          {practice.legal_business_name && (
            <i>{practice.legal_business_name}</i>
          )}
          {practice.practice_description && (
            <p>{practice.practice_description}</p>
          )}
          {practice.practice_address && <p>{practice.practice_address}</p>}
          {
            <p>
              Default Provider: {practice.default_provider_id}
              <br />
              TIN: {practice.tin}
              <br />
            </p>
          }
          {practice.practice_id}
        </>
      }
    >
      <Tag {...rest}>{practice.practice_name}</Tag>
    </Tooltip>
  );
};

export default PracticeTag;
