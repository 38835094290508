import React from 'react';
import { Auth } from 'aws-amplify';

import { Button, Tooltip, Spin, Row, Col, Typography } from 'antd';

import {
  CampaignLetterComponentDetailsFragment,
  LetterTemplateBasicFragment,
  PatientBasicFragment,
  PracticeDetailsFragment,
} from '../../graphql/generated';

import axios from 'axios';
import axiosRetry from 'axios-retry';
import { useRequest } from 'ahooks';
import sanitizeHtml from 'sanitize-html';
import * as Handlebars from 'handlebars';
import Frame from 'react-frame-component';

import { ZoomInOutlined } from '@ant-design/icons';
import { cacheIdFromObject } from '../../graphql/utils';
import { getMergeVars } from './TemplateUtils';
import ValidatedAddress from '../utils/ValidatedAddress';

interface LetterSizeParams {
  width: number;
  height: number;
  bleed: number;
  size_label: string;
}

const getLetterSizing = (
  format: string,
  size: string | null | undefined
): LetterSizeParams => {
  switch (format) {
    case 'LETTER':
      return { height: 11, width: 8.5, bleed: 0, size_label: 'us_letter' };
    case 'POSTCARD':
      // postcard sizes are "4x6", "6x9", etc.
      const [height, width] = size ? size.split('x').map(parseFloat) : [6, 9];
      return {
        height: height,
        width: width,
        bleed: 0.25,
        size_label: size ?? '6x9',
      };
    case 'SELF_MAILER':
    default:
      // self mailer sizes are 6x18_bifold, 11x9_bifold, 12x9_bifold, 17.75x9_trifold
      // determine height and width from size_label
      const [height2, width2] = size
        ? size.split('_')[0].split('x').map(parseFloat)
        : [6, 18];
      return {
        height: height2,
        width: width2,
        bleed: 0.25,
        size_label: size ?? '6x18_bifold',
      };
  }
};

const getLobTemplateHTML = async ({
  external_id,
  external_secondary_id,
}: any) => {
  axiosRetry(axios, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (err) => {
      const code = err.response?.status || 0;
      return 400 < code && code <= 599;
    },
  });

  const token = (await Auth.currentSession()).getIdToken().getJwtToken();

  const [front, back] = await Promise.all([
    axios.get(
      `${process.env.REACT_APP_CHALICE_URL}/lob/templates/${external_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ),
    external_secondary_id
      ? axios.get(
          `${process.env.REACT_APP_CHALICE_URL}/lob/templates/${external_secondary_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
      : Promise.resolve(null),
  ]);

  return {
    front: Handlebars.compile(front.data.published_version.html),
    back: back ? Handlebars.compile(back.data.published_version.html) : null,
  };
};

const getLobTemplateUrl = async (
  external_id: string,
  external_secondary_id: string | null | undefined,
  size: string | null | undefined,
  format: string,
  merge_vars: any
) => {
  axiosRetry(axios, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (err) => {
      const code = err.response?.status || 0;
      return 400 < code && code <= 599;
    },
  });
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  const getTemplateUrl = async (id: string) => {
    const { width, height, bleed, size_label } = getLetterSizing(format, size);

    const response = await axios.post(
      `${process.env.REACT_APP_CHALICE_URL}/lob/templates/${id}/preview`,
      {
        data: {
          // This lob endpoint is undocumented, but seems to expect titlecased 'product'
          product:
            format === 'LETTER'
              ? 'Letter'
              : format === 'POSTCARD'
              ? 'Postcard'
              : format === 'SELF_MAILER'
              ? 'Self Mailer'
              : format === 'CHECK'
              ? 'Check'
              : format,
          size: size_label,
          bleed: bleed > 0,
        },
        height: height + bleed,
        width: width + bleed,
        save_data: false,
        merge_variables: merge_vars,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  };

  const [front, back] = await Promise.all([
    getTemplateUrl(external_id),
    external_secondary_id
      ? getTemplateUrl(external_secondary_id)
      : Promise.resolve(null),
  ]);

  return {
    front: front.data.url,
    back: back?.data.url,
  };
};

const getLobTemplatePDF = async (
  external_id: string,
  external_secondary_id: string | null | undefined,
  size: string | null | undefined,
  format: string,
  template_id: string,
  practice_id: string,
  component_id?: string,
  patient_id?: string
) => {
  axiosRetry(axios, {
    retries: 10,
    retryDelay: (_) => 6000, // retry every 6 seconds for a minute
    retryCondition: (err) => {
      const code = err.response?.status || 0;
      return 400 < code && code <= 599;
    },
  });
  const merge_vars = await getMergeVars(
    'letter',
    component_id,
    practice_id,
    patient_id,
    template_id
  );
  const { front: frontUrl, back: backUrl } = await getLobTemplateUrl(
    external_id,
    external_secondary_id,
    size,
    format,
    merge_vars
  );

  //Sleep for 6 sseconds to allow Lob API time to generate the previews
  await new Promise((pause) => setTimeout(pause, 6000));

  const [front, back] = await Promise.all([
    axios.get(frontUrl, {
      responseType: 'blob',
    }),
    backUrl
      ? axios.get(backUrl, {
          responseType: 'blob',
        })
      : Promise.resolve(null),
  ]);

  return {
    front: front.data,
    back: back ? back.data : null,
  };
};

const LetterTemplatePreview: React.FC<{
  letter_template: LetterTemplateBasicFragment;
  practice: PracticeDetailsFragment;
  campaign_letter_component?: CampaignLetterComponentDetailsFragment;
  patient?: PatientBasicFragment;
}> = ({ letter_template, practice, campaign_letter_component, patient }) => {
  const { data: mergeVars, loading: mergeVarsLoading } = useRequest(
    async () =>
      getMergeVars(
        'letter',
        campaign_letter_component?.campaign_letter_component_id,
        practice.practice_id,
        patient?.patient_id,
        letter_template.letter_template_id
      ),
    {
      refreshDeps: [
        campaign_letter_component,
        practice,
        patient,
        letter_template,
      ],
    }
  );

  const { data: template, loading: templateLoading } = useRequest(
    async () =>
      getLobTemplateHTML({
        external_id: letter_template.external_id,
        external_secondary_id: letter_template.external_secondary_id,
      }),
    {
      cacheKey: cacheIdFromObject(letter_template)!,
      refreshDeps: [letter_template],
    }
  );

  const { data: previewUrl, loading: previewUrlLoading } = useRequest(
    async () =>
      getLobTemplateUrl(
        letter_template.external_id,
        letter_template.external_secondary_id,
        letter_template.size,
        letter_template.format,
        mergeVars
      ),
    {
      refreshDeps: [letter_template, mergeVars],
      ready: !!mergeVars,
    }
  );

  const { width, height, bleed } = getLetterSizing(
    letter_template.format,
    letter_template.size
  );
  // template dimensions are in inches and standard conversion = 96 pixels/inch
  const dpi = 96;
  // For wide letter templates, like self-mailers, need to shrink to fit on screen
  // So scale down by factor relative to the width of standard 8.5x11 letter
  const zoomRatio = 0.3 * Math.min(1, 8.5 / width);
  const zoomCss = {
    ['MsZoom' as any]: zoomRatio,
    ['MozTransform' as any]: 'scale(' + zoomRatio + ')',
    MozTransformOrigin: '0 0',
    ['OTransform' as any]: 'scale(' + zoomRatio + ')',
    OTransformOrigin: '0 0',
    WebkitTransform: 'scale(' + zoomRatio + ')',
    WebkitTransformOrigin: '0 0 ',
    ...(letter_template.is_color ? {} : { WebkitFilter: 'grayscale(100%)' }),
  };

  const letter_from = Handlebars.compile(letter_template.letter_from)(
    mergeVars
  ).toUpperCase();
  const letter_company = Handlebars.compile(letter_template.letter_company)(
    mergeVars
  ).toUpperCase();

  const formatted_from =
    letter_from +
    (letter_company ? `\n${letter_company}` : '') +
    (practice.address_verification_result
      ? `\n${practice.address_verification_result?.primary_line}` +
        `\n${practice.address_verification_result?.components?.city}, ${practice.address_verification_result?.components?.state} ${practice.address_verification_result?.components?.zip_code}`
      : '');

  const formatted_to =
    (patient?.patient_name ? patient.patient_name.toUpperCase() : '') +
    (patient?.address_verification_result
      ? `\n${patient?.address_verification_result?.primary_line}` +
        `\n${patient?.address_verification_result?.components?.city}, ${patient?.address_verification_result?.components?.state} ${patient?.address_verification_result?.components?.zip_code}`
      : '');
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '12px 0px',
        }}
      >
        <Button
          // This button is a hack. It's for spacing only, so frame is centered.
          style={{ visibility: 'hidden', margin: '-8px -4px' }}
          icon={<ZoomInOutlined />}
          type="link"
          size="large"
        />
        <Spin spinning={templateLoading || mergeVarsLoading}>
          <div
            style={{
              width: (width + bleed) * zoomRatio * dpi,
              textAlign: 'left',
              fontSize: 10,
              marginBottom: 8,
            }}
          >
            <Row gutter={8}>
              <Col span={12}>
                <b>From: </b>
                <br />
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'pre',
                    overflow: 'hidden',
                  }}
                >
                  {formatted_from && (
                    <Tooltip
                      title={
                        <ValidatedAddress
                          address={formatted_from}
                          deliverability={practice.deliverability}
                        />
                      }
                      overlayStyle={{ whiteSpace: 'pre' }}
                    >
                      <Typography.Text
                        type={
                          practice.deliverability?.startsWith('deliverable')
                            ? 'secondary'
                            : 'danger'
                        }
                      >
                        {formatted_from}
                      </Typography.Text>
                    </Tooltip>
                  )}
                </div>
              </Col>
              <Col span={12}>
                <b>To: </b>
                <br />
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'pre',
                    overflow: 'hidden',
                  }}
                >
                  {formatted_to && (
                    <Tooltip
                      title={
                        <ValidatedAddress
                          address={formatted_to}
                          deliverability={
                            patient?.address_verification_result?.deliverability
                          }
                        />
                      }
                      overlayStyle={{ whiteSpace: 'pre' }}
                    >
                      <Typography.Text
                        type={
                          patient?.address_verification_result?.deliverability.startsWith(
                            'deliverable'
                          )
                            ? 'secondary'
                            : 'danger'
                        }
                      >
                        {formatted_to}
                      </Typography.Text>
                    </Tooltip>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div
            style={{
              width: (width + bleed) * zoomRatio * dpi,
              height: (height + bleed) * zoomRatio * dpi,
              padding: 0,
              overflow: 'hidden',
            }}
            className="card-shadow"
          >
            <Frame
              style={{
                width: (width + bleed) * dpi,
                height: (height + bleed) * dpi,
                border: '1px solid #8c8c8c',
                background: 'white',
                ...zoomCss,
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(
                    template?.front && mergeVars
                      ? template.front(mergeVars)
                      : '',
                    {
                      allowedTags: false,
                      allowedAttributes: false,
                    }
                  ),
                }}
              />
            </Frame>
          </div>
        </Spin>
        <Tooltip
          title={`See full size ${
            letter_template.format === 'POSTCARD'
              ? 'front'
              : letter_template.format === 'SELF_MAILER'
              ? 'inside'
              : ''
          } preview`}
        >
          <Button
            icon={<ZoomInOutlined />}
            loading={previewUrlLoading}
            type="link"
            size="large"
            style={{ margin: '-8px -4px' }}
            onClick={() => {
              window.open(previewUrl?.front, '_blank');
            }}
          />
        </Tooltip>
      </div>
      {letter_template.external_secondary_id ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '12px 0px',
          }}
        >
          <Button
            // This button is a hack. It's for spacing only, so frame is centered.
            style={{ visibility: 'hidden', margin: '-8px -4px' }}
            icon={<ZoomInOutlined />}
            type="link"
            size="large"
          />
          <Spin spinning={templateLoading || mergeVarsLoading}>
            <div
              style={{
                width: (width + bleed) * zoomRatio * dpi,
                height: (height + bleed) * zoomRatio * dpi,
                padding: 0,
                overflow: 'hidden',
              }}
              className="card-shadow"
            >
              <Frame
                style={{
                  width: (width + bleed) * dpi,
                  height: (height + bleed) * dpi,
                  border: '1px solid #8c8c8c',
                  background: 'white',
                  ...zoomCss,
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(
                      template?.back && mergeVars
                        ? template.back(mergeVars)
                        : '',
                      {
                        allowedTags: false,
                        allowedAttributes: false,
                      }
                    ),
                  }}
                />
              </Frame>
            </div>
          </Spin>
          <Tooltip
            title={`See full size ${
              letter_template.format === 'POSTCARD'
                ? 'back'
                : letter_template.format === 'SELF_MAILER'
                ? 'outside'
                : ''
            } preview`}
          >
            <Button
              icon={<ZoomInOutlined />}
              loading={previewUrlLoading}
              type="link"
              size="large"
              style={{ margin: '-8px -4px' }}
              onClick={() => window.open(previewUrl?.back, '_blank')}
            />
          </Tooltip>
        </div>
      ) : null}
    </>
  );
};

export default LetterTemplatePreview;
export { getLobTemplatePDF };
