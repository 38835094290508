import React, { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import JSZip from 'jszip';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import * as Handlebars from 'handlebars';

import {
  Typography,
  Button,
  Space,
  Carousel,
  Tabs,
  Card,
  Row,
  Col,
  Checkbox,
  Form,
  Modal,
  Popover,
  message,
  Dropdown,
  Menu,
  ModalProps,
  Spin,
  Empty,
  Tooltip,
  Alert,
} from 'antd';
import { CarouselRef } from 'antd/lib/carousel';

import { UserContext } from '../contexts/UserContext';
import {
  useGetUserDetailsQuery,
  useGetPracticeDetailsLazyQuery,
  PracticeDetailsFragment,
  CampaignTemplateDetailsFragment,
  ListCampaignTemplatesQuery,
  useApproveCampaignMutation,
  CampaignLetterComponentDetailsFragment,
  CampaignEmailComponentDetailsFragment,
  CampaignSmsComponentDetailsFragment,
  PatientDetailsFragment,
} from '../graphql/generated';

import LetterTemplatePreview, {
  getLobTemplatePDF,
} from '../components/templates/LetterTemplatePreview';
import EmailTemplatePreview from '../components/templates/EmailTemplatePreview';
import SMSTemplatePreview from '../components/templates/SMSTemplatePreview';

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MailOutlined,
  ExclamationCircleOutlined,
  CheckCircleFilled,
  CalendarOutlined,
  MoreOutlined,
  DownloadOutlined,
  EyeOutlined,
  MessageOutlined,
  NumberOutlined,
} from '@ant-design/icons';

import {
  UserInjuredOutlined,
  PaperPlaneOutlined,
} from '../components/MabelIcons';

import { Link } from 'react-router-dom';
import { Store } from 'antd/lib/form/interface';
import { useForm } from 'antd/lib/form/Form';

import useRequest from '@ahooksjs/use-request';
import {
  getMergeVars,
  getComponentRecipients,
  getComponentRecipientCount,
} from '../components/templates/TemplateUtils';

// replaces invalid chars in filenames
const getValidFilename = (s: string) => s.replace(/[\\/:"*?<>|]/g, '-');

const CampaignDetailsPage: React.FC<{
  campaign: ListCampaignTemplatesQuery['campaign_templates'][0];
}> = ({ campaign }) => {
  const { user } = useContext(UserContext);
  const userDetailsQuery = useGetUserDetailsQuery({
    variables: { user_id: user?.user_id },
  });

  const getAllTemplates = async () => {
    let uniqueLetterTemplates = Object.fromEntries(
      campaign.campaign_letter_components
        .filter((comp) => !comp.is_archived)
        .map((comp) => [comp.letter_template_id, comp])
    );
    let uniqueEmailTemplates = Object.fromEntries(
      campaign.campaign_email_components
        .filter((comp) => !comp.is_archived)
        .map((comp) => [comp.email_template_id, comp])
    );
    let uniqueSMSTemplates = Object.fromEntries(
      campaign.campaign_sms_components
        .filter((comp) => !comp.is_archived)
        .map((comp) => [comp.sms_template_id, comp])
    );

    const [letters, emails, sms_messages] = await Promise.all([
      Promise.all(
        Object.entries(uniqueLetterTemplates).map(async ([_, lc], index) => ({
          name: getValidFilename(
            `${lc.letter_template.letter_template_name}_${index + 1}`
          ),
          data: await getLobTemplatePDF(
            lc.letter_template.external_id,
            lc.letter_template.external_secondary_id,
            lc.letter_template.size,
            lc.letter_template.format,
            lc.letter_template.letter_template_id,
            practiceDetailsQueryResult.data?.practices_by_pk?.practice_id!,
            lc.campaign_letter_component_id
          ),
        }))
      ),
      Promise.all(
        Object.entries(uniqueEmailTemplates).map(async ([_, ec], index) => ({
          name: getValidFilename(
            `${ec.email_template.email_template_name}_${index + 1}`
          ),
          data: Handlebars.compile(ec.email_template.body_html || '')(
            await getMergeVars(
              'email',
              ec.campaign_email_component_id,
              practiceDetailsQueryResult.data!.practices_by_pk!.practice_id
            )
          ),
        }))
      ),
      Promise.all(
        Object.entries(uniqueSMSTemplates).map(async ([_, ec], index) => ({
          name: getValidFilename(
            `${ec.sms_template.sms_template_name}_${index + 1}`
          ),
          data: Handlebars.compile(ec.sms_template.body_text || '')(
            await getMergeVars(
              'sms',
              ec.campaign_sms_component_id,
              practiceDetailsQueryResult.data!.practices_by_pk!.practice_id
            )
          ),
        }))
      ),
    ]);
    const jszip = new JSZip();
    const emailFolder = jszip.folder('emails');
    const letterFolder = jszip.folder('letters');
    const SMSFolder = jszip.folder('sms_messages');

    emails.forEach((em: any) => {
      emailFolder?.file(`${em.name}.html`, em.data);
    });
    letters.forEach((lt: any) => {
      if (lt.data.back) {
        letterFolder?.file(`${lt.name} (Front).pdf`, lt.data.front);
        letterFolder?.file(`${lt.name} (Back).pdf`, lt.data.back);
      } else {
        letterFolder?.file(`${lt.name}.pdf`, lt.data.front);
      }
    });
    sms_messages.forEach((em: any) => {
      SMSFolder?.file(`${em.name}.text`, em.data);
    });

    const blob = await jszip.generateAsync({ type: 'blob' });
    saveAs(
      blob,
      getValidFilename(
        `${campaign.campaign_name} - ${practiceDetailsQueryResult.data?.practices_by_pk?.practice_name}.zip`
      )
    );
  };

  const [
    practiceDetailsQuery,
    practiceDetailsQueryResult,
  ] = useGetPracticeDetailsLazyQuery();

  useEffect(() => {
    const practice_id = userDetailsQuery.data?.users_by_pk?.practice_id;
    if (practice_id) {
      practiceDetailsQuery({ variables: { practice_id: practice_id } });
    }
  }, [userDetailsQuery.data, practiceDetailsQuery]);

  const [approvalForm] = useForm();
  const [
    approveCampaignMutation,
    approveCampaignMutationResult,
  ] = useApproveCampaignMutation();

  const confirmApproval = (values: Store) => {
    // get components with send date within the next hour
    const now = dayjs();
    const soon_components = [
      ...campaign.campaign_letter_components,
      ...campaign.campaign_email_components,
      ...campaign.campaign_sms_components,
    ].filter(
      (comp) =>
        !comp.is_archived &&
        dayjs(comp.send_datetime).isAfter(now) &&
        dayjs(comp.send_datetime).isBefore(now.add(1, 'hour'))
    );
    const minutes_to_send =
      soon_components.length > 0
        ? dayjs(soon_components[0].send_datetime).diff(now, 'minutes')
        : -1;

    Modal.confirm({
      title: 'Approve the following components?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <ul>
            {values.approvals.includes('should_send_letters') && (
              <li>Direct Mail</li>
            )}
            {values.approvals.includes('should_send_emails') && <li>Email</li>}
            {values.approvals.includes('should_send_sms_messages') && (
              <li>SMS</li>
            )}
          </ul>
          <div>
            Once you've confirmed, you won't be able to cancel the campaign.
          </div>
          {minutes_to_send >= 0 && (
            <Alert
              message={`Warning: this campaign will start in ${
                minutes_to_send === 0
                  ? 'less than 1 minute'
                  : minutes_to_send === 1
                  ? '1 minute'
                  : `${minutes_to_send} minutes`
              }. Make sure you've left adequate processing time.`}
              type="error"
            />
          )}
        </>
      ),
      okText: 'Confirm',
      onOk() {
        const approvals = approvalForm.getFieldValue('approvals');
        const should_send_emails = approvals.includes('should_send_emails');
        const should_send_letters = approvals.includes('should_send_letters');
        const should_send_sms_messages = approvals.includes(
          'should_send_sms_messages'
        );
        approveCampaignMutation({
          variables: {
            practice_id: practiceDetailsQueryResult.data!.practices_by_pk!
              .practice_id!,
            campaign_template_id: campaign.campaign_template_id,
            letter_component_approvals: should_send_letters
              ? [
                  ...campaign.campaign_letter_components
                    .filter((comp) => !comp.is_archived)
                    .map((comp) => {
                      return {
                        campaign_letter_component_id:
                          comp.campaign_letter_component_id,
                      };
                    }),
                ]
              : [],
            email_component_approvals: should_send_emails
              ? [
                  ...campaign.campaign_email_components
                    .filter((comp) => !comp.is_archived)
                    .map((comp) => {
                      return {
                        campaign_email_component_id:
                          comp.campaign_email_component_id,
                      };
                    }),
                ]
              : [],
            sms_component_approvals: should_send_sms_messages
              ? [
                  ...campaign.campaign_sms_components
                    .filter((comp) => !comp.is_archived)
                    .map((comp) => {
                      return {
                        campaign_sms_component_id:
                          comp.campaign_sms_component_id,
                      };
                    }),
                ]
              : [],
          },
        });
      },
    });
  };

  const isApproved = campaign.campaign_approvals.length > 0;
  const approval = campaign.campaign_approvals?.[0];
  const email_component_approvals = approval?.email_component_approvals;
  const letter_component_approvals = approval?.letter_component_approvals;
  const sms_component_approvals = approval?.sms_component_approvals;

  return (
    <>
      <Helmet>
        <title>Campaign Details | Mabel</title>
      </Helmet>
      <div style={{ margin: '0 24px 20px' }}>
        <Link to="/campaigns">
          <ArrowLeftOutlined /> Back to Campaigns
        </Link>
      </div>
      <Row gutter={[42, 16]} justify="space-around">
        <Col md={20} lg={{ span: 6, offset: 1 }} xl={{ span: 10, offset: 1 }}>
          <Typography.Title level={4} style={{ textAlign: 'center' }}>
            {campaign.campaign_name}
          </Typography.Title>
          <Typography.Paragraph style={{ textAlign: 'center' }}>
            {campaign.campaign_description}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {/* You may customize these materials by{' '}
            <Link to="/settings/practice">
              changing your practice information
            </Link>
            . */}
            {/* If you have any questions about this step,
            please contact{' '}
            <Typography.Link href="mailto:support@getmabel.com">
              support@getmabel.com
            </Typography.Link> */}
          </Typography.Paragraph>
          <Typography.Paragraph>
            By clicking below, you authorize Mabel to send the following
            materials on your behalf:
          </Typography.Paragraph>
          <Row justify="center">
            <Col>
              <Form
                name="approval_form"
                form={approvalForm}
                initialValues={{
                  approvals: [
                    !isApproved || letter_component_approvals.length
                      ? 'should_send_letters'
                      : undefined,
                    !isApproved || email_component_approvals.length
                      ? 'should_send_emails'
                      : undefined,
                    !isApproved || sms_component_approvals.length
                      ? 'should_send_sms_messages'
                      : undefined,
                  ],
                }}
                onFinish={confirmApproval}
              >
                <Form.Item
                  name="approvals"
                  rules={[
                    { required: true, message: 'Please select at least one' },
                  ]}
                >
                  <Checkbox.Group disabled={isApproved}>
                    <Checkbox
                      value="should_send_letters"
                      indeterminate={Boolean(
                        letter_component_approvals?.length &&
                          letter_component_approvals?.length <
                            campaign.campaign_letter_components?.filter(
                              (comp) => !comp.is_archived
                            ).length
                      )}
                    >
                      Direct mail
                    </Checkbox>
                    <br />
                    <Checkbox
                      value="should_send_emails"
                      indeterminate={Boolean(
                        email_component_approvals?.length &&
                          email_component_approvals?.length <
                            campaign.campaign_email_components?.filter(
                              (comp) => !comp.is_archived
                            ).length
                      )}
                    >
                      Email
                    </Checkbox>
                    <br />
                    <Checkbox
                      value="should_send_sms_messages"
                      indeterminate={Boolean(
                        sms_component_approvals?.length &&
                          sms_component_approvals?.length <
                            campaign.campaign_sms_components?.filter(
                              (comp) => !comp.is_archived
                            ).length
                      )}
                    >
                      SMS
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={isApproved}
                    loading={approveCampaignMutationResult.loading}
                  >
                    Approve Campaign
                  </Button>
                </Form.Item>
              </Form>
              {isApproved && (
                <span>
                  <CheckCircleFilled className="success-color" /> Approved{' '}
                  <small>
                    <i>
                      by {approval.approver?.user_name || 'Unknown'} on{' '}
                      {dayjs(approval.created_at).format('lll')}
                    </i>
                  </small>
                </span>
              )}
            </Col>
          </Row>
        </Col>
        <Col xl={{ span: 12, offset: 1 }}>
          <Card
            style={{ width: 600, textAlign: 'center' }}
            className="card-shadow"
          >
            <div style={{ textAlign: 'right' }}>
              <Dropdown
                placement="bottomRight"
                overlay={
                  <Menu>
                    <Menu.Item
                      icon={<DownloadOutlined />}
                      onClick={() => {
                        const hide = message.loading(
                          'Downloading previews...',
                          0
                        );
                        getAllTemplates()
                          .then(hide)
                          .catch((err) => {
                            hide();
                            message.error(err.toString());
                          });
                      }}
                    >
                      Download previews
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button type="text" icon={<MoreOutlined />} />
              </Dropdown>
            </div>
            <Typography.Title level={4} style={{ marginBottom: 8 }}>
              Preview
            </Typography.Title>
            <Tabs centered>
              {campaign.campaign_letter_components.find(
                (comp) => !comp.is_archived
              ) && (
                <Tabs.TabPane
                  tab={
                    <span>
                      <PaperPlaneOutlined />
                      Direct Mail
                    </span>
                  }
                  key="letters"
                  forceRender
                >
                  <TemplateCarousel
                    letters={campaign.campaign_letter_components.filter(
                      (comp) => !comp.is_archived
                    )}
                    practice={practiceDetailsQueryResult.data?.practices_by_pk!}
                    campaign={campaign}
                  />
                </Tabs.TabPane>
              )}
              {campaign.campaign_email_components.find(
                (comp) => !comp.is_archived
              ) && (
                <Tabs.TabPane
                  tab={
                    <span>
                      <MailOutlined />
                      Email
                    </span>
                  }
                  key="email"
                  forceRender
                >
                  <TemplateCarousel
                    emails={campaign.campaign_email_components.filter(
                      (comp) => !comp.is_archived
                    )}
                    practice={practiceDetailsQueryResult.data?.practices_by_pk!}
                    campaign={campaign}
                  />
                </Tabs.TabPane>
              )}
              {campaign.campaign_sms_components.find(
                (comp) => !comp.is_archived
              ) && (
                <Tabs.TabPane
                  tab={
                    <span>
                      <MessageOutlined />
                      SMS
                    </span>
                  }
                  key="sms"
                  forceRender
                >
                  <TemplateCarousel
                    sms_messages={campaign.campaign_sms_components.filter(
                      (comp) => !comp.is_archived
                    )}
                    practice={practiceDetailsQueryResult.data?.practices_by_pk!}
                    campaign={campaign}
                  />
                </Tabs.TabPane>
              )}
            </Tabs>
          </Card>
        </Col>
      </Row>
    </>
  );
};

interface PatientPreviewModalProps extends ModalProps {
  practice: PracticeDetailsFragment;
  component?:
    | CampaignLetterComponentDetailsFragment
    | CampaignEmailComponentDetailsFragment
    | CampaignSmsComponentDetailsFragment;
  campaign_merge_vars?: { [key: string]: any };
  limit?: number;
  offset?: number;
}

// defined functional component to show the count of the number of recipients
const ComponentRecipientCount: React.FC<{
  practice: PracticeDetailsFragment;
  component?:
    | CampaignLetterComponentDetailsFragment
    | CampaignEmailComponentDetailsFragment
    | CampaignSmsComponentDetailsFragment;
}> = ({ practice, component }) => {
  const { loading: loadingRecipients, data: recipientCount } = useRequest(
    async () =>
      getComponentRecipientCount({
        practice_id: practice.practice_id,
        patient_segment_id: component!.patient_segment_id,
        send_datetime: component!.send_datetime,
      }),
    {
      throwOnError: true,
      refreshDeps: [practice, component],
    }
  );

  return loadingRecipients ? <Spin spinning /> : recipientCount?.count;
};

const PatientPreviewModal: React.FC<PatientPreviewModalProps> = ({
  practice,
  component,
  campaign_merge_vars,
  limit,
  offset,
  ...props
}) => {
  const { loading: loadingRecipients, data: componentRecipients } = useRequest(
    async () =>
      getComponentRecipients({
        practice_id: practice.practice_id,
        patient_segment_id: component!.patient_segment_id,
        send_datetime: component!.send_datetime,
        limit: limit || 10,
        offset: offset,
      }),
    {
      throwOnError: true,
      refreshDeps: [practice, component, limit, offset],
    }
  );

  const carouselRef = useRef<CarouselRef>(null);
  const [slideNum, setSlideNum] = useState(0);

  return (
    <Modal
      title={`Previewing template to be sent on ${dayjs(
        component!.send_datetime
      ).format('ll')}`}
      {...props}
      onCancel={(e) => {
        if (props.onCancel) props.onCancel(e);
        carouselRef.current?.goTo(0, true);
      }}
    >
      {component!.patient_segment.patient_segment_description}:
      <Spin spinning={loadingRecipients}>
        {component && componentRecipients?.patients.length > 0 ? (
          <Space>
            <Button
              shape="circle"
              icon={<ArrowLeftOutlined />}
              onClick={() => carouselRef.current?.prev()}
              disabled={slideNum === 0}
            />
            <div style={{ width: 450 }}>
              <Carousel
                ref={carouselRef}
                draggable={false}
                infinite={false}
                afterChange={(current) => setSlideNum(current)}
              >
                {componentRecipients.patients.map(
                  (p: PatientDetailsFragment) => {
                    return (
                      <div key={p.patient_id}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '12px 0px',
                          }}
                        >
                          <Tooltip
                            title={
                              <>
                                <div>{p.patient_name}</div>
                                <div>
                                  <b>Born</b>: {p.birth_date}
                                </div>
                                <div>
                                  <b>Primary Insurance</b>:{' '}
                                  {p.primary_insurance_payer_name}
                                </div>
                                <div>
                                  <b>Secondary Insurance</b>:{' '}
                                  {p.secondary_insurance_payer_name}
                                </div>
                                <div>
                                  <b>PCP</b>: {p.patient_pcp}
                                </div>
                              </>
                            }
                          >
                            <Link to={`/patients?query=${p.patient_id}`}>
                              <UserInjuredOutlined /> {p.patient_name}
                            </Link>
                          </Tooltip>
                        </div>
                        {component?.__typename ===
                        'campaign_letter_components' ? (
                          <LetterTemplatePreview
                            practice={practice}
                            letter_template={
                              (component as CampaignLetterComponentDetailsFragment)
                                .letter_template
                            }
                            campaign_letter_component={
                              component as CampaignLetterComponentDetailsFragment
                            }
                            patient={p}
                          />
                        ) : component?.__typename ===
                          'campaign_email_components' ? (
                          <EmailTemplatePreview
                            practice={practice}
                            email_template={
                              (component as CampaignEmailComponentDetailsFragment)
                                .email_template
                            }
                            campaign_email_component={
                              component as CampaignEmailComponentDetailsFragment
                            }
                            patient={p}
                          />
                        ) : (
                          <SMSTemplatePreview
                            practice={practice}
                            sms_template={
                              (component as CampaignSmsComponentDetailsFragment)
                                .sms_template
                            }
                            campaign_sms_component={
                              component as CampaignSmsComponentDetailsFragment
                            }
                            patient={p}
                          />
                        )}
                      </div>
                    );
                  }
                )}
              </Carousel>
            </div>
            <Button
              shape="circle"
              icon={<ArrowRightOutlined />}
              onClick={() => carouselRef.current?.next()}
              disabled={
                slideNum === (componentRecipients?.patients.length || 1) - 1
              }
            />
          </Space>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Matching Patients Found"
          />
        )}
      </Spin>
    </Modal>
  );
};

const TemplateCarousel: React.FC<{
  letters?: CampaignTemplateDetailsFragment['campaign_letter_components'];
  emails?: CampaignTemplateDetailsFragment['campaign_email_components'];
  sms_messages?: CampaignTemplateDetailsFragment['campaign_sms_components'];
  practice: PracticeDetailsFragment;
  campaign?: CampaignTemplateDetailsFragment;
}> = ({ letters = [], emails = [], sms_messages = [], practice, campaign }) => {
  const [slideNum, setSlideNum] = useState(0);
  const carouselRef = useRef<CarouselRef>(null);

  const [activeComponent, setActiveComponent] = useState<
    | CampaignLetterComponentDetailsFragment
    | CampaignEmailComponentDetailsFragment
    | CampaignSmsComponentDetailsFragment
  >();
  const [patientPreviewVisible, setPatientPreviewVisible] = useState(false);

  return (
    <>
      <Space>
        <Button
          shape="circle"
          icon={<ArrowLeftOutlined />}
          onClick={() => carouselRef.current?.prev()}
          disabled={slideNum === 0}
        />
        <div style={{ width: 450 }}>
          <Carousel
            ref={carouselRef}
            draggable={false}
            dots={false}
            infinite={false}
            afterChange={(current) => setSlideNum(current)}
          >
            {letters
              .concat()
              .sort((a, b) =>
                dayjs(a.send_datetime).isAfter(dayjs(b.send_datetime)) ? 1 : -1
              )
              .map((letter) => {
                return (
                  practice && (
                    <div key={letter.letter_template_id}>
                      <LetterTemplatePreview
                        letter_template={letter.letter_template}
                        practice={practice}
                        campaign_letter_component={letter}
                      />
                      <Popover
                        content={
                          <>
                            Try customizing your practice's name, logo, and more
                            in your{' '}
                            <Link to="/settings/practice">
                              practice settings
                            </Link>
                            .
                          </>
                        }
                      >
                        <Typography.Link>
                          <small>Doesn't look right?</small>
                        </Typography.Link>
                      </Popover>
                      <Typography.Paragraph
                        style={{ textAlign: 'start', marginTop: 16 }}
                      >
                        <p style={{ textAlign: 'start' }}>
                          <b>
                            <CalendarOutlined /> When will it be sent?
                          </b>{' '}
                          {dayjs(letter.send_datetime).isBefore(dayjs()) ? (
                            <Tooltip title="Date has passed">
                              <Typography.Text type="secondary">
                                {dayjs(letter.send_datetime).format('lll')}
                              </Typography.Text>
                            </Tooltip>
                          ) : (
                            dayjs(letter.send_datetime).format('lll')
                          )}
                        </p>
                        <p>
                          <b>
                            <UserInjuredOutlined /> Who will it be sent to?
                          </b>{' '}
                          {letter.patient_segment.patient_segment_description}
                          <Tooltip title="Preview this segment">
                            <Button
                              type="link"
                              icon={<EyeOutlined />}
                              onClick={() => {
                                setPatientPreviewVisible(true);
                                setActiveComponent(letter);
                              }}
                            />
                          </Tooltip>
                        </p>
                        <p>
                          <b>
                            <NumberOutlined /> Patients:
                          </b>{' '}
                          <ComponentRecipientCount
                            practice={practice}
                            component={letter}
                          />
                        </p>
                      </Typography.Paragraph>
                      {campaign?.campaign_description &&
                        campaign?.campaign_description.includes('#HEDR') && (
                          <CampaignsHEDRGenerateButton
                            practice={practice}
                            component={letter}
                          />
                        )}
                    </div>
                  )
                );
              })}
            {emails
              .concat()
              .sort((a, b) =>
                dayjs(a.send_datetime).isAfter(dayjs(b.send_datetime)) ? 1 : -1
              )
              .map((email) => {
                return (
                  practice && (
                    <div key={email.email_template_id}>
                      <EmailTemplatePreview
                        email_template={email.email_template}
                        practice={practice}
                        campaign_email_component={email}
                      />
                      <Popover
                        content={
                          <>
                            Try customizing your practice's name, logo, and more
                            in your{' '}
                            <Link to="/settings/practice">
                              practice settings
                            </Link>
                            .
                          </>
                        }
                      >
                        <Typography.Link>
                          <small>Doesn't look right?</small>
                        </Typography.Link>
                      </Popover>

                      <Typography.Paragraph
                        style={{ textAlign: 'start', marginTop: 16 }}
                      >
                        <p style={{ textAlign: 'start' }}>
                          <b>
                            <CalendarOutlined /> When will it be sent?
                          </b>{' '}
                          {dayjs(email.send_datetime).isBefore(dayjs()) ? (
                            <Tooltip title="Date has passed">
                              <Typography.Text type="secondary">
                                {dayjs(email.send_datetime).format('lll')}
                              </Typography.Text>
                            </Tooltip>
                          ) : (
                            dayjs(email.send_datetime).format('lll')
                          )}
                        </p>
                        <p>
                          <b>
                            <UserInjuredOutlined /> Who will it be sent to?
                          </b>{' '}
                          {email.patient_segment.patient_segment_description}
                          <Tooltip title="Preview this segment">
                            <Button
                              type="link"
                              icon={<EyeOutlined />}
                              onClick={() => {
                                setPatientPreviewVisible(true);
                                setActiveComponent(email);
                              }}
                            />
                          </Tooltip>
                        </p>
                        <p>
                          <b>
                            <NumberOutlined /> Patients:
                          </b>{' '}
                          <ComponentRecipientCount
                            practice={practice}
                            component={email}
                          />
                        </p>
                      </Typography.Paragraph>
                      {campaign?.campaign_description &&
                        campaign?.campaign_description.includes('#HEDR') && (
                          <CampaignsHEDRGenerateButton
                            practice={practice}
                            component={email}
                          />
                        )}
                    </div>
                  )
                );
              })}

            {sms_messages
              .concat()
              .sort((a, b) =>
                dayjs(a.send_datetime).isAfter(dayjs(b.send_datetime)) ? 1 : -1
              )
              .map((sms) => {
                return (
                  practice && (
                    <div key={sms.sms_template_id}>
                      <SMSTemplatePreview
                        sms_template={sms.sms_template}
                        practice={practice}
                        campaign_sms_component={sms}
                      />
                      <Popover
                        content={
                          <>
                            Try customizing your practice's name, logo, and more
                            in your{' '}
                            <Link to="/settings/practice">
                              practice settings
                            </Link>
                            .
                          </>
                        }
                      >
                        <Typography.Link>
                          <small>Doesn't look right?</small>
                        </Typography.Link>
                      </Popover>

                      <Typography.Paragraph
                        style={{ textAlign: 'start', marginTop: 16 }}
                      >
                        <p style={{ textAlign: 'start' }}>
                          <b>
                            <CalendarOutlined /> When will it be sent?
                          </b>{' '}
                          {dayjs(sms.send_datetime).isBefore(dayjs()) ? (
                            <Tooltip title="Date has passed">
                              <Typography.Text type="secondary">
                                {dayjs(sms.send_datetime).format('lll')}
                              </Typography.Text>
                            </Tooltip>
                          ) : (
                            dayjs(sms.send_datetime).format('lll')
                          )}
                        </p>
                        <p>
                          <b>
                            <UserInjuredOutlined /> Who will it be sent to?
                          </b>{' '}
                          {sms.patient_segment.patient_segment_description}
                          <Tooltip title="Preview this segment">
                            <Button
                              type="link"
                              icon={<EyeOutlined />}
                              onClick={() => {
                                setPatientPreviewVisible(true);
                                setActiveComponent(sms);
                              }}
                            />
                          </Tooltip>
                        </p>
                        <p>
                          <b>
                            <NumberOutlined /> Patients:
                          </b>{' '}
                          <ComponentRecipientCount
                            practice={practice}
                            component={sms}
                          />
                        </p>
                      </Typography.Paragraph>
                      {campaign?.campaign_description &&
                        campaign?.campaign_description.includes('#HEDR') && (
                          <CampaignsHEDRGenerateButton
                            practice={practice}
                            component={sms}
                          />
                        )}
                    </div>
                  )
                );
              })}
          </Carousel>
          {campaign && activeComponent && (
            <PatientPreviewModal
              practice={practice}
              campaign_merge_vars={campaign?.campaign_merge_vars}
              component={activeComponent}
              visible={patientPreviewVisible}
              onCancel={() => {
                setPatientPreviewVisible(false);
                setActiveComponent(undefined);
              }}
              width={600}
              footer={null}
            />
          )}
        </div>
        <Button
          shape="circle"
          icon={<ArrowRightOutlined />}
          onClick={() => carouselRef.current?.next()}
          disabled={
            slideNum ===
            letters.length + emails.length + sms_messages.length - 1
          }
        />
      </Space>
    </>
  );
};

async function processInBatches<T, U>(
  items: T[],
  batchworker: (items: T[]) => Promise<U[]>,
  batchSize: number,
  waitTime: number
): Promise<U[]> {
  let result: U[] = [];
  for (let i = 0; i < items.length; i += batchSize) {
    const batch = items.slice(i, i + batchSize);
    result = [...result, ...(await batchworker(batch))];
    if (i + batchSize < items.length) {
      await new Promise((resolve) => setTimeout(resolve, waitTime));
    }
  }
  return result;
}

const CampaignsHEDRGenerateButton: React.FC<{
  practice: PracticeDetailsFragment;
  component?:
    | CampaignLetterComponentDetailsFragment
    | CampaignEmailComponentDetailsFragment
    | CampaignSmsComponentDetailsFragment;
}> = ({ practice, component }) => {
  const { loading, run } = useRequest(
    async (practice, component) => {
      // get all recipients for this component, in batches
      const batchSize = 1000;
      let offset = 0;
      let recipients: any[] = [];
      while (true) {
        const new_recipients = await getComponentRecipients({
          practice_id: practice.practice_id,
          patient_segment_id: 'ALL',
          send_datetime: component!.send_datetime,
          limit: batchSize,
          offset: offset,
        });
        recipients = [...recipients, ...new_recipients.patients];
        if (new_recipients.patients.length < batchSize) {
          break;
        }
        offset += batchSize;
      }
      // sort recipients by the 'patient_id' string since
      // getMergeVars returns batches ordered by patient_id
      recipients = recipients.sort((a, b) =>
        a.patient_id.localeCompare(b.patient_id)
      );

      // get the mergeVars for all recipients, in batches
      const mergeVars = await processInBatches(
        recipients,
        async (patients: any[]) => {
          return await getMergeVars(
            component?.__typename === 'campaign_letter_components'
              ? 'letter'
              : component?.__typename === 'campaign_email_components'
              ? 'email'
              : 'sms',
            component?.__typename === 'campaign_letter_components'
              ? component.campaign_letter_component_id
              : component?.__typename === 'campaign_email_components'
              ? component.campaign_email_component_id
              : component?.__typename === 'campaign_sms_components'
              ? component.campaign_sms_component_id
              : undefined,
            practice.practice_id,
            patients.map((p) => p.patient_id)
          );
        },
        500,
        2000 //wait 2s between batches due to short.io rate limits
      );

      const csv_fields = mergeVars.map((mv: any, i) => {
        const patient = recipients[i];
        return {
          'ACO ID': patient.practice.primary_network.dc_id,
          'Practice Name': patient.patient_pcp,
          TIN: patient.alignment_tin,
          'Patient Name': patient.patient_name,
          'Date of Birth': patient.birth_date,
          MBI: patient.mbi,
          'Deceased Date': patient.deceased_as_of_date,
          'HEDR Demographic Status':
            patient.patient_hedr_label.hedr_demographic_status,
          'HEDR SDoH Status 2024':
            patient.patient_hedr_label.hedr_sdoh_status_2024,
          'Survey Link': `https://${mv.signature_url}`,
        };
      });

      // split patients based on TIN
      const tin_groups = csv_fields.reduce((acc: any, curr: any) => {
        if (!acc[curr['TIN']]) {
          acc[curr['TIN']] = [];
        }
        acc[curr['TIN']].push(curr);
        return acc;
      }, {});

      // Using jszip, create a zip file with a CSV for each TIN
      const jszip = new JSZip();
      const getValidFilename = (s: string) => s.replace(/[\\/:"*?<>|]/g, '-');
      // get current date in YYYY-MM-DD format, to append to filename
      let date = new Date();
      date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
      const output_folder = getValidFilename(
        `${csv_fields[0]['ACO ID']}_HEDR_Survey_Links_${
          date.toISOString().split('T')[0]
        }`
      );
      const link_folder = jszip.folder(output_folder);
      Object.entries(tin_groups).forEach(([tin, csv_fields]) => {
        const csv = Papa.unparse(csv_fields as any[]);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        link_folder?.file(
          getValidFilename(
            `${(csv_fields as any)[0]['ACO ID']}_${tin}_${
              (csv_fields as any)[0]['Practice Name']
            }_Survey_Links.csv`
          ),
          blob
        );
      });
      const blob = await jszip.generateAsync({ type: 'blob' });
      saveAs(blob, `${output_folder}.zip`);

      // // write out mergeVars to CSV using papaparse
      // const csv = Papa.unparse(csv_fields);
      // const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      // saveAs(blob, `test.csv`);

      return mergeVars;
    },
    {
      manual: true,
      throwOnError: true,
      refreshDeps: [practice, component],
    }
  );

  return (
    <Button
      type="primary"
      icon={<DownloadOutlined />}
      onClick={() => run(practice, component)}
      loading={loading}
    >
      Generate HEDR Links
    </Button>
  );
};

export default CampaignDetailsPage;
