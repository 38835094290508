import React, { useState } from 'react';

import { Storage } from 'aws-amplify';

import { Progress, Upload, message, Button, Space } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import ImgCrop, { ImgCropProps } from 'antd-img-crop';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';

//import { v4 as uuidv4 } from 'uuid';
//import pica from 'pica';

interface ImageUploadValue {
  url?: string;
  file?: File;
}

interface PublicImageUploadProps {
  value?: ImageUploadValue; //public url of the upload
  onChange?: (value: ImageUploadValue) => void;
  uploadPath?: string; // this will become the file path in s3.
  cropProps?: Partial<ImgCropProps>;
  previewStyle?: React.CSSProperties;
  targetWidth?: number;
  targetHeight?: number;
  maxFileSize?: number;
}

const PublicImageUpload: React.FC<PublicImageUploadProps> = React.forwardRef(
  (
    {
      value,
      onChange,
      uploadPath = '',
      cropProps,
      previewStyle,
      targetWidth = 0,
      targetHeight = 0,
      maxFileSize,
    },
    ref
  ) => {
    const [avatarUploadState, setAvatarUploadState] = useState({
      loading: false,
      progress: 0,
    });
    const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
      //setFileList(info.fileList);
      if (info.file.status === 'uploading') {
        setAvatarUploadState({
          loading: true,
          progress: info.event?.percent || 0,
        });
        return;
      }
      if (info.file.status === 'done') {
        setAvatarUploadState({ loading: false, progress: 0 });
      }
    };

    const beforeUpload = (file: File) => {
      if (file.type && !file.type.startsWith('image')) {
        message.error('File type must be .JPG, .JPEG, or .PNG');
        return false;
      }
      if (maxFileSize && file.size > maxFileSize) {
        message.error(
          `Image must be smaller than ${maxFileSize / 1024 / 1024}MB.`
        );
        return false;
      }
      if (targetWidth || targetHeight) {
        getDimensions(file).then((dim) => {
          if (dim.width < targetWidth || dim.height < targetHeight) {
            message.warning(
              `Warning: file should be at least ${targetWidth}px by ${targetHeight}px`
            );
          }
        });
      }
      return true;
    };

    const readFilePromise = (file: File) => {
      return new Promise<string>((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve((reader.result as string)?.toString());
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };

    const getDimensions = async (file: File) => {
      return new Promise<{ width: number; height: number }>(
        (resolve, reject) => {
          readFilePromise(file).then((fileContents) => {
            const image = new Image();
            image.onload = () =>
              resolve({ width: image.width, height: image.height });
            image.src = fileContents;
          });
        }
      );
    };

    // this does image resizing in the browser, but pica seems to add some weird edge artifacts
    // so commenting it out for now.

    // const transformFile = async (file: File) => {
    //   const dim = await getDimensions(file);
    //   if (
    //     !targetWidth ||
    //     !targetHeight ||
    //     (dim.width === targetWidth && dim.height === targetHeight)
    //   ) {
    //     return Promise.resolve(file);
    //   }
    //   return new Promise<File>((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => {
    //       const canvas = document.createElement('canvas');
    //       canvas.width = targetWidth;
    //       canvas.height = targetHeight;
    //       const img = document.createElement('img');
    //       img.onerror = () => {
    //         reject(new Error('Image resize failed!'));
    //       };
    //       img.onload = () => {
    //         pica()
    //           .resize(img, canvas, { alpha: true })
    //           .then((result) => pica().toBlob(result, file.type))
    //           .then((blob) => {
    //             const randomPrefix = uuidv4().slice(0, 8);
    //             const fileNameNoExtension = file.name.replace(/\.[^/.]+$/, '');
    //             resolve(
    //               new File(
    //                 [blob],
    //                 `${randomPrefix}-${fileNameNoExtension}-${targetWidth}x${targetHeight}.${
    //                   file.type === 'image/png' ? 'png' : 'jpeg'
    //                 }`,
    //                 {
    //                   type: file.type,
    //                 }
    //               )
    //             );
    //           });
    //       };
    //       img.src = reader.result as string;
    //     };
    //   });
    // };

    const [
      currentUploadVal,
      setCurrentUploadVal,
    ] = useState<ImageUploadValue>();
    const customRequest = ({
      onProgress,
      onError,
      onSuccess,
      data,
      filename,
      file,
    }: any) => {
      const key = uploadPath + file.name;
      Storage.put(key, file, {
        level: 'protected',
        contentType: file.type,
        bucket: process.env.REACT_APP_PUBLIC_STORAGE_BUCKET,
        progressCallback(progress: any) {
          onProgress(
            { percent: (100 * progress.loaded) / progress.total },
            file
          );
        },
      })
        .then((result) => {
          Storage.get(key, {
            level: 'protected',
            bucket: process.env.REACT_APP_PUBLIC_STORAGE_BUCKET,
          }).then((result) => {
            const url = new URL(result.toString());
            const newVal = { url: url.origin + url.pathname, file: file };
            setCurrentUploadVal(newVal);
            if (onChange) onChange(newVal);
            onSuccess(newVal, file);
          });
        })
        .catch((err) => onError(err));
    };

    return (
      <Space align="end">
        <ImgCrop {...cropProps} beforeCrop={beforeUpload}>
          <Upload
            name="avatar"
            accept="image/*,.png,.jpg,.jpeg"
            listType="picture-card"
            showUploadList={false}
            //beforeUpload={beforeUpload} do this before crop instead
            //transformFile={transformFile}
            customRequest={customRequest}
            onChange={handleChange}
            progress={{
              showInfo: false,
              strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
              },
              strokeWidth: 3,
              format: (percent) => `${parseFloat(percent?.toFixed(2) || '0')}%`,
            }}
          >
            {(value?.url || currentUploadVal?.url) &&
            !avatarUploadState.loading ? (
              <img
                src={value?.url || currentUploadVal?.url}
                alt="avatar"
                style={{ width: '100%', ...previewStyle }}
              />
            ) : (
              <div>
                {avatarUploadState.loading ? (
                  <Progress
                    percent={avatarUploadState.progress}
                    size="small"
                    showInfo={false}
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }}
                    strokeWidth={3}
                  />
                ) : (
                  <PlusOutlined />
                )}
                <div>Upload</div>
              </div>
            )}
          </Upload>
        </ImgCrop>
        {value?.url || currentUploadVal?.url ? (
          <div style={{ paddingBottom: 16 }}>
            <Button
              type="text"
              size="small"
              icon={<CloseOutlined />}
              onClick={() => {
                setCurrentUploadVal({});
                if (onChange) onChange({});
              }}
            >
              clear
            </Button>
          </div>
        ) : null}
      </Space>
    );
  }
);

export default PublicImageUpload;
