import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Typography } from 'antd';

const PracticeAnalyticsPage = () => {
  return (
    <>
      <Helmet>
        <title>Analytics | Mabel</title>
      </Helmet>
      <Typography.Title level={3}>Practice Analytics</Typography.Title>
    </>
  );
};

export default PracticeAnalyticsPage;
