import React from 'react';

import { Button, Popconfirm } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { PopconfirmProps } from 'antd/lib/popconfirm';

import { useMutation } from '@apollo/react-hooks';
import { BaseMutationOptions } from '@apollo/react-common';
import { DocumentNode } from 'graphql';

interface MutateButtonProps extends ButtonProps {
  document: DocumentNode;
  variables: any;
  refetchQueries?: BaseMutationOptions['refetchQueries'];
  popconfirm?: PopconfirmProps;
}

const MutateButton: React.FC<MutateButtonProps> = ({
  refetchQueries,
  ...rest
}) => {
  const [mutation, mutationResult] = useMutation(rest.document);
  const handleMutation = () =>
    mutation({
      variables: rest.variables,
      refetchQueries: refetchQueries,
    });
  const button = (
    <Button
      {...rest}
      type={rest.type || 'link'}
      size={rest.size || 'small'}
      style={rest.style || { margin: 0 }}
      loading={mutationResult.loading}
      onClick={!rest.popconfirm ? () => handleMutation() : undefined}
    />
  );
  return !rest.popconfirm || rest.disabled ? (
    button
  ) : (
    <Popconfirm
      {...rest.popconfirm}
      title={rest.popconfirm?.title}
      okText={rest.popconfirm?.okText || 'Yes'}
      cancelText={rest.popconfirm?.cancelText || 'No'}
      onConfirm={() => handleMutation()}
    >
      {button}
    </Popconfirm>
  );
};

export default MutateButton;
