import React from 'react';
import { Icon as IconifyIcon } from '@iconify/react';

import clinic from '@iconify/icons-bx/bx-clinic';
import networkChart from '@iconify/icons-bx/bx-network-chart';
import stethoscope from '@iconify/icons-fa/stethoscope';
import userInjured from '@iconify/icons-fa-solid/user-injured';
import mailBulkSolid from '@iconify/icons-la/mail-bulk-solid';
import rocket from '@iconify/icons-bx/bx-rocket';
import paperPlane from '@iconify/icons-fa-regular/paper-plane';
import building from '@iconify/icons-bx/bx-building';
import web from '@iconify/icons-mdi/web';

const MailBulkOutlined: React.FC<{ style?: object }> = ({ style }) => (
  <span role="img" aria-label="campaign" className="anticon" style={style}>
    <IconifyIcon icon={mailBulkSolid} />
  </span>
);

const ClinicOutlined: React.FC<{ style?: object }> = ({ style }) => (
  <span role="img" aria-label="clinic" className="anticon" style={style}>
    <IconifyIcon icon={clinic} />
  </span>
);

const NetworkOutlined: React.FC<{ style?: object }> = ({ style }) => (
  <span role="img" aria-label="network" className="anticon" style={style}>
    <IconifyIcon icon={networkChart} />
  </span>
);

const StethoscopeOutlined: React.FC<{ style?: object }> = ({ style }) => (
  <span role="img" aria-label="provider" className="anticon" style={style}>
    <IconifyIcon icon={stethoscope} />
  </span>
);

const UserInjuredOutlined: React.FC<{ style?: object }> = ({ style }) => (
  <span role="img" aria-label="patient" className="anticon" style={style}>
    <IconifyIcon icon={userInjured} />
  </span>
);

const RocketOutlined: React.FC<{ style?: object }> = ({ style }) => (
  <span role="img" aria-label="onboard" className="anticon" style={style}>
    <IconifyIcon icon={rocket} />
  </span>
);

const PaperPlaneOutlined: React.FC<{ style?: object }> = ({ style }) => (
  <span role="img" aria-label="letter" className="anticon" style={style}>
    <IconifyIcon icon={paperPlane} />
  </span>
);

const BuildingOutlined: React.FC<{ style?: object }> = ({ style }) => (
  <span role="img" aria-label="organization" className="anticon" style={style}>
    <IconifyIcon icon={building} />
  </span>
);

const WebOutlined: React.FC<{ style?: object }> = ({ style }) => (
  <span role="img" aria-label="web" className="anticon" style={style}>
    <IconifyIcon icon={web} />
  </span>
);

export {
  RocketOutlined,
  UserInjuredOutlined,
  ClinicOutlined,
  MailBulkOutlined,
  NetworkOutlined,
  StethoscopeOutlined,
  PaperPlaneOutlined,
  BuildingOutlined,
  WebOutlined,
};
