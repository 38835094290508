import { createContext } from 'react';

import { CognitoUser } from '@aws-amplify/auth';

type MabelUserRole =
  | 'admin'
  | 'network_user'
  | 'practice_user'
  | 'organization_user';

class MabelUser {
  private _user: CognitoUser;
  private _current_role: MabelUserRole;

  constructor(user: CognitoUser, role: MabelUserRole | undefined = undefined) {
    this._user = user;
    this._current_role =
      role && this.allowed_roles.includes(role) ? role : this.default_role;

    if (!this._current_role) {
      console.warn(`role missing for this user`);
    }
  }

  get user_id() {
    return this.claims?.sub;
  }

  get cognito_user() {
    return this._user;
  }

  get role() {
    return this._current_role;
  }

  get default_role() {
    const hasura_claims =
      this._user && this.claims
        ? JSON.parse(this.claims['https://hasura.io/jwt/claims'])
        : {};
    return hasura_claims['x-hasura-default-role'];
  }

  get allowed_roles(): MabelUserRole[] {
    const hasura_claims =
      this._user && this.claims && this.claims['https://hasura.io/jwt/claims']
        ? JSON.parse(this.claims['https://hasura.io/jwt/claims'])
        : {};
    return hasura_claims['x-hasura-allowed-roles'] || [];
  }

  get claims() {
    return this._user?.getSignInUserSession()?.getIdToken().decodePayload();
  }

  get jwt() {
    return this._user?.getSignInUserSession()?.getIdToken().getJwtToken();
  }
}

type UserContext = {
  user: MabelUser | undefined;
  setRole: (role: MabelUserRole) => void;
};
const defaultUserContext = { user: undefined, setRole: () => {} };

const UserContext = createContext<UserContext>(defaultUserContext);

export { MabelUser, UserContext };
export type { MabelUserRole };
