import React, { useState, useEffect } from 'react';

import { Result, Button, Table, Row, Col, Tooltip, Input } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PaginationProps } from 'antd/lib/pagination';
import { PlusOutlined, StopOutlined, CheckOutlined } from '@ant-design/icons';

import { textToHighlight } from '../../components/utils/Highlight';
import PracticeDescription from './PracticeDescription';
import { PracticeMutationModal } from './PracticeForm';
import ProviderTag from '../providers/ProviderTag';
import NetworkTag from '../networks/NetworkTag';
import MutateButton from '../utils/MutateButton';

import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { DEBOUNCE_MS } from '../autocompletes';

import {
  PracticeDetailsFragment,
  SearchPracticesDocument,
  SearchPracticesAdvancedDocument,
  useSearchPracticesAdvancedLazyQuery,
  SearchPracticesAdvancedQueryVariables,
  UpdatePracticeDocument,
  Order_By,
} from '../../graphql/generated';
import { getOperationAST } from 'graphql';
import ValidatedAddress from '../utils/ValidatedAddress';
import { MAX_ITEM_AGGREGATE } from '../../constants';

interface PracticesTableProps {
  initialQuery?: string;
}

const PracticesTable: React.FC<PracticesTableProps> = ({ initialQuery }) => {
  const initialSearchParams: {
    variables: SearchPracticesAdvancedQueryVariables;
    pagination: PaginationProps;
  } = {
    variables: {
      exact_query: initialQuery || null,
      substring_query: initialQuery ? `%${initialQuery}%` : null,
      additional_filters: [{ is_archived: { _in: [false] } }],
      offset: 0,
      limit: 20,
      order_by: [{ created_at: Order_By.Desc }],
      count_aggregate_limit: MAX_ITEM_AGGREGATE,
    },
    pagination: {
      current: 1,
      pageSize: 20,
    },
  };
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [
    searchPracticesQuery,
    searchPracticesQueryResults,
  ] = useSearchPracticesAdvancedLazyQuery({
    variables: initialSearchParams.variables,
  });
  const searchPracticesDebounced = useConstant(() =>
    AwesomeDebouncePromise(
      (searchParams: SearchPracticesAdvancedQueryVariables) => {
        return searchPracticesQuery({ variables: searchParams });
      },
      DEBOUNCE_MS,
      { key: (fieldId, _) => fieldId }
    )
  );
  useEffect(() => {
    searchPracticesDebounced(searchParams.variables);
  }, [searchParams, searchPracticesDebounced]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<
    PracticeDetailsFragment | undefined
  >();

  if (searchPracticesQueryResults.error)
    return (
      <Result
        status="error"
        title="There was an error retrieving practices"
        subTitle={searchPracticesQueryResults.error?.message}
      />
    );

  const columns: ColumnsType<PracticeDetailsFragment> = [
    {
      title: 'Practice Name',
      dataIndex: 'practice_name',
      key: 'practice_name',
      sorter: true,
      render: textToHighlight(searchParams.variables.exact_query),
    },
    {
      title: 'Address',
      dataIndex: 'practice_address',
      key: 'practice_address',
      sorter: true,
      render: (value, record) =>
        record.practice_address && (
          <ValidatedAddress
            address={textToHighlight(searchParams.variables.exact_query)(value)}
            deliverability={record.deliverability}
          />
        ),
    },
    {
      title: 'BAA Signed',
      dataIndex: 'baas',
      key: 'baas',
      render: (text, record) => (
        <>
          {record.baas?.filter((baa) => baa.status === 'Completed').length ? (
            <CheckOutlined className="success-color" />
          ) : null}
        </>
      ),
    },
    {
      title: 'Patient Count',
      dataIndex: ['patients_aggregate', 'aggregate', 'count'],
      key: 'patient_count',
      sorter: true,
      render: (text, record) =>
        record.patients_aggregate.aggregate?.count
          ? Number(record.patients_aggregate.aggregate?.count).toLocaleString()
          : null,
    },
    {
      title: 'Default Provider',
      dataIndex: 'default_provider',
      key: 'default_provider',
      render: (_, record) =>
        record.default_provider && (
          <ProviderTag provider={record.default_provider} />
        ),
    },
    {
      title: 'Preferred Network',
      dataIndex: ['primary_network', 'network_name'],
      key: 'primary_network_id',
      sorter: true,
      filters: searchPracticesQueryResults.data?.networks.map((n) => {
        return { value: n.network_id, text: n.network_name };
      }),
      onFilter: (value, record) => value === record.primary_network?.network_id,
      render: (_, record) =>
        record.primary_network ? (
          <NetworkTag network={record.primary_network} />
        ) : null,
    },
    {
      title: 'Active?',
      dataIndex: 'is_archived',
      key: 'is_archived',
      width: 1,
      align: 'center',
      sorter: true,
      filters: [
        {
          text: 'Active',
          value: 'false',
        },
        {
          text: 'Archived',
          value: 'true',
        },
      ],
      defaultFilteredValue: ['false'],
      onFilter: (value, record) => record.is_archived.toString() === value,
      render: (is_archived) =>
        is_archived ? (
          <Tooltip title="Item has been archived">
            <StopOutlined className="danger-color" />
          </Tooltip>
        ) : (
          <CheckOutlined className="success-color" />
        ),
    },
    {
      key: 'edit',
      width: 1,
      align: 'center',
      render: (text, record) => {
        return record.is_archived ? (
          <MutateButton
            document={UpdatePracticeDocument}
            variables={{
              practice_id: record.practice_id,
              changes: { is_archived: false },
            }}
            refetchQueries={[
              getOperationAST(SearchPracticesDocument)?.name?.value || '',
              getOperationAST(SearchPracticesAdvancedDocument)?.name?.value ||
                '',
            ]}
          >
            Restore
          </MutateButton>
        ) : (
          <div style={{ display: 'inline-flex' }}>
            <Button
              type="link"
              size="small"
              style={{ margin: 0 }}
              onClick={() => {
                setSelectedRecord(record);
                setShowUpdateModal(true);
              }}
            >
              Edit
            </Button>
            <MutateButton
              document={UpdatePracticeDocument}
              variables={{
                practice_id: record.practice_id,
                changes: { is_archived: true },
              }}
              refetchQueries={[
                getOperationAST(SearchPracticesDocument)?.name?.value || '',
                getOperationAST(SearchPracticesAdvancedDocument)?.name?.value ||
                  '',
              ]}
              popconfirm={{
                title: 'Archive this item?',
              }}
            >
              Archive
            </MutateButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={[8, 8]} justify="center">
        <Col flex="auto">
          <Input.Search
            defaultValue={initialQuery || undefined}
            placeholder="Search by practice name, address, or id"
            enterButton
            allowClear
            loading={searchPracticesQueryResults.loading}
            onSearch={(value) => {
              const trimmedValue = value;
              const newParams = {
                exact_query: trimmedValue,
                substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                offset: 0,
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: { ...old.pagination, current: 1 },
                };
              });
            }}
            onChange={(e) => {
              const trimmedValue = e.target.value.trim();
              const newParams = {
                exact_query: trimmedValue,
                substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                offset: 0,
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: { ...old.pagination, current: 1 },
                };
              });
            }}
          />
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowCreateModal(true)}
          >
            Add Practice
          </Button>
          <PracticeMutationModal
            formName="Create"
            visible={showCreateModal}
            onCancel={() => setShowCreateModal(false)}
            afterSubmit={() => setShowCreateModal(false)}
          />
          <PracticeMutationModal
            formName="Update"
            initialRecord={selectedRecord}
            visible={showUpdateModal}
            onCancel={() => setShowUpdateModal(false)}
            afterSubmit={() => setShowUpdateModal(false)}
          />
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <Table<PracticeDetailsFragment>
            size="small"
            rowKey="practice_id"
            loading={searchPracticesQueryResults.loading}
            dataSource={searchPracticesQueryResults.data?.practices}
            pagination={{
              ...searchParams.pagination,
              position: ['bottomRight'],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total}${
                  total === MAX_ITEM_AGGREGATE ? '+' : ''
                } items`,
              total:
                searchPracticesQueryResults.data?.practices_aggregate.aggregate
                  ?.count || 0,
            }}
            columns={columns}
            showSorterTooltip={false}
            onChange={(pagination, filters, sorter, extra) => {
              if (extra.action === 'sort') {
                const new_order = !sorter
                  ? []
                  : (sorter instanceof Array ? sorter : [sorter])
                      .filter((c) => c.field && c.order)
                      .map((column) => {
                        const key = column.field?.toString() || '';
                        const order =
                          column.order === 'ascend'
                            ? Order_By.AscNullsFirst
                            : Order_By.DescNullsLast;
                        return column.field instanceof Array
                          ? //special case patient_count, "aggregate" is not used in order_by
                            //{patients_aggregate: {aggregate: {count}}} vs {patients_aggregate: {count: asc}}
                            (column.columnKey === 'patient_count'
                              ? ['patients_aggregate', 'count']
                              : column.field
                            ).reduceRight(
                              (value: any, parent: any) => ({
                                [parent]: value,
                              }),
                              order
                            )
                          : { [key]: order };
                      });
                const newParams = {
                  order_by: !new_order.length
                    ? initialSearchParams.variables.order_by
                    : new_order,
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: old.pagination,
                  };
                });
              } else if (extra.action === 'filter') {
                const additional_filters: any[] = [];
                for (const k in filters) {
                  if (filters[k]?.length) {
                    additional_filters.push({ [k]: { _in: filters[k] } });
                  }
                }
                const newParams = {
                  offset: 0,
                  additional_filters: additional_filters,
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              } else if (extra.action === 'paginate') {
                const newParams = {
                  limit: pagination.pageSize,
                  offset:
                    (pagination.pageSize || 0) *
                    ((pagination.current || 0) - 1),
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: {
                      ...old.pagination,
                      current: pagination.current || 1,
                      pageSize: pagination.pageSize || old.pagination.pageSize,
                    },
                  };
                });
              }
            }}
            expandable={{
              expandedRowRender: (record) => (
                <PracticeDescription practice={record} />
              ),
            }}
          ></Table>
        </Col>
      </Row>
    </>
  );
};

export default PracticesTable;
