const mabel_purple = '#870051';
const mabel_dark_blue = '#00526f';
const mabel_green = '#006b77';
const mabel_orange = '#f05655';
const mabel_blue = '#6886c5';
const mabel_light_green = '#1dd3bd';
const mabel_yellow = '#ffb87d';
const mabel_light_blue = '#a6dcef';
const mabel_light_grey = '#dddde1';
const mabel_dark_burgandy = '#5b1e35';
const mabel_dark_purple = '#3a2c4d';
const mabel_dark_grey = '#425563';

const primary_color = mabel_purple;
const success_color = '#169e8e';
const warning_color = mabel_yellow;
const error_color = mabel_orange;

export {
  mabel_purple,
  mabel_dark_blue,
  mabel_green,
  mabel_orange,
  mabel_blue,
  mabel_light_green,
  mabel_yellow,
  mabel_light_blue,
  mabel_light_grey,
  mabel_dark_burgandy,
  mabel_dark_purple,
  mabel_dark_grey,
  primary_color,
  success_color,
  warning_color,
  error_color,
};
