import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Typography } from 'antd';
import { TeamOutlined, ToolOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

import UsersTable from '../components/users/UsersTable';
import {
  ClinicOutlined,
  NetworkOutlined,
  BuildingOutlined,
} from '../components/MabelIcons';

const UsersAdminPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('query') || undefined;

  return (
    <>
      <Helmet>
        <title>Users Admin | Mabel</title>
      </Helmet>
      <Typography.Title level={3}>
        <TeamOutlined /> Practice Users <ClinicOutlined />
      </Typography.Title>
      <UsersTable initialQuery={query} role="practice_user" />

      <div style={{ marginTop: 32 }} />
      <Typography.Title level={3}>
        <TeamOutlined /> Network Users <NetworkOutlined />
      </Typography.Title>
      <UsersTable initialQuery={query} role="network_user" />

      <div style={{ marginTop: 32 }} />
      <Typography.Title level={3}>
        <TeamOutlined /> Organization Users <BuildingOutlined />
      </Typography.Title>
      <UsersTable initialQuery={query} role="organization_user" />

      <div style={{ marginTop: 32 }} />
      <Typography.Title level={3}>
        <TeamOutlined /> Admin Users <ToolOutlined />
      </Typography.Title>
      <UsersTable initialQuery={query} role="admin" />
    </>
  );
};

export default UsersAdminPage;
