import React from 'react';
import { Typography, Spin, Timeline, Space } from 'antd';

import {
  useGetPvaAuditLogQuery,
  useGetPatientAuditLogQuery,
  useGetHedrAuditLogQuery,
  PvaAuditEntryFragment,
  PatientAuditEntryFragment,
  HedrAuditEntryFragment,
} from '../graphql/generated';
import dayjs from 'dayjs';

const PVAAuditTimeline: React.FC<{
  pva_id: string;
}> = ({ pva_id }) => {
  const getPVAauditLogResults = useGetPvaAuditLogQuery({
    variables: { pva_id },
    fetchPolicy: 'network-only',
  });

  if (getPVAauditLogResults.loading) {
    return <Spin />;
  }
  return getPVAauditLogResults.data?.pvas_audit ? (
    <AuditTimeline log_entries={getPVAauditLogResults.data?.pvas_audit} />
  ) : null;
};

const HEDRAuditTimeline: React.FC<{
  health_equity_report_id: string;
}> = ({ health_equity_report_id }) => {
  const getHEDRauditLogResults = useGetHedrAuditLogQuery({
    variables: { health_equity_report_id },
    fetchPolicy: 'network-only',
  });

  if (getHEDRauditLogResults.loading) {
    return <Spin />;
  }
  return getHEDRauditLogResults.data?.health_equity_reports_audit ? (
    <AuditTimeline
      log_entries={getHEDRauditLogResults.data?.health_equity_reports_audit}
    />
  ) : null;
};

const PatientAuditTimeline: React.FC<{
  patient_id: string;
}> = ({ patient_id }) => {
  const getPatientAuditLogResults = useGetPatientAuditLogQuery({
    variables: { patient_id },
    fetchPolicy: 'network-only',
  });

  if (getPatientAuditLogResults.loading) {
    return <Spin />;
  }
  return getPatientAuditLogResults.data?.patients_audit ? (
    <AuditTimeline
      log_entries={getPatientAuditLogResults.data?.patients_audit}
    />
  ) : null;
};

const AuditTimeline: React.FC<{
  log_entries: (
    | PvaAuditEntryFragment
    | PatientAuditEntryFragment
    | HedrAuditEntryFragment
  )[];
}> = ({ log_entries }) => {
  return (
    <Timeline>
      {log_entries?.map((log_entry) => (
        <Timeline.Item>
          {dayjs(log_entry.action_tstamp_stm).format('MM/DD/YYYY h:mm A')}{' '}
          <div>
            <small>
              <i>
                {log_entry.action === 'I' ? 'Created' : 'Updated'} by{' '}
                {log_entry.session_user
                  ? log_entry.session_user.user_name
                  : log_entry.hasura_user
                  ? 'Backend Process'
                  : 'Manual Entry'}
              </i>
            </small>
          </div>
          <ul>
            {Object.keys(log_entry.changed_fields || {})
              .filter((k) => !['created_at', 'updated_at'].includes(k))
              .map((k) => (
                <li>
                  {k}:{' '}
                  <Space direction="horizontal">
                    <Typography.Text
                      code
                      type={
                        log_entry.row_data[k] === null ? 'secondary' : undefined
                      }
                    >
                      {String(log_entry.row_data[k])}
                    </Typography.Text>
                    {' → '}
                    <Typography.Text
                      code
                      type={
                        log_entry.changed_fields[k] === null
                          ? 'secondary'
                          : undefined
                      }
                    >
                      {String(log_entry.changed_fields[k])}
                    </Typography.Text>
                  </Space>
                </li>
              ))}
          </ul>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export { PVAAuditTimeline, HEDRAuditTimeline, PatientAuditTimeline };
