import React from 'react';

import { Tooltip, Spin, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import {
  CampaignEmailComponentDetailsFragment,
  EmailTemplateBasicFragment,
  PatientBasicFragment,
  PracticeDetailsFragment,
} from '../../graphql/generated';

import { useRequest } from 'ahooks';
import sanitizeHtml from 'sanitize-html';
import * as Handlebars from 'handlebars';
import Frame from 'react-frame-component';
import { getMergeVars } from './TemplateUtils';

const { Text } = Typography;

const EmailTemplatePreview: React.FC<{
  email_template: EmailTemplateBasicFragment;
  practice: PracticeDetailsFragment;
  campaign_email_component?: CampaignEmailComponentDetailsFragment;
  patient?: PatientBasicFragment;
}> = ({ email_template, practice, campaign_email_component, patient }) => {
  const { data: mergeVars, loading: mergeVarsLoading } = useRequest(
    async () =>
      getMergeVars(
        'email',
        campaign_email_component?.campaign_email_component_id,
        practice.practice_id,
        patient?.patient_id,
        email_template.email_template_id
      ),
    {
      refreshDeps: [
        campaign_email_component,
        practice,
        patient,
        email_template,
      ],
    }
  );

  const frameWidth = 660;
  const frameHeight = 660;
  const zoomRatio = 0.5;
  const zoomCss = {
    ['MsZoom' as any]: zoomRatio,
    ['MozTransform' as any]: 'scale(' + zoomRatio + ')',
    MozTransformOrigin: '0 0',
    ['OTransform' as any]: 'scale(' + zoomRatio + ')',
    OTransformOrigin: '0 0',
    WebkitTransform: 'scale(' + zoomRatio + ')',
    WebkitTransformOrigin: '0 0 ',
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '12px 0px',
      }}
    >
      <div
        style={{
          width: frameWidth * zoomRatio + 2 * 16, //allows for side padding
          height: frameHeight * zoomRatio + 90, //allows for padding + From/To/Subject
          padding: 16,
          overflow: 'hidden',
          borderRadius: 8,
          background: '#a6dcef4d',
        }}
        className="card-shadow"
      >
        <Spin spinning={mergeVarsLoading}>
          <div style={{ textAlign: 'left', fontSize: 12, marginBottom: 8 }}>
            <b>From: </b>
            <Text type="secondary" ellipsis style={{ width: '80%' }}>
              {`${Handlebars.compile(email_template.email_from)(
                mergeVars
              )} <do-not-reply@mg.getmabel.com>`}
            </Text>
            <br />
            <b>To: </b>
            <Text type="secondary" ellipsis style={{ width: '80%' }}>
              {patient
                ? `${patient.patient_name} <${patient.patient_email}>`
                : `Patient Name <patient@email.com>`}{' '}
            </Text>
            <br />
            <b>Subject: </b>
            <Text
              type="secondary"
              ellipsis
              style={{ width: '80%', fontWeight: 600 }}
            >
              {email_template.email_subject}
            </Text>
          </div>
          <Frame
            style={{
              width: frameWidth,
              height: frameHeight,
              border: 'none',
              background: 'white',
              ...zoomCss,
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(
                  email_template.body_html && mergeVars
                    ? Handlebars.compile(email_template.body_html)(mergeVars)
                    : '',
                  {
                    allowedTags: false,
                    allowedAttributes: false,
                  }
                ),
              }}
            />
          </Frame>
        </Spin>
      </div>
      <Tooltip title="Download email html">
        <a
          href={`data:application/octet-stream,${encodeURIComponent(
            email_template.body_html && mergeVars
              ? Handlebars.compile(email_template.body_html)(mergeVars)
              : ''
          )}`}
          download={`${email_template.email_template_name}.html`}
        >
          <Spin spinning={mergeVarsLoading}>
            <DownloadOutlined style={{ fontSize: 18, marginLeft: 8 }} />
          </Spin>
        </a>
      </Tooltip>
    </div>
  );
};
export default EmailTemplatePreview;
