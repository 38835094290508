import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import {
  useGetUserDetailsQuery,
  useGetPracticeDetailsQuery,
  useGetNetworkDetailsQuery,
} from '../graphql/generated';

import { Typography, Tabs, Skeleton, Result, Divider } from 'antd';

import { SettingOutlined, UserOutlined } from '@ant-design/icons';
import { BuildingOutlined, ClinicOutlined } from '../components/MabelIcons';
import { UserContext, MabelUserRole } from '../contexts/UserContext';

import { PracticeForm } from '../components/practices/PracticeForm';
import { NetworkForm } from '../components/networks/NetworkForm';
import { UserForm } from '../components/users/UserForm';
import { MFAsettingsForm } from '../components/users/MFAsettingsForm';

import { useParams, useHistory } from 'react-router-dom';

const { TabPane } = Tabs;

const SettingsPage = () => {
  const { user } = useContext(UserContext);
  const { data } = useGetUserDetailsQuery({
    variables: { user_id: user?.user_id },
  });

  const { entity } = useParams<{ entity?: string }>();
  const history = useHistory();
  return (
    <>
      <Helmet>
        <title>Settings | Mabel</title>
      </Helmet>
      <Typography.Title level={3}>
        <SettingOutlined /> Settings
      </Typography.Title>

      <Tabs
        defaultActiveKey="user"
        activeKey={entity}
        onChange={(key) => history.push(`/settings/${key}`)}
      >
        <TabPane
          tab={
            <>
              <UserOutlined />
              My Profile
            </>
          }
          key="user"
        >
          <UserSettingsPane user_id={user?.user_id} userRole={user?.role!} />
        </TabPane>
        {user?.role === 'practice_user' && data?.users_by_pk?.practice_id ? (
          <TabPane
            tab={
              <>
                <ClinicOutlined />
                My Practice
              </>
            }
            key="practice"
          >
            <PracticeSettingsPane
              practice_id={data?.users_by_pk?.practice_id}
            />
          </TabPane>
        ) : null}
        {user?.role === 'network_user' && data?.users_by_pk?.network_id ? (
          <TabPane
            tab={
              <>
                <BuildingOutlined />
                My Network
              </>
            }
            key="network"
          >
            <NetworkSettingsPane network_id={data?.users_by_pk?.network_id} />
          </TabPane>
        ) : null}
      </Tabs>
    </>
  );
};

const PracticeSettingsPane: React.FC<{ practice_id: string }> = ({
  practice_id,
}) => {
  const { data, loading, error } = useGetPracticeDetailsQuery({
    variables: { practice_id: practice_id },
  });
  if (loading) return <Skeleton />;
  if (error)
    return (
      <Result
        status="error"
        title="There was an error retrieving practice details"
        subTitle={error?.message}
      />
    );

  return (
    <PracticeForm
      initialPractice={data?.practices_by_pk!}
      wrapperCol={{ span: 12 }}
      showSubmit
    />
  );
};

const NetworkSettingsPane: React.FC<{ network_id: string }> = ({
  network_id,
}) => {
  const { data, loading, error } = useGetNetworkDetailsQuery({
    variables: { network_id: network_id },
  });
  if (loading) return <Skeleton />;
  if (error)
    return (
      <Result
        status="error"
        title="There was an error retrieving network details"
        subTitle={error?.message}
      />
    );

  return (
    <NetworkForm
      initialNetwork={data?.networks_by_pk!}
      wrapperCol={{ span: 12 }}
      showSubmit
    />
  );
};

const UserSettingsPane = ({
  user_id,
  userRole,
}: {
  user_id: string;
  userRole: MabelUserRole;
}) => {
  const [haveValuesChanged, setHaveValuesChanged] = useState(false);
  const { data, loading, error } = useGetUserDetailsQuery({
    variables: { user_id: user_id },
  });

  if (loading) return <Skeleton />;
  if (error)
    return (
      <Result
        status="error"
        title="There was an error retrieving user details"
        subTitle={error?.message}
      />
    );

  return (
    <>
      <p>
        <b>User Details</b>
      </p>
      <UserForm
        userRole={userRole}
        initialUser={data?.users_by_pk!}
        wrapperCol={{ span: 12 }}
        haveValuesChanged={haveValuesChanged}
        setHaveValuesChanged={setHaveValuesChanged}
        showSubmit
      />
      <Divider />
      <p>
        <b>Security</b>
      </p>
      <MFAsettingsForm initialUser={data?.users_by_pk!} />
    </>
  );
};

export default SettingsPage;
