import React, { useState, useEffect } from 'react';

import { Result, Table, Row, Col, Input, Badge, Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PaginationProps } from 'antd/lib/pagination';

import { textToHighlight } from '../../components/utils/Highlight';

import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { DEBOUNCE_MS } from '../autocompletes';

import {
  useSearchHedRsLazyQuery,
  SearchHedRsQueryVariables,
  Order_By,
  HedrDetailsFragment,
} from '../../graphql/generated';
import { HEDRForm } from './HEDRForm';
import NetworkTag from '../networks/NetworkTag';
import {
  CloseCircleFilled,
  FileOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import AlignmentPDFPreview from '../alignments/AlignmentPDFPreview';
import dayjs from 'dayjs';
import { WebOutlined } from '../MabelIcons';
import { MAX_ITEM_AGGREGATE } from '../../constants';

interface AlignmentsTableProps {
  initialQuery?: string;
}

const HEDRTable: React.FC<AlignmentsTableProps> = ({ initialQuery }) => {
  const initialSearchParams: {
    variables: SearchHedRsQueryVariables;
    pagination: PaginationProps;
  } = {
    variables: {
      exact_query: initialQuery || null,
      substring_query: initialQuery ? `%${initialQuery}%` : null,
      prefix_query: initialQuery ? `${initialQuery}%` : null,
      additional_filters: [
        {
          processing_status: { _in: ['Unprocessed'] },
          _or: [
            { _not: { network: {} } },
            {
              network: { is_archived: { _eq: false } },
            },
          ],
        },
      ],
      offset: 0,
      limit: 10,
      order_by: [{ created_at: Order_By.Asc }],
      count_aggregate_limit: MAX_ITEM_AGGREGATE,
    },
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [searchHEDRsQuery, searchHEDRsQueryResults] = useSearchHedRsLazyQuery({
    variables: initialSearchParams.variables,
    fetchPolicy: 'network-only',
  });
  const searchHEDRsDebounced = useConstant(() =>
    AwesomeDebouncePromise(
      (searchParams: SearchHedRsQueryVariables) => {
        return searchHEDRsQuery({
          variables: searchParams,
        });
      },
      DEBOUNCE_MS,
      { key: (fieldId, _) => fieldId }
    )
  );

  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  useEffect(() => {
    searchHEDRsDebounced(searchParams.variables);
  }, [searchParams, searchHEDRsDebounced]);

  if (searchHEDRsQueryResults.error)
    return (
      <Result
        status="error"
        title="There was an error retrieving alignment forms"
        subTitle={searchHEDRsQueryResults.error?.message}
      />
    );

  const columns: ColumnsType<HedrDetailsFragment> = [
    {
      title: 'Submission ID',
      dataIndex: 'submission_id',
      key: 'submission_id',
      sorter: true,
      render: textToHighlight(searchParams.variables.exact_query),
    },
    {
      title: 'Submission Source',
      dataIndex: 'submission_source',
      key: 'submission_source',
      sorter: true,
      filters: [
        { value: 'Jotform', text: 'Jotform' },
        { value: 'EarthClassMail', text: 'EarthClassMail' },
        { value: 'StableMail', text: 'StableMail' },
        { value: 'DropboxUpload', text: 'DropboxUpload' },
        { value: 'WebUpload', text: 'WebUpload' },
        { value: 'FileImport', text: 'FileImport' },
      ],
      render: (value) =>
        value === 'Jotform' ? (
          <>
            <img
              alt="Jotform icon"
              src="https://www.jotform.com/resources/assets/svg/jotform-icon-transparent.svg"
              width={20}
            />
            Jotform
          </>
        ) : value === 'EarthClassMail' ? (
          <>
            <img
              alt="EarthClassMail icon"
              src="https://www.earthclassmail.com/images/logo/ecm-no-text.svg"
              width={12}
              style={{ margin: 4 }}
            />
            EarthClassMail
          </>
        ) : value === 'StableMail' ? (
          <>
            <img
              alt="StableMail icon"
              src="https://assets-global.website-files.com/63af3bafea6dbc658ece0e8d/63c1d1f9b0f8b51af0163f87_stable-32.png"
              width={12}
              style={{ margin: 4 }}
            />
            StableMail
          </>
        ) : value === 'DropboxUpload' ? (
          <>
            <img
              alt="Dropbox icon"
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Dropbox_Icon.svg"
              width={12}
              style={{ margin: 4 }}
            />
            DropboxUpload
          </>
        ) : value === 'WebUpload' ? (
          <>
            <WebOutlined style={{ margin: 4 }} />
            WebUpload
          </>
        ) : value === 'FileImport' ? (
          <>
            <FileOutlined style={{ margin: 4 }} />
            FileImport
          </>
        ) : (
          value
        ),
    },
    {
      title: 'Network',
      dataIndex: ['network', 'network_name'],
      key: 'network_number',
      sorter: true,
      filters: searchHEDRsQueryResults.data?.networks.map((n) => {
        return { value: n.network_number, text: n.network_name };
      }),
      onFilter: (value, record) => value === record.network?.network_number,
      render: (_, record) =>
        record.network ? (
          <NetworkTag network={record.network} />
        ) : (
          <CloseCircleFilled className="danger-color" />
        ),
    },
    {
      title: 'Version',
      dataIndex: 'template_version',
      key: 'template_version',
      sorter: true,
      filters: [
        { value: 'HEDR-DEMO', text: 'HEDR-DEMO' },
        { value: 'HEDR-DEMO-0', text: 'HEDR-DEMO-0' },
        { value: 'HEDR-DEMO-1', text: 'HEDR-DEMO-1' },
        { value: 'HEDR-DEMO-2', text: 'HEDR-DEMO-2' },
        { value: 'HEDR-DEMO-2-ES', text: 'HEDR-DEMO-2-ES' },
        { value: 'HEDR-DEMO-2-ZH', text: 'HEDR-DEMO-2-ZH' },
        { value: 'HEDR-SDOH-AHC-1', text: 'HEDR-SDOH-AHC-1' },
        { value: 'HEDR-SDOH-AHC-2', text: 'HEDR-SDOH-AHC-2' },
        { value: 'HEDR-SDOH-NC-1', text: 'HEDR-SDOH-NC-1' },
        { value: 'HEDR-SDOH-NC-2', text: 'HEDR-SDOH-NC-2' },
        { value: 'HEDR-SDOH-NC-2-ES', text: 'HEDR-SDOH-NC-2-ES' },
        { value: 'HEDR-SDOH-NC-2-ZH', text: 'HEDR-SDOH-NC-2-ZH' },
        { value: 'HEDR-SDOH-PRA-2', text: 'HEDR-SDOH-PRA-2' },
      ],
    },
    {
      title: 'MBI',
      dataIndex: 'mbi',
      key: 'mbi',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'processing_status',
      key: 'processing_status',
      sorter: true,
      defaultFilteredValue: ['Unprocessed'],
      filters: [
        {
          text: 'Incoming',
          value: 'Incoming',
        },
        {
          text: 'Unprocessed',
          value: 'Unprocessed',
        },
        {
          text: 'Internal Review',
          value: 'Internal Review',
        },
        {
          text: 'Awaiting Information',
          value: 'Awaiting Information',
        },
        {
          text: 'Completed',
          value: 'Completed',
        },
        {
          text: 'Invalid',
          value: 'Invalid',
        },
      ],
      render: (value) =>
        value === 'Unprocessed' ? (
          <>
            <Badge status="default" />
            Unprocessed
          </>
        ) : value === 'Incoming' ? (
          <>
            <Badge status="warning" />
            Incoming
          </>
        ) : value === 'Internal Review' ? (
          <>
            <Badge status="warning" />
            Internal Review
          </>
        ) : value === 'Awaiting Information' ? (
          <>
            <Badge status="warning" />
            Awaiting Information
          </>
        ) : value === 'Completed' ? (
          <>
            <Badge status="success" />
            Completed
          </>
        ) : value === 'Invalid' ? (
          <>
            <Badge status="error" />
            Invalid
          </>
        ) : null,
    },
    {
      title: 'Last Updated',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sorter: true,
      render: (value) => dayjs(value).format('lll'),
    },
  ];

  return (
    <>
      <Row gutter={[8, 8]} justify="center">
        <Col flex="auto">
          <Input.Search
            defaultValue={initialQuery || undefined}
            placeholder="Search by Health Equity Report ID, submission ID, patient name, or mbi"
            enterButton
            allowClear
            loading={searchHEDRsQueryResults.loading}
            onSearch={(value) => {
              const trimmedValue = value;
              const newParams = {
                exact_query: trimmedValue,
                substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                prefix_query: trimmedValue ? `${trimmedValue}%` : null,
                offset: 0,
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: { ...old.pagination, current: 1 },
                };
              });
            }}
            onChange={(e) => {
              const trimmedValue = e.target.value.trim();
              const newParams = {
                exact_query: trimmedValue,
                substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                prefix_query: trimmedValue ? `${trimmedValue}%` : null,
                offset: 0,
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: { ...old.pagination, current: 1 },
                };
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <Tooltip title="expand all">
            <Button
              type="link"
              icon={<PlusSquareOutlined />}
              onClick={() => {
                setExpandedRows(
                  searchHEDRsQueryResults.data?.health_equity_reports.map(
                    (health_equity_report) =>
                      health_equity_report.health_equity_report_id
                  ) || []
                );
              }}
            />
          </Tooltip>
          /
          <Tooltip title="collapse all">
            <Button
              type="link"
              icon={<MinusSquareOutlined />}
              onClick={() => {
                setExpandedRows([]);
              }}
            />
          </Tooltip>
          <Table<HedrDetailsFragment>
            size="small"
            rowKey="health_equity_report_id"
            loading={searchHEDRsQueryResults.loading}
            dataSource={searchHEDRsQueryResults.data?.health_equity_reports}
            pagination={{
              ...searchParams.pagination,
              position: ['bottomRight'],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total}${
                  total === MAX_ITEM_AGGREGATE ? '+' : ''
                } items`,
              total:
                searchHEDRsQueryResults.data?.health_equity_reports_aggregate
                  .aggregate?.count || 0,
            }}
            columns={columns}
            showSorterTooltip={false}
            onChange={(pagination, filters, sorter, extra) => {
              if (extra.action === 'sort') {
                const new_order = !sorter
                  ? []
                  : (sorter instanceof Array ? sorter : [sorter])
                      .filter((c) => c.columnKey && c.order)
                      .map((column) => {
                        const key = column.columnKey?.toString() || '';
                        const order =
                          column.order === 'ascend'
                            ? Order_By.AscNullsFirst
                            : Order_By.DescNullsLast;
                        return { [key]: order };
                      });
                const newParams = {
                  order_by: !new_order.length
                    ? initialSearchParams.variables.order_by
                    : new_order,
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: old.pagination,
                  };
                });
              } else if (extra.action === 'filter') {
                // if specific network_number is in additional filters, use that, but otherwise ignore archived networks
                const additional_filters: any[] = filters.network_number
                  ? []
                  : [
                      {
                        _or: [
                          { _not: { network: {} } },
                          {
                            network: { is_archived: { _eq: false } },
                          },
                        ],
                      },
                    ];
                for (const k in filters) {
                  if (filters[k]?.length) {
                    additional_filters.push({ [k]: { _in: filters[k] } });
                  }
                }
                const newParams = {
                  offset: 0,
                  additional_filters: additional_filters,
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              } else if (extra.action === 'paginate') {
                const newParams = {
                  limit: pagination.pageSize,
                  offset:
                    (pagination.pageSize || 0) *
                    ((pagination.current || 0) - 1),
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: {
                      ...old.pagination,
                      current: pagination.current || 1,
                      pageSize: pagination.pageSize || old.pagination.pageSize,
                    },
                  };
                });
              }
            }}
            expandable={{
              expandedRowKeys: expandedRows,
              onExpand: (expanded, record) => {
                setExpandedRows((old) => {
                  if (expanded) {
                    return Array.from(
                      new Set(old).add(record.health_equity_report_id)
                    );
                  } else {
                    return old.filter(
                      (id) => id !== record.health_equity_report_id
                    );
                  }
                });
              },
              expandedRowRender: (record) => (
                <HEDRDetailsView record={record} />
              ),
            }}
          ></Table>
        </Col>
      </Row>
    </>
  );
};

interface ShortURLResponse {
  url: string;
  query_params: { [key: string]: string };
}

const HEDRDetailsView: React.FC<{ record: HedrDetailsFragment }> = ({
  record,
}) => {
  // parsedQRCodes holds an array of items, one for each page of the PDF
  // If the page doesn't have a QR, or the QR encodes a non-URL, then the item is null.
  // If it has a valid QR, then the item is an object with the url and query params
  const [parsedQRCodes, setParsedQRCodes] = useState<
    (ShortURLResponse | null)[]
  >();

  return (
    <Row gutter={16}>
      <Col span={12}>
        <HEDRForm initialHEDR={record} parsedQRCodes={parsedQRCodes} />
      </Col>
      <Col span={12}>
        {record.submission_id &&
          record.submission_source &&
          record.submission_source !== 'Jotform' &&
          record.submission_source !== 'FileImport' && (
            <AlignmentPDFPreview
              submission_id={record.submission_id}
              submission_source={record.submission_source}
              onedrive_file_id={record.onedrive_file_id}
              onQrCodeDetection={setParsedQRCodes}
            />
          )}
      </Col>
    </Row>
  );
};

export type { ShortURLResponse };
export default HEDRTable;
