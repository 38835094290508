import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Typography, Card, Row, Col } from 'antd';

import {
  useGetPatientCountQuery,
  useListCampaignTemplatesQuery,
} from '../graphql/generated';

import { useHistory, Redirect } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import {
  MailBulkOutlined,
  UserInjuredOutlined,
} from '../components/MabelIcons';

const PracticeHomePage = () => {
  const getPatientCountQuery = useGetPatientCountQuery();
  const numPatients =
    getPatientCountQuery.data?.patients_aggregate.aggregate?.count;

  if (numPatients === 0) return <Redirect to="/onboarding" />;

  return (
    <>
      <Helmet>
        <title>Practice Home | Mabel</title>
      </Helmet>
      <Typography.Title level={3}>
        <HomeOutlined /> Home
      </Typography.Title>
      <Row gutter={[32, 32]}>
        <Col>
          <PatientsCard />
        </Col>
        <Col>
          <CampaignsCard />
        </Col>
      </Row>
    </>
  );
};

const PatientsCard = () => {
  const history = useHistory();
  const getPatientCountQuery = useGetPatientCountQuery();
  const numPatients =
    getPatientCountQuery.data?.patients_aggregate.aggregate?.count;

  return (
    <Card
      title="Patients"
      hoverable
      style={{ width: 240, textAlign: 'center' }}
      className="home-card"
      onClick={() => history.push('/patients')}
      loading={getPatientCountQuery.loading}
    >
      <Typography.Title level={1}>
        <span style={{ fontSize: 64 }}>
          <UserInjuredOutlined />
        </span>
      </Typography.Title>
      <div>{numPatients} patients</div>
    </Card>
  );
};

const CampaignsCard = () => {
  const history = useHistory();

  const campaignTemplatesQuery = useListCampaignTemplatesQuery({
    variables: { include_approvals: true },
  });
  const numCampaigns =
    campaignTemplatesQuery.data?.campaign_templates.length || 0;

  return (
    <Card
      title="Campaigns"
      hoverable
      style={{ width: 240, textAlign: 'center' }}
      className="home-card"
      onClick={() => history.push('/campaigns')}
      loading={campaignTemplatesQuery.loading}
    >
      <Typography.Title level={1}>
        <span style={{ fontSize: 64 }}>
          <MailBulkOutlined />
        </span>
      </Typography.Title>
      <div>
        {numCampaigns} campaign{numCampaigns !== 1 ? 's' : null}
      </div>
    </Card>
  );
};

export default PracticeHomePage;
