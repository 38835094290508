import React from 'react';
import Highlighter, { HighlighterProps } from 'react-highlight-words';

const Highlight = (props: HighlighterProps) => {
  return props.searchWords.length ? (
    <Highlighter
      {...props}
      highlightStyle={{ backgroundColor: '#ffe58f', padding: 0 }}
      autoEscape
    />
  ) : (
    <>{props.textToHighlight}</>
  );
};

const textToHighlight = (searchQuery: string | null | undefined) => {
  return (text: any) => (
    <Highlight
      searchWords={searchQuery ? [searchQuery] : []}
      textToHighlight={(text || '').toString()}
    />
  );
};

export { textToHighlight, Highlight };
