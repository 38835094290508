import React, { useState, useEffect } from 'react';

import {
  Result,
  Button,
  Table,
  Row,
  Col,
  Tooltip,
  Input,
  Typography,
  Form,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PaginationProps } from 'antd/lib/pagination';
import { StopOutlined, CheckOutlined, SearchOutlined } from '@ant-design/icons';

import { textToHighlight } from '../../components/utils/Highlight';
import PatientDescription from './PatientDescription';
import { PatientMutationModal } from './PatientForm';
import MutateButton from '../utils/MutateButton';

import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {
  DEBOUNCE_MS,
  NetworkSearchSelect,
  PracticeSearchSelect,
  ProviderSearchSelect,
} from '../autocompletes';

import {
  PatientDetailsFragment,
  SearchPatientsAdvancedDocument,
  SearchPatientsAdvancedQuery,
  useSearchPatientsAdvancedLazyQuery,
  SearchPatientsAdvancedQueryVariables,
  UpdatePatientDocument,
  Order_By,
} from '../../graphql/generated';
import { getOperationAST } from 'graphql';
import dayjs from 'dayjs';
import NetworkTag from '../networks/NetworkTag';
import PracticeTag from '../practices/PracticeTag';
import { MAX_ITEM_AGGREGATE } from '../../constants';

interface PatientsTableProps {
  initialQuery?: string;
}

const PatientsAdminTable: React.FC<PatientsTableProps> = ({ initialQuery }) => {
  const initialSearchParams: {
    variables: SearchPatientsAdvancedQueryVariables;
    pagination: PaginationProps;
  } = {
    variables: {
      exact_query: initialQuery || null,
      substring_query: initialQuery ? `%${initialQuery}%` : null,
      prefix_query: initialQuery ? `${initialQuery}%` : null,
      additional_filters: [{ is_archived: { _in: [false] } }],
      networks: null,
      practices: null,
      suspected_providers: null,
      offset: 0,
      limit: 10,
      order_by: [],
      count_aggregate_limit: MAX_ITEM_AGGREGATE,
    },
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [
    searchPatientsQuery,
    searchPatientsQueryResults,
  ] = useSearchPatientsAdvancedLazyQuery({
    variables: initialSearchParams.variables,
  });
  const searchPatientsDebounced = useConstant(() =>
    AwesomeDebouncePromise(
      (searchParams: SearchPatientsAdvancedQueryVariables) => {
        return searchPatientsQuery({
          variables: { ...searchParams, include_admin_details: true },
        });
      },
      DEBOUNCE_MS,
      { key: (fieldId, _) => fieldId }
    )
  );
  useEffect(() => {
    searchPatientsDebounced(searchParams.variables);
  }, [searchParams, searchPatientsDebounced]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<
    PatientDetailsFragment | undefined
  >();

  const [filterForm] = Form.useForm();

  if (searchPatientsQueryResults.error)
    return (
      <Result
        status="error"
        title="There was an error retrieving patients"
        subTitle={searchPatientsQueryResults.error?.message}
      />
    );

  const columns: ColumnsType<SearchPatientsAdvancedQuery['patients'][0]> = [
    {
      title: 'Network',
      dataIndex: ['practice', 'primary_network', 'network_name'],
      render: (_, record) => (
        <NetworkTag network={record.practice?.primary_network!} />
      ),
    },
    {
      title: 'Practice',
      dataIndex: ['practice', 'practice_name'],
      render: (_, record) => <PracticeTag practice={record.practice!} />,
    },
    {
      title: 'Patient Name',
      dataIndex: 'patient_name',
      key: 'patient_name',
      sorter: true,
      render: textToHighlight(searchParams.variables.exact_query),
    },
    {
      title: 'Birthdate',
      dataIndex: 'birth_date',
      key: 'birth_date',
      sorter: true,
      render: (val) =>
        dayjs(val).isValid() ? (
          dayjs(val).format('L')
        ) : (
          <Typography.Text type="danger">{val}</Typography.Text>
        ),
    },
    {
      title: 'MBI',
      dataIndex: 'mbi',
      key: 'mbi',
      sorter: true,
      render: (val, record) =>
        textToHighlight(searchParams.variables.exact_query)(record.mbi),
    },
    {
      title: 'Active?',
      dataIndex: 'is_archived',
      key: 'is_archived',
      width: 1,
      align: 'center',
      sorter: true,
      filters: [
        {
          text: 'Active',
          value: 'false',
        },
        {
          text: 'Archived',
          value: 'true',
        },
      ],
      defaultFilteredValue: ['false'],
      onFilter: (value, record) => record.is_archived.toString() === value,
      render: (is_archived) =>
        is_archived ? (
          <Tooltip title="Item has been archived">
            <StopOutlined className="danger-color" />
          </Tooltip>
        ) : (
          <CheckOutlined className="success-color" />
        ),
    },
    {
      key: 'edit',
      width: 1,
      align: 'center',
      render: (text, record) => {
        return record.is_archived ? (
          <MutateButton
            document={UpdatePatientDocument}
            variables={{
              patient_id: record.patient_id,
              changes: { is_archived: false },
            }}
            refetchQueries={[
              getOperationAST(SearchPatientsAdvancedDocument)?.name?.value ||
                '',
            ]}
          >
            Restore
          </MutateButton>
        ) : (
          <div style={{ display: 'inline-flex' }}>
            <Button
              type="link"
              size="small"
              style={{ margin: 0 }}
              onClick={() => {
                setSelectedRecord(record);
                setShowUpdateModal(true);
              }}
            >
              Edit
            </Button>
            <MutateButton
              document={UpdatePatientDocument}
              variables={{
                patient_id: record.patient_id,
                changes: { is_archived: true },
              }}
              refetchQueries={[
                getOperationAST(SearchPatientsAdvancedDocument)?.name?.value ||
                  '',
              ]}
              popconfirm={{
                title: 'Archive this item?',
              }}
            >
              Archive
            </MutateButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Form
        form={filterForm}
        name="advanced_search"
        style={{
          padding: 24,
          marginBottom: 24,
          background: '#fbfbfb',
          border: '1px solid #d9d9d9',
          borderRadius: 2,
        }}
        onFinish={(values: any) => {
          const trimmedValue = values.text_query;
          const newParams = {
            exact_query: trimmedValue,
            substring_query: trimmedValue ? `%${trimmedValue}%` : null,
            prefix_query: trimmedValue ? `${trimmedValue}%` : null,
            networks: values.networks?.length
              ? values.networks.map((x: any) => x.value)
              : null,
            practices: values.practices?.length
              ? values.practices?.map((x: any) => x.value)
              : null,
            suspected_providers: values.suspected_providers?.length
              ? values.suspected_providers?.map((x: any) => x.value)
              : null,
            offset: 0,
          };
          setSearchParams((old) => {
            return {
              variables: { ...old.variables, ...newParams },
              pagination: { ...old.pagination, current: 1 },
            };
          });
        }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Networks" name="networks" key="networks">
              <NetworkSearchSelect style={{ minWidth: 200 }} mode="multiple" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Practices" name="practices" key="practices">
              <PracticeSearchSelect style={{ minWidth: 200 }} mode="multiple" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Suspected Provider"
              name="suspected_providers"
              key="suspected_providers"
            >
              <ProviderSearchSelect style={{ minWidth: 200 }} mode="multiple" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Search Terms"
              name="text_query"
              help="Search by patient name, email, address, MBI, or letter/email code"
            >
              <Input defaultValue={initialQuery || undefined} allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                Search
              </Button>
              <Button
                style={{ margin: '0 8px' }}
                onClick={() => {
                  filterForm.resetFields();
                }}
              >
                Clear
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <PatientMutationModal
        formName="Create"
        visible={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        afterSubmit={() => setShowCreateModal(false)}
      />
      <PatientMutationModal
        formName="Update"
        initialRecord={selectedRecord}
        visible={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        afterSubmit={() => setShowUpdateModal(false)}
      />
      <Row>
        <Col flex="auto">
          <Table<SearchPatientsAdvancedQuery['patients'][0]>
            size="small"
            rowKey="patient_id"
            loading={searchPatientsQueryResults.loading}
            dataSource={searchPatientsQueryResults.data?.patients}
            pagination={{
              ...searchParams.pagination,
              position: ['bottomRight'],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total}${
                  total === MAX_ITEM_AGGREGATE ? '+' : ''
                } items`,
              total:
                searchPatientsQueryResults.data?.patients_aggregate.aggregate
                  ?.count || 0,
            }}
            columns={columns}
            showSorterTooltip={false}
            onChange={(pagination, filters, sorter, extra) => {
              if (extra.action === 'sort') {
                const new_order = !sorter
                  ? []
                  : (sorter instanceof Array ? sorter : [sorter])
                      .filter((c) => c.columnKey && c.order)
                      .map((column) => {
                        const key = column.columnKey?.toString() || '';
                        const order =
                          column.order === 'ascend'
                            ? Order_By.AscNullsFirst
                            : Order_By.DescNullsLast;
                        return { [key]: order };
                      });
                const newParams = {
                  order_by: !new_order.length
                    ? initialSearchParams.variables.order_by
                    : new_order,
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: old.pagination,
                  };
                });
              } else if (extra.action === 'filter') {
                const additional_filters: any[] = [];
                for (const k in filters) {
                  if (filters[k]?.length) {
                    additional_filters.push({ [k]: { _in: filters[k] } });
                  }
                }
                const newParams = {
                  offset: 0,
                  additional_filters: additional_filters,
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              } else if (extra.action === 'paginate') {
                const newParams = {
                  limit: pagination.pageSize,
                  offset:
                    (pagination.pageSize || 0) *
                    ((pagination.current || 0) - 1),
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: {
                      ...old.pagination,
                      current: pagination.current || 1,
                      pageSize: pagination.pageSize || old.pagination.pageSize,
                    },
                  };
                });
              }
            }}
            expandable={{
              expandedRowRender: (record) => (
                <PatientDescription patient={record} />
              ),
            }}
          ></Table>
        </Col>
      </Row>
    </>
  );
};

export default PatientsAdminTable;
