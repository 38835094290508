import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Form, Typography, Table, Select, message, Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  FilterOutlined,
  QuestionCircleOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { DatePicker } from '../components';

import {
  GetInsuranceCountsQuery,
  useGetInsuranceCountsQuery,
  useUpsertInsuranceSegmentMappingMutation,
} from '../graphql/generated';
import dayjs from 'dayjs';
import {
  NetworkSearchSelect,
  PracticeSearchSelect,
} from '../components/autocompletes';
const { Option } = Select;

const InsuranceSegmentMappingPage = () => {
  const insuranceCountsQuery = useGetInsuranceCountsQuery();
  const [upsertMappingMutation] = useUpsertInsuranceSegmentMappingMutation({
    onCompleted: () => message.success('Saved'),
  });

  const columns: ColumnsType<
    GetInsuranceCountsQuery['filtered_insurance_counts'][0]
  > = [
    {
      title: 'Patient Count',
      dataIndex: 'count',
      key: 'count',
      sorter: (a, b) => a.count - b.count,
    },
    {
      title: 'Primary Insurance',
      dataIndex: 'primary_insurance_payer_name',
      key: 'primary_insurance_payer_name',
      sorter: (a, b) =>
        a
          .primary_insurance_payer_name!.concat(
            String(a.primary_insurance_is_null)
          )
          .localeCompare(
            b.primary_insurance_payer_name!.concat(
              String(b.primary_insurance_is_null)
            )
          ),
      render: (_, record) =>
        record.primary_insurance_payer_name ||
        (record.primary_insurance_is_null ? (
          <Typography.Text type="secondary">
            <i>Unknown</i>{' '}
            <Tooltip title="No data was ever received for this field">
              <QuestionCircleOutlined />
            </Tooltip>
          </Typography.Text>
        ) : null),
    },
    {
      title: 'Secondary Insurance',
      dataIndex: 'secondary_insurance_payer_name',
      key: 'secondary_insurance_payer_name',
      sorter: (a, b) =>
        a
          .secondary_insurance_payer_name!.concat(
            String(a.secondary_insurance_is_null)
          )
          .localeCompare(
            b.secondary_insurance_payer_name!.concat(
              String(b.secondary_insurance_is_null)
            )
          ),
      render: (_, record) =>
        record.secondary_insurance_payer_name ||
        (record.secondary_insurance_is_null ? (
          <Typography.Text type="secondary">
            <i>Unknown</i>{' '}
            <Tooltip title="No data was ever received for this field">
              <QuestionCircleOutlined />
            </Tooltip>
          </Typography.Text>
        ) : null),
    },
    {
      title: 'Segment',
      dataIndex: 'patient_segment_id',
      key: 'patient_segment_id',
      render: (_, record) => (
        <Select
          style={{ width: 260 }}
          defaultValue={record.insurance_segment_mapping?.patient_segment_id}
          onChange={(value) => {
            upsertMappingMutation({
              variables: {
                primary_insurance_payer_name: record.primary_insurance_payer_name!,
                primary_insurance_is_null: record.primary_insurance_is_null!,
                secondary_insurance_payer_name: record.secondary_insurance_payer_name!,
                secondary_insurance_is_null: record.secondary_insurance_is_null!,
                patient_segment_id: value.toString() || null,
              },
            });
          }}
        >
          <Option value="INS_UNDETERMINED">Indeterminate</Option>
          <Option value="FFS_PDP">Original Medicare Only</Option>
          <Option value="FFSPLUSSUP">Original Medicare w/Supplemental</Option>
          <Option value="MAPD">Medicare Advantage</Option>
          <Option value="DUALS">Dual-Eligible</Option>
          <Option value="VETERANS">Veteran</Option>
          <Option value="COMMERCIAL">Commercial</Option>
          <Option value="EXCLUDE">EXCLUDE FROM CONTACT</Option>
        </Select>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Insurance Mapping | Mabel</title>
      </Helmet>
      <Typography.Title level={3}>
        <SwapOutlined /> Insurance Segmentation
      </Typography.Title>
      <Space direction="vertical" style={{ width: '100%' }} size="middle">
        <div
          style={{
            padding: 24,
            background: '#fbfbfb',
            border: '1px solid #d9d9d9',
            borderRadius: 2,
          }}
        >
          <Form
            layout="inline"
            colon={false}
            onValuesChange={(_, values) => {
              insuranceCountsQuery.refetch({
                birth_date_before: values.birth_date_before
                  ? values.birth_date_before.format('YYYY-MM-DD')
                  : null,
                birth_date_after: values.birth_date_after
                  ? values.birth_date_after.format('YYYY-MM-DD')
                  : null,
                network_id: values.network_id?.value || null,
                practice_id: values.practice_id?.value || null,
              });
            }}
          >
            <Form.Item>
              <b>
                <FilterOutlined /> Patient Filters:
              </b>
            </Form.Item>
            <Form.Item label="DOB Before" name="birth_date_before">
              <DatePicker defaultPickerValue={dayjs().subtract(65, 'year')} />
            </Form.Item>
            <Form.Item label="DOB After" name="birth_date_after">
              <DatePicker defaultPickerValue={dayjs().subtract(80, 'year')} />
            </Form.Item>
            <Form.Item label="Network" name="network_id">
              <NetworkSearchSelect style={{ width: 260 }} allowClear />
            </Form.Item>
            <Form.Item label="Practice" name="practice_id">
              <PracticeSearchSelect style={{ width: 260 }} allowClear />
            </Form.Item>
          </Form>
        </div>
        <Table<GetInsuranceCountsQuery['filtered_insurance_counts'][0]>
          dataSource={insuranceCountsQuery.data?.filtered_insurance_counts}
          size="small"
          pagination={false}
          loading={insuranceCountsQuery.loading}
          columns={columns}
          showSorterTooltip={false}
          rowKey={(record) =>
            `${record.primary_insurance_payer_name}:${record.primary_insurance_is_null}:${record.secondary_insurance_payer_name}:${record.secondary_insurance_is_null}`
          }
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={3} index={0}>
                <b>
                  Total:{' '}
                  {
                    insuranceCountsQuery.data
                      ?.filtered_insurance_counts_aggregate.aggregate?.sum
                      ?.count
                  }{' '}
                  patients
                </b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </Space>
    </>
  );
};

export default InsuranceSegmentMappingPage;
