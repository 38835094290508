import React from 'react';
import { Descriptions, Tooltip, Typography } from 'antd';

import { PatientDetailsFragment } from '../../graphql/generated';
import CommunicationTimeline from '../communications/CommunicationTimeline';
import ValidatedAddress from '../utils/ValidatedAddress';
import { QuestionCircleOutlined } from '@ant-design/icons';

const PatientDescription: React.FC<{ patient: PatientDetailsFragment }> = ({
  patient,
}) => {
  return (
    <Descriptions
      title={patient.patient_name}
      layout="horizontal"
      column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
      style={{ margin: '24px 48px' }}
      bordered
    >
      <Descriptions.Item label="Patient ID">
        {patient.external_id}
      </Descriptions.Item>
      <Descriptions.Item label="Mabel Patient ID">
        {patient.patient_id}
      </Descriptions.Item>
      <Descriptions.Item label="Birthdate">
        {patient.birth_date}
      </Descriptions.Item>
      <Descriptions.Item label="Deceased Date (as of)">
        {patient.deceased_as_of_date}
      </Descriptions.Item>
      <Descriptions.Item label="Sex Assigned at Birth">
        {patient.sex_assigned_at_birth}
      </Descriptions.Item>
      <Descriptions.Item label="Email">
        {patient.patient_email}
      </Descriptions.Item>
      <Descriptions.Item
        label="Mailing Address"
        style={{ whiteSpace: 'pre-line' }}
      >
        {patient.patient_address && (
          <ValidatedAddress
            address={patient.patient_address}
            deliverability={patient.deliverability}
          />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Mobile Phone">
        {patient.patient_phone}
      </Descriptions.Item>
      <Descriptions.Item label="Medicare Classification">
        {patient.patient_segment_label?.patient_segment_id === 'FFS_PDP' ? (
          'Original Medicare Only'
        ) : patient.patient_segment_label?.patient_segment_id ===
          'FFSPLUSSUP' ? (
          'Original Medicare w/Supplemental'
        ) : patient.patient_segment_label?.patient_segment_id === 'MAPD' ? (
          'Medicare Advantage'
        ) : patient.patient_segment_label?.patient_segment_id === 'DUALS' ? (
          'Dual-Eligible'
        ) : patient.patient_segment_label?.patient_segment_id === 'VETERANS' ? (
          'Veteran Plan'
        ) : patient.patient_segment_label?.patient_segment_id ===
          'COMMERCIAL' ? (
          'No (Commercial Plan)'
        ) : patient.patient_segment_label?.patient_segment_id === 'EXCLUDE' ? (
          'Excluded from marketing'
        ) : patient.patient_segment_label?.patient_segment_id ===
          'INS_UNDETERMINED' ? (
          <>
            Indeterminate{' '}
            <Tooltip title="We could not determine from the patient's insurance information what form of Medicare, if any, they currently have.">
              <QuestionCircleOutlined />
            </Tooltip>
          </>
        ) : (
          ''
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Primary Care Provider Name">
        {patient.patient_pcp}
      </Descriptions.Item>
      <Descriptions.Item label="Primary Care Provider NPI">
        {patient.suspected_provider_npi}
      </Descriptions.Item>
      <Descriptions.Item label="Alignment Report TIN">
        {patient.alignment_tin}
      </Descriptions.Item>
      <Descriptions.Item label="Medicare Beneficiary Identifier">
        {patient.mbi}
      </Descriptions.Item>
      <Descriptions.Item label="Primary Insurance">
        <div>
          <Typography.Text strong>Payer: </Typography.Text>
          {patient.primary_insurance_payer_name}
          {patient.primary_insurance_payer_id &&
            ` (${patient.primary_insurance_payer_id})`}
        </div>
        <div>
          <Typography.Text strong>Plan Description: </Typography.Text>
          {patient.primary_insurance_plan_name}
        </div>
        <div>
          <Typography.Text strong>Member ID: </Typography.Text>
          {patient.primary_insurance_member_id}
        </div>
      </Descriptions.Item>
      <Descriptions.Item label="Secondary Insurance">
        <div>
          <Typography.Text strong>Payer: </Typography.Text>
          {patient.secondary_insurance_payer_name}
          {patient.secondary_insurance_payer_id
            ? ` (${patient.secondary_insurance_payer_id})`
            : null}
        </div>
        <div>
          <Typography.Text strong>Plan Description: </Typography.Text>
          {patient.secondary_insurance_plan_name}
        </div>
        <div>
          <Typography.Text strong>Member ID: </Typography.Text>
          {patient.secondary_insurance_member_id}
        </div>
      </Descriptions.Item>
      <Descriptions.Item label="Logo">
        {patient.patient_logo ? (
          <img
            alt="Patient-level logo"
            src={patient.patient_logo}
            width={100}
          />
        ) : null}
      </Descriptions.Item>
      <Descriptions.Item label="Notes">
        {patient.patient_notes}
      </Descriptions.Item>
      <Descriptions.Item label="Communications">
        <CommunicationTimeline patientId={patient.patient_id} />
      </Descriptions.Item>
    </Descriptions>
  );
};

export default PatientDescription;
