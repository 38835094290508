import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Typography } from 'antd';
import { useLocation } from 'react-router-dom';

import AlignmentsTable from '../components/alignments/AlignmentsTable';
import { FileTextOutlined } from '@ant-design/icons';

const AlignmentProcessingPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('query') || undefined;

  return (
    <>
      <Helmet>
        <title>SVA Processing | Mabel</title>
      </Helmet>
      <Typography.Title level={3}>
        <FileTextOutlined /> SVA Processing
      </Typography.Title>
      <AlignmentsTable initialQuery={query} />
    </>
  );
};

export default AlignmentProcessingPage;
