import React from 'react';
import { Tag, Tooltip } from 'antd';
import { TagProps } from 'antd/lib/tag';
import { UserBasicFragment } from '../../graphql/generated';

interface UserTagProps extends TagProps {
  user: UserBasicFragment;
}
const UserTag: React.FC<UserTagProps> = ({ user, ...rest }) => {
  return (
    <Tooltip title={user.user_email || ''}>
      <Tag {...rest}>
        {user.user_name + `${user.is_disabled ? ' (disabled)' : ''}`}
      </Tag>
    </Tooltip>
  );
};

export default UserTag;
