import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Typography,
  Result,
  Button,
  Table,
  Space,
  Tooltip,
  Popconfirm,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PlusOutlined, StopOutlined, CheckOutlined } from '@ant-design/icons';

import {
  ListNetworksQuery,
  useListNetworksQuery,
  useUpdateNetworkMutation,
} from '../graphql/generated';

import { NetworkMutationModal } from '../components/networks/NetworkForm';

import { NetworkOutlined } from '../components/MabelIcons';
import NetworkDescription from '../components/networks/NetworkDescription';

const { Title } = Typography;

type NetworkType = ListNetworksQuery['networks'][0];

const NetworkUpdateButton = ({
  children,
  network_id,
  changes,
  require_confirm,
  confirm_title,
}: any) => {
  const [updateMutation, updateMutationResult] = useUpdateNetworkMutation();
  return !require_confirm ? (
    <Button
      type="link"
      size="small"
      style={{ margin: 0 }}
      loading={updateMutationResult.loading}
      onClick={() => {
        updateMutation({
          variables: {
            network_id: network_id,
            changes: changes,
          },
        });
      }}
    >
      {children}
    </Button>
  ) : (
    <Popconfirm
      title={confirm_title}
      onConfirm={() => {
        updateMutation({
          variables: {
            network_id: network_id,
            changes: changes,
          },
        });
      }}
      okText="Yes"
      cancelText="No"
    >
      <Button
        type="link"
        size="small"
        style={{ margin: 0 }}
        loading={updateMutationResult.loading}
      >
        {children}
      </Button>
    </Popconfirm>
  );
};

const NetworksAdminPage = () => {
  const listNetworksQuery = useListNetworksQuery();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<
    NetworkType | undefined
  >();

  if (listNetworksQuery.error)
    return (
      <Result
        status="error"
        title="There was an error retrieving networks"
        subTitle={listNetworksQuery.error?.message}
      />
    );

  const columns: ColumnsType<NetworkType> = [
    {
      title: 'Network Name',
      dataIndex: 'network_name',
      sorter: (a, b) => a.network_name.localeCompare(b.network_name),
    },
    {
      title: 'ACO ID',
      dataIndex: 'dc_id',
      sorter: (a, b) => (a.dc_id || '').localeCompare(b.dc_id || ''),
    },
    {
      title: 'Broker Name',
      dataIndex: 'broker_name',
    },
    {
      title: 'Broker Phone (emails)',
      dataIndex: 'broker_phone_for_emails',
    },
    {
      title: 'Broker Phone (letters)',
      dataIndex: 'broker_phone_for_letters',
    },
    {
      title: 'Broker URL (emails)',
      dataIndex: 'broker_url_for_emails',
    },
    {
      title: 'Broker URL (letters)',
      dataIndex: 'broker_url_for_letters',
    },
    {
      title: 'Active?',
      dataIndex: 'is_archived',
      width: 1,
      align: 'center',
      sorter: (a, b) => +a.is_archived - +b.is_archived,
      filters: [
        {
          text: 'Active',
          value: 'false',
        },
        {
          text: 'Archived',
          value: 'true',
        },
      ],
      defaultFilteredValue: ['false'],
      onFilter: (value, record) => record.is_archived.toString() === value,
      render: (is_archived) =>
        is_archived ? (
          <Tooltip title="Item has been archived">
            <StopOutlined className="danger-color" />
          </Tooltip>
        ) : (
          <CheckOutlined className="success-color" />
        ),
    },
    {
      key: 'edit',
      width: 1,
      align: 'center',
      render: (text, record) => {
        return record.is_archived ? (
          <NetworkUpdateButton
            network_id={record.network_id}
            changes={{
              is_archived: false,
            }}
          >
            Restore
          </NetworkUpdateButton>
        ) : (
          <div style={{ display: 'inline-flex' }}>
            <Button
              type="link"
              size="small"
              style={{ margin: 0 }}
              onClick={() => {
                setSelectedRecord(record);
                setShowUpdateModal(true);
              }}
            >
              Edit
            </Button>
            <NetworkUpdateButton
              network_id={record.network_id}
              changes={{
                is_archived: true,
              }}
              require_confirm={true}
              confirm_title="Archive this item?"
            >
              Archive
            </NetworkUpdateButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Networks Admin | Mabel</title>
      </Helmet>
      <Title level={3}>
        <NetworkOutlined /> Networks
      </Title>
      <Space direction="vertical" style={{ width: '100%' }}>
        <div style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowCreateModal(true)}
          >
            Add Network
          </Button>
          <NetworkMutationModal
            formName="Create"
            visible={showCreateModal}
            onCancel={() => setShowCreateModal(false)}
            afterSubmit={() => setShowCreateModal(false)}
          />
          <NetworkMutationModal
            formName="Update"
            initialRecord={selectedRecord}
            visible={showUpdateModal}
            onCancel={() => setShowUpdateModal(false)}
            afterSubmit={() => setShowUpdateModal(false)}
          />
        </div>
        <Table<NetworkType>
          size="small"
          rowKey="network_id"
          loading={listNetworksQuery.loading}
          dataSource={listNetworksQuery.data?.networks}
          pagination={{
            position: ['bottomRight'],
            showSizeChanger: true,
            defaultPageSize: 100,
          }}
          columns={columns}
          showSorterTooltip={false}
          expandable={{
            expandedRowRender: (record) => (
              <NetworkDescription network={record} />
            ),
          }}
        ></Table>
      </Space>
    </>
  );
};

export default NetworksAdminPage;
