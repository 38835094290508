import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Typography } from 'antd';

import { useLocation } from 'react-router-dom';

import { StethoscopeOutlined } from '../components/MabelIcons';
import ProvidersTable from '../components/providers/ProvidersTable';

const ProvidersPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('query') || undefined;

  return (
    <>
      <Helmet>
        <title>Providers | Mabel</title>
      </Helmet>
      <Typography.Title level={3}>
        <StethoscopeOutlined /> Providers
      </Typography.Title>
      <ProvidersTable initialQuery={query} />
    </>
  );
};

export default ProvidersPage;
