import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Typography } from 'antd';
import { useLocation } from 'react-router-dom';

import { UserInjuredOutlined } from '../components/MabelIcons';
import PatientsAdminTable from '../components/patients/PatientsAdminTable';

const PatientsAdminPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('query') || undefined;

  return (
    <>
      <Helmet>
        <title>Patients | Mabel</title>
      </Helmet>
      <Typography.Title level={3}>
        <UserInjuredOutlined /> Patients
      </Typography.Title>
      <PatientsAdminTable initialQuery={query} />
    </>
  );
};

export default PatientsAdminPage;
