import React from 'react';
import dayjs from 'dayjs';
import { Descriptions } from 'antd';

import { UserDetailsFragment } from '../../graphql/generated';
import PracticeTag from '../practices/PracticeTag';
import NetworkTag from '../networks/NetworkTag';
import { CheckOutlined, StopOutlined } from '@ant-design/icons';

const UserDescription: React.FC<{ user: UserDetailsFragment }> = ({ user }) => {
  return (
    <Descriptions
      title={user.user_name}
      layout="horizontal"
      column={1}
      style={{ margin: '24px 48px' }}
    >
      <Descriptions.Item label="Name">{user.user_name}</Descriptions.Item>
      <Descriptions.Item label="Email">{user.user_email}</Descriptions.Item>
      {user.practice ? (
        <Descriptions.Item label="Practice">
          <PracticeTag practice={user.practice} />
        </Descriptions.Item>
      ) : null}
      {user.network ? (
        <Descriptions.Item label="Network">
          <NetworkTag network={user.network} />
        </Descriptions.Item>
      ) : null}
      <Descriptions.Item label="MFA">
        {user.preferred_mfa === 'NOMFA' ? (
          <StopOutlined style={{ color: 'lightgrey' }} />
        ) : (
          <CheckOutlined className="success-color" />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Last Login">
        {user.last_login_at ? dayjs(user.last_login_at).format('lll') : null}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default UserDescription;
