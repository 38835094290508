import React, { useState, useEffect } from 'react';

import { Form, Input, Modal, Button } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import {
  ProviderDetailsFragment,
  SearchProvidersDocument,
  SearchProvidersAdvancedDocument,
  useUpdateProviderMutation,
  useCreateProviderMutation,
} from '../../graphql/generated';
import { getOperationAST } from 'graphql';

import { PracticeSearchSelect, NetworkSearchSelect } from '../autocompletes';

interface ProviderFormProps extends FormProps {
  initialProvider?: ProviderDetailsFragment;
  updateMutation?: ReturnType<typeof useUpdateProviderMutation>[0];
  createMutation?: ReturnType<typeof useCreateProviderMutation>[0];
  showSubmit?: boolean;
  haveValuesChanged?: boolean;
  setHaveValuesChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProviderForm: React.FC<ProviderFormProps> = ({
  form,
  initialProvider,
  updateMutation,
  createMutation,
  showSubmit,
  haveValuesChanged,
  setHaveValuesChanged,
  ...rest
}) => {
  const [formInternal] = useForm(form);
  const [
    updateMutationInternal,
    updateMutationInternalResults,
  ] = useUpdateProviderMutation();
  const [
    createMutationInternal,
    createMutationInternalResults,
  ] = useCreateProviderMutation();

  // If an initial provider is given, this populates the form
  const recordToFormVals = (record: ProviderDetailsFragment) => {
    return {
      ...record,
      provider_employments: record.provider_employments
        .filter((e) => !e.is_archived && !e.practice.is_archived)
        .map((e) => {
          return {
            value: e.practice.practice_id,
            label: e.practice.practice_name,
          };
        }),
      provider_memberships: record.provider_memberships
        .filter((m) => !m.is_archived && !m.network.is_archived)
        .map((m) => {
          return {
            value: m.network.network_id,
            label: m.network.network_name,
          };
        }),
    };
  };
  useEffect(() => {
    if (initialProvider) {
      formInternal.resetFields();
      formInternal.setFieldsValue(recordToFormVals(initialProvider));
    }
  }, [formInternal, initialProvider]);

  // Takes the form vals and puts them into right format for writing
  const formValsToRecordChanges = (formValues: Store) => {
    const { provider_memberships, provider_employments, ...rest } = formValues;
    const changes = initialProvider
      ? Object.fromEntries(
          Object.entries(rest).filter(
            ([k, v]) => (initialProvider as any)[k] !== v
          )
        )
      : rest;
    return changes;
  };

  const formValsToMembershipChanges = (formValues: Store) => {
    const old_non_archived_networks = new Set(
      (initialProvider?.provider_memberships || [])
        .filter((m) => !m.is_archived)
        .map((m) => m.network.network_id)
    );
    const current_networks = new Set<string>(
      (formValues.provider_memberships || []).map((p: any) => p.value)
    );
    const deleted_networks = [...old_non_archived_networks].filter(
      (k) => !current_networks.has(k)
    );
    const added_networks = [...current_networks].filter(
      (k) => !old_non_archived_networks.has(k)
    );
    const updated_networks = [
      ...deleted_networks.map((n) => {
        return {
          provider_id: initialProvider?.provider_id,
          network_id: n,
          is_archived: true,
        };
      }),
      ...added_networks.map((n) => {
        return {
          provider_id: initialProvider?.provider_id,
          network_id: n,
          is_archived: false,
        };
      }),
    ];
    return updated_networks;
  };

  const formValsToEmploymentChanges = (formValues: Store) => {
    const old_non_archived_practices = new Set(
      (initialProvider?.provider_employments || [])
        .filter((e) => !e.is_archived)
        .map((e) => e.practice.practice_id)
    );
    const current_practices = new Set<string>(
      (formValues.provider_employments || []).map((p: any) => p.value)
    );
    const deleted_practices = [...old_non_archived_practices].filter(
      (k) => !current_practices.has(k)
    );
    const added_practices = [...current_practices].filter(
      (k) => !old_non_archived_practices.has(k)
    );
    const updated_employments = [
      ...deleted_practices.map((p) => {
        return {
          provider_id: initialProvider?.provider_id,
          practice_id: p,
          is_archived: true,
        };
      }),
      ...added_practices.map((p) => {
        return {
          provider_id: initialProvider?.provider_id,
          practice_id: p,
          is_archived: false,
        };
      }),
    ];
    return updated_employments;
  };

  const valuesHaveChanged = (formValues: Store) => {
    return (
      Object.keys(formValsToRecordChanges(formValues)).length > 0 ||
      Object.keys(formValsToMembershipChanges(formValues)).length > 0 ||
      Object.keys(formValsToEmploymentChanges(formValues)).length > 0
    );
  };

  const handleInsert = (values: any) => {
    const provider = {
      ...formValsToRecordChanges(values),
      provider_memberships: {
        data: formValsToMembershipChanges(values),
      },
      provider_employments: {
        data: formValsToEmploymentChanges(values),
      },
    };
    (createMutation || createMutationInternal)({
      variables: { provider: provider },
      refetchQueries: [
        // This just gets the name of the query to refresh.
        // By passing it as just a string, we force a refresh of *all*
        // calls made by the query, regardless of variable values
        // This is useful since we're fetching pages individually, and
        // after inserting a new document then any page might have changed
        getOperationAST(SearchProvidersDocument)?.name?.value || '',
        getOperationAST(SearchProvidersAdvancedDocument)?.name?.value || '',
      ],
    });
  };

  const handleUpdate = (values: any) => {
    if (!initialProvider) {
      return;
    }
    const changes = formValsToRecordChanges(values);
    (updateMutation || updateMutationInternal)({
      variables: {
        provider_id: initialProvider.provider_id,
        changes: Object.keys(changes).length ? changes : undefined,
        provider_memberships: formValsToMembershipChanges(values),
        provider_employments: formValsToEmploymentChanges(values),
      },
    });
    if (setHaveValuesChanged) setHaveValuesChanged(false);
  };

  return (
    <Form
      {...rest}
      form={formInternal}
      labelCol={rest.labelCol || { span: 6 }}
      wrapperCol={rest.wrapperCol || { span: 18 }}
      onValuesChange={(_, allValues) =>
        setHaveValuesChanged
          ? setHaveValuesChanged(valuesHaveChanged(allValues))
          : null
      }
      onFinish={initialProvider ? handleUpdate : handleInsert}
    >
      <Form.Item
        label="Provider Name"
        name="provider_name"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please provide a provider name',
          },
        ]}
      >
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item
        label="Provider NPI"
        name="provider_id"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please provide an NPI number',
          },
        ]}
      >
        <Input placeholder="NPI" />
      </Form.Item>
      <Form.Item
        label="Networks"
        name="provider_memberships"
        key="provider_memberships"
        rules={[
          {
            required: true,
            message:
              'Doctors should be part of at least one network (default if none other)',
          },
        ]}
      >
        <NetworkSearchSelect
          mode="multiple"
          onChange={(value) => {
            form?.setFieldsValue({ provider_memberships: value });
          }}
        />
      </Form.Item>

      <Form.Item
        label="Practices"
        name="provider_employments"
        key="provider_employments"
      >
        <PracticeSearchSelect
          mode="multiple"
          onChange={(value) => {
            form?.setFieldsValue({ provider_employments: value });
          }}
        />
      </Form.Item>
      {showSubmit ? (
        <Form.Item
          style={{ textAlign: 'center', marginTop: 12 }}
          wrapperCol={{ span: 18, offset: 6 }}
        >
          <Button
            type="primary"
            htmlType="submit"
            disabled={!haveValuesChanged}
            loading={
              createMutationInternalResults.loading ||
              updateMutationInternalResults.loading
            }
          >
            Submit
          </Button>
        </Form.Item>
      ) : null}
    </Form>
  );
};

interface ProviderMutationModalProps {
  formName: string;
  initialRecord?: ProviderDetailsFragment;
  visible: boolean;
  onCancel?: (() => void) | undefined;
  afterSubmit?: (() => void) | undefined;
}

const ProviderMutationModal: React.FC<ProviderMutationModalProps> = ({
  initialRecord,
  visible,
  onCancel,
  afterSubmit,
}) => {
  const [form] = useForm();
  const [haveValuesChanged, setHaveValuesChanged] = useState(false);

  const [updateMutation, updateMutationResult] = useUpdateProviderMutation({
    onCompleted: () => {
      form.resetFields();
      if (afterSubmit) afterSubmit();
    },
  });
  const [createMutation, createMutationResult] = useCreateProviderMutation({
    onCompleted: () => {
      form.resetFields();
      if (afterSubmit) afterSubmit();
    },
  });

  return (
    <Modal
      title={initialRecord ? 'Update Provider' : 'Create Provider'}
      okText={initialRecord ? 'Update Provider' : 'Create Provider'}
      okButtonProps={{ disabled: initialRecord && !haveValuesChanged }}
      visible={visible}
      onCancel={onCancel}
      onOk={(e) => form.submit()}
      confirmLoading={
        updateMutationResult.loading || createMutationResult.loading
      }
    >
      <ProviderForm
        form={form}
        initialProvider={initialRecord}
        updateMutation={updateMutation}
        createMutation={createMutation}
        haveValuesChanged={haveValuesChanged}
        setHaveValuesChanged={setHaveValuesChanged}
      />
    </Modal>
  );
};

export { ProviderForm, ProviderMutationModal };
