import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Auth } from 'aws-amplify';
import useRequest from '@ahooksjs/use-request';
import axios from 'axios';
import { Spin, Tabs } from 'antd';
import IframeResizer from 'iframe-resizer-react';
import useUrlState from '@ahooksjs/use-url-state';

const OrganizatioHomePage: React.FC<any> = ({ organization, ...rest }) => {
  const { data, loading, cancel } = useRequest(
    async () => {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      const results = await axios.get(
        `${process.env.REACT_APP_CHALICE_URL}/dashboards`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return results.data;
    },
    {
      pollingInterval: 1000 * 60 * 59, // 59 minutes, since the token expires in 60
    }
  );
  useEffect(() => {
    return cancel; //stop polling when unmount
  }, [cancel]);

  const [urlState, setUrlState] = useUrlState<{ id?: string }>({
    id: undefined,
  });

  return (
    <>
      <Helmet>
        <title>Dashboards | Mabel</title>
      </Helmet>
      <Spin
        spinning={loading}
        size="large"
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          WebkitTransform: 'translate(-50%, -50%)',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {data?.dashboards?.length > 0 && (
          <Tabs
            activeKey={urlState.id}
            onTabClick={(key) => {
              setUrlState({ id: key });
            }}
            defaultActiveKey={data?.dashboards[0]['id']}
            type="card"
            size="large"
          >
            {data?.dashboards.map(
              (d: { title?: string; id: string; url: string }, i: number) => (
                <Tabs.TabPane tab={d.title || `Dashboard ${i}`} key={d.id}>
                  <IframeResizer
                    src={d.url}
                    style={{ width: '1px', minWidth: '100%' }}
                    frameBorder={0}
                    allowTransparency
                  />
                </Tabs.TabPane>
              )
            )}
          </Tabs>
        )}
      </Spin>
    </>
  );
};

export default OrganizatioHomePage;
