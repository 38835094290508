import React, { useState, useEffect } from 'react';

import {
  Result,
  Button,
  Table,
  Row,
  Col,
  Tooltip,
  Input,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PaginationProps } from 'antd/lib/pagination';
import {
  PlusOutlined,
  StopOutlined,
  CheckOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { textToHighlight } from '../../components/utils/Highlight';
import PatientDescription from './PatientDescription';
import { PatientMutationModal } from './PatientForm';
import MutateButton from '../utils/MutateButton';

import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { DEBOUNCE_MS } from '../autocompletes';

import {
  PatientDetailsFragment,
  SearchPatientsAdvancedDocument,
  useSearchPatientsAdvancedLazyQuery,
  SearchPatientsAdvancedQueryVariables,
  UpdatePatientDocument,
  Order_By,
} from '../../graphql/generated';
import { getOperationAST } from 'graphql';
import dayjs from 'dayjs';
import ValidatedAddress from '../utils/ValidatedAddress';

interface PatientsTableProps {
  initialQuery?: string;
}

const PatientsTable: React.FC<PatientsTableProps> = ({ initialQuery }) => {
  const initialSearchParams: {
    variables: SearchPatientsAdvancedQueryVariables;
    pagination: PaginationProps;
  } = {
    variables: {
      exact_query: initialQuery || null,
      substring_query: initialQuery ? `%${initialQuery}%` : null,
      prefix_query: initialQuery ? `${initialQuery}%` : null,
      additional_filters: [{ is_archived: { _in: [false] } }],
      offset: 0,
      limit: 10,
      order_by: [],
    },
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [
    searchPatientsQuery,
    searchPatientsQueryResults,
  ] = useSearchPatientsAdvancedLazyQuery({
    variables: initialSearchParams.variables,
  });
  const searchPatientsDebounced = useConstant(() =>
    AwesomeDebouncePromise(
      (searchParams: SearchPatientsAdvancedQueryVariables) => {
        return searchPatientsQuery({ variables: searchParams });
      },
      DEBOUNCE_MS,
      { key: (fieldId, _) => fieldId }
    )
  );
  useEffect(() => {
    searchPatientsDebounced(searchParams.variables);
  }, [searchParams, searchPatientsDebounced]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<
    PatientDetailsFragment | undefined
  >();

  if (searchPatientsQueryResults.error)
    return (
      <Result
        status="error"
        title="There was an error retrieving patients"
        subTitle={searchPatientsQueryResults.error?.message}
      />
    );

  const columns: ColumnsType<PatientDetailsFragment> = [
    {
      title: 'Patient Name',
      dataIndex: 'patient_name',
      key: 'patient_name',
      sorter: true,
      render: textToHighlight(searchParams.variables.exact_query),
    },
    {
      title: 'Birthdate',
      dataIndex: 'birth_date',
      key: 'birth_date',
      sorter: true,
      render: (val) =>
        dayjs(val).isValid() ? (
          dayjs(val).format('L')
        ) : (
          <Typography.Text type="danger">{val}</Typography.Text>
        ),
    },
    {
      title: 'Email',
      dataIndex: 'patient_email',
      key: 'patient_email',
      sorter: true,
      render: textToHighlight(searchParams.variables.exact_query),
    },
    {
      title: 'Mailing Address',
      dataIndex: 'patient_address',
      key: 'patient_address',
      sorter: true,
      render: (value, record) =>
        record.patient_address && (
          <ValidatedAddress
            address={textToHighlight(searchParams.variables.exact_query)(value)}
            deliverability={record.deliverability}
          />
        ),
    },
    {
      title: 'Medicare Classification',
      dataIndex: ['patient_segment_label', 'patient_segment_id'],
      key: 'patient_segment_label',
      render: (val) =>
        val === 'FFS_PDP' ? (
          'Original Medicare Only'
        ) : val === 'FFSPLUSSUP' ? (
          'Original Medicare w/Supplemental'
        ) : val === 'MAPD' ? (
          'Medicare Advantage'
        ) : val === 'DUALS' ? (
          'Dual-Eligible'
        ) : val === 'VETERANS' ? (
          'Veteran Plan'
        ) : val === 'COMMERCIAL' ? (
          'No (Commercial Plan)'
        ) : val === 'EXCLUDE' ? (
          'Excluded from marketing'
        ) : val === 'INS_UNDETERMINED' ? (
          <>
            Indeterminate{' '}
            <Tooltip title="We could not determine from the patient's insurance information what form of Medicare, if any, they currently have.">
              <QuestionCircleOutlined />
            </Tooltip>
          </>
        ) : (
          ''
        ),
    },
    {
      title: 'Active?',
      dataIndex: 'is_archived',
      key: 'is_archived',
      width: 1,
      align: 'center',
      sorter: true,
      filters: [
        {
          text: 'Active',
          value: 'false',
        },
        {
          text: 'Archived',
          value: 'true',
        },
      ],
      defaultFilteredValue: ['false'],
      onFilter: (value, record) => record.is_archived.toString() === value,
      render: (is_archived) =>
        is_archived ? (
          <Tooltip title="Item has been archived">
            <StopOutlined className="danger-color" />
          </Tooltip>
        ) : (
          <CheckOutlined className="success-color" />
        ),
    },
    {
      key: 'edit',
      width: 1,
      align: 'center',
      render: (text, record) => {
        return record.is_archived ? (
          <MutateButton
            document={UpdatePatientDocument}
            variables={{
              patient_id: record.patient_id,
              changes: { is_archived: false },
            }}
            refetchQueries={[
              getOperationAST(SearchPatientsAdvancedDocument)?.name?.value ||
                '',
            ]}
          >
            Restore
          </MutateButton>
        ) : (
          <div style={{ display: 'inline-flex' }}>
            <Button
              type="link"
              size="small"
              style={{ margin: 0 }}
              onClick={() => {
                setSelectedRecord(record);
                setShowUpdateModal(true);
              }}
            >
              Edit
            </Button>
            <MutateButton
              document={UpdatePatientDocument}
              variables={{
                patient_id: record.patient_id,
                changes: { is_archived: true },
              }}
              refetchQueries={[
                getOperationAST(SearchPatientsAdvancedDocument)?.name?.value ||
                  '',
              ]}
              popconfirm={{
                title: 'Archive this item?',
              }}
            >
              Archive
            </MutateButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={[8, 8]} justify="center">
        <Col flex="auto">
          <Input.Search
            defaultValue={initialQuery || undefined}
            placeholder="Search by patient name, address, or id"
            enterButton
            allowClear
            loading={searchPatientsQueryResults.loading}
            onSearch={(value) => {
              const trimmedValue = value;
              const newParams = {
                exact_query: trimmedValue,
                substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                prefix_query: trimmedValue ? `${trimmedValue}%` : null,
                offset: 0,
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: { ...old.pagination, current: 1 },
                };
              });
            }}
            onChange={(e) => {
              const trimmedValue = e.target.value.trim();
              const newParams = {
                exact_query: trimmedValue,
                substring_query: trimmedValue ? `%${trimmedValue}%` : null,
                prefix_query: trimmedValue ? `${trimmedValue}%` : null,
                offset: 0,
              };
              setSearchParams((old) => {
                return {
                  variables: { ...old.variables, ...newParams },
                  pagination: { ...old.pagination, current: 1 },
                };
              });
            }}
          />
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowCreateModal(true)}
          >
            Add Patient
          </Button>
          <PatientMutationModal
            formName="Create"
            visible={showCreateModal}
            onCancel={() => setShowCreateModal(false)}
            afterSubmit={() => setShowCreateModal(false)}
          />
          <PatientMutationModal
            formName="Update"
            initialRecord={selectedRecord}
            visible={showUpdateModal}
            onCancel={() => setShowUpdateModal(false)}
            afterSubmit={() => setShowUpdateModal(false)}
          />
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <Table<PatientDetailsFragment>
            size="small"
            rowKey="patient_id"
            loading={searchPatientsQueryResults.loading}
            dataSource={searchPatientsQueryResults.data?.patients}
            pagination={{
              ...searchParams.pagination,
              position: ['bottomRight'],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              total:
                searchPatientsQueryResults.data?.patients_aggregate.aggregate
                  ?.count || 0,
            }}
            columns={columns}
            showSorterTooltip={false}
            onChange={(pagination, filters, sorter, extra) => {
              if (extra.action === 'sort') {
                const new_order = !sorter
                  ? []
                  : (sorter instanceof Array ? sorter : [sorter])
                      .filter((c) => c.columnKey && c.order)
                      .map((column) => {
                        const key = column.columnKey?.toString() || '';
                        const order =
                          column.order === 'ascend'
                            ? Order_By.AscNullsFirst
                            : Order_By.DescNullsLast;
                        return { [key]: order };
                      });
                const newParams = {
                  order_by: !new_order.length
                    ? initialSearchParams.variables.order_by
                    : new_order,
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: old.pagination,
                  };
                });
              } else if (extra.action === 'filter') {
                const additional_filters: any[] = [];
                for (const k in filters) {
                  if (filters[k]?.length) {
                    additional_filters.push({ [k]: { _in: filters[k] } });
                  }
                }
                const newParams = {
                  offset: 0,
                  additional_filters: additional_filters,
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: { ...old.pagination, current: 1 },
                  };
                });
              } else if (extra.action === 'paginate') {
                const newParams = {
                  limit: pagination.pageSize,
                  offset:
                    (pagination.pageSize || 0) *
                    ((pagination.current || 0) - 1),
                };
                setSearchParams((old) => {
                  return {
                    variables: { ...old.variables, ...newParams },
                    pagination: {
                      ...old.pagination,
                      current: pagination.current || 1,
                      pageSize: pagination.pageSize || old.pagination.pageSize,
                    },
                  };
                });
              }
            }}
            expandable={{
              expandedRowRender: (record) => (
                <PatientDescription patient={record} />
              ),
            }}
          ></Table>
        </Col>
      </Row>
    </>
  );
};

export default PatientsTable;
