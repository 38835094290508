import React from 'react';
import { Spin, Tooltip, Typography } from 'antd';
import { FileUploadBasicFragment } from '../../graphql/generated';
import { useRequest } from 'ahooks';
import { Auth } from 'aws-amplify';
import axios from 'axios';

const SignedAWSURL: React.FC<{
  file: FileUploadBasicFragment;
}> = ({ file }) => {
  const getUrl = async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const response = await axios.get(
      `${process.env.REACT_APP_CHALICE_URL}/get_signed_url`,
      {
        params: { key: file.level + '/' + file.identity_id + '/' + file.key },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.url;
  };
  const { data, error, loading } = useRequest(getUrl);

  return (
    <Spin spinning={loading}>
      <Tooltip
        title={
          file.upload_status !== 'Completed' || error
            ? `Error during upload. This must be fixed manually.`
            : file.file_name
        }
      >
        <Typography.Link
          href={data}
          target="_blank"
          rel="noopener noreferrer"
          type={
            file.upload_status !== 'Completed' || error ? 'danger' : undefined
          }
        >
          {file.file_name}
        </Typography.Link>
      </Tooltip>
    </Spin>
  );
};

export default SignedAWSURL;
