import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { Typography, List, Button, Card, Space, Spin, Tooltip } from 'antd';

import { UserContext } from '../contexts/UserContext';
import {
  useListCampaignTemplatesQuery,
  useGetUserDetailsQuery,
  useGetPracticeDetailsLazyQuery,
  ListCampaignTemplatesQuery,
} from '../graphql/generated';

import CampaignDetailsPage from './CampaignDetailsPage';
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Link,
} from 'react-router-dom';
import {
  EyeOutlined,
  CheckOutlined,
  MailOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { InlineIcon } from '@iconify/react';
import { MailBulkOutlined } from '../components/MabelIcons';

import paperPlane from '@iconify/icons-fa-regular/paper-plane';
import Tag from 'antd/es/tag';

const CampaignsPage = () => {
  const { path } = useRouteMatch();

  const { user } = useContext(UserContext);
  const userDetailsQuery = useGetUserDetailsQuery({
    variables: { user_id: user?.user_id },
  });
  const [
    practiceDetailsQuery,
    practiceDetailsQueryResult,
  ] = useGetPracticeDetailsLazyQuery();
  useEffect(() => {
    const practice_id = userDetailsQuery.data?.users_by_pk?.practice_id;
    if (practice_id) {
      practiceDetailsQuery({ variables: { practice_id: practice_id } });
    }
  }, [userDetailsQuery.data, practiceDetailsQuery]);

  const campaignTemplatesQuery = useListCampaignTemplatesQuery({
    variables: { include_approvals: true },
  });

  return (
    <>
      <Helmet>
        <title>Campaigns | Mabel</title>
      </Helmet>
      <Switch>
        <Route exact path={path}>
          <Typography.Title level={3}>
            <MailBulkOutlined style={{ fontSize: 28 }} /> Campaigns
          </Typography.Title>
          <Spin
            spinning={
              userDetailsQuery.loading ||
              practiceDetailsQueryResult.loading ||
              campaignTemplatesQuery.loading
            }
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {campaignTemplatesQuery.data?.campaign_templates.map(
                (campaign) => (
                  <CampaignListItem
                    campaign={campaign}
                    key={campaign.campaign_template_id}
                  />
                )
              )}
            </Space>
          </Spin>
        </Route>
        {campaignTemplatesQuery.data?.campaign_templates.map((campaign) => {
          return (
            <Route
              key={campaign.campaign_template_id}
              path={`${path}/${campaign.campaign_template_id}`}
              render={() => <CampaignDetailsPage campaign={campaign} />}
            />
          );
        })}
      </Switch>
    </>
  );
};

const CampaignListItem: React.FC<{
  campaign: ListCampaignTemplatesQuery['campaign_templates'][0];
}> = ({ campaign }) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const numLetters = new Set(
    campaign.campaign_letter_components
      .filter((lc) => !lc.is_archived)
      .map((lc) => lc.letter_template_id)
  ).size;
  const numEmails = new Set(
    campaign.campaign_email_components
      .filter((ec) => !ec.is_archived)
      .map((ec) => ec.email_template_id)
  ).size;
  const numSMSMessages = new Set(
    campaign.campaign_sms_components
      .filter((ec) => !ec.is_archived)
      .map((ec) => ec.sms_template_id)
  ).size;

  const isApproved = campaign.campaign_approvals.length > 0;

  return (
    <List bordered key={campaign.campaign_template_id}>
      <Card
        hoverable
        bordered={false}
        bodyStyle={{ padding: 0 }}
        onClick={() => history.push(`${url}/${campaign.campaign_template_id}`)}
      >
        <List.Item
          className="card-shadow"
          actions={[
            <Link to={`${url}/${campaign.campaign_template_id}`}>
              {!isApproved ? (
                <Button type="primary" shape="round" icon={<EyeOutlined />}>
                  View and Approve
                </Button>
              ) : (
                <Button type="default" shape="round" icon={<EyeOutlined />}>
                  View
                </Button>
              )}
            </Link>,
          ]}
        >
          <List.Item.Meta
            title={
              <Space>
                {campaign.campaign_name}
                {numLetters > 0 && (
                  <Tooltip
                    title={`${numLetters} letter template${
                      numLetters > 1 ? 's' : ''
                    }`}
                  >
                    <InlineIcon icon={paperPlane} />
                  </Tooltip>
                )}
                {numEmails > 0 && (
                  <Tooltip
                    title={`${numEmails} email template${
                      numEmails > 1 ? 's' : ''
                    }`}
                  >
                    <MailOutlined />
                  </Tooltip>
                )}
                {numSMSMessages > 0 && (
                  <Tooltip
                    title={`${numSMSMessages} sms template${
                      numSMSMessages > 1 ? 's' : ''
                    }`}
                  >
                    <MessageOutlined />
                  </Tooltip>
                )}
              </Space>
            }
            description={campaign.campaign_description}
          />
          <div>
            {isApproved && (
              <Tag color="success">
                <CheckOutlined /> Approved
              </Tag>
            )}
          </div>
        </List.Item>
      </Card>
    </List>
  );
};

export default CampaignsPage;
