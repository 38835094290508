import React, { useState } from 'react';
import { Popover } from 'antd';

import { SketchPicker } from 'react-color';

interface ColorInputProps {
  value?: string; //hex
  presetColors?: string[]; //hex array for initial color palette
  onChange?: (value: string) => void;
}

const ColorInput: React.FC<ColorInputProps> = React.forwardRef(
  ({ value, presetColors, onChange }, ref) => {
    const [selectedColor, setSelectedColor] = useState<string>();
    React.useEffect(() => {
      setSelectedColor(value);
    }, [value]);

    return (
      <Popover
        placement="bottom"
        overlayClassName="color-picker-popup"
        content={
          <SketchPicker
            disableAlpha
            color={selectedColor || undefined}
            presetColors={presetColors}
            onChange={(color) => {
              setSelectedColor(color.hex);
            }}
            onChangeComplete={(color) => {
              if (onChange) onChange(color.hex);
            }}
          />
        }
        trigger="click"
      >
        <div
          style={{
            padding: 4,
            margin: 2,
            background: '#fff',
            borderRadius: '2px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          }}
        >
          <div
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '2px',
              background: selectedColor,
            }}
          />
        </div>
      </Popover>
    );
  }
);

export default ColorInput;
