import React, { useContext } from 'react';

import { useApolloClient } from '@apollo/react-hooks';
import { Button, ButtonProps } from 'antd';

import { MabelUserRole, UserContext } from '../../contexts/UserContext';
import { useUpdateUserMutation } from '../../graphql/generated';
import { Auth } from 'aws-amplify';
import { UserSwitchOutlined } from '@ant-design/icons';

interface SwitchRoleButtonProps extends ButtonProps {
  practice_id?: string;
  network_id?: string;
  organization_id?: string;
  changeRole?: MabelUserRole;
  buttonText?: string;
}

const SwitchRoleButton: React.FC<SwitchRoleButtonProps> = ({
  network_id,
  practice_id,
  organization_id,
  changeRole,
  buttonText,
  ...props
}) => {
  const client = useApolloClient();
  const { user, setRole } = useContext(UserContext);
  const [updateUser, updateUserResult] = useUpdateUserMutation({
    onCompleted: () => {
      Auth.currentSession().then((currentSession) =>
        user?.cognito_user.refreshSession(
          currentSession.getRefreshToken(),
          () => {}
        )
      );
    },
  });

  return (
    <Button
      type="primary"
      danger
      icon={<UserSwitchOutlined />}
      onClick={() => {
        updateUser({
          variables: {
            user_id: user?.user_id,
            changes: {
              network_id: network_id,
              practice_id: practice_id,
              organization_id: organization_id,
            },
          },
        }).then(() => {
          if (changeRole) {
            setRole(changeRole);
            client.clearStore();
          }
        });
      }}
      {...props}
      loading={updateUserResult.loading || props.loading}
    >
      {buttonText || 'Assume Role'}
    </Button>
  );
};

export default SwitchRoleButton;
