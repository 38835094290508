import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Typography } from 'antd';

import { useLocation } from 'react-router-dom';

import { ClinicOutlined } from '../components/MabelIcons';
import PracticesTable from '../components/practices/PracticesTable';

const PracticesAdminPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('query') || undefined;

  return (
    <>
      <Helmet>
        <title>Practices Admin | Mabel</title>
      </Helmet>
      <Typography.Title level={3}>
        <ClinicOutlined /> Practices
      </Typography.Title>
      <PracticesTable initialQuery={query} />
    </>
  );
};

export default PracticesAdminPage;
