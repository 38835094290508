import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { Button, message, Popconfirm, Typography } from 'antd';
import { useLocation } from 'react-router-dom';

import { UserInjuredOutlined } from '../components/MabelIcons';
import PatientsTable from '../components/patients/PatientsTable';
import { DeleteOutlined } from '@ant-design/icons';
import {
  SearchPatientsAdvancedDocument,
  useArchiveAllPatientsMutation,
  useGetUserDetailsQuery,
} from '../graphql/generated';
import { UserContext } from '../contexts/UserContext';
import { getOperationAST } from 'graphql';

const PatientsPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('query') || undefined;

  const { user } = useContext(UserContext);
  const userDetailsQuery = useGetUserDetailsQuery({
    variables: { user_id: user?.user_id },
  });

  const [
    archiveAllMutation,
    archiveAllMutationResult,
  ] = useArchiveAllPatientsMutation({
    onCompleted: (data) => {
      message.success(
        `Archived ${data.update_patients?.affected_rows} patients`
      );
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  return (
    <>
      <Helmet>
        <title>Patients | Mabel</title>
      </Helmet>
      <Typography.Title level={3}>
        <UserInjuredOutlined /> Patients
      </Typography.Title>
      <PatientsTable initialQuery={query} />
      <Popconfirm
        title="Are you sure you want to archive all patients?"
        okText="Archive"
        cancelText="Cancel"
        onConfirm={() => {
          const practice_id =
            userDetailsQuery.data?.users_by_pk?.practice_id || '';
          if (practice_id) {
            archiveAllMutation({
              variables: { practice_id: practice_id },
              refetchQueries: [
                getOperationAST(SearchPatientsAdvancedDocument)?.name?.value ||
                  '',
              ],
            });
          }
        }}
      >
        <Button
          type="primary"
          danger
          icon={<DeleteOutlined />}
          loading={archiveAllMutationResult.loading}
        >
          Archive all Patients
        </Button>
      </Popconfirm>
    </>
  );
};

export default PatientsPage;
