import React, { useState, useEffect, useContext } from 'react';

import { Auth } from 'aws-amplify';

import { Form, Input, Modal, Button } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import {
  UserDetailsFragment,
  SearchUsersAdvancedDocument,
  useCreateUserMutation,
  useUpdateUserMutation,
  //useSendBaaMutation,
} from '../../graphql/generated';
import { getOperationAST } from 'graphql';

import {
  PracticeSearchSelect,
  NetworkSearchSelect,
  OrganizationSearchSelect,
} from '../autocompletes';
import { MabelUserRole, UserContext } from '../../contexts/UserContext';
import PublicImageUpload from '../utils/PublicImageUpload';

interface UserFormProps extends FormProps {
  initialUser?: UserDetailsFragment;
  initialValues?: Partial<UserDetailsFragment>;
  userRole: MabelUserRole;
  updateMutation?: ReturnType<typeof useUpdateUserMutation>[0];
  createMutation?: ReturnType<typeof useCreateUserMutation>[0];
  showSubmit?: boolean;
  haveValuesChanged?: boolean;
  setHaveValuesChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserForm: React.FC<UserFormProps> = ({
  form,
  initialUser,
  initialValues,
  userRole,
  updateMutation,
  createMutation,
  showSubmit,
  haveValuesChanged,
  setHaveValuesChanged,
  ...rest
}) => {
  const [formInternal] = useForm(form);
  const { user } = useContext(UserContext);
  const [
    updateMutationInternal,
    updateMutationInternalResults,
  ] = useUpdateUserMutation({
    // since a user update changes attributes in both cognito and the db, we force
    // a refresh of the JWT with updated user credentials
    onCompleted: () => {
      Auth.currentSession().then((currentSession) => {
        user?.cognito_user.refreshSession(
          currentSession.getRefreshToken(),
          () => {}
        );
      });
    },
  });

  const [
    createMutationInternal,
    createMutationInternalResults,
  ] = useCreateUserMutation();

  // If an initial user is given, this populates the form
  const recordToFormVals = (record: Partial<UserDetailsFragment>) => {
    return {
      ...record,
      photo: { url: record.photo },
      network_id: record.network_id
        ? {
            value: record.network?.network_id,
            label: record.network?.network_name,
          }
        : undefined,
      organization_id: record.organization_id
        ? {
            value: record.organization?.organization_id,
            label: record.organization?.organization_name,
          }
        : undefined,
      practice_id: record.practice_id
        ? {
            value: record.practice?.practice_id,
            label: record.practice?.practice_name,
          }
        : undefined,
    };
  };
  useEffect(() => {
    if (initialUser || initialValues) {
      formInternal.resetFields();
      formInternal.setFieldsValue(
        recordToFormVals(initialUser || initialValues!)
      );
    }
  }, [formInternal, initialUser, initialValues]);

  // Takes the form vals and puts them into right format for writing
  const formValsToRecordChanges = (formValues: Store) => {
    const new_obj = {
      ...formValues,
      photo: formValues.photo?.url || null,
    } as any;
    if (formValues.organization_id) {
      new_obj.organization_id = formValues.organization_id?.value;
    }
    if (formValues.network_id) {
      new_obj.network_id = formValues.network_id?.value;
    }
    if (formValues.practice_id) {
      new_obj.practice_id = formValues.practice_id?.value;
    }
    const changes = initialUser
      ? Object.fromEntries(
          Object.entries(new_obj).filter(
            ([k, v]) => (initialUser as any)[k] !== v
          )
        )
      : new_obj;
    return changes;
  };

  const valuesHaveChanged = (formValues: Store) => {
    return Object.keys(formValsToRecordChanges(formValues)).length > 0;
  };

  const handleInsert = (values: any) => {
    (createMutation || createMutationInternal)({
      variables: { ...formValsToRecordChanges(values) } as any,
      refetchQueries: [
        // This just gets the name of the query to refresh.
        // By passing it as just a string, we force a refresh of *all*
        // calls made by the query, regardless of variable values
        // This is useful since we're fetching pages individually, and
        // after inserting a new document then any page might have changed
        getOperationAST(SearchUsersAdvancedDocument)?.name?.value || '',
      ],
    });
    // Don't send the BAA automatically, since docusign costs $$$
    //   .then((result) => {
    //   if (
    //     userRole === 'practice_user' &&
    //     result.data?.create_user?.user?.practice &&
    //     !result.data?.create_user?.user?.practice?.completed_baas?.length
    //   ) {
    //     sendBAAMutation({
    //       variables: {
    //         practice_id: result.data?.create_user?.user?.practice_id!,
    //         recipient_email: result.data?.create_user?.user?.user_email!,
    //         recipient_name: result.data?.create_user?.user?.user_name,
    //       },
    //     });
    //   }
    // });
  };

  const handleUpdate = (values: any) => {
    if (!initialUser) {
      return;
    }
    const changes = formValsToRecordChanges(values);
    (updateMutation || updateMutationInternal)({
      variables: {
        user_id: initialUser.user_id,
        changes: Object.keys(changes).length ? changes : undefined,
      },
    });
    if (setHaveValuesChanged) setHaveValuesChanged(false);
  };

  return (
    <Form
      {...rest}
      form={formInternal}
      labelCol={rest.labelCol || { span: 6 }}
      wrapperCol={rest.wrapperCol || { span: 18 }}
      onValuesChange={(_, allValues) =>
        setHaveValuesChanged
          ? setHaveValuesChanged(valuesHaveChanged(allValues))
          : null
      }
      onFinish={initialUser ? handleUpdate : handleInsert}
    >
      <Form.Item
        label="Email"
        name="user_email"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: 'Please provide an email',
          },
          {
            type: 'email',
            message: 'Please input a valid email address',
          },
        ]}
      >
        <Input placeholder="Email" disabled={!!initialUser?.user_email} />
      </Form.Item>
      <Form.Item
        label="Name"
        name="user_name"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please provide a name',
          },
        ]}
      >
        <Input placeholder="Name" />
      </Form.Item>
      {userRole === 'admin' ||
      (userRole === 'practice_user' && !initialUser) ? (
        <Form.Item
          label="Practice"
          name="practice_id"
          key="practice_id"
          rules={[
            {
              required: true,
              message: 'Please enter a practice',
            },
          ]}
        >
          <PracticeSearchSelect
            onChange={(value) => {
              form?.setFieldsValue({ practice_id: value });
            }}
          />
        </Form.Item>
      ) : null}
      {userRole === 'admin' || (userRole === 'network_user' && !initialUser) ? (
        <Form.Item
          label="Network"
          name="network_id"
          key="network_id"
          rules={[
            {
              required: true,
              message: 'Please enter a network',
            },
          ]}
        >
          <NetworkSearchSelect
            onChange={(value) => {
              form?.setFieldsValue({ network_id: value });
            }}
          />
        </Form.Item>
      ) : null}
      {userRole === 'admin' ||
      (userRole === 'organization_user' && !initialUser) ? (
        <Form.Item
          label="Organization"
          name="organization_id"
          key="organization_id"
          rules={[
            {
              required: true,
              message: 'Please enter an Organization',
            },
          ]}
        >
          <OrganizationSearchSelect
            onChange={(value) => {
              form?.setFieldsValue({ organization_id: value });
            }}
          />
        </Form.Item>
      ) : null}

      <Form.Item label="Profile Photo" name="photo">
        <PublicImageUpload
          uploadPath="avatars/"
          cropProps={{ rotate: true, shape: 'round' }}
          previewStyle={{ borderRadius: '50%', height: 104, width: 104 }}
          maxFileSize={2 * 1024 * 1024} //2 MB
        />
      </Form.Item>

      {showSubmit ? (
        <Form.Item style={{ marginTop: 12 }} wrapperCol={{ offset: 6 }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!haveValuesChanged}
            loading={
              createMutationInternalResults.loading ||
              updateMutationInternalResults.loading
            }
          >
            Save
          </Button>
        </Form.Item>
      ) : null}
    </Form>
  );
};

interface UserMutationModalProps {
  formName: string;
  initialRecord?: UserDetailsFragment;
  initialValues?: Partial<UserDetailsFragment>;
  userRole: MabelUserRole;
  visible: boolean;
  onCancel?: (() => void) | undefined;
  afterSubmit?: (() => void) | undefined;
}

const UserMutationModal: React.FC<UserMutationModalProps> = ({
  initialRecord,
  initialValues,
  userRole,
  visible,
  onCancel,
  afterSubmit,
}) => {
  const { user } = useContext(UserContext);
  const [form] = useForm();
  const [haveValuesChanged, setHaveValuesChanged] = useState(false);

  const [updateMutation, updateMutationResult] = useUpdateUserMutation({
    // since a user update changes attributes in both cognito and the db, we force
    // a refresh of the JWT with updated user credentials
    onCompleted: () => {
      Auth.currentSession().then((currentSession) => {
        user?.cognito_user.refreshSession(
          currentSession.getRefreshToken(),
          () => {}
        );
      });

      form.resetFields();
      if (afterSubmit) afterSubmit();
    },
  });
  const [createMutation, createMutationResult] = useCreateUserMutation({
    onCompleted: () => {
      form.resetFields();
      if (afterSubmit) afterSubmit();
    },
  });

  return (
    <Modal
      title={initialRecord ? 'Update User' : 'Create User'}
      okText={initialRecord ? 'Update User' : 'Create User'}
      okButtonProps={{ disabled: initialRecord && !haveValuesChanged }}
      visible={visible}
      onCancel={onCancel}
      onOk={(e) => form.submit()}
      confirmLoading={
        updateMutationResult.loading || createMutationResult.loading
      }
    >
      <UserForm
        form={form}
        initialUser={initialRecord}
        initialValues={initialValues}
        userRole={userRole}
        updateMutation={updateMutation}
        createMutation={createMutation}
        haveValuesChanged={haveValuesChanged}
        setHaveValuesChanged={setHaveValuesChanged}
      />
    </Modal>
  );
};

export { UserForm, UserMutationModal };
