import React from 'react';
import { Tag, Tooltip } from 'antd';
import { TagProps } from 'antd/lib/tag';
import { ProviderBasicFragment } from '../../graphql/generated';

interface ProviderTagProps extends TagProps {
  provider: ProviderBasicFragment;
}
const ProviderTag: React.FC<ProviderTagProps> = ({ provider, ...rest }) => {
  return (
    <Tooltip title={`NPI: ${provider.provider_id}`}>
      <Tag {...rest}>{provider.provider_name}</Tag>
    </Tooltip>
  );
};

export default ProviderTag;
