import React from 'react';
import { Descriptions } from 'antd';

import { ProviderDetailsFragment } from '../../graphql/generated';
import PracticeTag from '../practices/PracticeTag';
import NetworkTag from '../networks/NetworkTag';

const ProviderDescription: React.FC<{ provider: ProviderDetailsFragment }> = ({
  provider,
}) => {
  return (
    <Descriptions
      title={provider.provider_name}
      layout="horizontal"
      column={1}
      style={{ margin: '24px 48px' }}
    >
      <Descriptions.Item label="NPI">{provider.provider_id}</Descriptions.Item>
      <Descriptions.Item label="Name">
        {provider.provider_name}
      </Descriptions.Item>
      <Descriptions.Item label="Practices">
        <>
          {provider.provider_employments
            .filter((e) => !e.is_archived && !e.practice.is_archived)
            .map((e) => (
              <PracticeTag practice={e.practice} key={e.practice.practice_id} />
            ))}
        </>
      </Descriptions.Item>
      <Descriptions.Item label="Networks">
        <div style={{ display: 'block' }}>
          {provider.provider_memberships
            .filter((m) => !m.is_archived && !m.network.is_archived)
            .map((m) => (
              <NetworkTag network={m.network} key={m.network.network_id} />
            ))}
        </div>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ProviderDescription;
