import React, { useState } from 'react';
import { Select, Typography, Spin, Empty, AutoComplete, Space } from 'antd';

import { SelectProps } from 'antd/lib/select/';
import { AutoCompleteProps } from 'antd/lib/auto-complete/';

import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import {
  useSearchProvidersLazyQuery,
  useSearchPracticesLazyQuery,
  useSearchNetworksLazyQuery,
  useSearchOrganizationsLazyQuery,
  useSearchActiveParticipantsLazyQuery,
} from '../graphql/generated';

import ProviderTag from './providers/ProviderTag';
import PracticeTag from './practices/PracticeTag';
import NetworkTag from './networks/NetworkTag';
import OrganizationTag from './organizations/OrganizationTag';
import dayjs from 'dayjs';

const DEBOUNCE_MS = 100;

const useDebouncedSearchPractices = (include_archived = true) => {
  const [
    searchPracticesQuery,
    searchPracticesQueryResults,
  ] = useSearchPracticesLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const searchPracticesDebounced = useConstant<(val: string) => void>(() =>
    AwesomeDebouncePromise((val) => {
      return searchPracticesQuery({
        variables: {
          query: val ? `%${val}%` : '',
          include_archived: include_archived,
        },
      });
    }, DEBOUNCE_MS)
  );
  const handlePracticeSearch = async (query: string) => {
    await searchPracticesDebounced(query.trim());
  };
  return {
    searchPracticesQuery,
    searchPracticesQueryResults,
    handlePracticeSearch,
  };
};

const useDebouncedSearchProviders = () => {
  const [
    searchProvidersQuery,
    searchProvidersQueryResults,
  ] = useSearchProvidersLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const searchProvidersDebounced = useConstant<(val: string) => void>(() =>
    AwesomeDebouncePromise((val) => {
      return searchProvidersQuery({
        variables: { query: val ? `%${val}%` : '' },
      });
    }, DEBOUNCE_MS)
  );
  const handleProviderSearch = async (query: string) => {
    await searchProvidersDebounced(query.trim());
  };
  return {
    searchProvidersQuery,
    searchProvidersQueryResults,
    handleProviderSearch,
  };
};

const useDebouncedSearchNetworks = () => {
  const [
    searchNetworksQuery,
    searchNetworksQueryResults,
  ] = useSearchNetworksLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const searchNetworksDebounced = useConstant<(val: string) => void>(() =>
    AwesomeDebouncePromise((val) => {
      return searchNetworksQuery({
        variables: { query: val ? `%${val}%` : '' },
      });
    }, DEBOUNCE_MS)
  );
  const handleNetworkSearch = async (query: string) => {
    await searchNetworksDebounced(query.trim());
  };
  return {
    searchNetworksQuery,
    searchNetworksQueryResults,
    handleNetworkSearch,
  };
};

const useDebouncedSearchOrganizations = () => {
  const [
    searchOrganizationsQuery,
    searchOrganizationsQueryResults,
  ] = useSearchOrganizationsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const searchOrganizationsDebounced = useConstant<(val: string) => void>(() =>
    AwesomeDebouncePromise((val) => {
      return searchOrganizationsQuery({
        variables: { query: val ? `%${val}%` : '' },
      });
    }, DEBOUNCE_MS)
  );
  const handleOrganizationSearch = async (query: string) => {
    await searchOrganizationsDebounced(query.trim());
  };
  return {
    searchOrganizationsQuery,
    searchOrganizationsQueryResults,
    handleOrganizationSearch,
  };
};

const PracticeSearchAutocomplete: React.FC<AutoCompleteProps> = React.forwardRef(
  (props, ref) => {
    const {
      searchPracticesQueryResults,
      handlePracticeSearch,
    } = useDebouncedSearchPractices();
    return (
      <AutoComplete
        {...props}
        showSearch
        placeholder="Search or type practice name"
        onSearch={handlePracticeSearch}
        onChange={(value, op) => {
          if (props.onChange) props.onChange(value, op);
        }}
      >
        {(searchPracticesQueryResults.data?.practices || []).map((d) => (
          <AutoComplete.Option key={d.practice_id} value={d.practice_name}>
            {d.practice_name}
            <br />
            <Typography.Text type="secondary">
              <small>
                <i>{d.practice_address}</i>
              </small>
            </Typography.Text>
          </AutoComplete.Option>
        ))}
      </AutoComplete>
    );
  }
);

const ProviderSearchAutocomplete: React.FC<AutoCompleteProps> = React.forwardRef(
  (props, ref) => {
    const {
      searchProvidersQueryResults,
      handleProviderSearch,
    } = useDebouncedSearchProviders();
    return (
      <AutoComplete
        {...props}
        showSearch
        placeholder="Search NPI or name"
        onSearch={handleProviderSearch}
        onChange={(value, op) => {
          if (props.onChange) props.onChange(value, op);
        }}
      >
        {(searchProvidersQueryResults.data?.providers || []).map((p) => (
          <AutoComplete.Option key={p.provider_id} value={p.provider_id}>
            {p.provider_id}
            <br />
            <Typography.Text type="secondary">
              <small>
                <i>{p.provider_name}</i>
              </small>
            </Typography.Text>
          </AutoComplete.Option>
        ))}
      </AutoComplete>
    );
  }
);

const OrganizationSearchSelect: React.FC<
  SelectProps<string>
> = React.forwardRef((props, ref) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const {
    searchOrganizationsQueryResults,
    handleOrganizationSearch,
  } = useDebouncedSearchOrganizations();
  return (
    <Select
      {...props}
      showSearch
      filterOption={false}
      placeholder={
        props.placeholder || `Search for organization${props.mode ? 's' : ''}`
      }
      onSearch={handleOrganizationSearch}
      labelInValue
      optionLabelProp="label"
      onChange={(value, op) => {
        searchOrganizationsQueryResults.called &&
          searchOrganizationsQueryResults.updateQuery(() => {
            return { organizations: [] };
          });
        setShowDropdown(false);
        if (props.onChange) props.onChange(value, op);
      }}
      tagRender={(props) => (
        <OrganizationTag
          closable
          onClose={props.onClose}
          organization={{
            organization_id: props.value.toString(),
            organization_name: (props.label || '').toString(),
            dashboards: [],
            is_archived: false,
          }}
        />
      )}
      notFoundContent={
        searchOrganizationsQueryResults.loading ? (
          <Spin size="small" />
        ) : showDropdown &&
          searchOrganizationsQueryResults.data?.organizations &&
          !searchOrganizationsQueryResults.data?.organizations.length ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Results"
          />
        ) : null
      }
    >
      {(searchOrganizationsQueryResults.data?.organizations || []).map(
        (org) => (
          <Select.Option
            key={org.organization_id}
            label={org.organization_name}
            value={org.organization_id}
          >
            {org.organization_name}
            <br />
            <Typography.Text type="secondary">
              <small>
                <i>{org.organization_description}</i>
              </small>
            </Typography.Text>
          </Select.Option>
        )
      )}
    </Select>
  );
});

const NetworkSearchSelect: React.FC<SelectProps<string>> = React.forwardRef(
  (props, ref) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const {
      searchNetworksQueryResults,
      handleNetworkSearch,
    } = useDebouncedSearchNetworks();
    return (
      <Select
        {...props}
        showSearch
        filterOption={false}
        placeholder={
          props.placeholder || `Search for network${props.mode ? 's' : ''}`
        }
        onSearch={handleNetworkSearch}
        labelInValue
        optionLabelProp="label"
        onChange={(value, op) => {
          searchNetworksQueryResults.called &&
            searchNetworksQueryResults.updateQuery(() => {
              return { networks: [] };
            });
          setShowDropdown(false);
          if (props.onChange) props.onChange(value, op);
        }}
        tagRender={(props) => (
          <NetworkTag
            closable
            onClose={props.onClose}
            network={{
              network_id: props.value.toString(),
              network_name: (props.label || '').toString(),
              is_archived: false,
            }}
          />
        )}
        notFoundContent={
          searchNetworksQueryResults.loading ? (
            <Spin size="small" />
          ) : showDropdown &&
            searchNetworksQueryResults.data?.networks &&
            !searchNetworksQueryResults.data?.networks.length ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Results"
            />
          ) : null
        }
      >
        {(searchNetworksQueryResults.data?.networks || []).map((d) => (
          <Select.Option
            key={d.network_id}
            label={d.network_name}
            value={d.network_id}
          >
            {d.network_name}
            <br />
            <Typography.Text type="secondary">
              <small>
                <i>{d.network_description}</i>
              </small>
            </Typography.Text>
          </Select.Option>
        ))}
      </Select>
    );
  }
);

const PracticeSearchSelect: React.FC<SelectProps<string>> = React.forwardRef(
  (props, ref) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const {
      searchPracticesQueryResults,
      handlePracticeSearch,
    } = useDebouncedSearchPractices(false);
    return (
      <Select
        {...props}
        showSearch
        filterOption={false}
        placeholder={
          props.placeholder || `Search for practice${props.mode ? 's' : ''}`
        }
        onSearch={handlePracticeSearch}
        labelInValue
        optionLabelProp="label"
        onChange={(value, op) => {
          searchPracticesQueryResults.called &&
            searchPracticesQueryResults.updateQuery(() => {
              return { practices: [] };
            });
          setShowDropdown(false);
          if (props.onChange) props.onChange(value, op);
        }}
        tagRender={(props) => (
          <PracticeTag
            closable
            onClose={props.onClose}
            practice={{
              practice_id: props.value.toString(),
              practice_name: (props.label || '').toString(),
              is_archived: false,
            }}
          />
        )}
        notFoundContent={
          searchPracticesQueryResults.loading ? (
            <Spin size="small" />
          ) : showDropdown &&
            searchPracticesQueryResults.data?.practices &&
            !searchPracticesQueryResults.data?.practices.length ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Results"
            />
          ) : null
        }
      >
        {(searchPracticesQueryResults.data?.practices || []).map((d) => (
          <Select.Option
            key={d.practice_id}
            label={d.practice_name}
            value={d.practice_id}
          >
            {d.practice_name}
            <br />
            <Typography.Text type="secondary">
              <small>
                <i>{d.practice_description}</i>
              </small>
            </Typography.Text>
          </Select.Option>
        ))}
      </Select>
    );
  }
);

const ProviderSearchSelect: React.FC<SelectProps<string>> = React.forwardRef(
  (props, ref) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [
      searchProvidersQuery,
      searchProvidersQueryResults,
    ] = useSearchProvidersLazyQuery({
      fetchPolicy: 'cache-and-network',
      onCompleted: () => setShowDropdown(true),
    });
    const searchProvidersDebounced = useConstant<(val: string) => void>(() =>
      AwesomeDebouncePromise((val) => {
        return searchProvidersQuery({
          variables: { query: val ? `%${val}%` : '' },
        });
      }, DEBOUNCE_MS)
    );
    const handleProviderSearch = async (query: string) => {
      await searchProvidersDebounced(query.trim());
    };
    return (
      <Select
        {...props}
        placeholder={props.placeholder || 'Search by name or npi'}
        notFoundContent={
          searchProvidersQueryResults.loading ? (
            <Spin size="small" />
          ) : showDropdown &&
            searchProvidersQueryResults.data?.providers &&
            !searchProvidersQueryResults.data?.providers.length ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Results"
            />
          ) : null
        }
        filterOption={false}
        onSearch={handleProviderSearch}
        labelInValue
        optionLabelProp="label"
        onChange={(value, op) => {
          searchProvidersQueryResults.called &&
            searchProvidersQueryResults.updateQuery(() => {
              return { providers: [] };
            });
          setShowDropdown(false);
          if (props.onChange) props.onChange(value, op);
        }}
        tagRender={(props) => (
          <ProviderTag
            closable
            onClose={props.onClose}
            provider={{
              provider_id: props.value.toString(),
              provider_name: (props.label || '').toString(),
              is_archived: false,
            }}
          />
        )}
      >
        {(searchProvidersQueryResults.data?.providers || []).map((d) => (
          <Select.Option
            key={d.provider_id}
            value={d.provider_id}
            label={d.provider_name}
          >
            {d.provider_name}
            <br />
            <Typography.Text type="secondary">
              <small>
                <i>npi: {d.provider_id}</i>
              </small>
            </Typography.Text>
          </Select.Option>
        ))}
      </Select>
    );
  }
);

const ActiveParticipantSearchSelect: React.FC<
  SelectProps<string> & { signature_date: string } & { dc_id: string }
> = React.forwardRef((props, ref) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [
    searchActiveParticipantsQuery,
    searchActiveParticipantsQueryResults,
  ] = useSearchActiveParticipantsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setShowDropdown(true),
  });
  const searchActiveParticipantsDebounced = useConstant<
    (dc_id: string, query: string, signature_date: string) => void
  >(() =>
    AwesomeDebouncePromise((dc_id, query, signature_date) => {
      if (dc_id && signature_date) {
        return searchActiveParticipantsQuery({
          variables: {
            dc_id: dc_id,
            prefix_query: query ? `${query}%` : '',
            substring_query: query ? `%${query}%` : '',
            signature_date: signature_date,
          },
        });
      } else {
        return [];
      }
    }, DEBOUNCE_MS)
  );
  const handleActiveParticipantSearch = async (
    dce_id: string,
    name: string,
    signature_date: string
  ) => {
    await searchActiveParticipantsDebounced(
      dce_id,
      name.trim(),
      signature_date
    );
  };
  return (
    <Select
      {...props}
      placeholder={props.placeholder || 'Search for by name or NPI'}
      notFoundContent={
        searchActiveParticipantsQueryResults.loading ? (
          <Spin size="small" />
        ) : showDropdown &&
          searchActiveParticipantsQueryResults.data
            ?.analytics_analytics_cms_active_dce_participants &&
          !searchActiveParticipantsQueryResults.data
            ?.analytics_analytics_cms_active_dce_participants.length ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Results"
          />
        ) : null
      }
      filterOption={false}
      showSearch
      onSearch={(val) => {
        handleActiveParticipantSearch(props.dc_id, val, props.signature_date);
      }}
      labelInValue
      optionLabelProp="label"
      onChange={(value, op) => {
        searchActiveParticipantsQueryResults.called &&
          searchActiveParticipantsQueryResults.updateQuery(() => {
            return { analytics_analytics_cms_active_dce_participants: [] };
          });
        setShowDropdown(false);
        if (props.onChange) props.onChange(value, op);
      }}
      options={(
        searchActiveParticipantsQueryResults.data
          ?.analytics_analytics_cms_active_dce_participants || []
      ).map((d) => ({
        key: d.cms_dce_participant_id!,
        value: d.cms_dce_participant_id!,
        participant: d,
        label: (
          <>
            {d.legal_business_name} <br />
            <Typography.Text type="secondary">
              <small>
                <Space>
                  <span>NPI:{d.individual_npi}</span>
                  <span>TIN:{d.tin}</span>
                </Space>
                <br />
                <i>
                  Effective {dayjs(d.effective_start_date).format('MM/DD/YY')} -{' '}
                  {dayjs(d.effective_end_date).format('MM/DD/YY')}
                </i>
              </small>
            </Typography.Text>
          </>
        ),
      }))}
    />
  );
});

export {
  ProviderSearchAutocomplete,
  ProviderSearchSelect,
  PracticeSearchAutocomplete,
  PracticeSearchSelect,
  NetworkSearchSelect,
  OrganizationSearchSelect,
  ActiveParticipantSearchSelect,
  DEBOUNCE_MS,
};
