import React, { useState } from 'react';
import {
  Descriptions,
  Tooltip,
  Typography,
  Form,
  Modal,
  Table,
  Col,
  Row,
  Button,
  Space,
  message,
} from 'antd';

import {
  NetworkDetailsFragment,
  useListAlignmentUploadsQuery,
  useListParticipantUploadQuery,
  useListProviderAlignmentUploadsQuery,
  useListPvaResponseUploadQuery,
  useListHedrTemplateUploadsQuery,
  useUpdateNetworkMutation,
} from '../../graphql/generated';
import PracticeTag from '../practices/PracticeTag';
import SwitchRoleButton from '../utils/SwitchRoleButton';
import KeyValueForm from '../utils/KeyValueForm';
import {
  FileOutlined,
  FileSyncOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { StethoscopeOutlined, UserInjuredOutlined } from '../MabelIcons';
import CMSUploadModal from '../utils/CMSUploadModal';
import SignedAWSURL from '../utils/SignedAWSURL';

const NetworkDescription: React.FC<{ network: NetworkDetailsFragment }> = ({
  network,
}) => {
  const [
    updateNetworkMutation,
    updateNetworkMutationResult,
  ] = useUpdateNetworkMutation();

  const [mergeVarModalVisible, setMergeVarModalVisible] = useState(false);
  const [mergeVarForm] = Form.useForm();
  return (
    <Descriptions
      title={network.network_name}
      layout="horizontal"
      column={1}
      style={{ margin: '24px 48px' }}
    >
      <Descriptions.Item label="ID">{network.network_id}</Descriptions.Item>
      <Descriptions.Item label="Network Number">
        {network.network_number}
      </Descriptions.Item>
      <Descriptions.Item label="ACO ID">{network.dc_id}</Descriptions.Item>
      <Descriptions.Item label="Slug">{network.network_slug}</Descriptions.Item>
      <Descriptions.Item label="Description">
        {network.network_description}
      </Descriptions.Item>
      <Descriptions.Item label="Default Practice">
        {network.default_practice && (
          <PracticeTag practice={network.default_practice} />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Broker Phone (emails)">
        {network.broker_phone_for_emails}
      </Descriptions.Item>
      <Descriptions.Item label="Broker Phone (letters)">
        {network.broker_phone_for_letters}
      </Descriptions.Item>
      <Descriptions.Item label="Broker URL (emails)">
        {network.broker_url_for_emails}
      </Descriptions.Item>
      <Descriptions.Item label="Broker URL (letters)">
        {network.broker_url_for_letters}
      </Descriptions.Item>
      <Descriptions.Item label="Broker Disclaimer">
        {network.broker_disclaimer}
      </Descriptions.Item>
      <Descriptions.Item label="Logo" style={{ display: 'flex' }}>
        {network.network_logo ? (
          <img
            src={network.network_logo}
            style={{ width: 320, height: 120 }}
            alt="network logo"
          />
        ) : null}
      </Descriptions.Item>
      <Descriptions.Item label="Color" style={{ display: 'flex' }}>
        {network.network_color ? (
          <div
            style={{
              padding: 4,
              margin: 2,
              background: '#fff',
              borderRadius: '2px',
              boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
              display: 'inline-block',
            }}
          >
            <div
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '2px',
                background: network.network_color,
              }}
            />
          </div>
        ) : null}
      </Descriptions.Item>
      <Descriptions.Item label="Network Variables">
        <Tooltip
          title={
            <pre>
              {JSON.stringify(network.network_merge_vars || {}, null, 2)}
            </pre>
          }
        >
          <Typography.Text
            ellipsis
            code
            editable={{
              tooltip: false,
              editing: false,
              onStart: () => {
                setMergeVarModalVisible(true);
              },
            }}
          >
            {JSON.stringify(network.network_merge_vars || {}, null, 2)}
          </Typography.Text>
        </Tooltip>
        <Modal
          visible={mergeVarModalVisible}
          title={
            <Typography.Text>
              Modify Network Variables{' '}
              <Tooltip title="Values will only be used if not defined at the practice, campaign, component, or patient level">
                <QuestionCircleOutlined />
              </Tooltip>
            </Typography.Text>
          }
          okText="Save"
          cancelText="Cancel"
          confirmLoading={updateNetworkMutationResult.loading}
          onCancel={() => {
            mergeVarForm.resetFields();
            setMergeVarModalVisible(false);
          }}
          onOk={mergeVarForm.submit}
        >
          <KeyValueForm
            form={mergeVarForm}
            initialValues={network.network_merge_vars}
            onFinish={(vars) => {
              updateNetworkMutation({
                variables: {
                  network_id: network.network_id,
                  changes: { network_merge_vars: vars },
                },
              }).then(() => setMergeVarModalVisible(false));
            }}
          />
        </Modal>
      </Descriptions.Item>
      <Descriptions.Item label="4i Files Loaded">
        {network.dc_id && (
          <CMSTables network_id={network.network_id} dc_id={network.dc_id} />
        )}
      </Descriptions.Item>
      <Descriptions.Item>
        <SwitchRoleButton
          network_id={network.network_id}
          changeRole="network_user"
        />
      </Descriptions.Item>
    </Descriptions>
  );
};

const CMSTables: React.FC<{ network_id: string; dc_id: string }> = ({
  network_id,
  dc_id,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileCategory, setfileCategory] = useState<
    | 'hedr_submission_templates'
    | 'cms_alignment_reports'
    | 'cms_provider_alignment_reports'
    | 'cms_dce_participants'
    | 'cms_pva_responses'
    | undefined
  >(undefined);

  const {
    data: pvaResponseData,
    loading: pvaResponseDataLoading,
    refetch: pvaResponseRefetch,
  } = useListPvaResponseUploadQuery({
    variables: { DC_ID: dc_id },
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: participantResponseData,
    loading: participantResponseDataLoading,
    refetch: participantResponseRefetch,
  } = useListParticipantUploadQuery({
    variables: { DC_ID: dc_id },
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: alignmentResponseData,
    loading: alignmentResponseDataLoading,
    refetch: alignmentResponseRefetch,
  } = useListAlignmentUploadsQuery({
    variables: { DC_ID: dc_id },
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: providerAlignmentResponseData,
    loading: providerAlignmentResponseDataLoading,
    refetch: providerAlignmentRefetch,
  } = useListProviderAlignmentUploadsQuery({
    variables: { DC_ID: dc_id },
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: hedrTemplateResponseData,
    loading: hedrTemplateResponseDataLoading,
    refetch: hedrTemplateResponseRefetch,
  } = useListHedrTemplateUploadsQuery({
    variables: { DC_ID: dc_id },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <div>
      <Row gutter={[4, 40]}>
        <Col xs={24} sm={24} lg={12}>
          <Table
            title={() => (
              <span>
                <UserInjuredOutlined /> Beneficiary Alignment Reports (ALGC)
                <span style={{ float: 'right' }}>
                  <Space direction="horizontal">
                    <Button
                      type="default"
                      size="small"
                      icon={<ReloadOutlined />}
                      onClick={() => alignmentResponseRefetch()}
                    />
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        setIsModalVisible(true);
                        setfileCategory('cms_alignment_reports');
                      }}
                      icon={<UploadOutlined />}
                    >
                      Upload
                    </Button>
                  </Space>
                </span>
              </span>
            )}
            loading={alignmentResponseDataLoading}
            bordered
            size="small"
            scroll={{ x: true, y: 120 }}
            pagination={false}
            dataSource={alignmentResponseData?.cms_alignment_reports}
            columns={[
              { title: 'ACO ID', dataIndex: 'dc_id', key: 'dc_id', width: 70 },
              {
                title: 'Performance Year',
                dataIndex: 'performance_year',
                key: 'performance_year',
                width: 160,
                sorter: (a, b) => a.performance_year - b.performance_year,
                defaultSortOrder: 'descend',
              },
              {
                title: 'Updated At',
                dataIndex: 'updated_at',
                key: 'updated_at',
                sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
                defaultSortOrder: 'descend',
                width: 120,
                render: (text) => (
                  <Tooltip title={new Date(text).toLocaleString()}>
                    <Typography.Text ellipsis style={{ width: 90 }}>
                      {new Date(text).toLocaleDateString()}
                    </Typography.Text>
                  </Tooltip>
                ),
              },
              {
                title: 'File Name',
                dataIndex: 'file_upload_id',
                key: 'file_upload_id',
                render: (_, record) =>
                  record.file_upload ? (
                    <Typography.Text ellipsis style={{ width: 150 }}>
                      <SignedAWSURL file={record.file_upload} />
                    </Typography.Text>
                  ) : (
                    <Typography.Text
                      ellipsis
                      style={{ width: 150 }}
                      type="secondary"
                    >
                      {record.file_upload_id}
                    </Typography.Text>
                  ),
              },
            ]}
            rowKey={(record) =>
              `${record.dc_id}:${record.performance_year}:${record.file_upload_id}`
            }
          />
        </Col>
        <Col xs={24} sm={24} lg={12}>
          <Table
            title={() => (
              <span>
                Provider Alignment Reports (PALMR)
                <span style={{ float: 'right' }}>
                  <Space direction="horizontal">
                    <Button
                      type="default"
                      size="small"
                      icon={<ReloadOutlined />}
                      style={{ float: 'right' }}
                      onClick={() => providerAlignmentRefetch()}
                    />
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        setIsModalVisible(true);
                        setfileCategory('cms_provider_alignment_reports');
                      }}
                      icon={<UploadOutlined />}
                    >
                      Upload
                    </Button>
                  </Space>
                </span>
              </span>
            )}
            loading={providerAlignmentResponseDataLoading}
            bordered
            size="small"
            scroll={{ x: true, y: 120 }}
            pagination={false}
            dataSource={
              providerAlignmentResponseData?.cms_provider_alignment_reports
            }
            columns={[
              {
                title: 'ACO ID',
                dataIndex: 'ACO_ID',
                key: 'ACO_ID',
                width: 70,
              },
              {
                title: 'Performance Year',
                dataIndex: 'PERFORMANCE_YEAR',
                key: 'PERFORMANCE_YEAR',
                width: 160,
                sorter: (a, b) => a.PERFORMANCE_YEAR - b.PERFORMANCE_YEAR,
                defaultSortOrder: 'descend',
              },
              {
                title: 'Updated At',
                dataIndex: 'updated_at',
                key: 'updated_at',
                sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
                defaultSortOrder: 'descend',
                width: 120,
                render: (text) => (
                  <Tooltip title={new Date(text).toLocaleString()}>
                    <Typography.Text ellipsis style={{ width: 90 }}>
                      {new Date(text).toLocaleDateString()}
                    </Typography.Text>
                  </Tooltip>
                ),
              },
              {
                title: 'File Name',
                dataIndex: 'file_upload_id',
                key: 'file_upload_id',
                render: (_, record) =>
                  record.file_upload ? (
                    <Typography.Text ellipsis style={{ width: 150 }}>
                      <SignedAWSURL file={record.file_upload} />
                    </Typography.Text>
                  ) : (
                    <Typography.Text
                      ellipsis
                      style={{ width: 150 }}
                      type="secondary"
                    >
                      {record.file_upload_id}
                    </Typography.Text>
                  ),
              },
            ]}
            rowKey={(record) =>
              `${record.ACO_ID}:${record.PERFORMANCE_YEAR}:${record.file_upload_id}`
            }
          />
        </Col>
        <Col xs={24} sm={24} lg={12}>
          <Table
            title={() => (
              <span>
                <FileSyncOutlined /> SVA Response Files (PBVAR)
                <span style={{ float: 'right' }}>
                  <Space direction="horizontal">
                    <Button
                      type="default"
                      size="small"
                      icon={<ReloadOutlined />}
                      style={{ float: 'right' }}
                      onClick={() => pvaResponseRefetch()}
                    />
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        setIsModalVisible(true);
                        setfileCategory('cms_pva_responses');
                      }}
                      icon={<UploadOutlined />}
                    >
                      Upload
                    </Button>
                  </Space>
                </span>
              </span>
            )}
            loading={pvaResponseDataLoading}
            bordered
            size="small"
            scroll={{ x: true, y: 120 }}
            pagination={false}
            dataSource={pvaResponseData?.cms_pva_responses}
            columns={[
              {
                title: 'ACO ID',
                dataIndex: 'DCE_ID',
                key: 'DCE_ID',
                width: 70,
              },
              {
                title: 'Submission Date',
                dataIndex: 'SUBMISSION_DATE',
                key: 'SUBMISSION_DATE',
                width: 160,
                sorter: (a, b) =>
                  a.SUBMISSION_DATE.localeCompare(b.SUBMISSION_DATE),
                defaultSortOrder: 'descend',
              },
              {
                title: 'Created At',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: (a, b) => a.created_at.localeCompare(b.created_at),
                defaultSortOrder: 'descend',
                width: 120,
                render: (text) => (
                  <Tooltip title={new Date(text).toLocaleString()}>
                    <Typography.Text ellipsis style={{ width: 90 }}>
                      {new Date(text).toLocaleDateString()}
                    </Typography.Text>
                  </Tooltip>
                ),
              },
              {
                title: 'File Name',
                dataIndex: 'file_upload_id',
                key: 'file_upload_id',
                render: (_, record) =>
                  record.file_upload ? (
                    <Typography.Text ellipsis style={{ width: 150 }}>
                      <SignedAWSURL file={record.file_upload} />
                    </Typography.Text>
                  ) : (
                    <Typography.Text
                      ellipsis
                      style={{ width: 150 }}
                      type="secondary"
                    >
                      {record.file_upload_id}
                    </Typography.Text>
                  ),
              },
            ]}
            rowKey={(record) =>
              `${record.DCE_ID}:${record.SUBMISSION_DATE}:${record.file_upload_id}`
            }
          />
        </Col>
        <Col xs={24} sm={24} lg={12}>
          <Table
            title={() => (
              <span>
                <StethoscopeOutlined /> Participant Lists
                <span style={{ float: 'right' }}>
                  <Space direction="horizontal">
                    <Button
                      type="default"
                      size="small"
                      icon={<ReloadOutlined />}
                      style={{ float: 'right' }}
                      onClick={() => participantResponseRefetch()}
                    />
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        setIsModalVisible(true);
                        setfileCategory('cms_dce_participants');
                      }}
                      icon={<UploadOutlined />}
                    >
                      Upload
                    </Button>
                  </Space>
                </span>
              </span>
            )}
            loading={participantResponseDataLoading}
            bordered
            size="small"
            scroll={{ x: true, y: 120 }}
            pagination={false}
            dataSource={participantResponseData?.cms_dce_participants}
            columns={[
              {
                title: 'ACO ID',
                dataIndex: 'entity_id',
                key: 'entity_id',
                width: 70,
              },
              {
                title: 'Performance Year',
                dataIndex: 'performance_year',
                key: 'performance_year',
                width: 160,
                sorter: (a, b) =>
                  (a.performance_year || '').localeCompare(
                    b.performance_year || ''
                  ),
                defaultSortOrder: 'descend',
              },
              {
                title: 'Created At',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: (a, b) => a.created_at.localeCompare(b.created_at),
                defaultSortOrder: 'descend',
                width: 120,
                render: (text) => (
                  <Tooltip title={new Date(text).toLocaleString()}>
                    <Typography.Text ellipsis style={{ width: 90 }}>
                      {new Date(text).toLocaleDateString()}
                    </Typography.Text>
                  </Tooltip>
                ),
              },
              {
                title: 'File Name',
                dataIndex: 'file_upload_id',
                key: 'file_upload_id',
                render: (_, record) =>
                  record.file_upload ? (
                    <Typography.Text ellipsis style={{ width: 150 }}>
                      <SignedAWSURL file={record.file_upload} />
                    </Typography.Text>
                  ) : (
                    <Typography.Text
                      ellipsis
                      style={{ width: 150 }}
                      type="secondary"
                    >
                      {record.file_upload_id}
                    </Typography.Text>
                  ),
              },
            ]}
            rowKey={(record) =>
              `${record.entity_id}:${record.performance_year}:${record.file_upload_id}`
            }
          />
        </Col>
        <Col xs={24} sm={24} lg={12}>
          <Table
            title={() => (
              <span>
                <FileOutlined /> HEDR Submission Templates
                <span style={{ float: 'right' }}>
                  <Space direction="horizontal">
                    <Button
                      type="default"
                      size="small"
                      icon={<ReloadOutlined />}
                      style={{ float: 'right' }}
                      onClick={() => hedrTemplateResponseRefetch()}
                    />
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        setIsModalVisible(true);
                        setfileCategory('hedr_submission_templates');
                      }}
                      icon={<UploadOutlined />}
                    >
                      Upload
                    </Button>
                  </Space>
                </span>
              </span>
            )}
            loading={hedrTemplateResponseDataLoading}
            bordered
            size="small"
            scroll={{ x: true, y: 120 }}
            pagination={false}
            dataSource={hedrTemplateResponseData?.hedr_submission_templates}
            columns={[
              {
                title: 'ACO ID',
                dataIndex: 'entity_id',
                key: 'entity_id',
                width: 70,
              },
              {
                title: 'Performance Year',
                dataIndex: 'performance_year',
                key: 'performance_year',
                width: 160,
                sorter: (a, b) => a.performance_year - b.performance_year,
                defaultSortOrder: 'descend',
              },
              {
                title: 'Created At',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: (a, b) => a.created_at.localeCompare(b.created_at),
                defaultSortOrder: 'descend',
                width: 120,
                render: (text) => (
                  <Tooltip title={new Date(text).toLocaleString()}>
                    <Typography.Text ellipsis style={{ width: 90 }}>
                      {new Date(text).toLocaleDateString()}
                    </Typography.Text>
                  </Tooltip>
                ),
              },
              {
                title: 'File Name',
                dataIndex: 'file_upload_id',
                key: 'file_upload_id',
                render: (_, record) =>
                  record.file_upload ? (
                    <Typography.Text ellipsis style={{ width: 150 }}>
                      <SignedAWSURL file={record.file_upload} />
                    </Typography.Text>
                  ) : (
                    <Typography.Text
                      ellipsis
                      style={{ width: 150 }}
                      type="secondary"
                    >
                      {record.file_upload_id}
                    </Typography.Text>
                  ),
              },
            ]}
            rowKey={(record) =>
              `${record.entity_id}:${record.performance_year}:${record.file_upload_id}`
            }
          />
        </Col>
      </Row>
      <CMSUploadModal
        network_id={network_id}
        dc_id={dc_id}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onUpload={(file) => {
          setIsModalVisible(false);
          message.success(`${file.name} uploaded successfully`);
          if (fileCategory === 'cms_alignment_reports') {
            alignmentResponseRefetch();
          } else if (fileCategory === 'cms_provider_alignment_reports') {
            providerAlignmentRefetch();
          } else if (fileCategory === 'cms_dce_participants') {
            participantResponseRefetch();
          } else if (fileCategory === 'cms_pva_responses') {
            pvaResponseRefetch();
          } else if (fileCategory === 'hedr_submission_templates') {
            hedrTemplateResponseRefetch();
          }
        }}
        fileCategory={fileCategory}
      />
    </div>
  );
};

export default NetworkDescription;
