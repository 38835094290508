import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import dayjs, { Dayjs } from 'dayjs';

import {
  Button,
  Typography,
  Form,
  Modal,
  Select,
  Row,
  Col,
  Space,
  Tooltip,
  Card,
  Tag,
  Input,
  message,
  Checkbox,
} from 'antd';
import { DatePicker } from '../../src/components';
import { SelectProps } from 'antd/lib/select/';

import {
  useListPatientSegmentsQuery,
  useListLetterTemplatesQuery,
  useListEmailTemplatesQuery,
  useListSmsTemplatesQuery,
  useUpdateCampaignTemplateMutation,
  Campaign_Templates_Set_Input,
  Campaign_Letter_Components_Set_Input,
  Campaign_Email_Components_Set_Input,
  Campaign_Sms_Components_Set_Input,
  useCreateCampaignEmailComponentMutation,
  useUpdateCampaignEmailComponentMutation,
  useCreateCampaignLetterComponentMutation,
  useUpdateCampaignLetterComponentMutation,
  useCreateCampaignSmsComponentMutation,
  useUpdateCampaignSmsComponentMutation,
  CampaignTemplateDetailsFragment,
  CampaignTemplateDetailsFragmentDoc,
  CampaignEmailComponentDetailsFragment,
  CampaignLetterComponentDetailsFragment,
  CampaignSmsComponentDetailsFragment,
  useCreateComponentTrackingIdMutation,
  useDeleteComponentTrackingIdMutation,
} from '../graphql/generated';
import { cacheIdFromObject } from '../graphql/utils';

import {
  PlusOutlined,
  DeleteOutlined,
  MailOutlined,
  EditOutlined,
  ArrowLeftOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { PaperPlaneOutlined } from '../components/MabelIcons';
import KeyValueForm from '../components/utils/KeyValueForm';
import { Link } from 'react-router-dom';
import { mabel_dark_grey } from '../colors';

type RecurrenceDuration = 'never' | 'week' | 'fortnight' | 'month';

const CampaignEditPage: React.FC<{
  campaign: CampaignTemplateDetailsFragment;
}> = ({ campaign }) => {
  const [
    updateTemplateMutation,
    updateCampaignTemplateMutationResult,
  ] = useUpdateCampaignTemplateMutation();

  const handleTemplateUpdate = (
    changes: Campaign_Templates_Set_Input,
    onFinish = () => {}
  ) => {
    const changedFields = Object.keys(changes) as Array<
      keyof Campaign_Templates_Set_Input
    >;
    if (changedFields.some((k) => (campaign as any)[k] !== changes[k])) {
      updateTemplateMutation({
        variables: {
          campaign_template_id: campaign.campaign_template_id,
          changes: changes,
        },
        optimisticResponse: {
          __typename: 'mutation_root',
          update_campaign_templates_by_pk: {
            ...(campaign as any),
            ...changes,
          },
        },
      }).then(onFinish);
    } else {
      onFinish();
    }
  };

  const [campaignMergeVarsForm] = Form.useForm();
  const [mergeVarModalVisible, setMergeVarModalVisible] = useState(false);

  const [componentMergeVarsForm] = Form.useForm();

  const [createLetterComponentForm] = Form.useForm();
  const [letterModalVisible, setLetterModalVisible] = useState(false);
  const [
    createLetterComponentMutation,
    createLetterComponentMutationResult,
  ] = useCreateCampaignLetterComponentMutation();

  const [createEmailComponentForm] = Form.useForm();
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [
    createEmailComponentMutation,
    createEmailComponentMutationResult,
  ] = useCreateCampaignEmailComponentMutation();

  const [createSMSComponentForm] = Form.useForm();
  const [SMSModalVisible, setSMSModalVisible] = useState(false);
  const [
    createSMSComponentMutation,
    createSMSComponentMutationResult,
  ] = useCreateCampaignSmsComponentMutation();

  const MAX_REPEATS = 52;

  const computeSendDates = (
    send_datetime: Dayjs,
    repeatPeriod: RecurrenceDuration,
    repeatCount: string
  ) => {
    const n = Math.max(Math.min(parseInt(repeatCount) || 0, MAX_REPEATS), 0);
    switch (repeatPeriod) {
      case 'never':
        return [send_datetime];
      case 'fortnight':
        return [...Array(n).keys()].map((i) =>
          send_datetime.add(2 * i, 'week')
        );
      case 'week':
      case 'month':
        return [...Array(n).keys()].map((i) =>
          send_datetime.add(i, repeatPeriod)
        );
    }
  };

  return (
    <>
      <Helmet>
        <title>Campaign Edit | Mabel</title>
      </Helmet>
      <div style={{ margin: '0 24px 20px' }}>
        <Link to="/campaigns">
          <ArrowLeftOutlined /> Back to Campaigns
        </Link>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Typography.Text style={{ color: mabel_dark_grey }}>
          <i>
            {campaign.network ? 'Network' : 'Practice'}-level campaign for{' '}
            <Tooltip
              title={
                campaign.network?.network_description ||
                campaign.practice?.practice_description ||
                ''
              }
            >
              <b>
                {campaign.network?.network_name ||
                  campaign.practice?.practice_name}
              </b>
            </Tooltip>
          </i>
        </Typography.Text>
        <Typography.Title
          level={4}
          editable={{
            onChange: (value) => {
              if (value)
                handleTemplateUpdate({
                  campaign_name: value,
                });
            },
          }}
        >
          {campaign.campaign_name}
        </Typography.Title>
        <Typography.Paragraph
          editable={{
            onChange: (value) => {
              handleTemplateUpdate({
                campaign_description: value,
              });
            },
          }}
        >
          {campaign.campaign_description}
        </Typography.Paragraph>
        <Typography.Title level={5}>Campaign Variables: </Typography.Title>
        <Typography.Text code ellipsis style={{ maxWidth: 600 }}>
          <Tooltip
            title={
              <pre>
                {JSON.stringify(campaign.campaign_merge_vars || {}, null, 2)}
              </pre>
            }
          >
            {JSON.stringify(campaign.campaign_merge_vars || {})}
          </Tooltip>
        </Typography.Text>
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => setMergeVarModalVisible(true)}
        />
        <Modal
          visible={mergeVarModalVisible}
          title="Modify campaign variables"
          okText="Save"
          confirmLoading={updateCampaignTemplateMutationResult.loading}
          cancelText="Cancel"
          onCancel={() => {
            campaignMergeVarsForm.resetFields();
            setMergeVarModalVisible(false);
          }}
          onOk={campaignMergeVarsForm.submit}
        >
          <KeyValueForm
            form={campaignMergeVarsForm}
            initialValues={campaign.campaign_merge_vars}
            onFinish={(vars) => {
              handleTemplateUpdate({ campaign_merge_vars: vars }, () =>
                setMergeVarModalVisible(false)
              );
            }}
          />
        </Modal>
      </div>
      <div style={{ margin: 16 }} />
      <Typography.Title level={4}>
        <PaperPlaneOutlined /> Letter Components:
      </Typography.Title>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Space
          direction="vertical"
          size="middle"
          style={{ marginLeft: 16, width: '100%' }}
        >
          {campaign.campaign_letter_components
            .filter((component) => !component.is_archived)
            .map((component, index) => (
              <Card key={component.campaign_letter_component_id}>
                <EditableLetterComponent letter_component={component} />
              </Card>
            ))}
        </Space>
        <div style={{ textAlign: 'center', marginBottom: 24 }}>
          <Button
            type="dashed"
            style={{ maxWidth: 350 }}
            onClick={() => setLetterModalVisible(true)}
          >
            <PlusOutlined /> Add letter component <PaperPlaneOutlined />
          </Button>
          <Modal
            visible={letterModalVisible}
            title="Add a letter component to this campaign"
            okText="Create"
            cancelText="Cancel"
            confirmLoading={createLetterComponentMutationResult.loading}
            onCancel={() => setLetterModalVisible(false)}
            onOk={() => {
              createLetterComponentForm
                .validateFields()
                .then((values) => {
                  const mergeVars = Object.fromEntries(
                    componentMergeVarsForm?.getFieldValue('key_val_pairs')
                      ? componentMergeVarsForm
                          ?.getFieldValue('key_val_pairs')
                          .filter((x: any) => !!x && x.variableKey !== '')
                          .map((v: any) => [
                            v.variableKey,
                            v.variableValue || '',
                          ])
                      : []
                  );

                  const sendDates = computeSendDates(
                    values.send_datetime,
                    values.repeatPeriod,
                    values.repeatCount
                  );

                  const trackingIDs = values.tracking_id?.trim()
                    ? [{ tracking_id: values.tracking_id?.trim() }]
                    : [];

                  sendDates.forEach((sd) => {
                    createLetterComponentMutation({
                      variables: {
                        campaign_letter_component: {
                          campaign_template_id: campaign.campaign_template_id,
                          letter_template_id: values.letter_template_id,
                          patient_segment_id: values.patient_segment_id,
                          return_envelope_id: values.return_envelope_id,
                          is_first_class: values.is_first_class,
                          skip_undeliverable_addresses:
                            values.skip_undeliverable_addresses,
                          send_datetime: sd,
                          component_merge_vars: mergeVars,
                          tracking_ids: { data: trackingIDs },
                        },
                      },
                      update: (cache, { data }) => {
                        const cached_template = cache.readFragment<CampaignTemplateDetailsFragment>(
                          {
                            id: cacheIdFromObject(campaign) as string,
                            fragment: CampaignTemplateDetailsFragmentDoc,
                            fragmentName: 'CampaignTemplateDetails',
                          }
                        );
                        if (cached_template) {
                          cache.writeFragment<CampaignTemplateDetailsFragment>({
                            id: cacheIdFromObject(campaign) as string,
                            fragment: CampaignTemplateDetailsFragmentDoc,
                            fragmentName: 'CampaignTemplateDetails',
                            data: {
                              ...cached_template,
                              campaign_letter_components: cached_template.campaign_letter_components
                                .concat(
                                  data?.insert_campaign_letter_components_one ||
                                    []
                                )
                                .sort((a, b) =>
                                  a.send_datetime.localeCompare(b.send_datetime)
                                ),
                            },
                          });
                        }
                      },
                    });
                  });
                })
                .then(() => {
                  createLetterComponentForm?.resetFields();
                  componentMergeVarsForm?.resetFields();
                  setLetterModalVisible(false);
                })
                .catch(() => {});
            }}
          >
            <Form
              form={createLetterComponentForm}
              layout="vertical"
              name="create_letter_component_form"
              initialValues={{
                skip_undeliverable_addresses: true,
              }}
            >
              <Form.Item
                name="send_datetime"
                label="Send Date"
                rules={[
                  {
                    required: true,
                    message: 'Please select a date',
                  },
                ]}
              >
                <DatePicker
                  showTime={{ defaultValue: dayjs('09:30:00', 'HH:mm') }}
                  format="lll z"
                  style={{ width: 236 }}
                />
              </Form.Item>
              <Form.Item
                name="letter_template_id"
                label="Letter Template"
                rules={[
                  {
                    required: true,
                    message: 'Please select a template',
                  },
                ]}
              >
                <LetterTemplateSelect />
              </Form.Item>
              <Form.Item
                name="patient_segment_id"
                label="Patient Segment"
                rules={[
                  {
                    required: true,
                    message: 'Please select a patient segment',
                  },
                ]}
              >
                <PatientSegmentSelect />
              </Form.Item>
              <Form.Item name="return_envelope_id" label="Return Envelope ID">
                <Input placeholder="Enter an envelope ID" />
              </Form.Item>
              <Form.Item
                name="is_first_class"
                label="Send First Class?"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                name="skip_undeliverable_addresses"
                label="Skip Undeliverable Addresses?"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                name="repeatPeriod"
                label="Recurring"
                initialValue="never"
                rules={[{ required: true }]}
                style={{
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                }}
              >
                <Select
                  options={[
                    { value: 'never', label: 'Never' },
                    { value: 'week', label: 'Weekly' },
                    { value: 'fortnight', label: 'Fortnightly' },
                    { value: 'month', label: 'Monthly' },
                  ]}
                />
              </Form.Item>
              <Form.Item
                noStyle
                dependencies={['send_datetime', 'repeatPeriod', 'repeatCount']}
              >
                {({ getFieldValue }) => {
                  const sendDatetime = getFieldValue('send_datetime');
                  const repeatPeriod = getFieldValue('repeatPeriod');
                  const repeatCount = getFieldValue('repeatCount');
                  return (
                    repeatPeriod &&
                    repeatPeriod !== 'never' && (
                      <>
                        <Form.Item
                          name="repeatCount"
                          label="For..."
                          style={{
                            display: 'inline-block',
                            width: 'calc(50% - 8px)',
                            margin: '0 8px',
                          }}
                          rules={[
                            {
                              validator(_, value) {
                                const n = parseFloat(value);
                                if (!Number.isInteger(n))
                                  return Promise.reject('Number required');
                                if (n < 2)
                                  return Promise.reject(
                                    'Must enter at least 2'
                                  );
                                if (n > MAX_REPEATS)
                                  return Promise.reject(
                                    `Maximum of ${MAX_REPEATS} recurrences allowed`
                                  );
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          {/*
                        Using Input instead of InputNumber 
                        because the latter does not support suffix at present:

                        https://github.com/ant-design/ant-design/issues/14284
                      */}
                          <Input
                            type="number"
                            min={2}
                            max={MAX_REPEATS}
                            suffix={`${repeatPeriod}(s)`}
                          />
                        </Form.Item>
                        <Form.Item>
                          {repeatCount && sendDatetime ? (
                            <>
                              This component will send on:
                              <ul>
                                {computeSendDates(
                                  sendDatetime,
                                  repeatPeriod,
                                  repeatCount
                                ).map((sendDate, i) => (
                                  <li key={i}>{sendDate.format('lll z')}</li>
                                ))}
                              </ul>
                            </>
                          ) : null}
                        </Form.Item>
                      </>
                    )
                  );
                }}
              </Form.Item>
              <Form.Item
                name="component_merge_vars"
                label="Component Variables"
              >
                <KeyValueForm form={componentMergeVarsForm} />
              </Form.Item>
              <Form.Item name="tracking_id" label="Tracking ID">
                <Input placeholder="Enter a tracking ID" />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </Space>
      <Typography.Title level={4}>
        <MailOutlined /> Email Components:
      </Typography.Title>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Space
          direction="vertical"
          size="middle"
          style={{ marginLeft: 16, width: '100%' }}
        >
          {campaign.campaign_email_components
            .filter((component) => !component.is_archived)
            .map((component) => (
              <Card key={component.campaign_email_component_id}>
                <EditableEmailComponent email_component={component} />
              </Card>
            ))}
        </Space>
        <div style={{ textAlign: 'center', marginBottom: 24 }}>
          <Button
            type="dashed"
            style={{ maxWidth: 350 }}
            onClick={() => setEmailModalVisible(true)}
          >
            <PlusOutlined /> Add email component <MailOutlined />
          </Button>
          <Modal
            visible={emailModalVisible}
            title="Add an email component to this campaign"
            okText="Create"
            cancelText="Cancel"
            confirmLoading={createEmailComponentMutationResult.loading}
            onCancel={() => setEmailModalVisible(false)}
            onOk={() => {
              createEmailComponentForm
                .validateFields()
                .then((values) => {
                  const mergeVars = Object.fromEntries(
                    componentMergeVarsForm?.getFieldValue('key_val_pairs')
                      ? componentMergeVarsForm
                          ?.getFieldValue('key_val_pairs')
                          .filter((x: any) => !!x && x.variableKey !== '')
                          .map((v: any) => [
                            v.variableKey,
                            v.variableValue || '',
                          ])
                      : []
                  );

                  const sendDates = computeSendDates(
                    values.send_datetime,
                    values.repeatPeriod,
                    values.repeatCount
                  );

                  const trackingIDs = values.tracking_id?.trim()
                    ? [{ tracking_id: values.tracking_id?.trim() }]
                    : [];

                  sendDates.forEach((sd) => {
                    createEmailComponentMutation({
                      variables: {
                        campaign_email_component: {
                          campaign_template_id: campaign.campaign_template_id,
                          email_template_id: values.email_template_id,
                          patient_segment_id: values.patient_segment_id,
                          send_datetime: sd,
                          component_merge_vars: mergeVars,
                          tracking_ids: { data: trackingIDs },
                        },
                      },
                      update: (cache, { data }) => {
                        const cached_template = cache.readFragment<CampaignTemplateDetailsFragment>(
                          {
                            id: cacheIdFromObject(campaign) as string,
                            fragment: CampaignTemplateDetailsFragmentDoc,
                            fragmentName: 'CampaignTemplateDetails',
                          }
                        );
                        if (cached_template) {
                          cache.writeFragment<CampaignTemplateDetailsFragment>({
                            id: cacheIdFromObject(campaign) as string,
                            fragment: CampaignTemplateDetailsFragmentDoc,
                            fragmentName: 'CampaignTemplateDetails',
                            data: {
                              ...cached_template,
                              campaign_email_components: cached_template.campaign_email_components
                                .concat(
                                  data?.insert_campaign_email_components_one ||
                                    []
                                )
                                .sort((a, b) =>
                                  a.send_datetime.localeCompare(b.send_datetime)
                                ),
                            },
                          });
                        }
                      },
                    });
                  });
                })
                .then(() => {
                  createEmailComponentForm?.resetFields();
                  componentMergeVarsForm?.resetFields();
                  setEmailModalVisible(false);
                })
                .catch(() => {});
            }}
          >
            <Form
              form={createEmailComponentForm}
              layout="vertical"
              name="create_email_component_form"
            >
              <Form.Item
                name="send_datetime"
                label="Send Date"
                rules={[
                  {
                    required: true,
                    message: 'Please select a date',
                  },
                ]}
              >
                <DatePicker
                  showTime={{ defaultValue: dayjs('06:00:00', 'HH:mm') }}
                  format="lll z"
                  style={{ width: 236 }}
                />
              </Form.Item>
              <Form.Item
                name="email_template_id"
                label="Email Template"
                rules={[
                  {
                    required: true,
                    message: 'Please select a template',
                  },
                ]}
              >
                <EmailTemplateSelect />
              </Form.Item>
              <Form.Item
                name="patient_segment_id"
                label="Patient Segment"
                rules={[
                  {
                    required: true,
                    message: 'Please select a patient segment',
                  },
                ]}
              >
                <PatientSegmentSelect />
              </Form.Item>
              <Form.Item
                name="repeatPeriod"
                label="Recurring"
                initialValue="never"
                rules={[{ required: true }]}
                style={{
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                }}
              >
                <Select
                  options={[
                    { value: 'never', label: 'Never' },
                    { value: 'week', label: 'Weekly' },
                    { value: 'fortnight', label: 'Fortnightly' },
                    { value: 'month', label: 'Monthly' },
                  ]}
                />
              </Form.Item>
              <Form.Item
                noStyle
                dependencies={['send_datetime', 'repeatPeriod', 'repeatCount']}
              >
                {({ getFieldValue }) => {
                  const sendDatetime = getFieldValue('send_datetime');
                  const repeatPeriod = getFieldValue('repeatPeriod');
                  const repeatCount = getFieldValue('repeatCount');
                  return (
                    repeatPeriod &&
                    repeatPeriod !== 'never' && (
                      <>
                        <Form.Item
                          name="repeatCount"
                          label="For..."
                          style={{
                            display: 'inline-block',
                            width: 'calc(50% - 8px)',
                            margin: '0 8px',
                          }}
                          rules={[
                            {
                              validator(_, value) {
                                const n = parseFloat(value);
                                if (!Number.isInteger(n))
                                  return Promise.reject('Number required');
                                if (n < 2)
                                  return Promise.reject(
                                    'Must enter at least 2'
                                  );
                                if (n > MAX_REPEATS)
                                  return Promise.reject(
                                    `Maximum of ${MAX_REPEATS} recurrences allowed`
                                  );
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          {/*
                        Using Input instead of InputNumber 
                        because the latter does not support suffix at present:

                        https://github.com/ant-design/ant-design/issues/14284
                      */}
                          <Input
                            type="number"
                            min={2}
                            max={MAX_REPEATS}
                            suffix={`${repeatPeriod}(s)`}
                          />
                        </Form.Item>
                        <Form.Item>
                          {repeatCount && sendDatetime ? (
                            <>
                              This component will send on:
                              <ul>
                                {computeSendDates(
                                  sendDatetime,
                                  repeatPeriod,
                                  repeatCount
                                ).map((sendDate, i) => (
                                  <li key={i}>{sendDate.format('lll z')}</li>
                                ))}
                              </ul>
                            </>
                          ) : null}
                        </Form.Item>
                      </>
                    )
                  );
                }}
              </Form.Item>
              <Form.Item
                name="component_merge_vars"
                label="Component Variables"
              >
                <KeyValueForm form={componentMergeVarsForm} />
              </Form.Item>
              <Form.Item name="tracking_id" label="Tracking ID">
                <Input placeholder="Enter a tracking ID" />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </Space>
      <Typography.Title level={4}>
        <MessageOutlined /> SMS Components:
      </Typography.Title>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Space
          direction="vertical"
          size="middle"
          style={{ marginLeft: 16, width: '100%' }}
        >
          {campaign.campaign_sms_components
            .filter((component) => !component.is_archived)
            .map((component) => (
              <Card key={component.campaign_sms_component_id}>
                <EditableSMSComponent sms_component={component} />
              </Card>
            ))}
        </Space>
        <div style={{ textAlign: 'center' }}>
          <Button
            type="dashed"
            style={{ maxWidth: 350 }}
            onClick={() => setSMSModalVisible(true)}
          >
            <PlusOutlined /> Add SMS component <MessageOutlined />
          </Button>
          <Modal
            visible={SMSModalVisible}
            title="Add an SMS component to this campaign"
            okText="Create"
            cancelText="Cancel"
            confirmLoading={createSMSComponentMutationResult.loading}
            onCancel={() => setSMSModalVisible(false)}
            onOk={() => {
              createSMSComponentForm
                .validateFields()
                .then((values) => {
                  const mergeVars = Object.fromEntries(
                    componentMergeVarsForm?.getFieldValue('key_val_pairs')
                      ? componentMergeVarsForm
                          ?.getFieldValue('key_val_pairs')
                          .filter((x: any) => !!x && x.variableKey !== '')
                          .map((v: any) => [
                            v.variableKey,
                            v.variableValue || '',
                          ])
                      : []
                  );

                  const sendDates = computeSendDates(
                    values.send_datetime,
                    values.repeatPeriod,
                    values.repeatCount
                  );

                  const trackingIDs = values.tracking_id?.trim()
                    ? [{ tracking_id: values.tracking_id?.trim() }]
                    : [];

                  sendDates.forEach((sd) => {
                    createSMSComponentMutation({
                      variables: {
                        campaign_sms_component: {
                          campaign_template_id: campaign.campaign_template_id,
                          sms_template_id: values.sms_template_id,
                          patient_segment_id: values.patient_segment_id,
                          send_datetime: sd,
                          component_merge_vars: mergeVars,
                          tracking_ids: { data: trackingIDs },
                        },
                      },
                      update: (cache, { data }) => {
                        const cached_template = cache.readFragment<CampaignTemplateDetailsFragment>(
                          {
                            id: cacheIdFromObject(campaign) as string,
                            fragment: CampaignTemplateDetailsFragmentDoc,
                            fragmentName: 'CampaignTemplateDetails',
                          }
                        );
                        if (cached_template) {
                          cache.writeFragment<CampaignTemplateDetailsFragment>({
                            id: cacheIdFromObject(campaign) as string,
                            fragment: CampaignTemplateDetailsFragmentDoc,
                            fragmentName: 'CampaignTemplateDetails',
                            data: {
                              ...cached_template,
                              campaign_sms_components: cached_template.campaign_sms_components
                                .concat(
                                  data?.insert_campaign_sms_components_one || []
                                )
                                .sort((a, b) =>
                                  a.send_datetime.localeCompare(b.send_datetime)
                                ),
                            },
                          });
                        }
                      },
                    });
                  });
                })
                .then(() => {
                  createSMSComponentForm?.resetFields();
                  componentMergeVarsForm?.resetFields();
                  setSMSModalVisible(false);
                })
                .catch(() => {});
            }}
          >
            <Form
              form={createSMSComponentForm}
              layout="vertical"
              name="create_sms_component_form"
            >
              <Form.Item
                name="send_datetime"
                label="Send Date"
                rules={[
                  {
                    required: true,
                    message: 'Please select a date',
                  },
                ]}
              >
                <DatePicker
                  showTime={{ defaultValue: dayjs('06:00:00', 'HH:mm') }}
                  format="lll z"
                  style={{ width: 236 }}
                />
              </Form.Item>
              <Form.Item
                name="sms_template_id"
                label="SMS Template"
                rules={[
                  {
                    required: true,
                    message: 'Please select a template',
                  },
                ]}
              >
                <SMSTemplateSelect />
              </Form.Item>
              <Form.Item
                name="patient_segment_id"
                label="Patient Segment"
                rules={[
                  {
                    required: true,
                    message: 'Please select a patient segment',
                  },
                ]}
              >
                <PatientSegmentSelect />
              </Form.Item>
              <Form.Item
                name="repeatPeriod"
                label="Recurring"
                initialValue="never"
                rules={[{ required: true }]}
                style={{
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                }}
              >
                <Select
                  options={[
                    { value: 'never', label: 'Never' },
                    { value: 'week', label: 'Weekly' },
                    { value: 'fortnight', label: 'Fortnightly' },
                    { value: 'month', label: 'Monthly' },
                  ]}
                />
              </Form.Item>
              <Form.Item
                noStyle
                dependencies={['send_datetime', 'repeatPeriod', 'repeatCount']}
              >
                {({ getFieldValue }) => {
                  const sendDatetime = getFieldValue('send_datetime');
                  const repeatPeriod = getFieldValue('repeatPeriod');
                  const repeatCount = getFieldValue('repeatCount');
                  return (
                    repeatPeriod &&
                    repeatPeriod !== 'never' && (
                      <>
                        <Form.Item
                          name="repeatCount"
                          label="For..."
                          style={{
                            display: 'inline-block',
                            width: 'calc(50% - 8px)',
                            margin: '0 8px',
                          }}
                          rules={[
                            {
                              validator(_, value) {
                                const n = parseFloat(value);
                                if (!Number.isInteger(n))
                                  return Promise.reject('Number required');
                                if (n < 2)
                                  return Promise.reject(
                                    'Must enter at least 2'
                                  );
                                if (n > MAX_REPEATS)
                                  return Promise.reject(
                                    `Maximum of ${MAX_REPEATS} recurrences allowed`
                                  );
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          {/*
                        Using Input instead of InputNumber 
                        because the latter does not support suffix at present:

                        https://github.com/ant-design/ant-design/issues/14284
                      */}
                          <Input
                            type="number"
                            min={2}
                            max={MAX_REPEATS}
                            suffix={`${repeatPeriod}(s)`}
                          />
                        </Form.Item>
                        <Form.Item>
                          {repeatCount && sendDatetime ? (
                            <>
                              This component will send on:
                              <ul>
                                {computeSendDates(
                                  sendDatetime,
                                  repeatPeriod,
                                  repeatCount
                                ).map((sendDate, i) => (
                                  <li key={i}>{sendDate.format('lll z')}</li>
                                ))}
                              </ul>
                            </>
                          ) : null}
                        </Form.Item>
                      </>
                    )
                  );
                }}
              </Form.Item>
              <Form.Item
                name="component_merge_vars"
                label="Component Variables"
              >
                <KeyValueForm form={componentMergeVarsForm} />
              </Form.Item>
              <Form.Item name="tracking_id" label="Tracking ID">
                <Input placeholder="Enter a tracking ID" />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </Space>
    </>
  );
};

const EditableLetterComponent: React.FC<{
  letter_component: CampaignLetterComponentDetailsFragment;
}> = ({ letter_component }) => {
  const [mergeVarsForm] = Form.useForm();
  const [mergeVarModalVisible, setMergeVarModalVisible] = useState(false);

  const [
    updateLetterComponentMutation,
    updateLetterComponentMutationResult,
  ] = useUpdateCampaignLetterComponentMutation();

  const handleLetterComponentUpdate = (
    changes: Campaign_Letter_Components_Set_Input
  ) => {
    const changedFields = Object.keys(changes) as Array<
      keyof Campaign_Letter_Components_Set_Input
    >;
    if (
      changedFields.some(
        (k) =>
          (letter_component as any)[k] !== changes[k] &&
          changes[k] !== undefined
      )
    ) {
      updateLetterComponentMutation({
        variables: {
          campaign_letter_component_id:
            letter_component.campaign_letter_component_id,
          changes: changes,
        },
        optimisticResponse: {
          __typename: 'mutation_root',
          update_campaign_letter_components_by_pk: {
            ...(letter_component as any),
            ...changes,
          },
        },
      })
        .then(() => {
          if (mergeVarModalVisible) setMergeVarModalVisible(false);
        })
        .then(
          () => message.success('Saved'),
          () => message.error('Failed to save')
        );
    }
  };

  return (
    <Row
      key={letter_component.campaign_letter_component_id}
      align="middle"
      gutter={24}
    >
      <Col flex="auto">
        <Space direction="vertical">
          <Row gutter={12} align="middle">
            <Col>On</Col>
            <Col>
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                format="lll z"
                style={{ width: 236 }}
                allowClear={false}
                defaultValue={
                  dayjs(letter_component.send_datetime).isValid()
                    ? dayjs(letter_component.send_datetime)
                    : undefined
                }
                onChange={(date) => {
                  handleLetterComponentUpdate({
                    send_datetime: date?.format(),
                  });
                }}
              />
            </Col>
          </Row>
          <Row gutter={12} align="middle">
            <Col>Send</Col>
            <Col flex="auto">
              <LetterTemplateSelect
                style={{ width: 650 }}
                defaultValue={letter_component.letter_template_id}
                onChange={(value) => {
                  handleLetterComponentUpdate({
                    letter_template_id: value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row gutter={12} align="middle">
            <Col>To</Col>
            <Col>
              <PatientSegmentSelect
                style={{ width: 650 }}
                defaultValue={letter_component.patient_segment_id}
                onChange={(value) => {
                  handleLetterComponentUpdate({
                    patient_segment_id: value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row gutter={12} align="middle">
            <Col>Return Envelope ID: </Col>
            <Col>
              <Input
                style={{ width: 250 }}
                disabled={!letter_component.letter_template.perforated_page}
                placeholder="Leave blank if no return envelope"
                defaultValue={letter_component.return_envelope_id || undefined}
                onBlur={(e) =>
                  handleLetterComponentUpdate({
                    return_envelope_id: e.target.value || null,
                  })
                }
              />
            </Col>
          </Row>
          <Row gutter={12} align="middle">
            <Col>Send First Class?</Col>
            <Col>
              <Checkbox
                defaultChecked={letter_component.is_first_class}
                onChange={(e) =>
                  handleLetterComponentUpdate({
                    is_first_class: e.target.checked,
                  })
                }
              />
            </Col>
          </Row>
          <Row gutter={12} align="middle">
            <Col>Skip Undeliverable Addresses?</Col>
            <Col>
              <Checkbox
                defaultChecked={letter_component.skip_undeliverable_addresses}
                onChange={(e) =>
                  handleLetterComponentUpdate({
                    skip_undeliverable_addresses: e.target.checked,
                  })
                }
              />
            </Col>
          </Row>
          <Row gutter={12} align="middle">
            <Col>Component-Level Variables: </Col>
            <Col>
              <Tooltip
                title={
                  <pre>
                    {JSON.stringify(
                      letter_component.component_merge_vars || {},
                      null,
                      2
                    )}
                  </pre>
                }
              >
                <Typography.Text
                  ellipsis
                  code
                  editable={{
                    tooltip: false,
                    editing: false,
                    onStart: () => setMergeVarModalVisible(true),
                  }}
                  style={{ maxWidth: 500, display: 'inline' }}
                >
                  {JSON.stringify(letter_component.component_merge_vars || {})}
                </Typography.Text>
              </Tooltip>
            </Col>
            <Modal
              visible={mergeVarModalVisible}
              title="Modify component-level variables"
              okText="Save"
              confirmLoading={updateLetterComponentMutationResult.loading}
              cancelText="Cancel"
              onCancel={() => {
                mergeVarsForm.resetFields();
                setMergeVarModalVisible(false);
              }}
              onOk={mergeVarsForm.submit}
            >
              <KeyValueForm
                form={mergeVarsForm}
                initialValues={letter_component.component_merge_vars}
                onFinish={(vars) => {
                  handleLetterComponentUpdate({ component_merge_vars: vars });
                }}
              />
            </Modal>
          </Row>
          <Row gutter={12} align="middle">
            <Col>Tracking IDs: </Col>
            <Col>
              <TrackingTagEditor
                component_id={letter_component.campaign_letter_component_id}
                tracking_ids={letter_component.tracking_ids.map(
                  (t) => t.tracking_id
                )}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: 8 }}>
            <Typography.Text type="secondary">
              <small>{letter_component.campaign_letter_component_id}</small>
            </Typography.Text>
          </Row>
        </Space>
      </Col>
      <Col>
        <Button
          icon={<DeleteOutlined />}
          danger
          type="default"
          onClick={() => {
            handleLetterComponentUpdate({
              is_archived: true,
            });
          }}
        >
          Remove
        </Button>
      </Col>
    </Row>
  );
};

const EditableEmailComponent: React.FC<{
  email_component: CampaignEmailComponentDetailsFragment;
}> = ({ email_component }) => {
  const [mergeVarsForm] = Form.useForm();
  const [mergeVarModalVisible, setMergeVarModalVisible] = useState(false);

  const [
    updateEmailComponentMutation,
    updateEmailComponentMutationResult,
  ] = useUpdateCampaignEmailComponentMutation();

  const handleEmailComponentUpdate = (
    changes: Campaign_Email_Components_Set_Input
  ) => {
    const changedFields = Object.keys(changes) as Array<
      keyof Campaign_Email_Components_Set_Input
    >;
    if (
      changedFields.some(
        (k) =>
          (email_component as any)[k] !== changes[k] && changes[k] !== undefined
      )
    ) {
      updateEmailComponentMutation({
        variables: {
          campaign_email_component_id:
            email_component.campaign_email_component_id,
          changes: changes,
        },
        optimisticResponse: {
          __typename: 'mutation_root',
          update_campaign_email_components_by_pk: {
            ...(email_component as any),
            ...changes,
          },
        },
      })
        .then(() => {
          if (mergeVarModalVisible) setMergeVarModalVisible(false);
        })
        .then(
          () => message.success('Saved'),
          () => message.error('Failed to save')
        );
    }
  };

  return (
    <>
      <Row
        key={email_component.campaign_email_component_id}
        align="middle"
        gutter={24}
      >
        <Col flex="auto">
          <Space direction="vertical">
            <Row gutter={12} align="middle">
              <Col>On</Col>
              <Col>
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  format="lll z"
                  style={{ width: 236 }}
                  allowClear={false}
                  defaultValue={
                    dayjs(email_component.send_datetime).isValid()
                      ? dayjs(email_component.send_datetime)
                      : undefined
                  }
                  onChange={(date) => {
                    handleEmailComponentUpdate({
                      send_datetime: date?.format(),
                    });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={12} align="middle">
              <Col>Send</Col>
              <Col flex="auto">
                <EmailTemplateSelect
                  style={{ width: 650 }}
                  defaultValue={email_component.email_template_id}
                  onChange={(value) => {
                    handleEmailComponentUpdate({
                      email_template_id: value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={12} align="middle">
              <Col>To</Col>
              <Col>
                <PatientSegmentSelect
                  style={{ width: 650 }}
                  defaultValue={email_component.patient_segment_id}
                  onChange={(value) => {
                    handleEmailComponentUpdate({
                      patient_segment_id: value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={12} align="middle">
              <Col>Component-Level Variables:</Col>
              <Col>
                <Tooltip
                  title={
                    <pre>
                      {JSON.stringify(
                        email_component.component_merge_vars || {},
                        null,
                        2
                      )}
                    </pre>
                  }
                >
                  <Typography.Text
                    ellipsis
                    code
                    editable={{
                      tooltip: false,
                      editing: false,
                      onStart: () => setMergeVarModalVisible(true),
                    }}
                    style={{ maxWidth: 500, display: 'inline' }}
                  >
                    {JSON.stringify(email_component.component_merge_vars || {})}
                  </Typography.Text>
                </Tooltip>
              </Col>
              <Modal
                visible={mergeVarModalVisible}
                title="Modify component-level variables"
                okText="Save"
                confirmLoading={updateEmailComponentMutationResult.loading}
                cancelText="Cancel"
                onCancel={() => {
                  mergeVarsForm.resetFields();
                  setMergeVarModalVisible(false);
                }}
                onOk={mergeVarsForm.submit}
              >
                <KeyValueForm
                  form={mergeVarsForm}
                  initialValues={email_component.component_merge_vars}
                  onFinish={(vars) => {
                    handleEmailComponentUpdate({ component_merge_vars: vars });
                  }}
                />
              </Modal>
            </Row>
            <Row gutter={12} align="middle">
              <Col>Tracking IDs: </Col>
              <Col>
                <TrackingTagEditor
                  component_id={email_component.campaign_email_component_id}
                  tracking_ids={email_component.tracking_ids.map(
                    (t) => t.tracking_id
                  )}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: 8 }}>
              <Typography.Text type="secondary">
                <small>{email_component.campaign_email_component_id}</small>
              </Typography.Text>
            </Row>
          </Space>
        </Col>
        <Col>
          <Button
            icon={<DeleteOutlined />}
            danger
            type="default"
            onClick={() => {
              handleEmailComponentUpdate({
                is_archived: true,
              });
            }}
          >
            Remove
          </Button>
        </Col>
      </Row>
    </>
  );
};

const EditableSMSComponent: React.FC<{
  sms_component: CampaignSmsComponentDetailsFragment;
}> = ({ sms_component }) => {
  const [mergeVarsForm] = Form.useForm();
  const [mergeVarModalVisible, setMergeVarModalVisible] = useState(false);

  const [
    updateSMSComponentMutation,
    updateSMSComponentMutationResult,
  ] = useUpdateCampaignSmsComponentMutation();

  const handleSMSComponentUpdate = (
    changes: Campaign_Sms_Components_Set_Input
  ) => {
    const changedFields = Object.keys(changes) as Array<
      keyof Campaign_Sms_Components_Set_Input
    >;
    if (
      changedFields.some(
        (k) =>
          (sms_component as any)[k] !== changes[k] && changes[k] !== undefined
      )
    ) {
      updateSMSComponentMutation({
        variables: {
          campaign_sms_component_id: sms_component.campaign_sms_component_id,
          changes: changes,
        },
        optimisticResponse: {
          __typename: 'mutation_root',
          update_campaign_sms_components_by_pk: {
            ...(sms_component as any),
            ...changes,
          },
        },
      })
        .then(() => {
          if (mergeVarModalVisible) setMergeVarModalVisible(false);
        })
        .then(
          () => message.success('Saved'),
          () => message.error('Failed to save')
        );
    }
  };

  return (
    <>
      <Row
        key={sms_component.campaign_sms_component_id}
        align="middle"
        gutter={24}
      >
        <Col flex="auto">
          <Space direction="vertical">
            <Row gutter={12} align="middle">
              <Col>On</Col>
              <Col>
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  format="lll z"
                  style={{ width: 236 }}
                  allowClear={false}
                  defaultValue={
                    dayjs(sms_component.send_datetime).isValid()
                      ? dayjs(sms_component.send_datetime)
                      : undefined
                  }
                  onChange={(date) => {
                    handleSMSComponentUpdate({
                      send_datetime: date?.format(),
                    });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={12} align="middle">
              <Col>Send</Col>
              <Col flex="auto">
                <SMSTemplateSelect
                  style={{ width: 650 }}
                  defaultValue={sms_component.sms_template_id}
                  onChange={(value) => {
                    handleSMSComponentUpdate({
                      sms_template_id: value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={12} align="middle">
              <Col>To</Col>
              <Col>
                <PatientSegmentSelect
                  style={{ width: 650 }}
                  defaultValue={sms_component.patient_segment_id}
                  onChange={(value) => {
                    handleSMSComponentUpdate({
                      patient_segment_id: value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={12} align="middle">
              <Col>Component-Level Variables:</Col>
              <Col>
                <Tooltip
                  title={
                    <pre>
                      {JSON.stringify(
                        sms_component.component_merge_vars || {},
                        null,
                        2
                      )}
                    </pre>
                  }
                >
                  <Typography.Text
                    ellipsis
                    code
                    editable={{
                      tooltip: false,
                      editing: false,
                      onStart: () => setMergeVarModalVisible(true),
                    }}
                    style={{ maxWidth: 500, display: 'inline' }}
                  >
                    {JSON.stringify(sms_component.component_merge_vars || {})}
                  </Typography.Text>
                </Tooltip>
              </Col>
              <Modal
                visible={mergeVarModalVisible}
                title="Modify component-level variables"
                okText="Save"
                confirmLoading={updateSMSComponentMutationResult.loading}
                cancelText="Cancel"
                onCancel={() => {
                  mergeVarsForm.resetFields();
                  setMergeVarModalVisible(false);
                }}
                onOk={mergeVarsForm.submit}
              >
                <KeyValueForm
                  form={mergeVarsForm}
                  initialValues={sms_component.component_merge_vars}
                  onFinish={(vars) => {
                    handleSMSComponentUpdate({
                      component_merge_vars: vars,
                    });
                  }}
                />
              </Modal>
            </Row>
            <Row gutter={12} align="middle">
              <Col>Tracking IDs: </Col>
              <Col>
                <TrackingTagEditor
                  component_id={sms_component.campaign_sms_component_id}
                  tracking_ids={sms_component.tracking_ids.map(
                    (t) => t.tracking_id
                  )}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: 8 }}>
              <Typography.Text type="secondary">
                <small>{sms_component.campaign_sms_component_id}</small>
              </Typography.Text>
            </Row>
          </Space>
        </Col>
        <Col>
          <Button
            icon={<DeleteOutlined />}
            danger
            type="default"
            onClick={() => {
              handleSMSComponentUpdate({
                is_archived: true,
              });
            }}
          >
            Remove
          </Button>
        </Col>
      </Row>
    </>
  );
};

const TrackingTagEditor: React.FC<{
  component_id: string;
  tracking_ids: string[];
}> = ({ component_id, tracking_ids }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [createTrackingIDMutation] = useCreateComponentTrackingIdMutation();
  const [deleteTrackingIDMutation] = useDeleteComponentTrackingIdMutation();

  const createTrackingId = (id: string) => {
    const stripped_id = id.trim();
    if (stripped_id && !tracking_ids.includes(stripped_id)) {
      createTrackingIDMutation({
        variables: { component_id: component_id, tracking_id: stripped_id },
      });
    }
    setIsEditing(false);
  };
  const deleteTrackingId = (id: string) => {
    deleteTrackingIDMutation({
      variables: { component_id: component_id, tracking_id: id },
    });
  };

  return (
    <Space>
      {tracking_ids.map((tracking_id) => (
        <Tag
          key={tracking_id}
          closable
          onClose={() => deleteTrackingId(tracking_id)}
        >
          {tracking_id}
        </Tag>
      ))}
      {isEditing ? (
        <Input
          autoFocus
          size="small"
          onBlur={(e) => createTrackingId(e.target.value)}
          onPressEnter={(e) => createTrackingId(e.currentTarget.value)}
        />
      ) : (
        <Button
          type="dashed"
          size="small"
          icon={<PlusOutlined />}
          onClick={() => setIsEditing(true)}
        >
          New Tracking ID
        </Button>
      )}
    </Space>
  );
};

const PatientSegmentSelect: React.FC<SelectProps<string>> = React.forwardRef(
  (props, ref) => {
    const patientSegmentQueryResult = useListPatientSegmentsQuery();
    return (
      <Select
        showSearch
        optionFilterProp="label"
        placeholder="Select a patient segment"
        //dropdownMatchSelectWidth={false}
        dropdownClassName="select-wrap-options"
        {...props}
        options={(patientSegmentQueryResult.data?.patient_segments || []).map(
          (segment) => {
            return {
              label: segment.patient_segment_description,
              value: segment.patient_segment_id,
            };
          }
        )}
      />
    );
  }
);

const LetterTemplateSelect: React.FC<SelectProps<string>> = React.forwardRef(
  (props, ref) => {
    const letterTemplatesQueryResult = useListLetterTemplatesQuery();
    return (
      <Select
        showSearch
        optionFilterProp="label"
        placeholder="Select a letter template"
        {...props}
        options={(letterTemplatesQueryResult.data?.letter_templates || []).map(
          (template) => {
            return {
              label: template.letter_template_name,
              value: template.letter_template_id,
            };
          }
        )}
      />
    );
  }
);

const EmailTemplateSelect: React.FC<SelectProps<string>> = React.forwardRef(
  (props, ref) => {
    const emailTemplatesQueryResult = useListEmailTemplatesQuery();
    return (
      <Select
        showSearch
        optionFilterProp="label"
        placeholder="Select an email template"
        {...props}
        options={(emailTemplatesQueryResult.data?.email_templates || []).map(
          (template) => {
            return {
              label: template.email_template_name,
              value: template.email_template_id,
            };
          }
        )}
      />
    );
  }
);

const SMSTemplateSelect: React.FC<SelectProps<string>> = React.forwardRef(
  (props, ref) => {
    const SMSTemplatesQueryResult = useListSmsTemplatesQuery();
    return (
      <Select
        showSearch
        optionFilterProp="label"
        placeholder="Select an email template"
        {...props}
        options={(SMSTemplatesQueryResult.data?.sms_templates || []).map(
          (template) => {
            return {
              label: template.sms_template_name,
              value: template.sms_template_id,
            };
          }
        )}
      />
    );
  }
);

export default CampaignEditPage;
