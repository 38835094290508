import React from 'react';

import { Button, Col, Form, FormProps, Input, Row } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

const variablesToFormVals = (jsonObj: { [key: string]: any } | undefined) => {
  return {
    key_val_pairs:
      jsonObj && Object.keys(jsonObj).length
        ? Object.entries(jsonObj).map(([k, v]) => ({
            variableKey: k,
            variableValue: v,
          }))
        : // If no entries exist, use an empty key/value pair
          // looks a bit nicer, and will get flagged if user attempts to submit
          [{ variableKey: '', variableValue: '' }],
  };
};

const formValsToVariables = (values: any) =>
  Object.fromEntries(
    values.key_val_pairs
      .filter((x: any) => !!x)
      .map((v: any) => [v.variableKey, v.variableValue || ''])
  );

const KeyValueForm: React.FC<FormProps> = ({
  initialValues,
  onFinish,
  ...props
}) => {
  return (
    <Form
      {...props}
      initialValues={variablesToFormVals(initialValues)}
      onFinish={(values: any) => {
        if (onFinish) onFinish(formValsToVariables(values));
      }}
    >
      <Form.List name="key_val_pairs">
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field, key_index) => (
                <Row gutter={16} key={field.key}>
                  <Col flex="auto">
                    <Form.Item
                      {...field}
                      name={[field.name, 'variableKey']}
                      fieldKey={[field.fieldKey, 'variableKey']}
                      rules={[
                        { required: true, message: 'Missing variable key' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              value &&
                              (getFieldValue('key_val_pairs') as any[])
                                .map((p) => p?.variableKey)
                                .filter((_, i) => i !== key_index)
                                .includes(value)
                            ) {
                              return Promise.reject(
                                new Error(`Key '${value}' already appears`)
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <Input placeholder="Key" />
                    </Form.Item>
                  </Col>
                  <Col flex="auto">
                    <Form.Item
                      {...field}
                      name={[field.name, 'variableValue']}
                      fieldKey={[field.fieldKey, 'variableValue']}
                    >
                      <Input placeholder="Value" />
                    </Form.Item>
                  </Col>
                  <Col flex="48px">
                    <Button
                      icon={<CloseOutlined />}
                      type="text"
                      onClick={() => remove(field.name)}
                    />
                  </Col>
                </Row>
              ))}
              <Row gutter={16}>
                <Col flex="auto">
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Variable
                    </Button>
                  </Form.Item>
                </Col>
                <Col flex="48px" />
              </Row>
            </>
          );
        }}
      </Form.List>
    </Form>
  );
};

export default KeyValueForm;
export { variablesToFormVals };
